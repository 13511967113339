import React from "react";
import {
  SHOW_COMMON_KEY,
  ALL_EQUIPMENT_KEY,
  SHOW_COMMON_CLUSTER_AUDIT_TRAIL_KEY,
  SHOW_COMMON_CLUSTER_DELETE_KEY,
  AUDIT_ACTIONS,
  entryType,
  CORELAB,
  RMDSAP
} from "../../constants";
import { find } from "lodash";
import styled from "styled-components";
import { Grid } from "@mui/material";
import { OwcTooltip } from "@one/react";

const ItemValueStyled = styled.span`
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
`;

const ShowEquipmentInfo = ({ equipmentDetail }) => {
  let finalArr = [...SHOW_COMMON_KEY];
  if (equipmentDetail?.entryType === entryType?.cluster) finalArr = [...SHOW_COMMON_CLUSTER_DELETE_KEY];

  if (
    equipmentDetail?.action === AUDIT_ACTIONS.CLUSTER_CREATE ||
    equipmentDetail?.action === AUDIT_ACTIONS.CLUSTER_DELETE ||
    equipmentDetail?.action === AUDIT_ACTIONS.CLUSTER_UPDATE
  )
    finalArr = [...SHOW_COMMON_CLUSTER_AUDIT_TRAIL_KEY];

  if (equipmentDetail?.linkedInstanceRef?.linkedInstance === "None") {
    finalArr.push(find(ALL_EQUIPMENT_KEY, { key: "materialNumber" }));
  } else if (equipmentDetail?.linkedInstanceRef?.secondaryIdMapping) {
    const fieldsName = equipmentDetail?.linkedInstanceRef?.secondaryIdMapping.split("; ");
    if (equipmentDetail?.entryType !== entryType?.cluster && typeof equipmentDetail?.entryType !== "undefined") {
      fieldsName?.forEach((item) => {
        if (!find(SHOW_COMMON_KEY, { key: item })) {
          finalArr.push(find(ALL_EQUIPMENT_KEY, { key: item }));
        }
      });
    } else if (
      equipmentDetail?.linkedInstanceRef?.linkedInstance === CORELAB ||
      equipmentDetail?.linkedInstanceRef?.linkedInstance === RMDSAP
    ) {
      fieldsName?.forEach((item) => {
        if (!find(SHOW_COMMON_KEY, { key: item })) {
          finalArr.push(find(ALL_EQUIPMENT_KEY, { key: item }));
        }
      });
    }
  }

  return (
    <div>
      <Grid container spacing={1}>
        {finalArr?.map((item) => {
          const isValue =
            equipmentDetail[item.key] && equipmentDetail[item.key] !== "null" ? equipmentDetail[item.key] : "-";
          return (
            <Grid container item xs={4} style={{ fontSize: 16, color: "#333" }} key={item.label} id={item.label}>
              {item.label}:
              <ItemValueStyled id="ClusterDecommission-ShowEquipmentInfo-ItemValueStyled">
                &nbsp;
                {isValue}
              </ItemValueStyled>
              <OwcTooltip anchor={item.label}>{isValue}</OwcTooltip>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default ShowEquipmentInfo;
