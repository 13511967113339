import React, { useContext } from "react";
import { ConfirmDialog } from "../../components/shared";
import { CreateFieldStyled } from "./ReasonMainPageStyle";
import { useFormikContext } from "formik";
import { commonPropsForInputsWithoutValue } from "../instruments/helpers";
import { ReasonContext } from "./context";
import { OwcButton, OwcInput } from "@one/react";
import { submit } from "./ReasonHelper";
import { useState } from "react";
import { PaginationContext } from "../../components/shared/pagination/PaginationContext";
import { compose } from "redux";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";

const ReasonDialog = ({ open, isEditMode, onCancel, client }) => {
  const formik = useFormikContext();
  const { currentReason, setDialogOpen } = useContext(ReasonContext);
  const [loading, setLoading] = useState(false);
  const { dispatchAction } = useContext(PaginationContext);

  return (
    <ConfirmDialog
      open={open}
      close={onCancel}
      approveText={isEditMode ? "Update" : "Add"}
      approveColor="primary"
      approveVariant="contained"
      cancelText="Cancel"
      cancelVariant="outlined"
      cancelColor="primary"
      onCancel={onCancel}
      isDivider
      testid={"import-file-dialog"}
      title={isEditMode ? "Edit reason" : "Add reason"}
      renderActions={({ cancelColor, cancelVariant, approveColor, approveVariant, cancelText, approveText }) => (
        <div style={{ display: "flex" }}>
          <OwcButton
            data-testid="confirm-dialog-actions-button-cancel"
            onClick={onCancel}
            variant="secondary"
            style={{ marginRight: 32 }}
          >
            {cancelText}
          </OwcButton>
          <OwcButton
            data-testid="confirm-dialog-actions-button-approve"
            onClick={async () => {
              await submit({
                dispatchAction,
                client,
                setLoading,
                formik,
                isEditMode,
                setDialogOpen
              });
            }}
            variant="primary"
            disabled={
              loading ||
              !formik.isValid ||
              formik.values?.configText.trim() === "" ||
              (isEditMode && formik.values?.configText === currentReason)
            }
          >
            {approveText}
          </OwcButton>
        </div>
      )}
    >
      <CreateFieldStyled>
        <OwcInput
          data-testid={`text-field-reason-name`}
          label={isEditMode ? "Edit reason" : "Add reason"}
          className="TextFieldInput"
          name="configText"
          value={formik.values?.configText}
          onInputChange={formik.handleChange}
          onBlur={formik.handleBlur}
          required
          {...commonPropsForInputsWithoutValue({
            formik,
            key: "configText",
            dataTestIdKey: "configText",
            dataTestIdForFormKey: "text-field"
          })}
          style={{ width: "100%" }}
          multiline
          rows={2}
        />
      </CreateFieldStyled>
    </ConfirmDialog>
  );
};

// export default ReasonDialog;
const mapStateToProps = (state) => ({
  user: state.user
});
export default compose(connect(mapStateToProps), withApollo)(ReasonDialog);
