import React, { useState } from "react";
import { ReasonContext } from "./context";

const ReasonWapper = ({ children }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogCancelOpen, setDialogCancelOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentReason, setCurrentReason] = useState({});

  return (
    <ReasonContext.Provider
      value={{
        dialogOpen,
        setDialogOpen,
        isEditMode,
        setIsEditMode,
        dialogCancelOpen,
        setDialogCancelOpen,
        currentReason,
        setCurrentReason
      }}
    >
      {children}
    </ReasonContext.Provider>
  );
};

export default ReasonWapper;
