import { OwcTypography } from "@one/react";
import React from "react";
import styled from "styled-components";
import ImportTabs from "./ImportTabs";

export const MainViewStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  flex-grow: 1;
  overflow: auto;
`;

const ImportMainView = () => {
  return (
    <MainViewStyled>
      <OwcTypography variant="title5">Data import</OwcTypography>
      <ImportTabs />
    </MainViewStyled>
  );
};

export default ImportMainView;
