import { OwcTypography, OwcGrid } from "@one/react";
import { OwcInputWithFormik } from "../../components/shared/OwcInputWithFormik";
import { OwcSelectWithChip } from "../../components/shared/OwcSelectWitchChip";
import {
  LIST_IR_CATEGORY_MAPPINGS_MINIMUM,
  LIST_IR_CLUSTER_CATEGORY_MAPPINGS_MINIMUM,
  LIST_IR_GROUP_MASTER,
  LIST_SITES
} from "../../gql/landingapi";
import { themeMapping } from "@one/web-components";
import { THEME_NAME } from "../../constants";

const THEME = themeMapping[THEME_NAME];
/**
 * Fields for Checklist modal
 * @param {object} props
 * @param {object} props.formik - Formik state and methods
 */

export const ChecklistForm = ({ formik }) => (
  <div style={{ transform: `translate(-${THEME["one-spacer-12"]})` }}>
    <OwcGrid columns={12} container hGutter={THEME["one-spacer-12"]}>
      <OwcGrid item xs="12">
        <OwcTypography variant="subtitle2">Link</OwcTypography>
      </OwcGrid>
      <OwcGrid item xs="12" m="6" style={{ alignSelf: "start", minHeight: 76 }}>
        <OwcInputWithFormik
          formik={formik}
          dataKey="description"
          label="Description *"
          type="textarea"
          maxLength={THEME["one-spacer-40"]}
          required
          assistiveText="E.g. Site Group checklist"
        />
      </OwcGrid>
      <OwcGrid item xs="12" s="6" style={{ alignSelf: "start", minHeight: 76 }}>
        <OwcInputWithFormik label="URL *" dataKey="url" type="textarea" formik={formik} required />
      </OwcGrid>

      <OwcGrid item xs="12" style={{ marginTop: THEME["one-spacer-12"] }}>
        <OwcTypography variant="subtitle3">Equipment definition</OwcTypography>
      </OwcGrid>
      <OwcGrid item xs="12" s="6" m="4" style={{ alignSelf: "start" }}>
        <OwcSelectWithChip
          formik={formik}
          dataKey="models"
          label="Model(s) *"
          queryDetails={[
            {
              query: LIST_IR_CATEGORY_MAPPINGS_MINIMUM,
              dataPath: ["data", "listIRCategoryMappings"],
              responseDataKey: "equipmentModel"
            },
            {
              query: LIST_IR_CLUSTER_CATEGORY_MAPPINGS_MINIMUM,
              dataPath: ["data", "listIRClusterCategoryMappings"],
              responseDataKey: "equipmentModel"
            }
          ]}
        />
      </OwcGrid>
      <OwcGrid item xs="12" s="6" m="4" style={{ alignSelf: "start" }}>
        <OwcSelectWithChip
          formik={formik}
          dataKey="sites"
          label="Site(s) *"
          queryDetails={{
            query: LIST_SITES,
            dataPath: ["data", "listSites"],
            responseDataKey: "siteName"
          }}
        />
      </OwcGrid>
      <OwcGrid item xs="12" s="6" m="4" style={{ alignSelf: "start" }}>
        <OwcSelectWithChip
          formik={formik}
          dataKey="groups"
          label="Group(s) *"
          queryDetails={{
            query: LIST_IR_GROUP_MASTER,
            dataPath: ["data", "listIRGroupMasters"],
            responseDataKey: "value"
          }}
        />
      </OwcGrid>
    </OwcGrid>
  </div>
);
