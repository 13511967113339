import React from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";
import { ReasonContext } from "./context";
import { useContext } from "react";
import { OwcButton } from "@one/react";
import { ADD_REASON } from "../../constants";
import { addButtonClick } from "./ReasonHelper";

export const TopBarButtons = styled.div`
  display: flex;
  margin-left: auto;
`;
const TopBarButton = () => {
  const formik = useFormikContext();
  const { setIsEditMode, setDialogOpen } = useContext(ReasonContext);
  return (
    <TopBarButtons data-testid="Top-Bar-Button-id">
      <OwcButton
        key="create-reason-button"
        data-testid="Top-Bar-Add-Reason-id"
        onClick={async () => {
          await addButtonClick({
            formik,
            setIsEditMode,
            setDialogOpen
          });
        }}
        variant="primary"
      >
        {ADD_REASON}
      </OwcButton>
    </TopBarButtons>
  );
};

export default TopBarButton;
