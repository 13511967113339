import styled from "styled-components";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useFormikContext } from "formik";
import { entryType } from "../../../constants";
import { OwcListItem, OwcSelectDropdown, OwcSlideToggle, OwcTypography } from "@one/react";
import { defaultInputStyle } from "../../../utils/helpers/text";

const BookMonitorStyled = styled.div`
  display: flex;
`;

const PeriodWrapper = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  gap: 0.5rem;
`;

const DropdownSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FORM_FIELD = Object.freeze({
  IS_BOOKABLE: "isBookable",
  CHECK_IN_GRACE_PERIOD: "checkInGracePeriod",
  IS_VISUALIZED: "isVisualized",
  CHECK_IN_REQUIRED: "checkInRequired",
  CHECK_IN_START_PERIOD: "checkInStartPeriod"
});

const checkInOptions = Object.freeze([
  { value: "15", label: "15 min" },
  { value: "30", label: "30 min" },
  { value: "45", label: "45 min" },
  { value: "60", label: "60 min" },
  { value: "120", label: "120 min" }
]);

const DigitalLabStep = () => {
  const formik = useFormikContext();
  const [isBookable, setIsBookable] = useState(formik?.values?.isBookable ?? false);
  const [isVisualized, setIsVisualized] = useState(formik?.values?.isVisualized ?? false);
  const [checkInRequired, setCheckInRequired] = useState(formik?.values?.checkInRequired ?? null);
  const [checkInStartPeriod, setCheckInStartPeriod] = useState(formik?.values?.checkInStartPeriod ?? null);
  const [checkInGracePeriod, setCheckInGracePeriod] = useState(formik?.values?.checkInGracePeriod ?? null);

  const setFormFieldValue = useCallback(
    async (field, value, validate = true) => {
      await formik.setFieldValue(field, value, validate);
    },
    [formik]
  );

  const renderedCheckInOptions = checkInOptions?.map((option) => (
    <OwcListItem value={option.value} key={option.value}>
      {option.label}
    </OwcListItem>
  ));

  useEffect(() => {
    const setFormValues = async () => {
      await setFormFieldValue(FORM_FIELD.IS_BOOKABLE, isBookable);
      await setFormFieldValue(FORM_FIELD.IS_VISUALIZED, isVisualized);
      await setFormFieldValue(FORM_FIELD.CHECK_IN_REQUIRED, checkInRequired);
      await setFormFieldValue(FORM_FIELD.CHECK_IN_START_PERIOD, checkInStartPeriod);
      await setFormFieldValue(FORM_FIELD.CHECK_IN_GRACE_PERIOD, checkInGracePeriod);
    };
    setFormValues().catch((error) => {
      console.error(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (checkInRequired) {
      setCheckInStartPeriod(checkInStartPeriod ?? checkInOptions[0].value);
      setFormFieldValue(FORM_FIELD.CHECK_IN_START_PERIOD, checkInStartPeriod ?? checkInOptions[0].value);
      setCheckInGracePeriod(checkInGracePeriod ?? checkInOptions[1].value);
      setFormFieldValue(FORM_FIELD.CHECK_IN_GRACE_PERIOD, checkInGracePeriod ?? checkInOptions[1].value);
    } else {
      setCheckInStartPeriod(null);
      setFormFieldValue(FORM_FIELD.CHECK_IN_START_PERIOD, undefined);
      setCheckInGracePeriod(null);
      setFormFieldValue(FORM_FIELD.CHECK_IN_GRACE_PERIOD, undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkInRequired]);

  return (
    <>
      <OwcTypography variant="subtitle2" style={{ padding: "10px 20px 20px 0" }}>
        Availability
      </OwcTypography>
      <BookMonitorStyled>
        <OwcTypography variant="subtitle1" style={{ marginRight: "36px" }} data-testid={`doc-data-isbookable`}>
          <OwcSlideToggle
            data-testid={`toggle-field-booking`}
            checked={isBookable}
            disabled={formik?.values?.entryType === entryType.clusterSubequipment}
            onInputChange={async (event) => {
              const checked = event.target.checked;
              await setIsBookable(checked);
              await setFormFieldValue(FORM_FIELD.IS_BOOKABLE, checked);
            }}
            labelPlacement="left"
          >
            Available in Booking ?
          </OwcSlideToggle>
        </OwcTypography>
        <OwcTypography variant="subtitle1" id="testtypo" data-testid={`doc-data-isVisualized`}>
          <OwcSlideToggle
            data-testid={`toggle-field-visualized`}
            checked={isVisualized}
            onInputChange={async (event) => {
              const checked = event.target.checked;
              await setIsVisualized(checked);
              await setFormFieldValue(FORM_FIELD.IS_VISUALIZED, checked);
            }}
            labelPlacement="left"
          >
            Available in Monitoring ?
          </OwcSlideToggle>
        </OwcTypography>
      </BookMonitorStyled>

      {isBookable && (
        <div className={"checkin"}>
          <OwcTypography variant="subtitle2" style={{ padding: "10px 20px 20px 0" }}>
            Check in
          </OwcTypography>
          <OwcTypography variant="subtitle1">
            <OwcSlideToggle
              data-testid={`toggle-field-check-in`}
              checked={checkInRequired}
              onInputChange={async (event) => {
                const checked = event.target.checked;
                await setCheckInRequired(checked);
                await setFormFieldValue(FORM_FIELD.CHECK_IN_REQUIRED, checked);
              }}
              labelPlacement="left"
            >
              Requires check in
            </OwcSlideToggle>
          </OwcTypography>
          {checkInRequired && (
            <PeriodWrapper>
              <DropdownSelectWrapper>
                <OwcSelectDropdown
                  data-testid={`toggle-field-check-in-start-period`}
                  label={"Check in period*"}
                  notFoundText={"No options"}
                  required={checkInRequired}
                  style={defaultInputStyle}
                  value={[checkInStartPeriod]}
                  onSelectChange={async (event) => {
                    const [index] = event.detail;
                    const value = checkInOptions[index].value.toString();
                    setCheckInStartPeriod(value);
                    await setFormFieldValue(FORM_FIELD.CHECK_IN_START_PERIOD, value);
                  }}
                >
                  {renderedCheckInOptions}
                </OwcSelectDropdown>
                <OwcTypography
                  variant="caption"
                  style={{
                    padding: "0 0 0 .5rem"
                  }}
                >
                  Possible time period to check in before booking start
                </OwcTypography>
              </DropdownSelectWrapper>
              <DropdownSelectWrapper>
                <OwcSelectDropdown
                  data-testid={`toggle-field-check-in-grace-period`}
                  label={"Grace period*"}
                  notFoundText={"No options"}
                  required={checkInRequired}
                  value={[checkInGracePeriod]}
                  style={defaultInputStyle}
                  onSelectChange={async (event) => {
                    const [index] = event.detail;
                    const value = checkInOptions[index].value.toString();
                    setCheckInGracePeriod(value);
                    await setFormFieldValue(FORM_FIELD.CHECK_IN_GRACE_PERIOD, value);
                  }}
                >
                  {renderedCheckInOptions}
                </OwcSelectDropdown>
                <OwcTypography
                  variant="caption"
                  style={{
                    padding: "0 0 0 .5rem"
                  }}
                >
                  Possible time period to check in after booking start
                </OwcTypography>
              </DropdownSelectWrapper>
            </PeriodWrapper>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  instrumentDetail: state.instruments?.instrumentDetail
});

export default connect(mapStateToProps)(DigitalLabStep);
