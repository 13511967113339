import Actions from "./Actions";
import { UsersCell } from "../../components/shared/CustomCell";
import DATA_MODEL_TABLE from "../../utils/constants/dataModelTable";

export const GroupTableMeta = {
  fields: {
    groupName: {
      text: DATA_MODEL_TABLE.belongingToGroup.value,
      headProps: {},
      cellProps: {}
    },
    userIdsString: {
      component: UsersCell,
      text: "User",
      headProps: {},
      cellProps: {}
    },
    action: {
      component: Actions,
      headProps: {},
      cellProps: {}
    }
  }
};

export default GroupTableMeta;
