import React, { useRef } from "react";
import DATA_MODEL_TABLE from "../../../../utils/constants/dataModelTable";
import { FormComponents } from "../../../instruments/addEditEquipment/AddEquipmentStyle";
import { connect } from "react-redux";
import { compose, withApollo } from "react-apollo";
import { useFormikContext } from "formik";
import { useState, useEffect } from "react";
import { updateBuilding, updateFloor, updateRoomData } from "../../LoadInstrumentsInfo";
import { find } from "lodash";
import { commonPropsForInputsWithoutValue } from "../../helpers";
import { uniqList } from "../../../importFile/utils/helpers";
import styled from "styled-components";
import DocumentsDataForm from "../../DocumentsDataForm";
import { emptyClusterInformation, GXPemptyInstrumentsOptions, systemStatusOptions } from "../../../../constants";
import locale from "date-fns/locale/en-US";
import { updateClusterDetail as updateClusterDetailAction } from "../redux/actions";
import { OwcButton, OwcDatepicker, OwcIcon, OwcInput, OwcSlideToggle, OwcTypography } from "@one/react";
import CustomAutoCompleteWithMultiSelect from "../../../../components/shared/CustomAutoCompleteWithMultiSelect";
import CommonOwcEditableDropDown from "./CommonOwcEditableDropDown";
import { clusterCategoryMappings, getTagsMaster } from "../../loadInstrumentsInfoService";
import { defaultInputStyle } from "../../../../utils/helpers/text";

const InstallDocumentContainer = styled.div`
  position: relative;
  display: inline-block;
  & > .selectBoxLarge {
    margin: 0 20px 20px 0px;
    width: 321px;
    max-width: 321px;
  }
`;

const testStyle = {
  color: "var(--one-color-background-brand-1)",
  position: "absolute",
  right: "30px"
};

const DocumentContainer = styled.div`
  position: fixed;
  top: 280px;
  left: 50px;
  width: 520px;
  background: var(--one-color-cobas-white);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.5);
  z-index: 10;
  border-radius: 4px;
`;
const AddCancelButton = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 1rem;
  margin-right: 1rem;
`;

const ClusterInformation = ({
  sites,
  client,
  isUpdateBadge = false,
  setIsNextDisabled,
  activeStep,
  setActiveStep,
  isEditMode,
  updateClusterDetail,
  isSubCluster
}) => {
  const formik = useFormikContext();
  const [buildings, setBuildings] = useState([]);
  const [floors, setFloors] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [categoryMappingList, setCategoryMappingList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [variantList, setVariantList] = useState([]);
  const [showLiknedDocumentsPopUp, setShowLinkedDocumentsPopUp] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [selectedSite, setSelectedSite] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [tempModelList, setTempModelList] = useState([]);
  const [tempVariantList, setTempVariantList] = useState([]);
  const [prevSiteValidation, setPrevSiteValidation] = useState([]);

  const currentSite = useRef(null);
  const currentBuilding = useRef(null);
  const currentFloor = useRef(null);
  const currentCategory = useRef(null);
  const currentModel = useRef(null);

  useEffect(() => {
    getClusterCategoryMappingList();
    getTagsMasterDatas();
    const loadFieldDatas = async () => {
      setSelectedSite(() => formik?.values?.siteName);
      await loadInitialBuildings(client, formik?.values?.siteName);
      await loadInitialFloors(client, formik?.values?.siteName, formik?.values?.buildingLocation?.id);
      await loadInitialRooms(client, formik?.values?.siteName, formik?.values?.floor?.id);
      setSelectedBuilding(() => `${formik?.values?.buildingLocation?.buildingNumber}`);
      setSelectedFloor(() => `${formik?.values?.floor?.floorCode}`);
      setSelectedRoom(() => `${formik?.values?.room?.roomCode}`);
    };
    if (formik?.values?.siteName) {
      loadFieldDatas();
    }
    if (isEditMode) {
      setIsTouched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateClusterDetail(formik.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  const loadInitialBuildings = async (client, siteName) => {
    const site = find(sites, { siteName });
    const { buildings: buildingData } = await updateBuilding(client, site?.siteId);
    setBuildings(() => [...buildingData]);
  };

  const loadInitialFloors = async (client, siteName, buildindId) => {
    const site = find(sites, { siteName: siteName });
    const { floors: floorDatas } = await updateFloor(client, site.siteId, buildindId);
    setFloors(() => [...floorDatas]);
  };

  const loadInitialRooms = async (client, siteName, floorId) => {
    const site = find(sites, { siteName: siteName });
    const { rooms: roomData } = await updateRoomData(client, site.siteId, floorId);
    setRooms(() => [...roomData]);
  };

  const getClusterCategoryMappingList = async () => {
    const { items } = await clusterCategoryMappings(client);
    setCategoryMappingList(items);
    updateAllMappingList(items);
  };

  const getTagsMasterDatas = async () => {
    const tagsData = await getTagsMaster(client);
    if (tagsData?.items.length > 0) {
      setTagList(uniqList(tagsData.items.map((x) => x.filterValue)));
    }
  };

  const updateAllMappingList = (list) => {
    setCategoryList(uniqList(list.map((x) => x.equipmentCategory)).sort());
    setModelList(
      uniqList(
        list.filter((item) => item.equipmentCategory === formik.values?.equipmentCategory).map((x) => x.equipmentModel)
      ).sort()
    );
    setVariantList(
      uniqList(
        list
          .filter((item) => item.equipmentModel === formik.values?.equipmentModel && item.variant !== "")
          .map((x) => x.variant)
      ).sort()
    );
    setSelectedCategory(formik.values?.equipmentCategory);
    setSelectedModel(formik.values?.equipmentModel);
    setSelectedVariant(formik.values?.variant);
  };
  if (!isTouched) {
    for (let x in emptyClusterInformation) {
      if (formik.touched[x] === true) {
        setIsTouched(true);
      }
    }
  }
  setIsNextDisabled(!(formik.isValid && Object.keys(formik.errors).length === 0 && isTouched));

  const onSiteSelected = (siteObj) => {
    formik.setValues({
      ...formik.values,
      siteName: siteObj?.siteName ?? null,
      siteTimezone: siteObj?.timeZone ?? null,
      siteId: siteObj?.siteId ?? null
    });
    setPrevSiteValidation((prevState) => {
      return [...prevState, siteObj];
    });
    let countSelectedSite = Object.keys(prevSiteValidation).length;
    if (countSelectedSite > 0) {
      if (siteObj?.siteId !== prevSiteValidation[0]?.siteId) {
        if (prevSiteValidation[countSelectedSite - 1]?.siteId !== siteObj?.siteId) {
          formik.setValues({
            ...formik.values,
            siteName: siteObj?.siteName ?? null,
            siteTimezone: siteObj?.timeZone ?? null,
            siteId: siteObj?.siteId ?? null,
            buildingLocation: null,
            floor: null,
            room: null
          });
        }
      }
    }
    setSelectedSite(() => siteObj?.siteName ?? null);
    if (!siteObj) {
      setBuildings(() => []);
      setFloors(() => []);
      setRooms(() => []);
      setSelectedBuilding(() => null);
      setSelectedFloor(() => null);
      setSelectedRoom(() => null);
    }
  };
  const onBuildingSelected = (buildingObj) => {
    formik.setValues({
      ...formik.values,
      floor: null,
      room: null,
      buildingLocation: buildingObj
    });
    setSelectedBuilding(() => buildingObj?.buildingNumber);
    if (!buildingObj) {
      setFloors(() => []);
      setRooms(() => []);
      setSelectedFloor(() => null);
      setSelectedRoom(() => null);
    }
  };

  const onFloorSelected = (floorObj) => {
    formik.setValues({
      ...formik.values,
      room: null,
      floor: floorObj
    });
    setSelectedFloor(() => floorObj?.floorCode);
    if (!floorObj) {
      setRooms(() => []);
      setSelectedRoom(() => null);
    }
  };

  const onRoomSelected = (roomObj) => {
    formik.setValues({
      ...formik.values,
      room: roomObj
    });
    setSelectedRoom(roomObj?.roomCode ?? null);
  };

  const onOpenSite = async () => {
    currentSite.current = selectedSite;
  };

  const onCloseSite = async () => {
    if (formik?.values?.siteName) {
      if (currentSite.current !== selectedSite) {
        const site = find(sites, { siteName: formik?.values?.siteName });
        const { buildings: buildingData } = await updateBuilding(client, site?.siteId);
        setBuildings(() => [...buildingData]);
        setFloors(() => []);
        setRooms(() => []);
        setSelectedBuilding(() => formik?.values?.buildingLocation?.buildingNumber);
        setSelectedFloor(() => null);
        setSelectedRoom(() => null);
      }
    } else if (!formik?.values?.siteName) {
      setBuildings(() => []);
      setFloors(() => []);
      setRooms(() => []);
      setSelectedBuilding(() => null);
      setSelectedFloor(() => null);
      setSelectedRoom(() => null);
    }
  };

  const onOpenBuilding = async () => {
    currentBuilding.current = selectedBuilding;
  };

  const onCloseBuilding = async () => {
    if (formik?.values?.siteName) {
      if (currentBuilding?.current !== selectedBuilding) {
        const site = find(sites, { siteName: formik?.values?.siteName });
        const { floors: floorDatas } = await updateFloor(client, site.siteId, formik?.values?.buildingLocation?.id);
        setFloors(() => [...floorDatas]);
        setRooms(() => []);
        setSelectedFloor(() => formik?.values?.floor?.floorCode);
        setSelectedRoom(() => null);
      }
    } else if (!formik?.values?.siteName) {
      setBuildings(() => []);
      setFloors(() => []);
      setRooms(() => []);
      setSelectedBuilding(() => null);
      setSelectedFloor(() => null);
      setSelectedRoom(() => null);
    }
  };

  const onOpenFloor = async () => {
    currentFloor.current = selectedFloor;
  };

  const onCloseFloor = async () => {
    if (formik?.values?.siteName) {
      if (currentFloor?.current !== selectedFloor) {
        const site = find(sites, { siteName: formik?.values?.siteName });
        const { rooms: roomData } = await updateRoomData(client, site.siteId, formik?.values?.floor?.id);
        setRooms(() => [...roomData]);
        setSelectedRoom(() => formik?.values?.room?.roomCode);
      }
    } else if (!formik?.values?.siteName) {
      setBuildings(() => []);
      setFloors(() => []);
      setRooms(() => []);
      setSelectedBuilding(() => null);
      setSelectedFloor(() => null);
      setSelectedRoom(() => null);
    }
  };

  const onOpenCategory = async () => {
    currentCategory.current = selectedCategory;
  };

  const onCloseCategory = async () => {
    if (formik?.values?.equipmentCategory) {
      if (currentCategory.current !== selectedCategory) {
        setModelList(tempModelList);
        setVariantList(tempVariantList);
        setSelectedModel(() => formik?.values?.equipmentModel);
        setSelectedVariant(() => null);
      }
    } else if (!formik?.values?.equipmentCategory) {
      setModelList(() => []);
      setVariantList(() => []);
      setSelectedModel(() => null);
      setSelectedVariant(() => null);
    }
  };

  const onOpenModel = async () => {
    currentModel.current = selectedModel;
  };

  const onCloseModel = async () => {
    if (formik?.values?.equipmentModel) {
      if (currentModel.current !== selectedModel) {
        setVariantList(tempVariantList);
        setSelectedVariant(() => formik?.values?.variant);
      }
    } else if (!formik?.values?.equipmentModel) {
      setModelList(() => []);
      setVariantList(() => []);
      setSelectedModel(() => null);
      setSelectedVariant(() => null);
    }
  };

  const categoryChange = (value) => {
    formik.setValues({
      ...formik.values,
      equipmentCategory: value
    });
    setSelectedCategory(() => value);
    if (!value) {
      setModelList(() => []);
      setVariantList(() => []);
      setSelectedModel(() => null);
      setSelectedVariant(() => null);
    }
    if (formik.values.linkedInstance?.linkedInstance !== "None") {
      const filterList = categoryMappingList.filter((item) => item.equipmentCategory === value);

      const tempMList = uniqList(filterList.map((x) => x.equipmentModel)).sort();
      let tempVList = uniqList(
        categoryMappingList
          .filter((item) => tempMList.includes(item.equipmentModel))
          .map((x) => x.variant)
          .sort()
      );
      tempVList = tempVList.filter((item) => item);
      formik.setValues({
        ...formik.values,
        equipmentCategory: value,
        equipmentModel: tempMList.length === 1 ? tempMList[0] : null,
        variant: tempVList.length === 1 && tempMList.length === 1 ? tempVList[0] : null
      });

      setTempModelList(tempMList);
      setTempVariantList(tempVList);
    } else {
      const tempVList = uniqList(
        categoryMappingList
          .filter((item) => item.equipmentModel === formik.values?.equipmentModel)
          .map((x) => x.variant)
          .sort()
      );

      formik.setValues({
        ...formik.values,
        equipmentCategory: value,
        variant: tempVList.length === 1 ? tempVList[0] : null
      });
      setTempVariantList(tempVList);
    }
  };

  const modelChange = (value) => {
    const filterList = categoryMappingList.filter((item) => item.equipmentModel === value);
    let tempVList = uniqList(filterList.map((x) => x.variant)).sort();
    tempVList = tempVList.filter((item) => item);
    formik.setValues({
      ...formik.values,
      equipmentModel: value,
      variant: tempVList.length === 1 ? tempVList[0] : null
    });

    setSelectedModel(() => value);
    setTempVariantList(tempVList);
    if (!value) {
      setVariantList(() => []);
      setSelectedVariant(() => null);
    }
  };

  const variantChange = (value) => {
    formik.setValues({
      ...formik.values,
      variant: value
    });
    setSelectedVariant(value);
    if (!formik.values?.model && formik.values.linkedInstance?.linkedInstance !== "None") {
      const tempList = uniqList(
        categoryMappingList.filter((item) => item.variant === value).map((item) => item.equipmentModel)
      );
      const filterList = categoryMappingList.filter((item) => tempList.includes(item.equipmentModel));

      const tempCList = uniqList(filterList.map((x) => x.equipmentCategory)).sort();

      const tempMList = uniqList(filterList.map((x) => x.equipmentModel)).sort();

      formik.setValues({
        ...formik.values,
        variant: value,
        equipmentCategory: tempCList.length === 1 ? tempCList[0] : formik.values?.equipmentCategory,

        equipmentModel: tempMList.length === 1 ? tempMList[0] : formik.values?.equipmentModel
      });
    }
  };
  const handleShowPopup = (value) => {
    if (value === "linkedDoc") {
      setShowLinkedDocumentsPopUp(!showLiknedDocumentsPopUp);
    }
  };
  const getInstalledDocValue = (selected) => {
    if (typeof selected != "undefined") {
      if (selected.length === 0) {
        return "";
      }
      if (selected.length > 0) {
        return `${selected[0]?.name}, ${selected[0]?.documentId}`;
      }
    } else {
      return "";
    }
  };
  const handleClosePopup = () => {
    if (showLiknedDocumentsPopUp) {
      setShowLinkedDocumentsPopUp(false);
    }
  };
  const handleDelete = (selectedObjects, selectedObject, setValue) => {
    selectedObjects = selectedObjects.filter((currentObject) => currentObject !== selectedObject);
    setValue(selectedObjects);
  };

  return (
    <>
      <OwcTypography variant="subtitle2" style={{ padding: "0px 20px 20px 0px" }}>
        Location and Occupation
      </OwcTypography>
      <FormComponents>
        <>
          <CommonOwcEditableDropDown
            labelValue={false}
            label={DATA_MODEL_TABLE.siteName.value + ` *`}
            defaultLabel={DATA_MODEL_TABLE.siteName.value + ` *`}
            propValue="siteName"
            style={defaultInputStyle}
            list={sites}
            selected={selectedSite}
            onChange={(selectedValue) => {
              const selVal = sites?.find((value, index) => index === selectedValue);
              onSiteSelected(selVal ?? null);
            }}
            onOpen={onOpenSite}
            onClose={onCloseSite}
            required={true}
            helperText={formik.errors[DATA_MODEL_TABLE.siteName.key]}
          />
        </>
        <>
          <CommonOwcEditableDropDown
            labelValue={false}
            label={DATA_MODEL_TABLE.buildingLocation.value + ` *`}
            defaultLabel={DATA_MODEL_TABLE.buildingLocation.value + ` *`}
            style={defaultInputStyle}
            list={buildings}
            propValue="buildingNumber"
            selected={selectedBuilding}
            onChange={(selectedValue) => {
              const selVal = buildings?.find((value, index) => index === selectedValue);
              onBuildingSelected(selVal ?? null);
            }}
            onOpen={onOpenBuilding}
            onClose={onCloseBuilding}
            required={true}
            helperText={formik.errors[DATA_MODEL_TABLE.buildingLocation.key]}
          />
        </>
        <>
          <CommonOwcEditableDropDown
            labelValue={false}
            label={DATA_MODEL_TABLE.floor.value + ` *`}
            defaultLabel={DATA_MODEL_TABLE.floor.value + ` *`}
            keylabel={DATA_MODEL_TABLE.floor.key}
            style={defaultInputStyle}
            list={floors}
            propValue="floorCode"
            selected={selectedFloor}
            onChange={(selectedValue) => {
              const selVal = floors?.find((value, index) => index === selectedValue);
              onFloorSelected(selVal ?? null);
            }}
            onOpen={onOpenFloor}
            onClose={onCloseFloor}
            required={true}
            disabled={!selectedBuilding}
            helperText={formik.errors[DATA_MODEL_TABLE.floor.key]}
          />
        </>
        <>
          <CommonOwcEditableDropDown
            labelValue={false}
            label={DATA_MODEL_TABLE.room.value + ` *`}
            keylabel={DATA_MODEL_TABLE.room.key}
            defaultLabel={DATA_MODEL_TABLE.room.value + ` *`}
            style={defaultInputStyle}
            list={rooms}
            propValue="roomCode"
            selected={selectedRoom}
            onChange={(selectedValue) => {
              const selVal = rooms?.find((value, index) => index === selectedValue);

              onRoomSelected(selVal ?? null);
            }}
            // onOpen={onCloseFloor}
            disabled={!selectedFloor}
            required={true}
            helperText={!selectedFloor ? "" : formik.errors[DATA_MODEL_TABLE.room.key]}
          />
        </>
        <div className="owcInputBox">
          <OwcInput
            data-testid={`text-field-for-equipment-administrator`}
            id={`text-field-for-equipment-administrator`}
            label={DATA_MODEL_TABLE.equipmentAdministrator.value}
            style={defaultInputStyle}
            name="equipmentAdministrator"
            value={formik.values?.equipmentAdministrator}
            onInputChange={(ev) => {
              if (ev.detail !== "") {
                formik.setFieldValue([DATA_MODEL_TABLE.equipmentAdministrator.key], ev.detail);
              } else {
                formik.setFieldValue([DATA_MODEL_TABLE.equipmentAdministrator.key], null);
              }
            }}
            onBlur={formik.handleBlur}
            {...commonPropsForInputsWithoutValue({
              formik,
              key: "equipmentAdministrator",
              dataTestIdKey: "equipmentAdministrator",
              dataTestIdForFormKey: "text-field"
            })}
          >
            {formik.values[DATA_MODEL_TABLE.equipmentAdministrator.key] !== null &&
              formik.touched[DATA_MODEL_TABLE.equipmentAdministrator.key] === true && (
                <OwcIcon
                  name="circle_clear_filled"
                  slot="suffix"
                  type="legacy"
                  onClick={() => formik.setFieldValue([DATA_MODEL_TABLE.equipmentAdministrator.key], null, true)}
                />
              )}
          </OwcInput>
        </div>
        <div className="owcInputBox">
          <OwcInput
            data-testid={`text-field-for-responsible-person`}
            id={`text-field-for-responsible-person`}
            label={DATA_MODEL_TABLE.responsiblePerson.value + ` *`}
            style={defaultInputStyle}
            name="responsiblePerson"
            value={formik.values?.responsiblePerson}
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
            {...commonPropsForInputsWithoutValue({
              formik,
              key: "responsiblePerson",
              dataTestIdKey: "responsiblePerson",
              dataTestIdForFormKey: "text-field"
            })}
          >
            {formik.errors["responsiblePerson"] && formik.touched["responsiblePerson"] === true && (
              <>
                <span
                  // id={generateID.UUID(adminTopBannerMessage, `topBannerMessage`, "error_slot")}
                  slot="error-text"
                >
                  {formik.errors["responsiblePerson"]}
                </span>
              </>
            )}
            {formik.values["responsiblePerson"] !== "" && formik.touched["responsiblePerson"] === true && (
              <OwcIcon
                // id={generateID.UUID(
                //   adminTopBannerMessage,
                //   `topBannerMessage`,
                //   "icon_clear_circle"
                // )}
                name="circle_clear_filled"
                slot="suffix"
                type="legacy"
                onClick={() => formik.setFieldValue(["responsiblePerson"], "", true)}
              />
            )}
          </OwcInput>
        </div>
        <div className="owcInputBox">
          <OwcInput
            data-testid={`text-field-for-responsible-proxy`}
            id={`text-field-for-responsible-proxy`}
            label={DATA_MODEL_TABLE.responsibleProxy.value + ` *`}
            style={defaultInputStyle}
            name="responsibleProxy"
            value={formik.values?.responsibleProxy}
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
            {...commonPropsForInputsWithoutValue({
              formik,
              key: "responsibleProxy",
              dataTestIdKey: "responsibleProxy",
              dataTestIdForFormKey: "text-field"
            })}
          >
            {formik.errors["responsibleProxy"] && formik.touched["responsibleProxy"] === true && (
              <>
                <span
                  // id={generateID.UUID(adminTopBannerMessage, `topBannerMessage`, "error_slot")}
                  slot="error-text"
                >
                  {formik.errors["responsibleProxy"]}
                </span>
              </>
            )}
            {formik.values["responsibleProxy"] !== "" && formik.touched["responsibleProxy"] === true && (
              <OwcIcon
                // id={generateID.UUID(
                //   adminTopBannerMessage,
                //   `topBannerMessage`,
                //   "icon_clear_circle"
                // )}
                name="circle_clear_filled"
                slot="suffix"
                type="legacy"
                onClick={() => formik.setFieldValue(["responsibleProxy"], "", true)}
              />
            )}
          </OwcInput>
        </div>
      </FormComponents>
      <OwcTypography variant="subtitle2" style={{ padding: "20px 20px 20px 0px" }}>
        General information
      </OwcTypography>
      <FormComponents>
        <div className="owcInputBox">
          <OwcInput
            data-testid={`select-field-belongingToGroup`}
            id={`select-field-belongingToGroup`}
            label={"Group *"}
            style={defaultInputStyle}
            name="belongingToGroup"
            value={formik.values?.belongingToGroup}
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
            {...commonPropsForInputsWithoutValue({
              formik,
              key: "belongingToGroup",
              dataTestIdKey: "belongingToGroup",
              dataTestIdForFormKey: "text-field"
            })}
          >
            {" "}
            {formik.errors["belongingToGroup"] && formik.touched["belongingToGroup"] === true && (
              <>
                <span
                  // id={generateID.UUID(adminTopBannerMessage, `topBannerMessage`, "error_slot")}
                  slot="error-text"
                >
                  {formik.errors["belongingToGroup"]}
                </span>
              </>
            )}
            {formik.values["belongingToGroup"] !== "" && formik.touched["belongingToGroup"] === true && (
              <OwcIcon
                // id={generateID.UUID(
                //   adminTopBannerMessage,
                //   `topBannerMessage`,
                //   "icon_clear_circle"
                // )}
                name="circle_clear_filled"
                slot="suffix"
                type="legacy"
                onClick={() => formik.setFieldValue(["belongingToGroup"], "", true)}
              />
            )}
          </OwcInput>
        </div>
        <>
          <CommonOwcEditableDropDown
            labelValue={false}
            label={DATA_MODEL_TABLE.equipmentCategory.value + ` *`}
            defaultLabel={DATA_MODEL_TABLE.equipmentCategory.value + ` *`}
            keylabel={DATA_MODEL_TABLE.equipmentCategory.key}
            style={defaultInputStyle}
            list={categoryList}
            selected={selectedCategory}
            onChange={(selectedValue) => {
              const selVal = categoryList?.find((value, index) => index === selectedValue);
              categoryChange(selVal ?? null);
            }}
            onOpen={onOpenCategory}
            onClose={onCloseCategory}
            required={true}
            helperText={formik.errors[DATA_MODEL_TABLE.equipmentCategory.key]}
          />
        </>
        <>
          <CommonOwcEditableDropDown
            labelValue={false}
            label={DATA_MODEL_TABLE.equipmentModel.value + ` *`}
            defaultLabel={DATA_MODEL_TABLE.equipmentModel.value + ` *`}
            keylabel={DATA_MODEL_TABLE.equipmentModel.key}
            style={defaultInputStyle}
            list={modelList}
            selected={selectedModel}
            onChange={(selectedValue) => {
              const selVal = modelList?.find((value, index) => index === selectedValue);
              modelChange(selVal ?? null);
            }}
            onOpen={onOpenModel}
            onClose={onCloseModel}
            required={true}
            helperText={formik.errors[DATA_MODEL_TABLE.equipmentModel.key]}
          />
        </>
        <>
          <CommonOwcEditableDropDown
            labelValue={false}
            label={DATA_MODEL_TABLE.variant.value}
            defaultLabel={DATA_MODEL_TABLE.variant.value}
            keylabel={DATA_MODEL_TABLE.variant.key}
            style={defaultInputStyle}
            list={variantList}
            selected={selectedVariant}
            disabled={!formik.values?.equipmentModel || !variantList.length}
            onChange={(selectedValue) => {
              const selVal = variantList?.find((value, index) => index === selectedValue);
              variantChange(selVal ?? null);
            }}
          />
        </>
        <div className="owcInputBox">
          <OwcInput
            data-testid={`text-field-equipment-nick-name`}
            id={`text-field-equipment-nick-name`}
            label={DATA_MODEL_TABLE.equipmentNickName.value}
            style={defaultInputStyle}
            name="equipmentNickName"
            value={formik.values?.equipmentNickName}
            onInputChange={(ev) => {
              if (ev.detail !== "") {
                formik.setFieldValue([DATA_MODEL_TABLE.equipmentNickName.key], ev.detail);
              } else {
                formik.setFieldValue([DATA_MODEL_TABLE.equipmentNickName.key], null);
              }
            }}
            onBlur={formik.handleBlur}
            {...commonPropsForInputsWithoutValue({
              formik,
              key: "equipmentNickName",
              dataTestIdKey: "equipmentNickName",
              dataTestIdForFormKey: "text-field"
            })}
          >
            {formik.values[DATA_MODEL_TABLE.equipmentNickName.key] !== null &&
              formik.touched[DATA_MODEL_TABLE.equipmentNickName.key] === true && (
                <OwcIcon
                  name="circle_clear_filled"
                  slot="suffix"
                  type="legacy"
                  onClick={() => formik.setFieldValue([DATA_MODEL_TABLE.equipmentNickName.key], null, true)}
                />
              )}
          </OwcInput>
        </div>
        <div className="owcInputBox">
          <OwcInput
            data-testid={`text-field-cluster`}
            id={`text-field-cluster`}
            label={DATA_MODEL_TABLE.cluster.value}
            style={defaultInputStyle}
            name="cluster"
            value={formik.values?.cluster}
            onInputChange={(ev) => {
              if (ev.detail !== "") {
                formik.setFieldValue([DATA_MODEL_TABLE.cluster.key], ev.detail);
              } else {
                formik.setFieldValue([DATA_MODEL_TABLE.cluster.key], null);
              }
            }}
            onBlur={formik.handleBlur}
            {...commonPropsForInputsWithoutValue({
              formik,
              key: "cluster",
              dataTestIdKey: "cluster",
              dataTestIdForFormKey: "text-field"
            })}
          >
            {formik.values[DATA_MODEL_TABLE.cluster.key] !== null &&
              formik.touched[DATA_MODEL_TABLE.cluster.key] === true && (
                <OwcIcon
                  name="circle_clear_filled"
                  slot="suffix"
                  type="legacy"
                  onClick={() => formik.setFieldValue([DATA_MODEL_TABLE.cluster.key], null, true)}
                />
              )}
          </OwcInput>
        </div>
        <div className="owctagInputBox">
          <CustomAutoCompleteWithMultiSelect
            style={{ width: "635px" }}
            id={DATA_MODEL_TABLE.tags.key}
            dataTestid={DATA_MODEL_TABLE.tags.key}
            optionsList={tagList}
            removable={true}
            handleDelete={handleDelete}
            label={DATA_MODEL_TABLE.tags.value}
            value={formik.values?.tags ?? []}
            disableCloseOnSelect={true}
            setValue={(newValue) => {
              formik.setFieldValue("tags", newValue, true);
            }}
            fullWidth={true}
            name={DATA_MODEL_TABLE.tags.key}
            multiple={true}
            error={formik.touched[DATA_MODEL_TABLE.tags.key] && Boolean(formik.errors[DATA_MODEL_TABLE.tags.key])}
            helperText={
              (formik.touched[DATA_MODEL_TABLE.tags.key] && formik.errors[DATA_MODEL_TABLE.tags.key]) ??
              "You can add/select max of 10 tags and each tag of 20 characters max"
            }
            onBlur={formik.handleBlur}
          />
        </div>
      </FormComponents>
      <OwcTypography variant="subtitle2" style={{ padding: "20px 20px 20px 0px" }}>
        Qualification and Settings
      </OwcTypography>
      <FormComponents>
        <OwcTypography
          variant="subtitle1"
          style={{ marginRight: "36px" }}
          data-testid={`doc-data-isbookable`}
          id={`doc-data-isbookable`}
        >
          <OwcSlideToggle
            data-testid={`toggle-field-booking`}
            id={`toggle-field-booking`}
            checked={formik.values?.isBookable}
            onInputChange={() => formik.setFieldValue("isBookable", !formik.values?.isBookable, true)}
            disabled={isSubCluster}
          >
            Booking ?
          </OwcSlideToggle>
        </OwcTypography>
        <OwcTypography variant="subtitle1" data-testid={`doc-data-isVisualized`} id={`doc-data-isVisualized`}>
          <OwcSlideToggle
            data-testid={`toggle-field-visualized`}
            id={`toggle-field-visualized`}
            checked={formik.values?.isVisualized}
            onInputChange={() =>
              formik.setFieldValue(
                "isVisualized",
                !formik.values?.isVisualized,

                true
              )
            }
            disabled={true}
          >
            Monitoring ?
          </OwcSlideToggle>
        </OwcTypography>
      </FormComponents>
      <br />
      <FormComponents>
        <div className="owcInputBox">
          <InstallDocumentContainer>
            <OwcInput
              style={defaultInputStyle}
              data-testid={`text-field-qualification-documents`}
              id={`text-field-qualification-documents`}
              label={DATA_MODEL_TABLE.qualificationDocuments.value}
              name="qualificationDocuments"
              size="medium"
              autoComplete="off"
              onClick={() => {
                handleShowPopup("linkedDoc");
              }}
              value={getInstalledDocValue(formik.values?.qualificationDocuments?.value)}
            >
              <div slot="suffix">
                {formik.values?.qualificationDocuments?.value?.length > 1 ? (
                  <div style={testStyle}>+{formik.values?.qualificationDocuments?.value?.length - 1}</div>
                ) : (
                  ""
                )}
                <OwcIcon name="circle_add" type="outlined" />
              </div>
            </OwcInput>

            <DocumentContainer
              style={{ display: showLiknedDocumentsPopUp ? "block" : "none" }}
              id={"document-container"}
            >
              {showLiknedDocumentsPopUp ? <DocumentsDataForm formik={formik} id={"document-data-form"} /> : ""}
              <AddCancelButton id={"add-cancel-button"}>
                <OwcButton
                  variant="primary"
                  style={{ textTransform: "none" }}
                  data-testid={`close-cluster-button`}
                  id={"close-cluster-button"}
                  onClick={handleClosePopup}
                >
                  Close
                </OwcButton>
              </AddCancelButton>
            </DocumentContainer>
          </InstallDocumentContainer>
        </div>
        <>
          <CommonOwcEditableDropDown
            labelValue={false}
            label={DATA_MODEL_TABLE.gxpRelevant.value + ` *`}
            defaultLabel={DATA_MODEL_TABLE.gxpRelevant.value + ` *`}
            keylabel={DATA_MODEL_TABLE.gxpRelevant.key}
            style={defaultInputStyle}
            list={GXPemptyInstrumentsOptions?.gxpRelevant}
            propValue="value"
            selected={
              formik.values[DATA_MODEL_TABLE.gxpRelevant.key]?.["value"] !== ""
                ? formik.values[DATA_MODEL_TABLE.gxpRelevant.key]?.["value"]
                : null
            }
            onChange={(selectedValue) => {
              const selVal = GXPemptyInstrumentsOptions?.gxpRelevant?.find((value, index) => index === selectedValue);
              formik.setValues({
                ...formik.values,
                gxpRelevant: selVal
              });
            }}
            required={true}
            helperText={formik.errors[DATA_MODEL_TABLE.gxpRelevant.key]?.["value"]}
          />
        </>
        <>
          <CommonOwcEditableDropDown
            labelValue={false}
            label={DATA_MODEL_TABLE.qualificationStatus.value + ` *`}
            defaultLabel={DATA_MODEL_TABLE.qualificationStatus.value + ` *`}
            keylabel={DATA_MODEL_TABLE.qualificationStatus.key}
            style={defaultInputStyle}
            propValue="value"
            list={GXPemptyInstrumentsOptions?.qualificationStatus}
            selected={
              formik.values[DATA_MODEL_TABLE.qualificationStatus.key]?.["value"] !== ""
                ? formik.values[DATA_MODEL_TABLE.qualificationStatus.key]?.["value"]
                : null
            }
            onChange={(selectedValue) => {
              const selVal = GXPemptyInstrumentsOptions?.qualificationStatus?.find(
                (value, index) => index === selectedValue
              );
              formik.setValues({
                ...formik.values,
                qualificationStatus: selVal
              });
            }}
            required={true}
            helperText={formik.errors[DATA_MODEL_TABLE.qualificationStatus.key]?.["value"]}
          />
        </>
        <div className="owcInputBox">
          <OwcDatepicker
            locale={{
              ...locale,
              options: {
                ...locale.options,
                weekStartsOn: 1
              }
            }}
            id={DATA_MODEL_TABLE.dateOfNextMaintanance.key}
            name={DATA_MODEL_TABLE.dateOfNextMaintanance.key}
            label={DATA_MODEL_TABLE.dateOfNextMaintanance.value}
            style={defaultInputStyle}
            data-testid={"basic-data-additional-info-fields-instrument-date-of-next-maintanance-input"}
            value={
              formik.values[DATA_MODEL_TABLE.dateOfNextMaintanance.key] !== null
                ? new Date(formik.values[DATA_MODEL_TABLE.dateOfNextMaintanance.key])
                : null
            }
            autoClose={true}
            minDate={new Date().getTime()}
            format="dd-MMM-yyyy"
            onValueChange={(e) => {
              formik.setFieldValue(DATA_MODEL_TABLE.dateOfNextMaintanance.key, e.detail, true);
            }}
          ></OwcDatepicker>
        </div>
        <div className="owcInputBox">
          <OwcInput
            data-testid={`text-field-equopment-software-version`}
            id={`text-field-equopment-software-version`}
            label="Software version"
            style={defaultInputStyle}
            name="softwareVersion"
            value={formik.values?.softwareVersion}
            onInputChange={(ev) => {
              if (ev.detail !== "") {
                formik.setFieldValue([DATA_MODEL_TABLE.softwareVersion.key], ev.detail);
              } else {
                formik.setFieldValue([DATA_MODEL_TABLE.softwareVersion.key], null);
              }
            }}
            disabled={true}
            onBlur={formik.handleBlur}
            {...commonPropsForInputsWithoutValue({
              formik,
              key: "softwareVersion",
              dataTestIdKey: "softwareVersion",
              dataTestIdForFormKey: "text-field"
            })}
          >
            {formik.values[DATA_MODEL_TABLE.softwareVersion.key] !== null &&
              formik.touched[DATA_MODEL_TABLE.softwareVersion.key] === true && (
                <OwcIcon
                  name="circle_clear_filled"
                  slot="suffix"
                  type="legacy"
                  onClick={() => formik.setFieldValue([DATA_MODEL_TABLE.softwareVersion.key], null, true)}
                />
              )}
          </OwcInput>
        </div>
        <>
          <CommonOwcEditableDropDown
            labelValue={false}
            label={DATA_MODEL_TABLE.systemStatus.value}
            defaultLabel={DATA_MODEL_TABLE.systemStatus.value}
            keylabel={DATA_MODEL_TABLE.systemStatus.key}
            style={defaultInputStyle}
            list={systemStatusOptions}
            selected={
              formik.values[DATA_MODEL_TABLE.systemStatus.key] !== ""
                ? formik.values[DATA_MODEL_TABLE.systemStatus.key]
                : null
            }
            onChange={(selectedValue) => {
              if (selectedValue !== null) {
                let selVal = systemStatusOptions?.find((value, index) => index === selectedValue);

                formik.setValues({
                  ...formik.values,
                  systemStatus: selVal
                });
              } else {
                formik.setValues({
                  ...formik.values,
                  systemStatus: null
                });
              }
            }}
            // helperText={formik.errors[DATA_MODEL_TABLE.siteName.key]}
          />
        </>
      </FormComponents>
    </>
  );
};

const mapStateToProps = (state) => ({
  sites: state.user.sites,
  clusterDetail: state?.clusters?.clusterDetail
});

export default compose(
  connect(mapStateToProps, {
    updateClusterDetail: updateClusterDetailAction
    // updateEditClusterDetail: updateEditClusterDetailAction
  }),
  withApollo
)(ClusterInformation);
