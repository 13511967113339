import { OwcIcon } from "@one/react";
import { ToastContainer, toast } from "react-toastify";
import styled, { css } from "styled-components";

export const StyledToastContainer = styled(ToastContainer).attrs({
  className: "toast-container",
  toastClassName: "toast",
  bodyClassName: "body",
  progressClassName: "progress"
})`
  height: 72px;
  width: 500px;
  border-radius: 4px;
  z-index: 10001;

  .toast {
    top: 56px;
    padding: 0;
    box-sizing: border-box;
    box-shadow: 0 12px 12px rgba(0, 0, 0, 0.34902);
  }

  button[aria-label="close"] {
    display: none;
  }

  .body {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .progress {
    background: transparent;
  }
`;

const getCSSForActions = (props) => {
  if (props.type === "success") {
    return css`
      color: var(--one-color-gray-800);
      background: var(--one-color-cobas-white);
      border: 2px solid var(--one-color-background-positive-1);
      & > .icon {
        background: var(--one-color-background-positive-1);
      }
      & > .icon > .snackbarIconSizeColor {
        font-size: 45px;
        color: var(--one-color-cobas-white);
      }
    `;
  }
  if (props.type === "warning") {
    return css`
      color: var(--one-color-gray-800);
      background: var(--one-color-cobas-white);
      border: 2px thin var(--one-color-red-a-300);
      & > .icon {
        background: var(--one-color-red-a-300);
      }
      & > .icon > .snackbarIconSizeColor {
        font-size: 45px;
        color: var(--one-color-cobas-white);
      }
    `;
  }
  if (props.type === "alert") {
    return css`
      color: ${props.theme.notification.alert.color};
      background: ${props.theme.notification.alert.backgroundColor};
      border: ${props.theme.notification.alert.border};
      & > .icon {
        background: ${props.theme.notification.alert.backgroundIconColor};
        color: ${props.theme.notification.alert.iconColor};
      }
    `;
  }
  return css``;
};

export const CustomNotificationStyled = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  box-sizing: border-box;
  position: relative;
  padding: 0;
  height: 100%;
  min-height: 72px;
  color: black;
  background: white;
  border: 2px solid black;
  & > .text,
  & > .icon {
    word-break: break-word;
    box-sizing: border-box;
    transform: scale(1.03);
    & > i {
      font-size: 38px;
    }
  }
  & > .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 72px;
    height: auto;
    background: black;
    color: white;
  }
  & > .text {
    font-family: "Roboto", sans-serif;
    flex: 1 1 auto;
    flex-direction: column;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    margin: 0;
    display: flex;
    padding: 16px 24px 15px 19px;
    text-align: justify;
  }
  ${(props) => getCSSForActions(props)}
`;

export const CustomNotification = ({ type, icon, text, appName }) => {
  return <CustomNotificationStyled data-testid="notify" type={ type }>
  <div className="icon" data-testid="notify-icon">
    <OwcIcon className="snackbarIconSizeColor" name={ icon } type="legacy" />
  </div>
  <div className="text" data-testid="notify-text">
    <strong data-testid="notify-name-text">{ appName }</strong>
    { text }
  </div>
</CustomNotificationStyled>
};

export const Notify = ({ icon = "caution", text = "", appName = "Equipment name", type = "warning" }) =>
  toast(<CustomNotification {...{ icon, text, appName, type }} />);

export default Notify;
