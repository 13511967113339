import * as types from "./actionTypes";

export const updateClusterDetail = (data) => ({
  type: types.UPDATE_CLUSTER_DETAIL,
  payload: data
});

export const updateEditClusterDetail = (data) => ({
  type: types.UPDATE_EDIT_CLUSTER_DETAIL,
  payload: data
});
