import React, { useState } from "react";
import styled from "styled-components";
import DATA_LABEL_TABLE from "../../../src/utils/constants/dataLabelTable";
import StatusLogPage from "../status-log/StatusLogPage";
import MasterDataPage from "../master-data/MasterDataPage";
import MasterDataWapper from "../master-data/MasterDataContext";
import { OwcTab, OwcTabs } from "@one/react";

export const ImportTabStyled = styled.div`
  width: 100%;
  padding: 0 0 20px 0;
  margin-top: 20px;
  background: var(--one-color-cobas-white);
  @media only screen and (max-width: 960px) {
    padding: 0 12px;
  }
`;

const ImportTabs = () => {
  const [value, setValue] = useState("equipmentImport");
  const handleChange = (e) => {
    setValue(e.detail);
  };

  return (
    <ImportTabStyled>
      <OwcTabs data-testid="admin-tabs" value={value} onTabChange={handleChange}>
        <OwcTab
          data-testid="admin-tab-drop-booking"
          label={DATA_LABEL_TABLE.IMPORT_TABS.equipment_import}
          value="equipmentImport"
        >
          {DATA_LABEL_TABLE.IMPORT_TABS.equipment_import}
        </OwcTab>
        <OwcTab
          data-testid="admin-tab-reason-management"
          label={DATA_LABEL_TABLE.IMPORT_TABS.master_data_import}
          value="masterDataImport"
        >
          {DATA_LABEL_TABLE.IMPORT_TABS.master_data_import}
        </OwcTab>
      </OwcTabs>
      {value === "equipmentImport" && <StatusLogPage />}
      {value === "masterDataImport" && (
        <MasterDataWapper>
          <MasterDataPage />
        </MasterDataWapper>
      )}
    </ImportTabStyled>
  );
};

export default ImportTabs;
