import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PaginationWrapper from "../../components/shared/pagination/PaginationWrapper";
import { DEFAULT_IR_PAGE_SIZE, equipmentImportStepContent } from "../../constants";
import StatusLogTable from "./StatusLogTable";
import StatusLogTopBar from "./StatusLogTopBar";
import { useSelector } from "react-redux";
import CommonWrapper from "../../common-context/CommonWrapper";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { FileContextWapper } from "../importFile/FileContext";
import { IR_EQUIPMENT_UPLOAD_TARGET_MODULE } from "../../constants";
import Notify from "../notifications/Notify";
import UploadComponent from "../importFile/components/UploadComponent";
import StaticStepContent from "../../components/shared/import/views/StaticStepContent";
import { OwcButton, OwcHyperlink } from "@one/react";
import { ImportCSVButton } from "../importFile/components/ImportCSVButton";

const StatusLogContainer = styled.div`
  padding: 0 20px 20px 20px;
  margin-top: 1.125rem;
`;

export const StepSecFooterContainer = styled.div`
  display: flex;
  margin-bottom: 50px;
`;

export const StepSecFooterBtn = styled.div`
  display: flex;
  justify-content: end;
  width: 50%;
  padding-right: 5%;
`;

const StatusLogPage = () => {
  let lastPageSize = useSelector((state) => state?.user?.lastPageSize);

  const stepContents = equipmentImportStepContent;
  const [importedUsers, setImportedUsers] = useState([]);
  const [importedBy, setImportedBy] = useState("");
  if (typeof lastPageSize === "string" && lastPageSize !== "") {
    lastPageSize = JSON.parse(lastPageSize);
  }
  // Track page view
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView({
      documentTitle: "Status Log",
      href: `${window.location?.origin}/status-log`
    });
    // eslint-disable-next-line
  }, []);

  const initialValues = {
    fetching: false,
    prevTokens: [],
    nextToken: null,
    nextData: 0,
    limit: lastPageSize?.ir_status_log ? lastPageSize?.ir_status_log : DEFAULT_IR_PAGE_SIZE.ir_status_log,
    data: [],
    sortDirection: "DESC",
    orderBy: "uploadDate",
    refreshData: 0
  };
  const handleerr = (event) => {
    try {
      Notify({
        type: "success",
        icon: "circle_confirm",
        appName: "",
        text: `Template downloaded successfully.`
      });
    } catch (err) {
      console.warn(err);
      return null;
    }
  };
  return (
    <StatusLogContainer data-testid="status-log-container">
      <StaticStepContent stepContents={stepContents} />
      <StepSecFooterContainer>
        <StepSecFooterBtn>
          <OwcButton data-testid="confirm-dialog-actions-button-approve" onClick={handleerr} variant="primary">
            <OwcHyperlink
              href={"/files/Equipment_Data_Upload_Template.csv"}
              prose
              color="white"
              style={{ color: "white", textDecoration: "none" }}
              target="_parent"
            >
              Download template
            </OwcHyperlink>
          </OwcButton>
        </StepSecFooterBtn>
        <StepSecFooterBtn>
          <FileContextWapper>
            <UploadComponent
              buttonContent={<ImportCSVButton />}
              targetModule={IR_EQUIPMENT_UPLOAD_TARGET_MODULE}
              isActionButtonDisabled={false}
            />
          </FileContextWapper>
        </StepSecFooterBtn>
      </StepSecFooterContainer>
      <StatusLogTopBar importedUsers={importedUsers} setImportedBy={setImportedBy} />
      <PaginationWrapper initialValues={initialValues}>
        <CommonWrapper>
          <StatusLogTable isMasterDataTable={false} setImportedUsers={setImportedUsers} importedBy={importedBy} />
        </CommonWrapper>
      </PaginationWrapper>
    </StatusLogContainer>
  );
};

export default StatusLogPage;
