import { DEFAULT_SITE_NAME, DEFAULT_SITE_TIMEZONE } from "../../constants";

export const emptyInstrumentImport = {
  siteName: DEFAULT_SITE_NAME,
  siteTimezone: DEFAULT_SITE_TIMEZONE,
  belongingToGroup: "",
  room: {
    key: "",
    value: ""
  },
  floor: {
    key: "",
    value: ""
  },
  buildingLocation: {
    key: "",
    value: ""
  },
  instrumentGTIN: "",
  qualificationStatus: "",
  instrumentRUDI: "",
  equipmentModel: "",
  isBookable: false,
  isVisualized: false,
  materialNumber: "",
  responsiblePerson: "",
  softwareVersion: "",
  serialNumber: "",
  configurationBaseline: "",
  dateOfLastMaintanance: null,
  dateOfNextMaintanance: null,
  qualificationDocuments: {
    isSynchronized: false,
    value: []
  },
  installedTests: [],
  responsibleProxy: "",
  equipmentId: null,
  manufacturer: "",
  systemOwner: "",
  comment: "",
  sop: {
    key: "",
    value: ""
  },
  csv: "",
  electronicRecord: "",
  electronicSignatures: "",
  dateOfNextPeriodicReview: "",
  createdAt: "",
  updatedAt: "",
  maintenanceIntervalInDays: "",
  maintenancePlan: "",
  gxpRelevant: "",
  location: "",
  equipmentCategory: "",
  cluster: "",
  equipmentAdministrator: "",
  equipmentNickName: "",
  functionalLocation: "",
  costCenter: "",
  inventoryNumber: null
};

export const emptyInstruments = {
  siteName: DEFAULT_SITE_NAME,
  siteTimezone: DEFAULT_SITE_TIMEZONE,
  belongingToGroup: "",
  room: {
    key: "",
    value: ""
  },
  floor: {
    key: "",
    value: ""
  },
  instrumentGTIN: "",
  buildingLocation: {
    key: "",
    value: ""
  },
  qualificationStatus: "",
  instrumentRUDI: "",
  equipmentModel: "",
  isBookable: false,
  isVisualized: false,
  materialNumber: "",
  responsiblePerson: "",
  softwareVersion: "",
  serialNumber: "",
  configurationBaseline: "",
  dateOfLastMaintanance: null,
  dateOfNextMaintanance: null,
  qualificationDocuments: {
    isSynchronized: false,
    value: []
  },
  installedTests: [],
  responsibleProxy: "",
  equipmentId: null,
  manufacturer: "",
  systemOwner: "",
  comment: "",
  sop: {
    key: "",
    value: ""
  },
  csv: "",
  electronicRecord: "",
  electronicSignatures: "",
  dateOfNextPeriodicReview: "",
  createdAt: "",
  updatedAt: "",
  maintenancePlan: "",
  gxpRelevant: "",
  location: "",
  equipmentCategory: "",
  cluster: "",
  equipmentAdministrator: "",
  equipmentNickName: "",
  functionalLocation: "",
  costCenter: "",
  inventoryNumber: null
};
