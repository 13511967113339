import React, { useContext, useEffect } from "react";
import { compose, withApollo } from "react-apollo";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { FilterWrapperContext } from "./instruments-context/context";
import { DEFAULT_FILTER, dropDownSelectionSource } from "../../../constants";
import { PaginationContext } from "../../../components/shared/pagination/PaginationContext";
import { GridAutocomplete } from "@digitallab/grid-common-components";
import { GridAutoCompleteStyles } from "../../../components/shared/GridAutoCompleteStyles";
import { useState } from "react";
import { find } from "lodash";

function PreDefinedFilters({
  lastFavFilter,
  onFilterSourceSelection = null,
  getPredefinedValue = null,
  preDefinedFilter = null
}) {
  const userFilters = useSelector((state) => state?.user?.userFilters);
  const myLabFilter = useSelector((state) => state?.user?.myLabFilter);
  const { setFilterObj, saveLastFavFilter, setCurrentFilter, setValue } = useContext(FilterWrapperContext);
  const [allFilterData, setAllFilterData] = useState([]);
  const [predefinedValue, setPreDefinedValue] = useState(null);
  const { dispatchAction } = useContext(PaginationContext);
  const handleItemClick = (item) => {
    if (getPredefinedValue) {
      getPredefinedValue(item);
    } else {
      setFilterObj(item?.filterDefinition);
      saveLastFavFilter(item);
      setCurrentFilter(item);
      if (onFilterSourceSelection) {
        onFilterSourceSelection(dropDownSelectionSource?.nonAgGridFilter);
      }
      dispatchAction({
        type: "refresh"
      });
    }
  };

  const handleDefaultClick = () => {
    if (getPredefinedValue) {
      getPredefinedValue(DEFAULT_FILTER);
    } else {
      setFilterObj(DEFAULT_FILTER?.filterDefinition);
      saveLastFavFilter(DEFAULT_FILTER);
      if (onFilterSourceSelection) {
        onFilterSourceSelection();
      }
      dispatchAction({
        type: "refresh"
      });
    }
  };

  useEffect(() => {
    if (!getPredefinedValue) {
      setValue(lastFavFilter?.filterName || DEFAULT_FILTER?.filterName);
    }
    setPreDefinedValue(
      preDefinedFilter
        ? preDefinedFilter?.filterName === ""
          ? null
          : preDefinedFilter?.filterName
        : lastFavFilter?.filterName === ""
        ? null
        : lastFavFilter?.filterName
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastFavFilter]);

  useEffect(() => {
    const filters =
      myLabFilter?.filterDefinition && myLabFilter?.filterDefinition?.inventoryIds?.length > 0
        ? [myLabFilter, ...userFilters]
        : [...userFilters];
    setAllFilterData(() => {
      return filters?.map((value) => value?.filterName);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFilters]);

  return (
    <div style={{ marginLeft: "20px" }}>
      <GridAutocomplete
        optionsList={allFilterData}
        label={"Predefined filters"}
        key={predefinedValue}
        value={predefinedValue}
        onAutoCompleteChange={(selectedValue) => {
          const filterObj = selectedValue
            ? find([myLabFilter, ...userFilters], { filterName: selectedValue })
            : DEFAULT_FILTER;
          if (!getPredefinedValue) {
            setValue(filterObj);
          }
          setPreDefinedValue(selectedValue ? filterObj?.filterName : null);
          if (!filterObj) {
            handleDefaultClick();
          } else {
            handleItemClick(filterObj);
          }
        }}
        size="small"
        disabled={[myLabFilter, ...userFilters]?.length === 0}
        sx={{
          width: 185,
          marginRight: 1,
          ...GridAutoCompleteStyles?.gridAutoCompleteLableShrink
        }}
      />
    </div>
  );
}

const mapStateToProps = (store) => ({
  lastFavFilter: store?.user?.lastFavFilter
});

export default compose(connect(mapStateToProps, {}), withApollo)(PreDefinedFilters);
