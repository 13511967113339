import {
  CREATE_IR_REASON_MASTER,
  DELETE_IR_REASON_MASTER,
  UPDATE_IR_REASON_MASTER
} from "../../gql/landingapi/mutations";
import { LIST_DIGITAL_LAB_INSTRUMENT_REASON_UNICITY_CHECK } from "../../gql/landingapi/queries";
import { getAllData } from "../../utils/helpers/fetching";
import Notify from "../notifications/Notify";

export const checkUnicityForReason = async (configText, { client }) => {
  let variables = { filter: configText };
  const { items } = await getAllData({
    client,
    query: LIST_DIGITAL_LAB_INSTRUMENT_REASON_UNICITY_CHECK,
    variables,
    fetchPolicy: "network-only",
    dataPath: ["data", "configurationByModelAndCreatedDate"],
    drillData: false
  });
  return items.length > 0 ? false : true;
};

export const submit = async ({ dispatchAction, client, setLoading, formik, isEditMode, setDialogOpen }) => {
  setLoading(true);
  let checkUnicityStatus = await checkUnicityForReason(formik.values?.configText?.trim(), { client });
  if (checkUnicityStatus) {
    let row = {
      configText: formik.values?.configText?.trim()
    };
    if (isEditMode) {
      row.id = formik.values?.reasonId;
    }
    try {
      await client.mutate({
        mutation: isEditMode ? UPDATE_IR_REASON_MASTER : CREATE_IR_REASON_MASTER,
        variables: row,
        fetchPolicy: "no-cache"
      });
      dispatchAction({
        type: "refresh"
      });
      setDialogOpen(false);
      Notify({
        type: "success",
        icon: "circle_confirm",
        appName: "",
        text: isEditMode ? `Reason updated successfully! ` : `Reason created successfully! `
      });
    } catch (error) {
      console.log("error", error);
      setDialogOpen(true);
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: isEditMode ? `Failed to update reason!` : `Failed to create reason! `
      });
    } finally {
      setLoading(false);
    }
  } else {
    setLoading(false);
    setDialogOpen(true);
    Notify({
      type: "warning",
      icon: "caution",
      appName: "",
      text: "Reason already exist"
    });
  }
};

export const deleteReason = async ({ client, formik, dispatchAction, setDialogCancelOpen }) => {
  let row = {
    id: formik.values.reasonId
  };
  try {
    await client.mutate({
      mutation: DELETE_IR_REASON_MASTER,
      variables: row,
      fetchPolicy: "no-cache"
    });
    dispatchAction({
      type: "refresh"
    });
    setDialogCancelOpen(false);
    Notify({
      type: "success",
      icon: "circle_confirm",
      appName: "",
      text: `Reason deleted successfully! `
    });
  } catch (error) {
    setDialogCancelOpen(false);
    Notify({
      type: "warning",
      icon: "caution",
      appName: "",
      text: `Failed to delete reason! `
    });
  }
};

export const addButtonClick = async ({ formik, setIsEditMode, setDialogOpen }) => {
  setIsEditMode(false);
  formik.resetForm();
  formik.setFieldValue("configText", "", false);
  formik.setFieldValue("reasonId", "", false);
  setDialogOpen(true);
};
export const closeDialog = (setDialogOpen) => {
  setDialogOpen(false);
};

export const cancelCloseDialog = (setDialogCancelOpen) => {
  setDialogCancelOpen(false);
};
