import * as types from "./actionTypes";

export const loadUserInfo = (userInfo) => ({
  type: types.LOAD_USER_INFO,
  payload: userInfo
});

export const loadLastFilter = (lastFilter) => ({
  type: types.LOAD_LAST_FILTER,
  payload: lastFilter
});

export const updateUserFilters = (UserFilter) => ({
  type: types.UPDATE_USER_FILTERS,
  payload: UserFilter
});
export const updateUserDisplayColumns = (UserDisplayColumns) => ({
  type: types.UPDATE_USER_DISPLAY_COLUMNS,
  payload: UserDisplayColumns
});
export const updateLastFavFilter = (lastFavFilter) => ({
  type: types.UPDATE_LAST_FAV_FILTER,
  payload: lastFavFilter
});
export const updateLastPageSize = (lastPageSize) => ({
  type: types.UPDATE_LAST_PAGE_SIZE,
  payload: lastPageSize
});
export const updateLastDisplayColumn = (lastDisplayColumn) => ({
  type: types.UPDATE_USER_LAST_DISPLAY_COLUMN,
  payload: lastDisplayColumn
});
export const updateSystemDisplayColumn = (systemDisplayColumn) => ({
  type: types.UPDATE_USER_SYSTEM_DISPLAY_COLUMN,
  payload: systemDisplayColumn
});
export const updateSearchPreference = (payload) => ({
  type: types.UPDATE_SEARCH_PREFERENCE,
  payload: payload
});
export const loadActionStatus = (actionStatus) => ({
  type: types.LOAD_ACTION_STATUS,
  payload: actionStatus
});
