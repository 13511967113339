import { OwcChip } from "@one/react";
import { equipmentStatus, statusChip } from "../../constants";

const EquipmentStatusRender = (params) => {
  return (
    <>
      <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
        {params.value[0] !== null ? (
          <OwcChip
            outlined={true}
            style={{
              minHeight: "22px",
              color:
                params.value[0] === equipmentStatus?.deleted?.key
                  ? "var(--one-color-red-a-300)"
                  : params.value[0] === equipmentStatus?.pending?.key
                  ? "var(--one-color-yellow-a-300)"
                  : "var(--one-color-cobas-green-600)"
            }}
          >
            <strong>{statusChip[params.value[0]]}</strong>
          </OwcChip>
        ) : (
          "-"
        )}
      </div>
    </>
  );
};
export default EquipmentStatusRender;
