import React, { useState } from "react";
import styled from "styled-components";
import AssaysDataForm from "../AssaysDataForm";
import { useFormikContext } from "formik";
import { OwcButton, OwcIcon, OwcInput, OwcTypography } from "@one/react";
import DigitalLabStep from "./DigitalLabStep";
import { SoftwareVersionInput } from "./SoftwareVersionInput";
import { ConfigurationBaselineInput } from "./ConfigurationBaselineInput";
import { ConfigurationDetails, DATA_MODEL_TABLE, THEME } from "@digitallab/grid-common-components";

const testStyle = {
  color: "var(--one-color-background-brand-1)",
  position: "absolute",
  right: "30px"
};

const InstallDocumentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  & > .selectBoxLarge {
    margin: 0 20px 20px 0;
    width: 321px;
    max-width: 321px;
  }
`;
const InstallContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 520px;
  background: var(--one-color-cobas-white);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
  z-index: 10;
  border-radius: 4px;
`;

const AddCancelButton = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 1rem;
  margin-right: 1rem;
`;

const AnalyzerConfiguration = ({ isEditMode }) => {
  const [showInstalledTestPopUp, setShowInstalledTestPopUp] = useState(false);
  const formik = useFormikContext();
  const handleClosePopup = () => {
    if (showInstalledTestPopUp) {
      setShowInstalledTestPopUp(false);
    }
  };

  const handleShowPopup = (value) => {
    if (value === "installedTest") {
      setShowInstalledTestPopUp(!showInstalledTestPopUp);
    }
  };

  const getTestValue = (selected) => {
    if (selected !== null) {
      if (selected?.length === 0) {
        return "";
      } else if (selected?.length > 0) {
        return `${selected[0]?.name}, ${selected[0]?.version}`;
      }
    } else {
      return "";
    }
  };

  return (
    <>
      {!isEditMode && (
        <OwcTypography variant="subtitle2" style={{ padding: "0px 20px 20px 0px" }}>
          Configuration and availability
        </OwcTypography>
      )}
      <OwcTypography variant="subtitle2" style={{ padding: "0 20px 20px 0" }}>
        Configuration
      </OwcTypography>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(auto-fit, minmax(${THEME["one-sizer-base-400"]}, 1fr))`,
          gap: THEME["one-spacer-12"]
        }}
      >
        <SoftwareVersionInput onFocus={handleClosePopup} />
        <ConfigurationBaselineInput onFocus={handleClosePopup} />
        <InstallDocumentContainer className="container">
          <OwcInput
            style={{ width: "100%" }}
            data-testid={`text-field-installed-test`}
            label={DATA_MODEL_TABLE.installedTests.value}
            name="installedTests"
            size="medium"
            autoComplete="off"
            onClick={() => {
              handleShowPopup("installedTest");
            }}
            value={getTestValue(formik.values?.installedTests)}
          >
            <div slot="suffix">
              {formik.values?.installedTests !== null && formik.values?.installedTests?.length > 1 ? (
                <div style={testStyle}> +{formik.values?.installedTests?.length - 1}</div>
              ) : (
                ""
              )}
              <OwcIcon
                name="circle_add"
                type="outlined"
                onClick={() => {
                  handleShowPopup("installedTest");
                }}
                data-testid={`show-popup-installedTest`}
              />
            </div>
          </OwcInput>

          <InstallContainer style={{ display: showInstalledTestPopUp ? "block" : "none" }}>
            {showInstalledTestPopUp ? <AssaysDataForm formik={formik} /> : ""}
            <AddCancelButton>
              <OwcButton variant="primary" onClick={handleClosePopup} data-testid={`close-popup-close-installedTest`}>
                Close
              </OwcButton>
            </AddCancelButton>
          </InstallContainer>
        </InstallDocumentContainer>
        <div style={{ gridColumn: "span 2" }}>
          <ConfigurationDetails formik={formik} onFocus={handleClosePopup} />
        </div>
      </div>

      <DigitalLabStep />
    </>
  );
};

export default AnalyzerConfiguration;
