import { ChecklistTrailLog } from "./ChecklistTrailLog";
import { CHECKLIST_STATUS_ENUM } from "../../constants";
import { ActionMenu, DialogServiceContext } from "@digitallab/grid-common-components";
import { useContext } from "react";

export const ChecklistActions = ({ data, handleDelete }) => {
  const dialogService = useContext(DialogServiceContext);
  const actionsList = [
    {
      disabled: data.status === CHECKLIST_STATUS_ENUM.DELETED,
      body: "Remove checklist",
      handleOnClick: () => {
        dialogService
          .show({
            title: "Remove checklist",
            body: "Do you want to remove this checklist?",
            confirmText: "Remove checklist"
          })
          .then(() => {
            handleDelete(data);
          })
          .catch(() => {});
      }
    },
    {
      body: "View audit trail",
      handleOnClick: () => {
        dialogService.show({
          title: "Log entry information",
          width: "1000px",
          body: <ChecklistTrailLog data={data} />,
          confirmOnly: true
        });
      }
    }
  ];

  return <ActionMenu id={`action-menu-${data.createdAt}`} actionsList={actionsList} />;
};
