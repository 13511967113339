import React from "react";
import { uniq } from "underscore";
import { Autocomplete, createFilterOptions, TextField, Typography } from "@mui/material";

const filter = createFilterOptions();

const onChangeItemToValue = (newValue, { propValue, propLabel }) => {
  const selectedMediaTypes = newValue.map((item) => {
    if (typeof item === "object" && item.inputValue) {
      item[propLabel] = item.inputValue;
      item[propValue] = item.inputValue.replace(/\s/g, "_") + new Date().toISOString();
      delete item["inputValue"];
    }
    return item;
  });

  return uniq(selectedMediaTypes, (item) => item[propLabel].toLowerCase());
};

const CustomSearchText = ({
  value,
  id,
  dataTestid,
  optionsList,
  setValue,
  label,
  propValue,
  propLabel,
  fullWidth,
  multiple,
  error,
  helperText,
  required = false,
  ...props
}) => {
  const renderTags = (selectedObjects) => {
    let renderTagValue = selectedObjects?.map((selectedObject) => {
      const chipLabel = selectedObject?.[propLabel];
      return selectedObjects.length === 1 ? `${chipLabel}` : `${chipLabel}, `;
    });
    return (
      <>
        <Typography style={{ width: "120px", maxWidth: "120px" }} noWrap={true}>
          {renderTagValue[0]}
        </Typography>
        {renderTagValue.length > 1 ? (
          <span style={{ color: "var(--one-color-background-brand-1)" }}>+{renderTagValue.length - 1}</span>
        ) : (
          ""
        )}
      </>
    );
  };

  const handleOnChange = (event, newValue) => {
    setValue(
      onChangeItemToValue(newValue, {
        propValue,
        propLabel
      })
    );
  };

  const handleOnFilterOptions = (options, params) => {
    const filtered = filter(options, params);
    return filtered;
  };

  const handleOnRenderInput = (params) => (
    <TextField
      {...params}
      label={label}
      variant="filled"
      error={error}
      helperText={helperText}
      data-testid={`${dataTestid}-text-field`}
      required={required}
      size="small"
    />
  );

  return (
    <>
      <Autocomplete
        data-testid={dataTestid}
        id={id}
        disableCloseOnSelect={true}
        multiple
        limitTags={1}
        value={value}
        onChange={handleOnChange}
        options={optionsList}
        filterOptions={handleOnFilterOptions}
        getOptionLabel={(option) => option[propLabel]}
        renderInput={handleOnRenderInput}
        // renderOption={(props, option) => (
        //   <>
        //     <div data-testid={`${dataTestid}-${option[propValue]}`}>
        //       {option[propLabel]}
        //     </div>
        //   </>
        // )}
        selectOnFocus
        clearOnBlur
        renderTags={renderTags}
        openOnFocus={true}
        {...props}
      />
    </>
  );
};

export default CustomSearchText;
