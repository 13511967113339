import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import DescriptionCard from "./DescriptionCard";
import BackendInfo from "./BackendInfo";
import useAuthenticatedUser from "../../utils/hooks/useAuthenticatedUser";
import { getEnv } from "../../utils/helpers/text";
import { OwcButton } from "@one/react";

export const ModuleVersionStyled = styled.div`
  width: 100%;
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #888;
  padding: 10px 0;
  overflow-y: auto;
  bottom: 50px;
  position: fixed;
  top: ${(props) => (props.currentEnv ? "42px" : 0)};
  height: ${(props) => (props.currentEnv ? "85%" : "92%")};
  }
`;

export const InfoHeaderStyled = styled.div`
  display: flex;
  padding: 15px 0;
  justify-content: flex-start;
  width: 80%;
  max-width: 800px;
`;

const ModuleVersion = ({ redirectPath = "/" }) => {
  const history = useHistory();
  const user = useAuthenticatedUser();
  const currentEnv = getEnv();
  return (
    <ModuleVersionStyled data-testid="info-page" currentEnv={currentEnv}>
      <InfoHeaderStyled style={currentEnv ? { marginTop: "180px" } : { marginTop: "120px" }}>
        {user && (
          <OwcButton
            data-testid="info-page-action-button-go-back"
            variant="secondary"
            onClick={() => {
              history?.goBack();
            }}
          >
            Go back
          </OwcButton>
        )}
      </InfoHeaderStyled>
      <DescriptionCard title="Frontend information" />
      <BackendInfo />
      <OwcButton
        data-testid="info-page-action-button-module-version"
        variant="primary"
        onClick={() => {
          history?.replace(redirectPath);
        }}
      >
        Go home
      </OwcButton>
    </ModuleVersionStyled>
  );
};

export default ModuleVersion;
