import React from "react";
import { DATA_UPLOAD_URL } from "../../gql/landingapi/queries";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { UPDATE_IR_INBOX } from "../../gql/landingapi/mutations";
import Notify from "../notifications/Notify";
import { useContext } from "react";
import { ExportCsvContext } from "./context";
import { OwcIconButton } from "@one/react";
import Tooltip from "@mui/material/Tooltip";

const Actions = ({ client, item }) => {
  const { setSelectedItem } = useContext(ExportCsvContext);
  const getPreSignedUrl = async () => {
    try {
      const presignedUrlData = await client.query({
        query: DATA_UPLOAD_URL,
        fetchPolicy: "no-cache",
        variables: {
          targetModule: "EXPORTCSV",
          fileName: item.fileName,
          contentType: "text/csv",
          s3VersionId: item.s3VersionId
        }
      });

      if (!presignedUrlData?.data?.dataUploadUrl?.presignedUrl) {
        Notify({
          type: "warning",
          icon: "caution",
          appName: "",
          text: "Export download failed!."
        });
      } else {
        const downloadPath = presignedUrlData?.data?.dataUploadUrl?.presignedUrl;
        const downloadLink = document.createElement("a");
        downloadLink.setAttribute("type", "hidden");
        downloadLink.href = downloadPath;
        downloadLink.download = item.fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.remove();
        updateDownloadStatus(item);
      }
    } catch (error) {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: "Export download failed!."
      });
    }
  };

  const updateDownloadStatus = async (item) => {
    if (item.status === "COMPLETED" || item.status === "VIEWED") {
      try {
        await client.mutate({
          mutation: UPDATE_IR_INBOX,
          fetchPolicy: "no-cache",
          variables: {
            status: "DOWNLOADED",
            id: item.id
          }
        });
        item.status = "DOWNLOADED";
        item.selected = false;
      } catch (error) {}
    }
  };

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "right" }}>
      <Tooltip
        data-testid={"download-export-csv-button-tooltip" + item.fileName}
        title={"Download export CSV"}
        placement="left"
        arrow
      >
        <OwcIconButton
          key={`booking-admin-table-edit-instrument-button-${item.fileName}`}
          data-testid={`booking-admin-table-edit-instrument-button-${item.fileName}`}
          onClick={() => {
            if (item.status === "FAILED") {
              Notify({
                type: "warning",
                icon: "caution",
                appName: "",
                text: "Export file corrupted, please export again"
              });
            } else {
              setSelectedItem(item);
              getPreSignedUrl();
            }
          }}
          icon="download"
          type="legacy"
          flat
        />
      </Tooltip>
    </div>
  );
};

export default compose(connect(null, {}), withApollo)(Actions);
