import * as yup from "yup";

/**
 *
 * @param {attributesValidationSchema} schema the schema which is used to validate that field
 * @param {changeTo} changeTo param to change the value if we need, default to empty string
 * @returns
 */
const getCustomNullable = (schema, changeTo = "") => schema.nullable().transform((value) => value ?? changeTo);

/**
 * schema for validating the equpiment identification form fields
 * have used formik and yup for setting up the validations
 * input type of select is validated against key, value or string based on the options
 * input type of text is validated against string
 */
export const attributesValidationSchema = yup.object({
  equipmentCategory: getCustomNullable(
    yup.string("Enter category").typeError("Invalid type").required("Category is required")
  ),
  manufacturer: getCustomNullable(
    yup.string("Enter manufacturer").typeError("Invalid type").required("Manufacturer is required")
  ),
  equipmentModel: getCustomNullable(yup.string("Enter model").typeError("Invalid type").required("Model is required")),
  variant: getCustomNullable(yup.string("Enter variant").typeError("Invalid type")),
  displayImage: getCustomNullable(yup.string("Enter displayImage").typeError("Invalid type"))
});
