import { Typography } from "@mui/material";
import { OwcButton } from "@one/react";
import React from "react";
import { Query } from "react-apollo";
import styled from "styled-components";
import ConnectSubscription from "./ConnectSubscription";

const ErrorStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;

export const Error = ({ refetch, title = "Cannot connect to endpoint" }) => {
  return (
    <ErrorStyled data-testid="bookingNotificationStatus-error">
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <OwcButton data-testid="bookingNotificationStatus-error-retry" variant="error" onClick={() => refetch()}>
        Retry
      </OwcButton>
    </ErrorStyled>
  );
};

const GenericSubscription = ({ query, subscription, onUpdateCallback, variables }) => {
  return (
    <Query query={query} fetchPolicy="no-cache" variables={variables}>
      {({ loading, error, subscribeToMore, refetch }) => {
        if (loading) return null;
        if (error !== undefined) return <Error refetch={refetch} />;
        return (
          <ConnectSubscription
            subscribeToMore={subscribeToMore}
            documentSubscription={subscription}
            dataCallback={onUpdateCallback}
            variables={{ limit: 1 }}
            refetch={refetch}
          />
        );
      }}
    </Query>
  );
};

export default GenericSubscription;
