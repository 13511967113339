import React, { useContext } from "react";
import AppBar from "./AppBarNotAuth";
import MaintenanceScreen from "../components/MaintenanceScreen";
import { BackendInfoContext } from "../features/backend-info-context/context";
import Main from "../Main";

const MainPageMaintenance = () => {
  const status = false;
  const { maintenance } = useContext(BackendInfoContext);
  if (maintenance)
    return (
      <>
        <AppBar disabled={status ? false : true} />
        <MaintenanceScreen
          text={"The application is currently undergoing scheduled maintenance. We should be back shortly"}
          title={"This application is under maintenance"}
        />
      </>
    );
  return <Main />;
};
export default MainPageMaintenance;
