import { useFormikContext } from "formik";
import { useEffect, useRef, useState } from "react";
import { OwcButton, OwcExpandable, OwcExpandableGroup, OwcWorkflowOverlay } from "@one/react";
import { connect } from "react-redux";
import { compose, withApollo } from "react-apollo";
import { AddContainer } from "../instruments/addEditEquipment/AddEquipmentStyle";
import ConfirmDialog from "../../components/shared/ConfirmDialog";
import useDialog from "../../utils/hooks/useDialog";
import MasterDataAttributes from "./MasterDataAttributes";
import MasterDataComplexAttributes from "./MasterDataComplexAttributes";
import MasterDataDimensions from "./MasterDataDimensions";
import { UPDATE_IR_CATEGORY_MAPPING } from "../../gql/landingapi";
import Notify from "../notifications/Notify";
import { updateEditMasterDataDetail as updateEditMasterDataDetailAction } from "../instruments/redux/actions";
import { isEqual } from "lodash";
import { OTHER } from "../../constants";
import { CloudSearchConfig } from "../../components/shared/CloudSearchConfig";

const MasterDataEditDialog = ({
  open,
  setOpen,
  masterDataDetail,
  isEditMode,
  client,
  setRefreshFlag,
  userId,
  updateEditMasterDataDetail,
  editMasterDataDetail
}) => {
  const formik = useFormikContext();
  const [isReasonUpdate, setIsReasonUpdate] = useState(false);
  const [saveReason, setSaveReason] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const { openDialog, ...dialogProps } = useDialog();
  const expandableGroupElement = useRef(null);
  const [expandID, setExpandID] = useState(0);
  const [affectedEquipments, setAffectedEquipments] = useState(0);
  const [masterDataRewritten, setMasterDataRewritten] = useState(false);
  const originalMasterData = { ...masterDataDetail };

  useEffect(() => {
    loadAffectedEquipment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentEquipmentObj = { ...masterDataDetail };
    formik.setValues({
      ...formik.values,
      ...currentEquipmentObj
    });
    updateEditMasterDataDetail(currentEquipmentObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterDataDetail]);

  useEffect(() => {
    if (isEditMode) {
      let tempFormikValues = updateKeys(formik?.values);
      let tempEditMasterDataDetail = updateKeys(editMasterDataDetail);
      setIsSubmitDisabled(() => isEqual(tempEditMasterDataDetail, tempFormikValues));
      setMasterDataRewritten(
        !["equipmentCategory", "manufacturer", "equipmentModel", "variant"].every(
          (field) => tempFormikValues[field] === originalMasterData[field]
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values]);

  const loadAffectedEquipment = async () => {
    const result = await CloudSearchConfig({
      start: 0,
      size: 100,
      searchValue:
        `equipment_category:"${originalMasterData.equipmentCategory}"` +
        ` AND manufacturer:"${originalMasterData.manufacturer}"` +
        ` AND equipment_model:"${originalMasterData.equipmentModel}"` +
        (originalMasterData.variant ? ` AND variant:"${originalMasterData.variant}"` : ` AND -variant:*`),
      sort: "asc",
      sortBy: "equipment_category",
      fields: "_no_fields"
    });
    setAffectedEquipments(result?.count ?? 0);
  };

  const updateKeys = (updatedDetail) => {
    return {
      ...updatedDetail,
      displayImage: updatedDetail?.displayImage || "",
      weightInkg: parseFloat(updatedDetail?.weightInkg) || null,
      heightInmm: parseInt(updatedDetail?.heightInmm) || null,
      lengthInmm: parseInt(updatedDetail?.lengthInmm) || null,
      depthInmm: parseInt(updatedDetail?.depthInmm) || null,
      clearanceLeftInmm: parseInt(updatedDetail?.clearanceLeftInmm) || null,
      clearanceRightInmm: parseInt(updatedDetail?.clearanceRightInmm) || null,
      clearanceHeightInmm: parseInt(updatedDetail?.clearanceHeightInmm) || null,
      clearanceToFrontInmm: parseInt(updatedDetail?.clearanceToFrontInmm) || null,
      clearanceBackwardsInmm: parseInt(updatedDetail?.clearanceBackwardsInmm) || null,
      heatOutputInW: parseInt(updatedDetail?.heatOutputInW) || null,
      noiseIndBA: parseInt(updatedDetail?.noiseIndBA) || null
    };
  };

  const submitHandle = async (masterData) => {
    let shouldClose = true;
    const originalMasterDataKeys = {
      equipmentCategory: originalMasterData.equipmentCategory,
      manufacturer: originalMasterData.manufacturer,
      equipmentModel: originalMasterData.equipmentModel,
      modelName: originalMasterData.modelName,
      variant: originalMasterData.variant
    };

    try {
      let inputData = {
        originalMasterDataKeys,
        changes: {
          modelName: "IRCategoryMapping",
          id: masterData.id,
          isActive: masterData.isActive,
          equipmentCategory: masterData.equipmentCategory,
          manufacturer: masterData.manufacturer,
          equipmentModel: masterData.equipmentModel,
          variant: masterData.variant,
          displayImage: masterData.displayImage,
          weightInkg: parseFloat(masterData.weightInkg),
          heightInmm: parseInt(masterData.heightInmm),
          lengthInmm: parseInt(masterData.lengthInmm),
          depthInmm: parseInt(masterData.depthInmm),
          clearanceLeftInmm: parseInt(masterData.clearanceLeftInmm),
          clearanceRightInmm: parseInt(masterData.clearanceRightInmm),
          clearanceHeightInmm: parseInt(masterData.clearanceHeightInmm),
          clearanceToFrontInmm: parseInt(masterData.clearanceToFrontInmm),
          clearanceBackwardsInmm: parseInt(masterData.clearanceBackwardsInmm),
          modifiedById: userId,
          editReason: masterData.configText,
          editComment: masterData.editComment,
          heatOutputInW: parseInt(masterData.heatOutputInW),
          pressureEquipment: masterData.pressureEquipment,
          noiseIndBA: parseInt(masterData.noiseIndBA),
          noiseSource: masterData.noiseSource,
          noiseSourceDetails: masterData.noiseSourceDetails,
          positioningNote: masterData.positioningNote,
          positioning: masterData.positioning,
          vibrationSensitive: masterData.vibrationSensitive
        }
      };

      const result = await client.mutate({
        mutation: UPDATE_IR_CATEGORY_MAPPING,
        variables: {
          input: inputData
        },
        fetchPolicy: "no-cache"
      });

      const statusCode = result.data.updateIRCategoryMapping.StatusCode;
      if (result) {
        if (statusCode === 200) {
          Notify({
            type: "success",
            icon: "circle_confirm",
            appName: "",
            text: "Master data saved successfully"
          });
        } else if (statusCode === 409) {
          Notify({
            type: "warning",
            icon: "alarm",
            appName: "",
            text: (
              <p>
                Saving of master data failed due to already existing duplicate.
                <br />
                To continue either you change “Basic attributes” or you press “Cancel”
              </p>
            )
          });
          shouldClose = false;
        } else if (result.data.updateIRCategoryMapping.error) {
          Notify({
            type: "warning",
            icon: "caution",
            appName: "",
            text: "Master data details update failed"
          });
        }
      } else {
        Notify({
          type: "warning",
          icon: "caution",
          appName: "",
          text: "Master data details update failed"
        });
      }
    } catch (error) {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: "Master data details update failed"
      });
    } finally {
      if (shouldClose) {
        formik.resetForm();
        setOpen(false);
        setRefreshFlag((prevState) => !prevState);
      } else {
        cancelReasons();
      }
    }
  };

  const updateReasons = () => {
    setIsReasonUpdate(true);
    setSaveReason(true);
  };

  const cancelReasons = () => {
    setIsReasonUpdate(false);
    setSaveReason(false);
  };

  const visibleChangeHandler = (event) => {
    /* Cancel popup implementation */
    if (event?.target?.className === "owcoverayZIndex title has-title") {
      openDialog();
    }
  };

  const expandedElementsChangedHandler = (event) => {
    setExpandID(() => event?.detail[0]);
  };

  return (
    <>
      <OwcWorkflowOverlay
        className="owcoverayZIndex"
        visible={open}
        onVisibleChange={visibleChangeHandler}
        disableBackdropClick
      >
        <>
          <div slot="title">Edit equipment master data</div>
          <div
            slot="content"
            style={{
              position: "relative",
              padding: "0",
              display: "block",
              marginBottom: "0",
              height: "100%"
            }}
          >
            <AddContainer id="add-edit-cluster-container">
              <OwcExpandableGroup
                ref={expandableGroupElement}
                onExpandedElementsChanged={expandedElementsChangedHandler}
                // multiple
              >
                <OwcExpandable variant="standard" round expanded={expandID === 0}>
                  <span slot="title">Basic attributes</span>
                  <span
                    slot="content"
                    style={{
                      height: "fit-content"
                    }}
                  >
                    <MasterDataAttributes isEditMode={isEditMode} />
                  </span>
                </OwcExpandable>
                <OwcExpandable variant="standard" round expanded={expandID === 1}>
                  <span slot="title">Physical dimensions & clearance</span>
                  <span
                    slot="content"
                    style={{
                      height: "fit-content"
                    }}
                  >
                    <MasterDataDimensions isEditMode={isEditMode} />
                  </span>
                </OwcExpandable>
                <OwcExpandable variant="standard" round expanded={expandID === 2}>
                  <span slot="title">Complex attributes</span>
                  <span
                    slot="content"
                    style={{
                      height: "fit-content"
                    }}
                  >
                    <MasterDataComplexAttributes isEditMode={isEditMode} />
                  </span>
                </OwcExpandable>
              </OwcExpandableGroup>
            </AddContainer>
            <ConfirmDialog
              {...dialogProps}
              approveText="Yes"
              approveColor="primary"
              approveVariant="contained"
              cancelText="No"
              cancelVariant="outlined"
              cancelColor="primary"
              onApprove={() => {
                formik.resetForm();
                setOpen(false);
              }}
              title="Cancel equipment master data"
              content="Do you want to cancel it?"
              disableBackdropClick={true}
            />
            <ConfirmDialog
              {...dialogProps}
              open={saveReason}
              isReasonUpdate={isReasonUpdate}
              isCommentVisible={true}
              approveText="OK"
              approveColor="primary"
              approveVariant="contained"
              cancelText="Cancel"
              cancelVariant="outlined"
              cancelColor="primary"
              onApprove={() => submitHandle(formik.values)}
              onCancel={cancelReasons}
              title="Reason for editing equipment master data"
              disableBackdropClick={true}
            />
            {masterDataRewritten && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  bottom: 0
                }}
              >
                <p style={{ fontWeight: "bold", textAlign: "center" }}>
                  Equipment affected by master data change
                  <span
                    style={{
                      backgroundColor: "#FFC414",
                      marginLeft: "0.4rem",
                      padding: "0.3rem 0.6rem",
                      borderRadius: "9999px"
                    }}
                  >
                    {affectedEquipments}
                  </span>
                </p>
              </div>
            )}
          </div>
          <div slot="actions" style={{ padding: "16px" }}>
            <>
              <div style={{ display: "flex" }}>
                <OwcButton
                  data-testid="master-data-previous-step-button"
                  onClick={() => openDialog()}
                  variant="secondary"
                  style={{ textTransform: "none", marginRight: "20px" }}
                >
                  Cancel
                </OwcButton>
                <OwcButton
                  variant="primary"
                  onClick={() => updateReasons()}
                  style={{
                    textTransform: "none"
                  }}
                  type="submit"
                  data-testid="master-data-save-step-button"
                  disabled={
                    formik?.values?.noiseSource === OTHER
                      ? formik?.values?.noiseSourceDetails === null || formik?.values?.noiseSourceDetails === ""
                      : Object.keys(formik.errors).length > 0 || isSubmitDisabled
                  }
                >
                  Save
                </OwcButton>
              </div>
            </>
          </div>
        </>
      </OwcWorkflowOverlay>
    </>
  );
};

const mapStateToProps = (state) => ({
  editMasterDataDetail: state.instruments?.editMasterDataDetail
});

export default compose(
  connect(mapStateToProps, {
    updateEditMasterDataDetail: updateEditMasterDataDetailAction
  }),
  withApollo
)(MasterDataEditDialog);
