import React, { useEffect, forwardRef, useState, useRef } from "react";
import {
  OwcTable,
  OwcTableBody,
  OwcTableCell,
  OwcTableHeader,
  OwcTableHeaderCell,
  OwcTableRow,
  OwcTooltip,
  OwcTypography
} from "@one/react";
import PaginationActions from "../../features/instruments/instrument-repository-pagination/PaginationActions";

const isObject = (item) => typeof item === "object" && item !== null;

const CustomList = ({
  meta,
  data = [],
  onRequestSort,
  isReverseOrder,
  orderBy,
  onRowClick,
  loading,
  preExpanded,
  fetching,
  className,
  Component = null,
  filteredDatas,
  setFilteredDatas,
  handlerQueryDatas,
  pageIndex,
  setPageIndex,
  totalDatas,
  setTotalDatas,
  isSelected = () => false
}) => {
  const bodyRef = useRef();
  if (!isObject(meta)) {
    return null;
  }
  if (!isObject(meta?.fields)) {
    return null;
  }
  if (!Array.isArray(data)) {
    return null;
  }

  let body = null;
  const height = data.length === 5 ? "300px" : data.length === 10 ? "48vh" : "64vh";
  if (data.length === 0) {
    body = (
      <OwcTypography
        variant="title6"
        data-testid="custom-list-no-data"
        style={{ display: "flex", justifyContent: "center", height: "100%" }}
      >
        No data
      </OwcTypography>
    );
  } else {
    body = data.map((item, index) => {
      const id = item?.[meta?.rowId] || item?.id || (typeof meta?.getId === "function" ? meta?.getId(item) : index);
      return (
        <ItemRow
          expandable={meta?.expandable}
          ExpandedComponent={meta?.ExpandedComponent}
          item={item}
          key={id}
          id={id}
          fields={meta?.fields}
          preExpanded={preExpanded}
          bodyRef={bodyRef}
          selected={isSelected(item) || item.selected}
          lastElement={false}
          onRowClick={onRowClick}
          meta={meta}
        />
      );
    });
  }
  return (
    <OwcTable data-testid="custom-list" spacing="compact" height={height}>
      <OwcTableHeader data-testid="custom-list-head" sticky>
        <Header meta={meta} onRequestSort={onRequestSort} isReverseOrder={isReverseOrder} orderBy={orderBy} />
      </OwcTableHeader>
      <OwcTableBody ref={bodyRef} loading={loading}>
        {body}
      </OwcTableBody>
      {Component}
      <div slot="footer">
        <PaginationActions
          filteredDatas={filteredDatas}
          setFilteredDatas={setFilteredDatas}
          handlerQueryDatas={handlerQueryDatas}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          totalDatas={totalDatas}
          setTotalDatas={setTotalDatas}
        />
      </div>
    </OwcTable>
  );
};

const SortingHeader = ({ xkey, meta, orderBy, onRequestSort, isReverseOrder }) => {
  return (
    <OwcTableHeaderCell
      sortable
      onSortChange={isReverseOrder}
      data-testid={`list-head-${xkey}`}
      key={xkey}
      {...(meta?.fields?.[xkey]?.headProps ?? {})}
      onClick={() => {
        if (xkey === orderBy) {
          onRequestSort(xkey, !isReverseOrder);
        } else {
          onRequestSort(xkey, false);
        }
      }}
    >
      {meta?.fields?.[xkey]?.text}
    </OwcTableHeaderCell>
  );
};

const HeaderName = ({ xkey, meta }) => {
  return (
    <OwcTableHeaderCell
      className="cell"
      data-testid={`list-head-${xkey}`}
      key={xkey}
      {...(meta?.fields?.[xkey]?.headProps ?? {})}
    >
      {meta?.fields?.[xkey]?.text}
    </OwcTableHeaderCell>
  );
};
export const Header = ({ meta, onRequestSort, isReverseOrder, orderBy }) => {
  return Object.keys(meta.fields).map((key) => {
    const Component = meta?.fields?.[key]?.headerComponent ? meta?.fields?.[key]?.headerComponent : null;
    if (!Component) {
      return meta.fields[key]?.sortable ? (
        <SortingHeader
          xkey={key}
          meta={meta}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          isReverseOrder={isReverseOrder}
        />
      ) : (
        <HeaderName xkey={key} meta={meta} />
      );
    }
    return (
      <OwcTableHeaderCell data-testid={`list-head-${key}`} key={key} {...(meta?.fields?.[key]?.headProps ?? {})}>
        <Component {...(meta?.fields?.[key]?.headProps ?? {})} />
      </OwcTableHeaderCell>
    );
  });
};
const mapItemsToRows = ({ fields, id, item, toggleExpanded, shouldExpand, selected }) => {
  return Object.keys(fields).map((field) => {
    const Component = fields?.[field]?.component ? fields?.[field].component : null;
    if (!Component) {
      return (
        <OwcTableCell
          key={`item-row-${id}-${field}`}
          id={`item-row-${id}-${field}`}
          data-testid={`item-row-${id}-${field}`}
          {...(fields?.[field]?.cellProps ?? {})}
          style={{
            maxWidth: 200,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            display: "flex",
            alignItems: "center"
          }}
        >
          {item[field] ?? "-"}
          <OwcTooltip anchor={`item-row-${id}-${field}`} offset={[0, 0]}>
            {item[field] ?? "-"}
          </OwcTooltip>
        </OwcTableCell>
      );
    }
    return (
      <OwcTableCell
        key={`item-row-${id}-${field}`}
        data-testid={`item-row-${id}-${field}`}
        {...(fields?.[field]?.cellProps ?? {})}
      >
        <Component
          item={item}
          {...(fields?.[field]?.cellProps ?? {})}
          toggleExpanded={toggleExpanded}
          expanded={shouldExpand}
          selected={selected}
        />
      </OwcTableCell>
    );
  });
};

export const ItemRow = forwardRef(
  (
    {
      item,
      fields,
      id,
      expandable,
      ExpandedComponent = React.Fragment,
      preExpanded,
      selected,
      lastElement,
      onRowClick,
      meta
    },
    elementRef
  ) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const ref = useRef();

    const toggleExpanded = () => {
      setIsExpanded((val) => !val);
    };

    useEffect(() => {
      if (preExpanded === item && expandable === true) {
        ref.current.scrollIntoView();
        setIsExpanded(true);
      }
    }, [ref, preExpanded, item, expandable]);

    const shouldExpand = isExpanded && expandable === true;

    return (
      <div ref={lastElement ? elementRef : null}>
        <OwcTableRow
          onClick={(event) => onRowClick && onRowClick(event, item)}
          data-testid={`${id}-row`}
          ref={ref}
          //   style={{ display: "flex", alignItems: "center" }}
        >
          {mapItemsToRows({
            fields,
            id,
            item,
            toggleExpanded,
            shouldExpand,
            selected
          })}
        </OwcTableRow>
        {/* <Collapse in={shouldExpand}>
          {expandable === true && (
            <div
              className={clsx("row", "expanded-content", {
                selected,
                expanded: isExpanded
              })}
              data-testid={`${id}-row-expanded`}
            >
              <ExpandedComponent
                item={item}
                fields={fields}
                {...(meta.expandedComponentProps ?? {})}
              />
            </div>
          )}
        </Collapse> */}
      </div>
    );
  }
);

export default CustomList;
