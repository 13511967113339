import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { compose, withApollo } from "react-apollo";
import { useEffect } from "react";
import { useFormikContext } from "formik";
import { EquipAddedStyle } from "../../InstrumentsTable";
import { OwcBadge, OwcIcon, OwcSlideToggle, OwcTypography } from "@one/react";
import { FormControlLabel, IconButton, Radio, RadioGroup, Tooltip } from "@mui/material";
import DLabGrid from "../../../../components/shared/DLabGrid/DLabGrid";
import { useRef } from "react";
import { useMemo } from "react";
import { find, sortBy } from "lodash";
import { getColumnObjClient } from "./addEquipmentService";
import { convertToSnakeCase } from "../../../../utils/helpers/converters";
import { getClusterSubEquipments, convertStrToObjColumns } from "../clusterListService";
import { useCallback } from "react";
import { GET_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY } from "../../../../gql/landingapi/queries";
import { SelectItemsContext } from "../../../../components/context/contexts";
import { entryType } from "../../../../constants";
import DATA_MODEL_TABLE from "../../../../utils/constants/dataModelTable";

const ClusterComponents = ({ client, updateClusteSubEquipmentsObjs, clusteSubEquipments }) => {
  const formik = useFormikContext();
  const [relevantState, setRelevantState] = useState(formik.values?.trackPositionInCluster);
  const { selected, updateSelectedItems } = useContext(SelectItemsContext);
  const { lastDisplayColumns } = useSelector((state) => state?.user);
  const [deleteClusterState, setDeleteClusterState] = useState(false);
  const [changeClusterPosition, setChangeClusterPosition] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [dataModel, setDataModel] = useState({});
  const [tableData, setTableData] = useState([]);
  const gridRef = useRef();

  useEffect(() => {
    const getClusterInfo = async () => {
      const result = await getClusterSubEquipments(selected);

      const orderedArray = result.map((item, index) => {
        const obj = find(clusteSubEquipments || [], {
          id: item?.id[0]
        });
        return { ...item, positionInCluster: obj?.positionInCluster || null };
      });
      setTableData(sortBy(orderedArray, "positionInCluster"));
    };
    getClusterInfo(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeRelevantState = () => {
    formik.setFieldValue("trackPositionInCluster", !formik.values?.trackPositionInCluster, true);
    setRelevantState(!formik.values?.trackPositionInCluster);
  };

  const deleteItem = ({ item, tableData, setTableData }) => {
    if (item.id[0] === formik.values.leadingSoftwareVersionId) {
      formik.setValues({
        ...formik.values,
        leadingSoftwareVersionId: null,
        softwareVersion: null
      });
    }
    setDeleteClusterState(true);
    const newArr = tableData.filter(function (obj) {
      return obj.id[0] !== item?.id[0];
    });

    updateSelectedItems([...newArr?.map((item) => item?.id[0])]);
    setTableData([...newArr]);
  };

  useEffect(() => {
    let positionCluster = [];
    if (gridRef) {
      let updatedData = [];
      gridRef?.current?.api?.forEachNode((item) => {
        updatedData.push(item.data);
      });
      positionCluster = updatedData.map((item, index) => {
        return { ...item, positionInCluster: ++index };
      });
    } else {
      positionCluster = tableData.map((item, index) => {
        return { ...item, positionInCluster: ++index };
      });
    }
    setTableData([...positionCluster]);

    if (positionCluster?.length > 0) {
      const temp = positionCluster.map((x) => {
        return { id: x?.id[0], positionInCluster: x?.positionInCluster };
      });
      updateClusteSubEquipmentsObjs(temp);
    }

    setChangeClusterPosition(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteClusterState, changeClusterPosition, selected]);

  useEffect(() => {
    let orderedShowColumns = sortBy(
      convertStrToObjColumns(lastDisplayColumns)?.displayDefinition?.showColumns,
      ["order"],
      ["asc"]
    );

    const modelColumn = {
      key: DATA_MODEL_TABLE.equipmentModel.key,
      val: DATA_MODEL_TABLE.equipmentModel.value,
      order: 1,
      show: true,
      sortStatus: true
    };

    orderedShowColumns.splice(0, 0, modelColumn);

    const redefinedColumns = [...orderedShowColumns]?.map((value) => {
      return getColumnObjClient(value);
    });

    setDataModel([...redefinedColumns]);
    // eslint-disable-next-line
  }, [lastDisplayColumns]);

  const onRowDragEnd = useCallback((e) => {
    setChangeClusterPosition(true);
  }, []);
  const dLabColumnDef = useMemo(() => {
    let columnFields = [];
    if (relevantState) {
      columnFields.push({
        maxWidth: 70,
        field: "",
        cellRenderer: (event) => {
          return event.rowIndex + 1;
        }
      });
    }
    let columnData = dataModel;
    columnData?.length > 0 &&
      columnData?.forEach((value, index) => {
        let defaultObj = {
          field: convertToSnakeCase(value.key),
          headerName: value.val,
          valueFormatter: (params) => (params.value === "null" || !params.value ? "-" : params.value)
        };

        if (index === 0) {
          defaultObj = {
            ...defaultObj,
            pinned: "left",
            lockVisible: true,
            lockPosition: "left",
            rowDrag: true,
            cellRenderer: (props) => {
              const headerName = props ? props?.colDef?.field : null;
              const data =
                headerName && Object.keys(props?.data).includes(headerName) ? props?.data[headerName][0] || "-" : "-";
              if (props.data.entry_type?.includes(entryType?.cluster)) {
                return (
                  <span
                    style={{
                      width: "10px",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <OwcIcon name="chevron_right" />
                    {data}
                  </span>
                );
              } else {
                return data;
              }
            }
          };
        }

        if (value?.cellClass) {
          defaultObj = {
            ...defaultObj,
            cellClass: value?.cellClass
          };
        }

        if (value?.cellRenderer) {
          defaultObj = {
            ...defaultObj,
            cellRenderer: value?.cellRenderer
          };
        }
        if (value?.filter) {
          defaultObj = {
            ...defaultObj,
            filter: value?.filter
          };
        }
        if (value?.filterParams) {
          defaultObj = {
            ...defaultObj,
            filterParams: value?.filterParams
          };
        }
        if (value?.sortable) {
          defaultObj = {
            ...defaultObj,
            sortable: value?.sortable
          };
        }

        columnFields.push(defaultObj);
      });

    columnFields = [...columnFields];

    const leadingSoftwareVersion = {
      field: "softwareVersion",
      headerName: "Leading cluster software",
      filter: false,
      pinned: "right",
      lockVisible: true,
      lockPosition: "right",
      cellClass: "action-render",
      cellRenderer: (params) => {
        return (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <FormControlLabel value={params.data.id[0]} control={<Radio />} name="softwareVersion" />
            </div>
          </>
        );
      }
    };

    columnFields.push(leadingSoftwareVersion);

    const actions = {
      field: "actions",
      headerName: "",
      maxWidth: 100,
      filter: false,
      pinned: "right",
      lockVisible: true,
      lockPosition: "right",
      cellClass: "action-render",
      cellRenderer: (params) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Tooltip
              data-testid="instrument-custom-delete-button-tooltip"
              id="instrument-custom-delete-button-tooltip"
              title="Delete equipment"
              placement="left"
              arrow
            >
              <IconButton
                data-testid="instrument-custom-table-delete-button"
                id="instrument-custom-table-delete-button"
                onClick={() => deleteItem({ item: params.data, tableData, setTableData })}
              >
                <OwcIcon name="delete" type="legacy" id="instrument-table-delete-icon" />
              </IconButton>
            </Tooltip>
          </div>
        );
      }
    };

    columnFields.push(actions);

    return columnFields;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataModel, relevantState, tableData]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 300,
      resizable: true,
      sortable: true,
      suppressMenu: true
    };
  }, []);

  let attr = {
    height: 503,
    rowQuickSearch: false,
    floatingFilter: false,
    suppressPaginationPanel: true,
    paginationPageSize: rowPerPage,
    pagination: false,
    animateRows: true,
    rowSelection: "multiple",
    rowExport: false,
    suppressContextMenu: true,
    defaultToolPanel: "filters",
    hiddenByDefault: true,
    setRowPerPage,
    rowPerPage,
    totalRows: selected.length
  };
  return (
    <>
      <RadioGroup
        aria-label="softwareVersion"
        name="softwareVersion"
        value={formik?.values?.leadingSoftwareVersionId}
        onChange={async (event) => {
          try {
            const result = await client.query({
              query: GET_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
              fetchPolicy: "no-cache",
              variables: {
                id: event.target.value
              }
            });
            if (result) {
              const softwareVersion = result?.data?.getDigitalLabInstrumentRepositoryEntry?.softwareVersion ?? null;
              formik.setValues({
                ...formik.values,
                leadingSoftwareVersionId: event.target.value,
                softwareVersion: softwareVersion
              });
            }
            setChangeClusterPosition(true);
          } catch (err) {
            console.log("cluster check error", err);
          }
        }}
      >
        <DLabGrid
          {...attr}
          defaultColDef={defaultColDef}
          gridRef={gridRef}
          animateRows={true}
          rowData={tableData}
          columnDefs={dLabColumnDef}
          onRowDragEnd={onRowDragEnd}
          rowDragManaged={true}
          hidePagination={true}
        />
      </RadioGroup>

      <div
        style={{
          display: "flex",
          marginRight: "10px",
          justifyContent: "space-between"
        }}
      >
        <OwcTypography variant="subtitle1" id="relevant-position" data-testid={`doc-data-relevant`}>
          <OwcSlideToggle
            checked={formik.values?.trackPositionInCluster}
            onInputChange={changeRelevantState}
            id="relevant-switch"
            labelPlacement="right"
          >
            Position is relevant
          </OwcSlideToggle>
        </OwcTypography>
        {selected && (
          <EquipAddedStyle>
            <OwcTypography variant="body2" style={{ marginRight: "10px" }}>
              Components added
            </OwcTypography>
            <OwcBadge
              type="active"
              style={{
                marginRight: "0px",
                width: "24px",
                height: "24px"
              }}
            >
              {selected?.length}
            </OwcBadge>
          </EquipAddedStyle>
        )}
      </div>
    </>
  );
};

export default compose(connect(null, {}), withApollo)(ClusterComponents);
