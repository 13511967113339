import * as yup from "yup";

/**
 *
 * @param {gxpValidationSchema} schema the schema which is used to validate that field
 * @param {changeTo} changeTo param to change the value if we need, default to empty string
 * @returns
 */
const getCustomNullable = (schema, changeTo = "") => schema.nullable().transform((value) => value ?? changeTo);

/**
 * schema for validating the equpiment gxp maintainance form fields
 * have used formik and yup for setting up the validations
 * input type of select is validated against key, value or string based on the options
 * input type of text is validated against string
 */
export const gxpValidationSchema = yup.object({
  sop: yup
    .array(
      yup.object({
        value: yup.string("Enter SOP").typeError("Invalid type").nullable()
      })
    )
    .nullable(),
  csv: yup
    .object({
      value: getCustomNullable(yup.string("Enter CSV").typeError("Invalid type").nullable())
    })
    .nullable(),
  electronicSignatures: yup
    .object({
      value: getCustomNullable(yup.string("Enter electronic signature").typeError("Invalid type").nullable())
    })
    .nullable(),
  electronicRecord: yup
    .object({
      value: getCustomNullable(yup.string("Enter electronic record").typeError("Invalid type").nullable())
    })
    .nullable(),
  gxpRelevant: yup
    .object({
      value: getCustomNullable(yup.string("Enter GxP relevant").typeError("Invalid type").nullable())
    })
    .nullable(),
  qualificationStatus: yup
    .object({
      value: getCustomNullable(
        yup
          .string("Enter qualification status")
          .typeError("Invalid type")
          .required("Qualification status is required")
          .nullable()
      )
    })
    .required("Qualification status is required")
    .nullable(),
  controlledEquipmentStatus: yup
    .object({
      value: getCustomNullable(
        yup
          .string("Enter controlled equipment status")
          .typeError("Invalid type")
          .required("Controlled equipment status is required")
          .nullable()
      )
    })
    .required("Controlled equipment status is required")
    .nullable(),
  dateOfNextPeriodicReview: yup.date().typeError("Invalid date").nullable(),
  dateOfNextMaintanance: yup.date().typeError("Invalid date").nullable(),
  dateOfLastMaintanance: yup.date().typeError("Invalid date").nullable(),
  documentedMaintenanceDate: getCustomNullable(
    yup.string("Enter maintenance dates documented").typeError("Invalid type")
  ),
  maintenanceIntervalInDays: getCustomNullable(
    yup
      .string("Enter maintenance interval in days")
      .typeError("Invalid type")
      .matches(/^(|\d)+$/, "Enter a numeric value only")
  ),
  systemStatus: getCustomNullable(yup.string("Select system status").typeError("Invalid type").nullable()),
  maintenancePlan: getCustomNullable(yup.string("Enter maintenance plan").typeError("Invalid type")),
  qualificationDocuments: yup
    .object({
      value: yup.array(
        yup.object({
          name: yup.string("Enter name").typeError("Invalid type").nullable(),
          documentId: yup.string("Enter documentId").typeError("Invalid type").nullable()
        })
      )
    })
    .nullable()
});
