import { useFormikContext } from "formik";
import React, { useState, useEffect, useContext } from "react";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";
import { defaultInputStyle } from "../../../utils/helpers/text";
import CommonOwcEditableDropDown from "../cluster/steps/CommonOwcEditableDropDown";
import { updateInstrumentDetail as updateInstrumentDetailAction } from "../redux/actions";
import { StepperContext } from "../Context";
import { connect } from "react-redux";

const SiteSelect = ({ sites, updateInstrumentDetail, instrumentDetail, isEditMode }) => {
  const formik = useFormikContext();
  const [selectedSite, setSelectedSite] = useState(null);
  const { stepsArray, setStepsArray } = useContext(StepperContext);

  useEffect(() => {
    if (formik?.values?.siteName) {
      setSelectedSite(`${formik?.values?.siteName}`);
    }
  }, [formik?.values?.siteName]);

  const onSiteSelected = async (siteName) => {
    if (formik?.values?.siteName !== siteName?.siteName) {
      formik.setValues({
        ...formik.values,
        buildingLocation: null,
        floor: null,
        room: null,
        siteName: siteName?.siteName
      });
      setSelectedSite(() => siteName?.siteName ?? null);
      updateInstrumentDetail({
        ...instrumentDetail,
        buildingLocation: null,
        floor: null,
        room: null,
        siteName
      });
      if (isEditMode) {
        const updatedStepsArray = stepsArray.map((p) =>
          p.id === 2 ? { ...p, error: true, completed: false } : { ...p, error: false, completed: true }
        );
        setStepsArray(updatedStepsArray);
      }
    }
  };

  return (
    <CommonOwcEditableDropDown
      labelValue={false}
      label={DATA_MODEL_TABLE.siteName.value + ` *`}
      defaultLabel={DATA_MODEL_TABLE.siteName.value + ` *`}
      propValue="siteName"
      style={defaultInputStyle}
      list={sites}
      selected={selectedSite}
      onChange={(selectedValue) => {
        const selVal = sites?.find((value, index) => index === selectedValue);
        onSiteSelected(selVal ?? null);
      }}
      required={true}
      helperText={formik.errors[DATA_MODEL_TABLE.siteName.key]}
      onClose={undefined}
      onOpen={undefined}
    />
  );
};

const mapStateToProps = (state) => ({
  instrumentDetail: state.instruments?.instrumentDetail,
  sites: state.user.sites,
  updateInstrumentDetail: updateInstrumentDetailAction
});
export default connect(mapStateToProps, null)(SiteSelect);
