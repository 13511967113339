import { Component } from "react";
import { Auth } from "aws-amplify";
import MainPageNotAuthenticated from "../views/MainPageNotAuthenticated";
import { FullScreenCentered } from "./instruments/LoadInstrumentsInfo";
import CircularProgress from "@mui/material/CircularProgress";

class Authentication extends Component {
  constructor(props) {
    super(props);
    this.getUser = this.getUser.bind(this);
  }
  state = {
    user: null,
    loading: true
  };

  getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => this.setState({ user: userData }))
      .catch((e) => console.info("Not signed in: ", e))
      .finally(() => this.setState({ loading: false }));
  }
  componentDidMount() {
    this.getUser();
  }
  render() {
    if (this.state.loading) {
      return (
        <FullScreenCentered data-testid="loader">
          <CircularProgress size={ 80 } />
        </FullScreenCentered>
      );
    }
    if (this.state.user === null) {
      return <MainPageNotAuthenticated />;
    }
    return <>{this.props.children}</>;
  }
}

export default Authentication;
