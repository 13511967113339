import React, { useEffect, useState } from "react";
import * as yup from "yup";
import styled from "styled-components";
import { MainRow, TitleRow } from "./BasicDataReqFieldsForm";
import { ButtonsForAttachment } from "./DocumentsDataForm";
import { useFormikContext } from "formik";
import DATA_MODEL_TABLE from "../../utils/constants/dataModelTable";
import { IconButton } from "@mui/material";
import { OwcIcon, OwcInput } from "@one/react";

export const InputModal = styled.div`
  text-align: left;
  color: var(--one-color-gray-600);
  display: flex;
`;
const TestNameRow = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: var(--one-color-gray-800);
  margin: 2px 0;
`;
const TestVersionRow = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: var(--one-color-gray-600);
  margin: 2px 0;
`;
const TestInfoRow = styled.div`
  padding: 0.75rem 0;
`;
const TestActionRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
const TestRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: thin solid var(--one-color-gray-300);
`;
const TestButtons = styled(TestActionRow)`
  max-height: 48px;
`;

const TestList = styled.div`
  max-height: 120px;
  overflow-y: scroll;
  margin: 1rem;
`;

export const NameVersionContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 16px 0 16px;
  border-top: thin solid var(--one-color-gray-300);
`;

const validationSchema = yup.object({
  name: yup.string("Enter your test name").trim().required("Enter your test name"),
  version: yup.string("Enter your test version").trim().required("Enter your test version")
});

const AssaysInput = ({ setInstalledTest, installedTest, fieldname, label, isSubmitted, errors }) => {
  return (
    <OwcInput
      color="primary"
      variant="filled"
      data-testid={`assays-data-form-test-${fieldname}-input`}
      id={fieldname}
      name={fieldname}
      label={label}
      value={installedTest?.[fieldname]}
      onInputChange={(event) =>
        setInstalledTest({
          ...installedTest,
          [fieldname]: event.target.value
        })
      }
      error={isSubmitted && Boolean(errors?.[fieldname])}
      helperText={isSubmitted && errors?.[fieldname]}
      FormHelperTextProps={{
        "data-testid": `assays-data-form-test-${fieldname}-input-helper-text`
      }}
    >
      {installedTest?.[fieldname]?.length > 0 && (
        <OwcIcon
          slot="suffix"
          name="circle_clear"
          type="legacy"
          onClick={() =>
            setInstalledTest({
              ...installedTest,
              [fieldname]: ""
            })
          }
        />
      )}
    </OwcInput>
  );
};

const AssaysInputs = ({ installedTest, setInstalledTest, isSubmitted, setIsSubmitted, errors, handleEditOrAdd }) => {
  const classes = {};
  return (
    <NameVersionContainer>
      <InputModal style={{ marginRight: 20, width: 170 }}>
        <AssaysInput
          errors={errors}
          fieldname="name"
          installedTest={installedTest}
          isSubmitted={isSubmitted}
          label="Test name"
          setInstalledTest={setInstalledTest}
        />
      </InputModal>
      <InputModal style={{ width: 170 }}>
        <AssaysInput
          errors={errors}
          fieldname="version"
          installedTest={installedTest}
          isSubmitted={isSubmitted}
          label="Version"
          setInstalledTest={setInstalledTest}
        />
      </InputModal>
      <TestButtons data-testid="assays-data-form-test-buttons-inputs">
        <ButtonsForAttachment
          dataTestIdKey="assays-data-form-test"
          onClear={() => {
            setIsSubmitted(false);
            setInstalledTest({
              index: -1,
              name: "",
              version: ""
            });
          }}
          attachment={installedTest}
          handleEditOrAdd={handleEditOrAdd}
          classes={classes}
        />
      </TestButtons>
    </NameVersionContainer>
  );
};

const handleEditOrAddAction =
  ({ installedTest, setIsSubmitted, formik, setInstalledTest, values, setValues }) =>
  () => {
    try {
      if (!formik.values?.installedTests || formik.values?.installedTests === undefined) {
        values["installedTests"] = [];
        setValues({ ...values });
      }
      validationSchema.validateSync(installedTest);
      setIsSubmitted(false);
      const { index, ...testToAdd } = installedTest;
      if (index > -1) {
        formik.setFieldValue(`installedTests.${index}`, {
          ...testToAdd
        });
      } else {
        formik.setFieldValue("installedTests", [...formik.values?.installedTests, { ...testToAdd }]);
      }
      setInstalledTest({
        index: -1,
        name: "",
        version: ""
      });
    } catch (e) {
      setIsSubmitted(true);
    }
  };

const AssaysDataForm = ({ formik }) => {
  const { values, setValues } = useFormikContext();
  const [installedTest, setInstalledTest] = useState({
    index: -1,
    name: "",
    version: ""
  });
  const [errors, setErrors] = useState({
    name: null,
    version: null
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const validate = async () => {
      setErrors({
        name: await validationSchema
          .validateAt("name", installedTest)
          .then(() => null)
          .catch((error) => error.errors[0]),
        version: await validationSchema
          .validateAt("version", installedTest)
          .then(() => null)
          .catch((error) => error.errors[0])
        // eslint-disable-next-line react-hooks/exhaustive-deps
      });
    };
    validate();
  }, [installedTest]);

  return (
    <>
      <MainRow data-testid="assays-data-form">
        <TitleRow data-testid="assays-data-form-title">{DATA_MODEL_TABLE.installedTests.value}</TitleRow>
        <AssaysInputs
          errors={errors}
          handleEditOrAdd={handleEditOrAddAction({
            formik,
            installedTest,
            setInstalledTest,
            setIsSubmitted,
            values,
            setValues
          })}
          installedTest={installedTest}
          isSubmitted={isSubmitted}
          setInstalledTest={setInstalledTest}
          setIsSubmitted={setIsSubmitted}
        />
        <TestList data-testid="assays-data-form-test-list">
          {formik.values?.installedTests?.map((test, index) => (
            <TestRow key={index}>
              <TestInfoRow data-testid="assays-data-form-test-list-info-row">
                <TestNameRow data-testid="assays-data-form-test-list-info-row-name">{test.name}</TestNameRow>
                <TestVersionRow data-testid="assays-data-form-test-list-info-row-version">
                  {test.version}
                </TestVersionRow>
              </TestInfoRow>
              <TestActionRow data-testid="assays-data-form-test-list-action-buttons">
                <IconButton
                  type="button"
                  onClick={() => {
                    setInstalledTest({
                      index,
                      ...test
                    });
                  }}
                  data-testid="assays-data-form-test-list-edit-iconbutton"
                >
                  <OwcIcon name="edit" type="legacy" />
                </IconButton>
                <IconButton
                  type="button"
                  onClick={() => {
                    formik.setFieldValue(
                      "installedTests",
                      formik.values.installedTests.filter((_, theIndex) => theIndex !== index)
                    );
                  }}
                  data-testid="assays-data-form-test-list-delete-iconbutton"
                >
                  <OwcIcon name="delete" type="legacy" />
                </IconButton>
              </TestActionRow>
            </TestRow>
          ))}
        </TestList>
      </MainRow>
    </>
  );
};
export default AssaysDataForm;
