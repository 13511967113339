import { FormComponents } from "../instruments/addEditEquipment/AddEquipmentStyle";
import { useFormikContext } from "formik";
import { commonPropsForInputsWithoutValue } from "../instruments/helpers";
import DATA_MODEL_TABLE from "../../utils/constants/dataModelTable";
import { OwcInput, OwcTypography, OwcIcon, OwcTextarea } from "@one/react";
import {
  OTHER,
  masterDataComplexAttributeNoiseIndBA,
  masterDataComplexAttributePositioning,
  masterDataComplexAttributePressureEquipment,
  masterDataComplexAttributeVibrationSensitive
} from "../../constants";
import CommonOwcEditableDropDown from "../instruments/cluster/steps/CommonOwcEditableDropDown";

const MasterDataComplexAttributes = () => {
  const formik = useFormikContext();

  const noiseSoureVal = formik?.values?.noiseSource && formik?.values?.noiseSource === OTHER;

  return (
    <>
      <OwcTypography variant="subtitle2" style={{ padding: "0px 20px 20px 0px" }}>
        Heat load & noise
      </OwcTypography>
      <FormComponents>
        <>
          <div className="owcInputBox">
            <OwcInput
              data-testid={`text-field-heatOutputInW`}
              label={DATA_MODEL_TABLE.heatOutputInW.value}
              name="heatOutputInW"
              style={{ width: 254, height: 48 }}
              value={formik.values?.heatOutputInW || null}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={!formik.values?.isActive}
              {...commonPropsForInputsWithoutValue({
                formik,
                key: "heatOutputInW",
                dataTestIdKey: "heatOutputInW",
                dataTestIdForFormKey: "text-field"
              })}
            >
              {formik.errors[DATA_MODEL_TABLE.heatOutputInW.key] &&
                formik.touched[DATA_MODEL_TABLE.heatOutputInW.key] === true && (
                  <>
                    <span
                      // id={generateID.UUID(adminTopBannerMessage, `topBannerMessage`, "error_slot")}
                      slot="error-text"
                    >
                      {formik.errors[DATA_MODEL_TABLE.heatOutputInW.key]}
                    </span>
                  </>
                )}
              {formik.values[DATA_MODEL_TABLE.heatOutputInW.key] !== null &&
                formik.touched[DATA_MODEL_TABLE.heatOutputInW.key] === true &&
                formik.values?.isActive && (
                  <OwcIcon
                    name="circle_clear_filled"
                    slot="suffix"
                    type="legacy"
                    onClick={() => formik.setFieldValue([DATA_MODEL_TABLE.heatOutputInW.key], null, true)}
                  />
                )}
            </OwcInput>
          </div>
          <div className="owcInputBoxComplex">
            <CommonOwcEditableDropDown
              labelValue={true}
              data-testid={`text-field-pressureEquipment`}
              label={DATA_MODEL_TABLE.pressureEquipment.value}
              propValue="pressureEquipment"
              list={masterDataComplexAttributePressureEquipment}
              selected={
                formik.values[DATA_MODEL_TABLE.pressureEquipment.key] !== ""
                  ? formik.values[DATA_MODEL_TABLE.pressureEquipment.key]
                  : null
              }
              onChange={(selectedValue) => {
                let pressureVal = masterDataComplexAttributePressureEquipment?.find(
                  (value, index) => index === selectedValue
                );
                pressureVal = pressureVal ? pressureVal.toString() : null;
                formik.setValues({
                  ...formik.values,
                  pressureEquipment: pressureVal
                });
              }}
              disabled={!formik.values?.isActive}
            />
          </div>
          <div className="owcInputBox">
            <OwcInput
              data-testid={`text-field-noiseIndBA`}
              label={DATA_MODEL_TABLE.noiseIndBA.value}
              name="noiseIndBA"
              style={{ width: 254, height: 48 }}
              value={formik.values?.noiseIndBA || null}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={!formik.values?.isActive}
              {...commonPropsForInputsWithoutValue({
                formik,
                key: "noiseIndBA",
                dataTestIdKey: "noiseIndBA",
                dataTestIdForFormKey: "text-field"
              })}
            >
              {formik.errors[DATA_MODEL_TABLE.noiseIndBA.key] &&
                formik.touched[DATA_MODEL_TABLE.noiseIndBA.key] === true && (
                  <>
                    <span
                      // id={generateID.UUID(adminTopBannerMessage, `topBannerMessage`, "error_slot")}
                      slot="error-text"
                    >
                      {formik.errors[DATA_MODEL_TABLE.noiseIndBA.key]}
                    </span>
                  </>
                )}
              {formik.values[DATA_MODEL_TABLE.noiseIndBA.key] !== null &&
                formik.touched[DATA_MODEL_TABLE.noiseIndBA.key] === true &&
                formik.values?.isActive && (
                  <OwcIcon
                    name="circle_clear_filled"
                    slot="suffix"
                    type="legacy"
                    onClick={() => formik.setFieldValue([DATA_MODEL_TABLE.noiseIndBA.key], null, true)}
                  />
                )}
            </OwcInput>
          </div>
          <div className="owcInputBoxComplex">
            <CommonOwcEditableDropDown
              labelValue={true}
              data-testid={`text-field-noiseSource`}
              label={DATA_MODEL_TABLE.noiseSource.value}
              disabled={!formik.values?.isActive}
              propValue="noiseSource"
              list={masterDataComplexAttributeNoiseIndBA}
              selected={
                formik.values[DATA_MODEL_TABLE.noiseSource.key] !== ""
                  ? formik.values[DATA_MODEL_TABLE.noiseSource.key]
                  : null
              }
              onChange={(selectedValue) => {
                let noiseSourceVal = masterDataComplexAttributeNoiseIndBA?.find(
                  (value, index) => index === selectedValue
                );
                noiseSourceVal = noiseSourceVal ? noiseSourceVal.toString() : null;
                formik.setValues({
                  ...formik.values,
                  noiseSource: noiseSourceVal
                });
              }}
            />
          </div>
          <div className="owcTextarea">
            <OwcTextarea
              style={{ height: 48 }}
              data-testid={`textarea-field-noiseSourceDetails`}
              disabled={!formik.values?.isActive}
              validation-mode={noiseSoureVal ? "realtime" : "off"}
              label={
                noiseSoureVal
                  ? DATA_MODEL_TABLE.noiseSourceDetails.value + ` *`
                  : DATA_MODEL_TABLE.noiseSourceDetails.value
              }
              name="noiseSourceDetails"
              required={noiseSoureVal}
              onInputReset={() => formik.setFieldValue([DATA_MODEL_TABLE.noiseSourceDetails.key], "", true)}
              value={formik.values?.noiseSourceDetails}
              onInputChange={(e) => {
                formik.setFieldValue([DATA_MODEL_TABLE.noiseSourceDetails.key], e.detail, true);
              }}
              onInputKeyDown={formik.handleChange}
              onBlur={formik.handleBlur}
              reset
              resizable
              cols={20}
              maxLength={48}
              minLength={0}
              multiline
              rows={1}
              minRows={2}
            />
          </div>
        </>
      </FormComponents>
      <OwcTypography variant="subtitle2" style={{ padding: "20px 20px 20px 0px" }}>
        Specifications lineup
      </OwcTypography>
      <FormComponents>
        <div className="specificationLinup">
          <div className="owcTextarea" style={{ marginRight: "10px" }}>
            <OwcTextarea
              style={{ height: 48 }}
              data-testid={`textarea-field-positioningNote`}
              label={DATA_MODEL_TABLE.positioningNote.value}
              disabled={!formik.values?.isActive}
              name="positioningNote"
              onInputReset={() => formik.setFieldValue([DATA_MODEL_TABLE.positioningNote.key], "", true)}
              value={formik.values?.positioningNote}
              onInputChange={formik.handleChange}
              onInputKeyDown={formik.handleChange}
              onBlur={formik.handleBlur}
              reset
              resizable
              cols={20}
              maxLength={48}
              minLength={0}
              multiline
              rows={2}
              minRows={2}
            />
          </div>
          <div className="owcInputBoxComplex">
            <CommonOwcEditableDropDown
              labelValue={true}
              data-testid={`text-field-positioning`}
              label={DATA_MODEL_TABLE.positioning.value}
              disabled={!formik.values?.isActive}
              propValue="positioning"
              list={masterDataComplexAttributePositioning}
              selected={
                formik.values[DATA_MODEL_TABLE.positioning.key] !== ""
                  ? formik.values[DATA_MODEL_TABLE.positioning.key]
                  : null
              }
              onChange={(selectedValue) => {
                let positioningVal = masterDataComplexAttributePositioning?.find(
                  (value, index) => index === selectedValue
                );
                positioningVal = positioningVal ? positioningVal.toString() : null;
                formik.setValues({
                  ...formik.values,
                  positioning: positioningVal
                });
              }}
            />
          </div>
          <div className="owcInputBoxComplex">
            <CommonOwcEditableDropDown
              labelValue={true}
              data-testid={`text-field-vibrationSensitive`}
              label={DATA_MODEL_TABLE.vibrationSensitive.value}
              disabled={!formik.values?.isActive}
              propValue="vibrationSensitive"
              list={masterDataComplexAttributeVibrationSensitive}
              selected={
                formik.values[DATA_MODEL_TABLE.vibrationSensitive.key] !== ""
                  ? formik.values[DATA_MODEL_TABLE.vibrationSensitive.key]
                  : null
              }
              onChange={(selectedValue) => {
                let vibrationSensitiveVal = masterDataComplexAttributeVibrationSensitive?.find(
                  (value, index) => index === selectedValue
                );
                vibrationSensitiveVal = vibrationSensitiveVal ? vibrationSensitiveVal.toString() : null;
                formik.setValues({
                  ...formik.values,
                  vibrationSensitive: vibrationSensitiveVal
                });
              }}
            />
          </div>
        </div>
      </FormComponents>
    </>
  );
};

export default MasterDataComplexAttributes;
