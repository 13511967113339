import React, { useEffect, useState } from "react";
import { FormComponents } from "./AddEquipmentStyle";
import { useFormikContext } from "formik";
import { commonPropsForInputsWithoutValue } from "../helpers";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";
import { uniqList } from "../../importFile/utils/helpers";
import { OwcIcon, OwcInput, OwcTypography } from "@one/react";
import CustomAutoCompleteWithMultiSelect from "../../../components/shared/CustomAutoCompleteWithMultiSelect";
import { compose, withApollo } from "react-apollo";
import { getTagsMaster } from "../loadInstrumentsInfoService";
import CommonOwcEditableDropDown from "../cluster/steps/CommonOwcEditableDropDown";
import { defaultInputStyle } from "../../../utils/helpers/text";
import { MainDescription } from "./MainDescription";

const NETWORK_LIST = ["CoreLab", "Molecular", "Point of Care", "Pathology", "Sequencing"];

const DEFAULT_NETWORK = {
  Molecular: NETWORK_LIST[1],
  CLP: NETWORK_LIST[0]
};

const EquipmentDescription = ({ isEditMode, client }) => {
  const [tagList, setTagList] = useState([]);
  const formik = useFormikContext();

  useEffect(() => {
    const getTagsMasterDatas = async () => {
      const tagsData = await getTagsMaster(client);

      if (tagsData?.items.length > 0) {
        setTagList(uniqList(tagsData.items.map((x) => x.filterValue)));
      }
    };
    getTagsMasterDatas();
  }, [client, formik, isEditMode]);

  const handleDelete = (selectedObjects, selectedObject, setValue) => {
    selectedObjects = selectedObjects.filter((currentObject) => currentObject !== selectedObject);
    setValue(selectedObjects);
  };

  // useEffect assigns default value to network if not present
  useEffect(() => {
    if (formik.values.network === undefined && !isEditMode) {
      const { linkedInstanceDisplay } = formik.values.linkedInstance;
      const defaultNetworkValue = DEFAULT_NETWORK[linkedInstanceDisplay];
      formik.setValues({
        ...formik.values,
        network: defaultNetworkValue
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.linkedInstance]);

  return (
    <>
      {!isEditMode && (
        <OwcTypography variant="subtitle2" style={{ padding: "0px 20px 20px 0px" }}>
          Equipment description
        </OwcTypography>
      )}
      <FormComponents>
        <>
          <MainDescription />

          <div className="owcInputBox">
            <OwcInput
              data-testid={`text-field-cluster`}
              label={DATA_MODEL_TABLE.cluster.value}
              name="cluster"
              style={defaultInputStyle}
              value={formik.values?.cluster}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              {...commonPropsForInputsWithoutValue({
                formik,
                key: "cluster",
                dataTestIdKey: "cluster",
                dataTestIdForFormKey: "text-field"
              })}
            >
              {formik.values[DATA_MODEL_TABLE.cluster.key] !== "" &&
                formik.touched[DATA_MODEL_TABLE.cluster.key] === true && (
                  <OwcIcon
                    name="circle_clear_filled"
                    slot="suffix"
                    type="legacy"
                    onClick={() => formik.setFieldValue([DATA_MODEL_TABLE.cluster.key], "", true)}
                  />
                )}
            </OwcInput>
          </div>
          <div className="owcInputBox">
            <OwcInput
              data-testid={`text-field-equipment-nickname`}
              label={DATA_MODEL_TABLE.equipmentNickName.value}
              name="equipmentNickName"
              style={defaultInputStyle}
              value={formik.values?.equipmentNickName}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              {...commonPropsForInputsWithoutValue({
                formik,
                key: "equipmentNickName",
                dataTestIdKey: "equipmentNickName",
                dataTestIdForFormKey: "text-field"
              })}
            >
              {formik.values[DATA_MODEL_TABLE.equipmentNickName.key] !== "" &&
                formik.touched[DATA_MODEL_TABLE.equipmentNickName.key] === true && (
                  <OwcIcon
                    name="circle_clear_filled"
                    slot="suffix"
                    type="legacy"
                    onClick={() => formik.setFieldValue([DATA_MODEL_TABLE.equipmentNickName.key], "", true)}
                  />
                )}
            </OwcInput>
          </div>
          <div className="owctagInputBox">
            <CustomAutoCompleteWithMultiSelect
              style={{
                width: "635px"
              }}
              id={DATA_MODEL_TABLE.tags.key}
              dataTestid={DATA_MODEL_TABLE.tags.key}
              optionsList={tagList ?? []}
              label={DATA_MODEL_TABLE.tags.value}
              removable={true}
              handleDelete={handleDelete}
              value={formik.values?.tags ?? []}
              disableCloseOnSelect={true}
              setValue={(newValue) => {
                formik.setFieldValue("tags", newValue, true);
              }}
              fullWidth={true}
              name={DATA_MODEL_TABLE.tags.key}
              multiple={true}
              error={formik.touched[DATA_MODEL_TABLE.tags.key] && Boolean(formik.errors[DATA_MODEL_TABLE.tags.key])}
              helperText={
                (formik.touched[DATA_MODEL_TABLE.tags.key] && formik.errors[DATA_MODEL_TABLE.tags.key]) ??
                "You can add/select max of 10 tags and each tag of 20 characters max"
              }
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="owcInputBox">
            <OwcInput
              style={defaultInputStyle}
              formik={formik}
              data-testid={`textarea-field-comment`}
              label={DATA_MODEL_TABLE.comment.value}
              name="comment"
              value={formik.values?.comment}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              {...commonPropsForInputsWithoutValue({
                formik,
                key: "comment",
                dataTestIdKey: "comment",
                dataTestIdForFormKey: "text-field"
              })}
              multiline
              rows={6}
            >
              {formik.values[DATA_MODEL_TABLE.comment.key] !== "" &&
                formik.touched[DATA_MODEL_TABLE.comment.key] === true && (
                  <OwcIcon
                    name="circle_clear_filled"
                    slot="suffix"
                    type="legacy"
                    onClick={() => formik.setFieldValue([DATA_MODEL_TABLE.comment.key], "", true)}
                  />
                )}
            </OwcInput>
          </div>
          <CommonOwcEditableDropDown
            labelValue={false}
            label={DATA_MODEL_TABLE.network.value}
            defaultLabel={DATA_MODEL_TABLE.network.value}
            keylabel={DATA_MODEL_TABLE.network.key}
            style={defaultInputStyle}
            list={NETWORK_LIST}
            selected={formik.values?.network}
            onChange={(selectedValue) => {
              formik.setValues({
                ...formik.values,
                network: NETWORK_LIST[selectedValue] ?? null
              });
            }}
            helperText={formik.errors[DATA_MODEL_TABLE.network.key]}
          />
        </>
      </FormComponents>
    </>
  );
};

export default compose(withApollo)(EquipmentDescription);
