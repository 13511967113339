export const LOAD_INSTRUMENTS = "[INSTRUMENTS] LOAD_INSTRUMENTS";
export const LOAD_ALL_LISTS = "[INSTRUMENTS] LOAD_ALL_LISTS";
export const LOAD_BUILDING_LISTS = "[INSTRUMENTS] LOAD_BUILDING_LISTS";
export const LOAD_ROOM_LISTS = "[INSTRUMENTS] LOAD_ROOM_LISTS";
export const LOAD_FLOOR_LISTS = "[INSTRUMENTS] LOAD_FLOOR_LISTS";
export const LOAD_GROUP_LISTS = "[INSTRUMENTS] LOAD_GROUP_LISTS";
export const LOAD_REASON_LISTS = "[INSTRUMENTS] LOAD_REASON_LISTS";
export const LOAD_LINKED_INSTANCE_LISTS = "[INSTRUMENTS] LOAD_LINKED_INSTANCE_LISTS";
export const LOAD_RESPONSIBLE_PERSON_LISTS = "[INSTRUMENTS] LOAD_RESPONSIBLE_PERSON_LISTS";
export const LOAD_EQUIPMENT_ADMINISTRATOR_LISTS = "[INSTRUMENTS] LOAD_EQUIPMENT_ADMINISTRATOR_LISTS";
export const UPDATE_INSTRUMENT_DETAIL = "[INSTRUMENTS] UPDATE_INSTRUMENT_DETAIL";
export const UPDATE_EDIT_INSTRUMENT_DETAIL = "[INSTRUMENTS] UPDATE_EDIT_INSTRUMENT_DETAIL";
export const UPDATE_INSTRUMENT_FILTER = "[INSTRUMENTS] UPDATE_INSTRUMENT_FILTER";
export const UPDATE_BUILDINGS_ON_SITE = "[INSTRUMENTS] UPDATE_BUILDINGS_ON_SITE";
export const UPDATE_ROOM_ON_SITE = "[INSTRUMENTS] UPDATE_ROOM_ON_SITE";
export const UPDATE_FLOOR_ON_SITE = "[INSTRUMENTS] UPDATE_FLOOR_ON_SITE";
export const UPDATE_LIMIT = "[INSTRUMENTS] UPDATE_LIMIT";
export const UPDATE_NEXT_TOKEN = "[INSTRUMENTS] UPDATE_NEXT_TOKEN";
export const RESET_PAGE_TOKEN_ARRAY = "[INSTRUMENTS] RESET_PAGE_TOKEN_ARRAY";
export const UPDATE_PAGE_TOKEN_ARRAY = "[INSTRUMENTS] UPDATE_PAGE_TOKEN_ARRAY";
export const UPDATE_FILTER_APPLIED = "[INSTRUMENTS] UPDATE_FILTER_APPLIED";
export const UPDATE_SEARCH_FILTER = "[INSTRUMENTS] UPDATE_SEARCH_FILTER";
export const UPDATE_LOADING = "[INSTRUMENTS] UPDATE_LOADING";
export const UPDATE_EDIT_MASTER_DATA_DETAIL = "[INSTRUMENTS] UPDATE_EDIT_MASTER_DATA_DETAIL";
