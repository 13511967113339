import React from "react";
import { FormComponents } from "../instruments/addEditEquipment/AddEquipmentStyle";
import { useFormikContext } from "formik";
import { commonPropsForInputsWithoutValue } from "../instruments/helpers";
import { connect } from "react-redux";
import DATA_MODEL_TABLE from "../../utils/constants/dataModelTable";
import { OwcInput, OwcTypography, OwcIcon } from "@one/react";
import { compose, withApollo } from "react-apollo";

const MasterDataDimensions = ({
  instrumentDetail,
  //   isUpdateBadge,
  //   isEditMode,
  client,
  setIsNextDisabled,
  isEditMode
}) => {
  const formik = useFormikContext();
  if (!isEditMode) {
    setIsNextDisabled(Object.keys(formik.errors).length > 0 || !formik.isValid);
  }
  return (
    <>
      <OwcTypography variant="subtitle2" style={{ padding: "0px 20px 20px 0px" }}>
        Physical dimensions
      </OwcTypography>
      <FormComponents>
        <>
          <div className="owcInputBox">
            <OwcInput
              data-testid={`text-field-weightInkg`}
              label={DATA_MODEL_TABLE.weightInkg.value}
              name="weightInkg"
              style={{ width: 254, height: 48 }}
              value={formik.values?.weightInkg || null}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={!formik.values?.isActive}
              {...commonPropsForInputsWithoutValue({
                formik,
                key: "weightInkg",
                dataTestIdKey: "weightInkg",
                dataTestIdForFormKey: "text-field"
              })}
            >
              {formik.errors[DATA_MODEL_TABLE.weightInkg.key] &&
                formik.touched[DATA_MODEL_TABLE.weightInkg.key] === true && (
                  <>
                    <span
                      // id={generateID.UUID(adminTopBannerMessage, `topBannerMessage`, "error_slot")}
                      slot="error-text"
                    >
                      {formik.errors[DATA_MODEL_TABLE.weightInkg.key]}
                    </span>
                  </>
                )}
              {formik.values[DATA_MODEL_TABLE.weightInkg.key] !== null &&
                formik.touched[DATA_MODEL_TABLE.weightInkg.key] === true &&
                formik.values?.isActive && (
                  <OwcIcon
                    name="circle_clear_filled"
                    slot="suffix"
                    type="legacy"
                    onClick={() => formik.setFieldValue([DATA_MODEL_TABLE.weightInkg.key], null, true)}
                  />
                )}
            </OwcInput>
          </div>
          <div className="owcInputBox">
            <OwcInput
              data-testid={`text-field-heightInmm`}
              label={DATA_MODEL_TABLE.heightInmm.value}
              name="heightInmm"
              style={{ width: 254, height: 48 }}
              value={formik.values?.heightInmm || null}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={!formik.values?.isActive}
              {...commonPropsForInputsWithoutValue({
                formik,
                key: "heightInmm",
                dataTestIdKey: "heightInmm",
                dataTestIdForFormKey: "text-field"
              })}
            >
              {formik.errors[DATA_MODEL_TABLE.heightInmm.key] &&
                formik.touched[DATA_MODEL_TABLE.heightInmm.key] === true && (
                  <>
                    <span
                      // id={generateID.UUID(adminTopBannerMessage, `topBannerMessage`, "error_slot")}
                      slot="error-text"
                    >
                      {formik.errors[DATA_MODEL_TABLE.heightInmm.key]}
                    </span>
                  </>
                )}
              {formik.values[DATA_MODEL_TABLE.heightInmm.key] !== null &&
                formik.touched[DATA_MODEL_TABLE.heightInmm.key] === true &&
                formik.values?.isActive && (
                  <OwcIcon
                    name="circle_clear_filled"
                    slot="suffix"
                    type="legacy"
                    onClick={() => formik.setFieldValue([DATA_MODEL_TABLE.heightInmm.key], null, true)}
                  />
                )}
            </OwcInput>
          </div>
          <div className="owcInputBox">
            <OwcInput
              data-testid={`text-field-lengthInmm`}
              label={DATA_MODEL_TABLE.lengthInmm.value}
              name="lengthInmm"
              style={{ width: 254, height: 48 }}
              value={formik.values?.lengthInmm || null}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={!formik.values?.isActive}
              {...commonPropsForInputsWithoutValue({
                formik,
                key: "lengthInmm",
                dataTestIdKey: "lengthInmm",
                dataTestIdForFormKey: "text-field"
              })}
            >
              {formik.errors[DATA_MODEL_TABLE.lengthInmm.key] &&
                formik.touched[DATA_MODEL_TABLE.lengthInmm.key] === true && (
                  <>
                    <span
                      // id={generateID.UUID(adminTopBannerMessage, `topBannerMessage`, "error_slot")}
                      slot="error-text"
                    >
                      {formik.errors[DATA_MODEL_TABLE.lengthInmm.key]}
                    </span>
                  </>
                )}
              {formik.values[DATA_MODEL_TABLE.lengthInmm.key] !== null &&
                formik.touched[DATA_MODEL_TABLE.lengthInmm.key] === true &&
                formik.values?.isActive && (
                  <OwcIcon
                    name="circle_clear_filled"
                    slot="suffix"
                    type="legacy"
                    onClick={() => formik.setFieldValue([DATA_MODEL_TABLE.lengthInmm.key], null, true)}
                  />
                )}
            </OwcInput>
          </div>
          <div className="owcInputBox">
            <OwcInput
              data-testid={`text-field-depthInmm`}
              label={DATA_MODEL_TABLE.depthInmm.value}
              name="depthInmm"
              style={{ width: 254, height: 48 }}
              value={formik.values?.depthInmm || null}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={!formik.values?.isActive}
              {...commonPropsForInputsWithoutValue({
                formik,
                key: "depthInmm",
                dataTestIdKey: "depthInmm",
                dataTestIdForFormKey: "text-field"
              })}
            >
              {formik.errors[DATA_MODEL_TABLE.depthInmm.key] &&
                formik.touched[DATA_MODEL_TABLE.depthInmm.key] === true && (
                  <>
                    <span
                      // id={generateID.UUID(adminTopBannerMessage, `topBannerMessage`, "error_slot")}
                      slot="error-text"
                    >
                      {formik.errors[DATA_MODEL_TABLE.depthInmm.key]}
                    </span>
                  </>
                )}
              {formik.values[DATA_MODEL_TABLE.depthInmm.key] !== null &&
                formik.touched[DATA_MODEL_TABLE.depthInmm.key] === true &&
                formik.values?.isActive && (
                  <OwcIcon
                    name="circle_clear_filled"
                    slot="suffix"
                    type="legacy"
                    onClick={() => formik.setFieldValue([DATA_MODEL_TABLE.depthInmm.key], null, true)}
                  />
                )}
            </OwcInput>
          </div>
        </>
      </FormComponents>
      <OwcTypography variant="subtitle2" style={{ padding: "0px 20px 20px 0px" }}>
        Clearance
      </OwcTypography>
      <FormComponents>
        <>
          <div className="owcInputBox">
            <OwcInput
              data-testid={`text-field-clearanceLeftInmm`}
              label={DATA_MODEL_TABLE.clearanceLeftInmm.value}
              name="clearanceLeftInmm"
              style={{ width: 254, height: 48 }}
              value={formik.values?.clearanceLeftInmm || null}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={!formik.values?.isActive}
              {...commonPropsForInputsWithoutValue({
                formik,
                key: "clearanceLeftInmm",
                dataTestIdKey: "clearanceLeftInmm",
                dataTestIdForFormKey: "text-field"
              })}
            >
              {formik.errors[DATA_MODEL_TABLE.clearanceLeftInmm.key] &&
                formik.touched[DATA_MODEL_TABLE.clearanceLeftInmm.key] === true && (
                  <>
                    <span
                      // id={generateID.UUID(adminTopBannerMessage, `topBannerMessage`, "error_slot")}
                      slot="error-text"
                    >
                      {formik.errors[DATA_MODEL_TABLE.clearanceLeftInmm.key]}
                    </span>
                  </>
                )}
              {formik.values[DATA_MODEL_TABLE.clearanceLeftInmm.key] !== null &&
                formik.touched[DATA_MODEL_TABLE.clearanceLeftInmm.key] === true &&
                formik.values?.isActive && (
                  <OwcIcon
                    name="circle_clear_filled"
                    slot="suffix"
                    type="legacy"
                    onClick={() => formik.setFieldValue([DATA_MODEL_TABLE.clearanceLeftInmm.key], null, true)}
                  />
                )}
            </OwcInput>
          </div>
          <div className="owcInputBox">
            <OwcInput
              data-testid={`text-field-clearanceRightInmm`}
              label={DATA_MODEL_TABLE.clearanceRightInmm.value}
              name="clearanceRightInmm"
              style={{ width: 254, height: 48 }}
              value={formik.values?.clearanceRightInmm || null}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={!formik.values?.isActive}
              {...commonPropsForInputsWithoutValue({
                formik,
                key: "clearanceRightInmm",
                dataTestIdKey: "clearanceRightInmm",
                dataTestIdForFormKey: "text-field"
              })}
            >
              {formik.errors[DATA_MODEL_TABLE.clearanceRightInmm.key] &&
                formik.touched[DATA_MODEL_TABLE.clearanceRightInmm.key] === true && (
                  <>
                    <span
                      // id={generateID.UUID(adminTopBannerMessage, `topBannerMessage`, "error_slot")}
                      slot="error-text"
                    >
                      {formik.errors[DATA_MODEL_TABLE.clearanceRightInmm.key]}
                    </span>
                  </>
                )}
              {formik.values[DATA_MODEL_TABLE.clearanceRightInmm.key] !== null &&
                formik.touched[DATA_MODEL_TABLE.clearanceRightInmm.key] === true &&
                formik.values?.isActive && (
                  <OwcIcon
                    name="circle_clear_filled"
                    slot="suffix"
                    type="legacy"
                    onClick={() => formik.setFieldValue([DATA_MODEL_TABLE.clearanceRightInmm.key], null, true)}
                  />
                )}
            </OwcInput>
          </div>
          <div className="owcInputBox">
            <OwcInput
              data-testid={`text-field-clearanceHeightInmm`}
              label={DATA_MODEL_TABLE.clearanceHeightInmm.value}
              name="clearanceHeightInmm"
              style={{ width: 254, height: 48 }}
              value={formik.values?.clearanceHeightInmm || null}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={!formik.values?.isActive}
              {...commonPropsForInputsWithoutValue({
                formik,
                key: "clearanceHeightInmm",
                dataTestIdKey: "clearanceHeightInmm",
                dataTestIdForFormKey: "text-field"
              })}
            >
              {formik.errors[DATA_MODEL_TABLE.clearanceHeightInmm.key] &&
                formik.touched[DATA_MODEL_TABLE.clearanceHeightInmm.key] === true && (
                  <>
                    <span
                      // id={generateID.UUID(adminTopBannerMessage, `topBannerMessage`, "error_slot")}
                      slot="error-text"
                    >
                      {formik.errors[DATA_MODEL_TABLE.clearanceHeightInmm.key]}
                    </span>
                  </>
                )}
              {formik.values[DATA_MODEL_TABLE.clearanceHeightInmm.key] !== null &&
                formik.touched[DATA_MODEL_TABLE.clearanceHeightInmm.key] === true &&
                formik.values?.isActive && (
                  <OwcIcon
                    name="circle_clear_filled"
                    slot="suffix"
                    type="legacy"
                    onClick={() => formik.setFieldValue([DATA_MODEL_TABLE.clearanceHeightInmm.key], null, true)}
                  />
                )}
            </OwcInput>
          </div>
          <div className="owcInputBox">
            <OwcInput
              data-testid={`text-field-clearanceToFrontInmm`}
              label={DATA_MODEL_TABLE.clearanceToFrontInmm.value}
              name="clearanceToFrontInmm"
              style={{ width: 254, height: 48 }}
              value={formik.values?.clearanceToFrontInmm || null}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={!formik.values?.isActive}
              {...commonPropsForInputsWithoutValue({
                formik,
                key: "clearanceToFrontInmm",
                dataTestIdKey: "clearanceToFrontInmm",
                dataTestIdForFormKey: "text-field"
              })}
            >
              {formik.errors[DATA_MODEL_TABLE.clearanceToFrontInmm.key] &&
                formik.touched[DATA_MODEL_TABLE.clearanceToFrontInmm.key] === true && (
                  <>
                    <span
                      // id={generateID.UUID(adminTopBannerMessage, `topBannerMessage`, "error_slot")}
                      slot="error-text"
                    >
                      {formik.errors[DATA_MODEL_TABLE.clearanceToFrontInmm.key]}
                    </span>
                  </>
                )}
              {formik.values[DATA_MODEL_TABLE.clearanceToFrontInmm.key] !== null &&
                formik.touched[DATA_MODEL_TABLE.clearanceToFrontInmm.key] === true &&
                formik.values?.isActive && (
                  <OwcIcon
                    name="circle_clear_filled"
                    slot="suffix"
                    type="legacy"
                    onClick={() => formik.setFieldValue([DATA_MODEL_TABLE.clearanceToFrontInmm.key], null, true)}
                  />
                )}
            </OwcInput>
          </div>
          <div className="owcInputBox">
            <OwcInput
              data-testid={`text-field-clearanceBackwardsInmm`}
              label={DATA_MODEL_TABLE.clearanceBackwardsInmm.value}
              name="clearanceBackwardsInmm"
              style={{ width: 254, height: 48 }}
              value={formik.values?.clearanceBackwardsInmm || null}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={!formik.values?.isActive}
              {...commonPropsForInputsWithoutValue({
                formik,
                key: "clearanceBackwardsInmm",
                dataTestIdKey: "clearanceBackwardsInmm",
                dataTestIdForFormKey: "text-field"
              })}
            >
              {formik.errors[DATA_MODEL_TABLE.clearanceBackwardsInmm.key] &&
                formik.touched[DATA_MODEL_TABLE.clearanceBackwardsInmm.key] === true && (
                  <>
                    <span
                      // id={generateID.UUID(adminTopBannerMessage, `topBannerMessage`, "error_slot")}
                      slot="error-text"
                    >
                      {formik.errors[DATA_MODEL_TABLE.clearanceBackwardsInmm.key]}
                    </span>
                  </>
                )}
              {formik.values[DATA_MODEL_TABLE.clearanceBackwardsInmm.key] !== null &&
                formik.touched[DATA_MODEL_TABLE.clearanceBackwardsInmm.key] === true &&
                formik.values?.isActive && (
                  <OwcIcon
                    name="circle_clear_filled"
                    slot="suffix"
                    type="legacy"
                    onClick={() => formik.setFieldValue([DATA_MODEL_TABLE.clearanceBackwardsInmm.key], null, true)}
                  />
                )}
            </OwcInput>
          </div>
        </>
      </FormComponents>
    </>
  );
};

const mapStateToProps = (state) => ({
  instrumentDetail: state.instruments?.instrumentDetail
});

export default compose(connect(mapStateToProps), withApollo)(MasterDataDimensions);
