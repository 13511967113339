import * as yup from "yup";

/**
 *
 * @param {laboratoryValidationSchema} schema the schema which is used to validate that field
 * @param {changeTo} changeTo param to change the value if we need, default to empty string
 * @returns
 */
const getCustomNullable = (schema, changeTo = "") => schema.nullable().transform((value) => value ?? changeTo);

/**
 * schema for validating the equpiment identification form fields
 * have used formik and yup for setting up the validations
 * input type of select is validated against key, value or string based on the options
 * input type of text is validated against string
 */
export const laboratoryValidationSchema = yup.object({
  belongingToGroup: yup.string("Enter group").required("Group is required").typeError("Invalid type").nullable(),
  buildingLocation: yup
    .object({
      id: yup.string("Enter building").typeError("Invalid type").required("Building is required"),
      buildingNumber: yup.string("Enter building").typeError("Invalid type").required("Building is required")
    })
    .required("Building is required")
    .nullable(),
  responsiblePerson: yup
    .string("Enter responsible person")
    .required("Responsible person is required")
    .typeError("Invalid type")
    .nullable(),
  responsibleProxy: getCustomNullable(yup.string("Enter responsible proxy").typeError("Invalid type")),
  floor: yup
    .object({
      id: yup.string("Enter floor").typeError("Invalid type").required("Floor is required"),
      floorCode: yup.string("Enter floor").typeError("Invalid type").required("Floor is required")
    })
    .required("Floor is required")
    .nullable(),
  room: yup
    .object({
      id: yup.string("Enter room").typeError("Invalid type").required("Room is required"),
      roomCode: yup.string("Enter room").typeError("Invalid type").required("Room is required")
    })
    .required("Room is required")
    .nullable(),
  systemOwner: getCustomNullable(yup.string("Enter system owner").typeError("Invalid type")),
  equipmentAdministrator: getCustomNullable(yup.string("Enter equipment administrator").typeError("Invalid type")),
  functionalLocation: getCustomNullable(yup.string("Enter functional location").typeError("Invalid type")),
  costCenter: getCustomNullable(yup.string("Enter cost center").typeError("Invalid type"))
});
