import { compose } from "redux";
import { withApollo } from "react-apollo";
import { Autocomplete, Popper, TextField } from "@mui/material";
import { OwcChip, OwcTypography } from "@one/react";
import { useState, useEffect } from "react";
import { getAllData } from "../../utils/helpers/fetching";
import { themeMapping } from "@one/web-components";
import { THEME_NAME } from "../../constants";
import { sortBy, uniqBy } from "lodash";
import { SELECT_ALL_VALUE } from "@digitallab/grid-common-components";

const THEME = themeMapping[THEME_NAME];
const BORDER_STYLE = `${THEME["one-box-shadow-positive-1"]} ${THEME["one-border-style-solid"]} ${THEME["one-color-cobas-gray-400"]}`;

/**
 * Multiselect with search - displays selected options below as Chips
 * Select values taken from provided query
 * @param {object} props
 * @param {object} props.formik - Formik state and methods
 * @param {object} props.client - Apollo client
 * @param {string} props.dataKey - name of select field for form
 * @param {string} props.label - label/visible description of select field
 * @param {Object} props.queryDetails - query params as object or array of objects
 * @param {Object} props.queryDetails.query - GQL query
 * @param {Object} props.queryDetails.dataPath - array with path to data in query response
 * @param {Object} props.queryDetails.responseDataKey - name of property for values to select from data array received in query response
 */

const PopperMy = function (props) {
  return (
    <Popper
      {...props}
      style={{
        border: BORDER_STYLE,
        borderRadius: THEME["one-border-radius-4"],
        ...props.style
      }}
      placement="bottom-start"
    />
  );
};

export const OwcSelectWithChip = compose(withApollo)(({ client, formik, dataKey, label, queryDetails }) => {
  const [values, setValues] = useState(formik?.values[dataKey] || []);
  const [optionsList, setOptionsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const processQuery = async (queryDetails) => {
    const { query, dataPath, responseDataKey, variables } = queryDetails;
    const { items, error, errors } = await getAllData({
      client,
      query,
      variables: variables ? variables : { limit: 1000 },
      dataPath
    });
    if (error) throw new Error(error);
    if (errors) {
      if (Array.isArray(errors)) {
        const errorsJoined = error.map((error) => error.message).join(", ");
        throw new Error(errorsJoined);
      } else new Error(errors);
    }
    const result = items.map((item) => ({
      label: item[responseDataKey],
      value: item[responseDataKey]
    }));
    return result;
  };

  useEffect(() => {
    const getOptionsList = async () => {
      let optionsList = [];
      setLoading(true);
      setError(false);
      try {
        if (Array.isArray(queryDetails))
          optionsList = [].concat(...(await Promise.all(queryDetails.map(async (query) => await processQuery(query)))));
        else optionsList = optionsList.concat(await processQuery(queryDetails));

        const uniqueOptionsList = uniqBy(optionsList, "value");
        const sortedOptionsList = sortBy(uniqueOptionsList, ["label"]);
        sortedOptionsList.unshift(SELECT_ALL_VALUE);
        setOptionsList(sortedOptionsList);
      } catch (error) {
        setError(true);
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    getOptionsList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, JSON.stringify(queryDetails)]);

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      [dataKey]: values
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(values), dataKey]);

  return (
    <>
      <Autocomplete
        name={dataKey}
        multiple
        disableCloseOnSelect
        options={optionsList}
        PopperComponent={PopperMy}
        sx={{ width: THEME["one-sizer-base-100-p"] }}
        loading={loading}
        noOptionsText={error ? "Error fetching data" : "No options"}
        value={values}
        onBlur={() => {
          formik.setFieldTouched(dataKey, true);
        }}
        onChange={(_, selectValue) => {
          const containsValueFromSelectAll = (myArray) => myArray.some(({ value }) => value === SELECT_ALL_VALUE.value);

          if (selectValue.length === 2 && containsValueFromSelectAll(selectValue) && containsValueFromSelectAll(values))
            setValues([...selectValue.filter((item) => item.value !== SELECT_ALL_VALUE.value)]);
          else setValues(containsValueFromSelectAll(selectValue) ? [SELECT_ALL_VALUE] : selectValue);
        }}
        isOptionEqualToValue={(option, passedValue) => passedValue.value === option.value}
        renderOption={(props, option) => (
          <li
            {...props}
            style={{
              borderBottom: BORDER_STYLE,
              padding: THEME["one-spacer-8"]
            }}
          >
            <input
              type="checkbox"
              readOnly
              style={{
                marginRight: THEME["one-spacer-8"],
                cursor: "pointer"
              }}
              checked={values.map(({ value }) => value).includes(option.value)}
            />

            <OwcTypography>{option.label}</OwcTypography>
          </li>
        )}
        renderTags={() => (
          <>
            {values.length > 0 && (
              <>
                <OwcTypography>{values[0].label}</OwcTypography>
                {values.length > 1 && <span style={{ marginLeft: THEME["one-spacer-8"] }}>+{values.length - 1}</span>}
              </>
            )}
          </>
        )}
        renderInput={(params) => {
          const hasValue = values.length > 0;
          return (
            <TextField
              {...params}
              helperText={formik.errors[dataKey]}
              error={formik.touched[dataKey] && !!formik.errors[dataKey]}
              placeholder={(!hasValue && label) || ""}
              variant="filled"
              name={dataKey}
            />
          );
        }}
      />
      <br />
      <div
        style={{
          margin: `${THEME["one-spacer-12"]} 0 ${THEME["one-spacer-12"]} 0`,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignContent: "flex-start",
          gap: THEME["one-spacer-12"],
          height: THEME["one-sizer-base-600"],
          overflow: "auto"
        }}
      >
        {Array.isArray(values) && values.length > 0 ? (
          values.map((option, index) => (
            <OwcChip
              key={index + option}
              removable
              round
              onRemove={() => {
                const arrayCopy = [...values];
                arrayCopy.splice(index, 1);
                formik.setFieldTouched(dataKey, true);
                return setValues(arrayCopy);
              }}
            >
              {option.label}
            </OwcChip>
          ))
        ) : (
          <OwcTypography>Selected {dataKey} will appear here </OwcTypography>
        )}
      </div>
    </>
  );
});
