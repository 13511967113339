import { useFormikContext } from "formik";
import React, { useContext, useState, useEffect } from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { CLUSTER_SUB_EQUIPMENT_MAX_SELECTED_LIMIT, CLUSTER_SUB_EQUIPMENT_MIN_SELECTED_LIMIT } from "../../../constants";
import useDialog from "../../../utils/hooks/useDialog";
import ConfirmDialog from "../../../components/shared/ConfirmDialog";
import { OwcButton } from "@one/react";
import { SelectItemsContext } from "../../../components/context/contexts";
import { connect } from "react-redux";
import { compose, withApollo } from "react-apollo";
import { compareArrayFunc } from "../helpers";

const FormActions = ({
  stepList,
  activeStep,
  isNextDisabled,
  cancelHandle,
  prevButtonHandle,
  nextButtonHandle,
  submitHandle,
  isSubmitDisabled,
  editClusterDetail,
  clusteSubEquipments,
  isEditMode,
  updateHandle,
  disableSave
}) => {
  const formik = useFormikContext();
  const { selected } = useContext(SelectItemsContext);
  const { openDialog, ...dialogProps } = useDialog();
  const [isReasonUpdate, setIsReasonUpdate] = useState(false);
  const [subEqipChecksDisable, setSubEqipChecksDisable] = useState(false);
  const { errors } = useFormikContext();

  /**
   * This method is used to track the cluster creation through matomo statistic
   */
  const { trackEvent } = useMatomo();

  const handlerOnCreateCluster = () => {
    // Track click on button
    trackEvent({ category: "Create cluster", action: "click-event" });
    submitHandle(formik.values);
  };
  const updateReasons = () => {
    setIsReasonUpdate(true);
    openDialog();
  };

  const handlerOnEditCluster = () => {
    // Track click on button
    trackEvent({ category: "Edit cluster", action: "click-event" });
    updateHandle(formik.values);
  };

  useEffect(() => {
    //validation of subEquipment count of enable/disable save button
    if (editClusterDetail) {
      let subEqpLengthDefault = editClusterDetail?.subEquipment?.items || [];
      let result = subEqpLengthDefault.length > 0 ? subEqpLengthDefault?.map((k) => k.id) : [];

      if (selected?.length === subEqpLengthDefault?.length) {
        if (clusteSubEquipments !== undefined || clusteSubEquipments !== null) {
          let resultCount = Object.values(clusteSubEquipments)?.map((k) => k.id);
          let sameOrder = compareArrayFunc(result, resultCount);
          if (sameOrder && editClusterDetail?.leadingSoftwareVersionId === formik.values?.leadingSoftwareVersionId) {
            setSubEqipChecksDisable(true);
          } else {
            setSubEqipChecksDisable(false);
          }
        }
      } else {
        setSubEqipChecksDisable(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, activeStep, disableSave, formik.values, clusteSubEquipments]);

  return (
    <>
      <div style={{ display: "flex", flexGrow: "1" }}>
        {activeStep.id !== 0 && !isEditMode && (
          <OwcButton
            data-testid="cluster-previous-step-button"
            variant="secondary"
            onClick={() => prevButtonHandle()}
            disabled={activeStep.id === 0}
          >
            Previous
          </OwcButton>
        )}
      </div>

      <div style={{ display: "flex" }}>
        <OwcButton
          data-testid="cluster-previous-step-button"
          onClick={() => cancelHandle()}
          variant="secondary"
          style={{ textTransform: "none", marginRight: "20px" }}
        >
          Cancel
        </OwcButton>

        {isEditMode && (
          <>
            <OwcButton
              variant="primary"
              style={{
                textTransform: "none",
                marginRight: !(activeStep.id === stepList.length - 1 || isNextDisabled) ? "20px" : ""
              }}
              disabled={
                (subEqipChecksDisable && disableSave) ||
                Object.keys(errors).length > 0 ||
                (selected?.length < CLUSTER_SUB_EQUIPMENT_MIN_SELECTED_LIMIT &&
                  selected?.length < CLUSTER_SUB_EQUIPMENT_MAX_SELECTED_LIMIT)
              }
              onClick={() => updateReasons()}
              data-testid="add-instrument-next-step-button"
            >
              Save
            </OwcButton>
            <ConfirmDialog
              {...dialogProps}
              isReasonUpdate={isReasonUpdate}
              approveText="OK"
              approveColor="primary"
              approveVariant="contained"
              cancelText="Cancel"
              cancelVariant="outlined"
              cancelColor="primary"
              onApprove={() => handlerOnEditCluster()}
              title="Reason for editing cluster"
              disableBackdropClick={true}
            />
          </>
        )}

        {!isEditMode && activeStep.id === stepList.length - 1 && (
          <OwcButton
            variant="primary"
            onClick={handlerOnCreateCluster}
            style={{
              textTransform: "none"
            }}
            type="submit"
            data-testid="cluster-save-step-button"
            disabled={
              activeStep.id !== stepList.length - 1 ||
              (selected?.length < CLUSTER_SUB_EQUIPMENT_MIN_SELECTED_LIMIT &&
                selected?.length < CLUSTER_SUB_EQUIPMENT_MAX_SELECTED_LIMIT) ||
              isSubmitDisabled
            }
          >
            Finalize
          </OwcButton>
        )}
        {activeStep.id >= 0 && activeStep.id < stepList.length - 1 && !isEditMode && (
          <OwcButton
            variant="primary"
            style={{ textTransform: "none" }}
            type="submit"
            data-testid="cluster-next-step-button"
            disabled={activeStep.id === stepList.length - 1 || isNextDisabled}
            onClick={() => {
              if (formik.isValid && Object.keys(formik.errors).length === 0) {
                nextButtonHandle(formik.values);
              }
            }}
          >
            Next
          </OwcButton>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  editClusterDetail: state?.clusters?.editClusterDetail
});
export default compose(connect(mapStateToProps, {}), withApollo)(FormActions);
