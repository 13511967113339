import * as types from "./actionTypes";
import initialState from "./initialState";

export default function clustersReducer(state = initialState, action = { type: "", payload: {} }) {
  switch (action?.type) {
    case types.UPDATE_CLUSTER_DETAIL:
      return {
        ...state,
        clusterDetail: {
          ...action?.payload
        }
      };
    case types.UPDATE_EDIT_CLUSTER_DETAIL:
      return {
        ...state,
        editClusterDetail: {
          ...action?.payload
        }
      };

    default:
      return state;
  }
}
