import gql from "graphql-tag";

export const ON_CREATE_IR_INBOX = gql`
  subscription onCreateIRInbox {
    onCreateIRInbox {
      emailId
      status
    }
  }
`;
export const ON_UPDATE_IR_INBOX = gql`
  subscription onUpdateIRInbox {
    onUpdateIRInbox {
      emailId
      status
    }
  }
`;
export const ON_UPDATE_IR_UPLOAD_STATUS = gql`
  subscription onUpdateIRUploadStatus {
    onUpdateIRUploadStatus {
      s3VersionId
      status
      errorMessage
      email
    }
  }
`;
export const ON_UPDATE_BACKEND_HEALTH_CHECK = gql`
  subscription onUpdateBackendHealthCheck {
    onUpdateBackendHealthCheck {
      isAppUnderMaintenance
    }
  }
`;

export const ON_UPDATE_CHANGE_LINKED_INSTANCE = gql`
  subscription onUpdateIRBulkLinkedInstanceChange {
    onUpdateIRBulkLinkedInstanceChange {
      failureCount
      progressCount
      status
      successCount
      totalCount
    }
  }
`;

export const ON_UPDATE_CHANGE_BULK_EDIT_EQUIP_LIST = gql`
  subscription onUpdateIRBulkEdit {
    onUpdateIRBulkEdit {
      failureCount
      progressCount
      status
      successCount
      totalCount
    }
  }
`;

export const ON_UPDATE_REVIEW_REQUIRED_TRACKING = gql`
  subscription onUpdateReviewRequiredTracking {
    onUpdateReviewRequiredTracking {
      reviewRequired
      reviewRequiredInventoryIds
    }
  }
`;
