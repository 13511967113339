import * as types from "./actionTypes";
import initialState from "./initialState";

export const comparatorInstrument = (x, y) =>
  x?.materialNumber === y?.materialNumber && x?.serialNumber === y?.serialNumber;

export const keyIds = ["materialNumber", "serialNumber"];

export default function instrumentsReducer(state = initialState, action = { type: "", payload: {} }) {
  switch (action?.type) {
    case types.LOAD_INSTRUMENTS:
      return {
        ...state,
        ...action?.payload
      };
    case types.LOAD_BUILDING_LISTS:
      return {
        ...state,
        buildingList: action?.payload?.listIRBuildingMasters?.items
      };
    case types.LOAD_FLOOR_LISTS:
      return {
        ...state,
        floorList: action?.payload?.listIRFloorMasters?.items
      };
    case types.LOAD_ROOM_LISTS:
      return {
        ...state,
        roomList: action?.payload?.listIRRoomMasters?.items
      };
    case types.LOAD_GROUP_LISTS:
      return {
        ...state,
        groupList: action?.payload?.listIRGroupMasters?.items.filter((item) => item?.value !== "null")
      };
    case types.LOAD_REASON_LISTS:
      return {
        ...state,
        reasonList: action?.payload?.listIRConfigurableMasters?.items
      };
    case types.LOAD_LINKED_INSTANCE_LISTS:
      return {
        ...state,
        linkedInstanceList: action?.payload?.listLinkedInstanceMasters?.items
      };
    case types.LOAD_RESPONSIBLE_PERSON_LISTS:
      return {
        ...state,
        responsiblePersonList: action?.payload?.listIRFilterLookups?.items.filter((item) => item?.value !== "null")
      };
    case types.UPDATE_BUILDINGS_ON_SITE:
      return {
        ...state,
        buildingDataOnSite: action?.payload
      };
    case types.UPDATE_FLOOR_ON_SITE:
      return {
        ...state,
        floorDataOnSite: action?.payload
      };
    case types.UPDATE_ROOM_ON_SITE:
      return {
        ...state,
        roomDataOnSite: action?.payload
      };
    case types.LOAD_EQUIPMENT_ADMINISTRATOR_LISTS:
      return {
        ...state,
        equipmentAdministratorList: action?.payload?.listIREquipmentAdministrators?.items.filter(
          (item) => item?.value !== "null"
        )
      };
    case types.LOAD_ALL_LISTS:
      return {
        ...state,
        manufacturerList: action?.payload?.listIRManufacturerMasters?.items,
        categoryMappingMasterList: action?.payload?.listIRCategoryMappingConfigurations?.items,
        variantList: action?.payload?.listIRVariant?.items,
        groupList: action?.payload?.listIRGroupMasters?.items.filter((item) => item?.value !== "null"),
        linkedInstanceList: action?.payload?.listLinkedInstanceMasters?.items,
        sopList: action?.payload?.listIRSOPMasters?.items,
        modelList: action?.payload?.listIRequipmentModelConfigurations?.items,
        clusterModelList: action?.payload?.listIRclusterModelConfigurations?.items,
        categoryList: action?.payload?.listIREquipmentCategoryConfigurations?.items,
        clusterCategoryList: action?.payload?.listIRClusterCategoryMappings?.items,
        responsiblePersonList: action?.payload?.listIRFilterLookups?.items.filter((item) => item?.value !== "null"),
        equipmentAdministratorList: action?.payload?.listIREquipmentAdministrators?.items.filter(
          (item) => item?.value !== "null"
        )
      };
    case types.UPDATE_INSTRUMENT_DETAIL:
      return {
        ...state,
        instrumentDetail: action?.payload
      };

    case types.UPDATE_EDIT_INSTRUMENT_DETAIL:
      return {
        ...state,
        editInstrumentDetail: action?.payload
      };

    case types.UPDATE_INSTRUMENT_FILTER:
      return {
        ...state,
        filters: action?.payload
      };

    case types.UPDATE_LIMIT:
      return {
        ...state,
        limit: action?.payload
      };
    case types.UPDATE_NEXT_TOKEN:
      return {
        ...state,
        nextToken: action?.payload
      };
    case types.RESET_PAGE_TOKEN_ARRAY:
      return {
        ...state,
        pageTokenArray: []
      };
    case types.UPDATE_PAGE_TOKEN_ARRAY:
      return {
        ...state,
        pageTokenArray: [...state.pageTokenArray, action?.payload]
      };
    case types.UPDATE_FILTER_APPLIED:
      return {
        ...state,
        filterApplied: action?.payload
      };
    case types.UPDATE_SEARCH_FILTER:
      return {
        ...state,
        search: action?.payload
      };

    case types.UPDATE_LOADING:
      return {
        ...state,
        loading: action?.payload
      };

    case types.UPDATE_EDIT_MASTER_DATA_DETAIL:
      return {
        ...state,
        editMasterDataDetail: action?.payload
      };

    default:
      return state;
  }
}
