const ITEMS_LIST_VALUES = Object.freeze({
  CSV_EQUIPMENT: "csv-equipment",
  BULK_CHANGE: "bulk-change",
  WINSHUTTLE_EQUIPMENT: "winshuttle-equipment",
  NONE: "none"
});

const itemsList = [
  {
    accessLevel: ["Admin", "User", "Viewer"],
    value: ITEMS_LIST_VALUES.CSV_EQUIPMENT,
    label: "CSV equipment",
    description:
      "Exports all equipment that match your filter criteria with the columns you've selected based on your column configuration.",
    disabled: false
  },
  {
    accessLevel: ["Admin", "User"],
    value: ITEMS_LIST_VALUES.BULK_CHANGE,
    label: "Bulk change",
    description:
      "Exports all equipment that match your filter criteria with all available columns. Used to apply changes and re-upload for bulk change.",
    disabled: false
  },
  {
    accessLevel: ["Admin", "User", "Viewer"],
    value: ITEMS_LIST_VALUES.WINSHUTTLE_EQUIPMENT,
    label: "Winshuttle equipment",
    description:
      "Exports all equipment that are shown based on your filter criteria, including all sub-equipment of clusters. Clusters themselves are not exported. Column settings match the equipment winshuttle script columns.",
    disabled: false
  }
];

export { itemsList, ITEMS_LIST_VALUES };
