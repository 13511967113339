import { compose } from "redux";
import { withApollo } from "react-apollo";
import { useState, useMemo, useRef, useEffect, useCallback } from "react";
import { DLabDataGrid, IconWithTexts } from "@digitallab/grid-common-components";
import { OwcButton, OwcIcon, OwcInput, OwcHyperlink } from "@one/react";

import { ModalDialog } from "./ModalDialog";
import { FileCreate } from "../../icons/FileCreate";
import { useFormik } from "formik";
import { checklistDialogValidationSchema } from "./checklistDialogValidationSchema";
import { ChecklistForm } from "./ChecklistForm";
import ReactDOMServer from "react-dom/server";
import { getData } from "../../utils/helpers/fetching";
import Snackbar from "../snackbar/Snackbar";
import { CREATE_CHECKLIST, GET_CHECKLISTS } from "../../gql/landingapi";
import { DELETE_CHECKLIST } from "../../gql/landingapi";
import { CHECKLIST_STATUS_ENUM } from "../../constants";
import { SELECT_ALL_VALUE } from "@digitallab/grid-common-components";
import { themeMapping } from "@one/web-components";
import { THEME_NAME } from "../../constants";
import { ChecklistActions } from "./ChecklistActions";

const THEME = themeMapping[THEME_NAME];

const convertToDisplayValue = (value) => {
  if (Array.isArray(value)) {
    return value.map((item) => (item === SELECT_ALL_VALUE.value ? SELECT_ALL_VALUE.label : item)).join(", ");
  }
  return value;
};

const filterDefaultParams = {
  buttons: ["reset", "apply"],
  closeOnApply: true
};

const defaultCellParams = {
  valueFormatter: (params) => convertToDisplayValue(params.value),
  cellRenderer: ({ value, data: { status } }) => {
    const displayValue = convertToDisplayValue(value);
    if (displayValue === undefined) return "undefined";
    return status === CHECKLIST_STATUS_ENUM.DELETED ? (
      <span style={{ color: THEME["one-color-cobas-gray-400"] }}>{displayValue}</span>
    ) : (
      displayValue
    );
  },
  filterParams: filterDefaultParams,
  tooltipValueGetter: (params) => convertToDisplayValue(params.value)
};

/**
 * Checklist main page
 */

export const ChecklistContainer = compose(withApollo)(({ client }) => {
  const [rowPerPage, setRowPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [items, setItems] = useState(undefined);
  const [itemsError, setItemsError] = useState("");
  const [openAddModal, setOpenAddModal] = useState(false);
  const [quickFilterText, setQuickFilterText] = useState("");
  const [gridApi, setGridApi] = useState(null);
  const gridRef = useRef();

  const handleDelete = async (checklistToDelete) => {
    try {
      const itemToDelete = items.find((item) => item.createdAt === checklistToDelete.createdAt);
      if (!itemToDelete) throw new Error("unable to find item to delete");
      itemToDelete.status = CHECKLIST_STATUS_ENUM.DELETED;
      setItems([...items]);
      await client
        .mutate({
          mutation: DELETE_CHECKLIST,
          variables: { createdAt: itemToDelete.createdAt }
        })
        .then((result) => {
          if (result.errors) {
            if (Array.isArray(result.errors)) {
              const errorsJoined = result.errors.map((error) => error.message).join(", ");
              throw new Error(errorsJoined);
            }
          }
          Snackbar({
            appName: "",
            icon: "circle_confirm",
            type: "success",
            text: "Checklist has been removed successfully"
          });
        });
    } catch (error) {
      items.find((item) => item.createdAt === checklistToDelete.createdAt).status = CHECKLIST_STATUS_ENUM.ACTIVE;
      setItems([...items]);
      Snackbar({
        appName: "",
        text: `Checklist deletion failure ${error.message || error}`
      });
    }
  };

  const columnDefs = [
    {
      field: "description",
      ...defaultCellParams,
      valueFormatter: undefined
    },
    {
      headerName: "URL",
      field: "url",
      cellRenderer: ({ value: link, data: { status } }) =>
        status === CHECKLIST_STATUS_ENUM.DELETED ? (
          <strong style={{ color: THEME["one-color-cobas-gray-400"] }}>{link}</strong>
        ) : (
          <OwcHyperlink href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </OwcHyperlink>
        ),
      filterParams: filterDefaultParams
    },
    {
      headerName: "Model(s)",
      field: "models",
      ...defaultCellParams
    },
    {
      headerName: "Site(s)",
      field: "sites",
      ...defaultCellParams
    },
    {
      headerName: "Group(s)",
      field: "groups",
      ...defaultCellParams
    },
    {
      field: "actions",
      headerName: "Actions",
      maxWidth: 50,
      filter: false,
      pinned: "right",
      lockVisible: true,
      cellRenderer: ({ data }) => <ChecklistActions data={data} handleDelete={handleDelete} />
    }
  ];

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 90,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      suppressMenu: true,
      sortable: true
    }),
    []
  );

  const onGridReady = useCallback((params) => {
    setGridApi(params);
  }, []);

  useEffect(() => {
    setItemsError("");
    getData({
      client,
      query: GET_CHECKLISTS,
      dataPath: ["data", "listChecklists"]
    })
      .then((result) => {
        if (result) setItems(result.items);
        else throw new Error(`No records found in result: ${null}`);
      })
      .catch((error) => {
        setItemsError(error.message);
        setItems([]);
      });
  }, [client]);

  useEffect(() => {
    if (gridApi) {
      onGridReady(gridApi);
      setTotalRows(gridRef.current.api.getDisplayedRowCount());
    }
  }, [rowPerPage, quickFilterText, gridApi, onGridReady, items?.length]);

  const onRowsPerPageChanged = (value) => {
    setRowPerPage(value);
  };

  const onFilterChanged = () => {
    setTotalRows(gridRef.current.api.getDisplayedRowCount());
  };

  const attr = {
    height: 83 + 40 * rowPerPage,
    columnDefs,
    suppressPaginationPanel: true,
    paginationPageSize: rowPerPage,
    paginationConfig: {
      rowsPerPage: rowPerPage,
      total: totalRows
    },
    pagination: true,
    animateRows: true,
    overlayNoRowsTemplate: itemsError
      ? // plain HTML string only allowed for overlayNoRowsTemplate
        ReactDOMServer.renderToStaticMarkup(
          <IconWithTexts
            icon={<OwcIcon name="alarm" family="outlined"></OwcIcon>}
            mainText="Error"
            additionalText={itemsError}
          />
        )
      : ReactDOMServer.renderToStaticMarkup(
          <IconWithTexts icon={<FileCreate />} mainText="No checklist here" additionalText="Add a new one" />
        ),
    rowData: items,
    onFilterChanged,
    onRowsPerPageChanged,
    setRowPerPage,
    onGridReady,
    defaultColDef,
    quickFilterText: quickFilterText,
    cacheQuickFilter: true,
    enableBrowserTooltips: true
  };

  const convertInputValuesForMutation = (inputValues) => {
    return {
      description: inputValues.description,
      url: inputValues.url,
      models: inputValues.models.map(({ value }) => value),
      sites: inputValues.sites.map(({ value }) => value),
      groups: inputValues.groups.map(({ value }) => value)
    };
  };

  const handleSubmit = (inputValues, { resetForm }) => {
    const valuesForMutation = convertInputValuesForMutation(inputValues);
    const updatedItems = [...items, valuesForMutation];
    setItems(updatedItems);
    client
      .mutate({
        mutation: CREATE_CHECKLIST,
        fetchPolicy: "no-cache",
        variables: valuesForMutation
      })
      .then((result) => {
        const {
          data: { createChecklist: receivedItem }
        } = result;
        if (!receivedItem) throw new Error("Response does not contain proper data", result);
        const newItemIndex = updatedItems.findIndex(
          (item) => JSON.stringify(item) === JSON.stringify(valuesForMutation)
        );
        if (newItemIndex < 0) throw new Error("Unable to locate new checklist");
        updatedItems[newItemIndex] = receivedItem;
        setItems([...updatedItems]);
        Snackbar({
          icon: "circle_confirm",
          type: "success",
          text: "New Checklist has been created"
        });
      })
      .catch((error) => {
        const newItemIndex = updatedItems.findIndex(
          (item) => JSON.stringify(item) === JSON.stringify(valuesForMutation)
        );
        if (newItemIndex > -1) updatedItems.splice(newItemIndex, 1);
        setItems([...updatedItems]);
        Snackbar({
          icon: "alert",
          text: "Failed to create checklist"
        });
        console.error("Failed to create checklist", error);
      });

    setOpenAddModal(false);
    resetForm();
  };

  const handleCloseModal = () => {
    setOpenAddModal(false);
    formik.resetForm();
  };

  const initialFormValues = {
    description: "",
    url: "",
    models: [],
    sites: [],
    groups: []
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    onSubmit: handleSubmit,
    validationSchema: checklistDialogValidationSchema
  });

  return (
    <>
      <div style={{ width: "100%" }}>
        <OwcInput
          type="text"
          label="Search all columns"
          value={quickFilterText}
          onInputChange={(e) => {
            setQuickFilterText(e.target.value);
          }}
        >
          <OwcIcon slot="prefix" name="search" type="legacy" />
        </OwcInput>
        <br />

        <DLabDataGrid {...attr} gridRef={gridRef} defaultColDef={defaultColDef}>
          <span name="pagination" style={{ display: "flex", alignItems: "center" }}>
            <OwcButton
              variant="primary"
              onClick={() => {
                setOpenAddModal(true);
              }}
            >
              Add checklist
            </OwcButton>
          </span>
        </DLabDataGrid>
      </div>

      {openAddModal && (
        <ModalDialog
          openModal={openAddModal}
          closeModal={handleCloseModal}
          modalTitle="Add Checklist"
          formik={formik}
          modalBody={<ChecklistForm formik={formik} />}
          confirmText="Save Checklist"
        />
      )}
    </>
  );
});
