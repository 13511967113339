import React, { useState, useEffect } from "react";
import InstrumentsTable from "./InstrumentsTable";
import styled from "styled-components";
import AddInstrumentContainer from "./addEditEquipment/AddEditEquipmentContainer";
import { Formik } from "formik";
import {
  DEFAULT_IR_PAGE_SIZE,
  MODIFY_CLUSTER,
  MODIFY_INSTRUMENT,
  SHOW_INSTRUMENT,
  BULK_EDIT_ACTIVE,
  NORMAL_BULK_EDIT,
  defaultinstrumentTableValues,
  EQUIPMENT_REVIEW_ACTIONS
} from "../../constants";
import { getValidationSchema, getInitialValue } from "./instrument-form/validation-schemas-step";
import PaginationWrapper from "../../components/shared/pagination/PaginationWrapper";
import StepperWrapper from "./addEditEquipment/StepperWrapper";
import { useSelector } from "react-redux";
import CommonWrapper from "../../common-context/CommonWrapper";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import AddEditClusterContainer from "./cluster/AddEditClusterContainer";
import { checkRoleInstrumentRepo } from "../../utils/helpers/checkRoleInstrumentRepo";
import BulkEditContainer from "./BulkEditContainer";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import EquipmentReviewDialog from "./equipment-review/EquipmentReviewDialog";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { reasonValidationSchema } from "../reasons/validationSchema";

const MainContainer = styled.div`
  margin-top: 22px;
  width: 100%;
`;

const InstrumentsMainPage = () => {
  const { ReviewId: reviewId } = useParams();
  const history = useHistory();
  const { groups, user: fullUserID } = useSelector((state) => state.user);
  const [activeModule, setActiveModule] = useState(SHOW_INSTRUMENT);
  const [selectedEquipmentId, setSelectedEquipmentId] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [reviewAction, setReviewAction] = useState(EQUIPMENT_REVIEW_ACTIONS?.review?.value);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [actionStatus, setActionStatus] = useState({});
  const [hasPermission, setHasPermission] = useState(false);
  const [saveReason, setSaveReason] = useState(false);
  const [bulkImportFilters, setBulkImportFilters] = useState(null);
  const [enableCheckbox, setEnableCheckbox] = useState(false);
  const [queryChoice, setQueryChoice] = useState(NORMAL_BULK_EDIT);
  const [searchFilterCount, setSearchFilterCount] = useState([]);
  const [reviewInventoryId, setReviewInventoryId] = useState(null);
  let lastPageSize = useSelector((state) => state?.user?.lastPageSize);
  // Track page view
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({
      documentTitle: "Equipment list",
      href: `${window.location?.origin}/AllEquipment`
    });
    // eslint-disable-next-line
  }, []);

  if (typeof lastPageSize === "string" && lastPageSize !== "") {
    lastPageSize = JSON.parse(lastPageSize);
  }
  const initialValues = {
    ...defaultinstrumentTableValues,
    limit: lastPageSize?.ir_grid ? lastPageSize?.ir_grid : DEFAULT_IR_PAGE_SIZE.ir_grid
  };

  const options = {
    showTitleNav: true,
    showTopBarAction: true,
    showPreDefinedFilter: true,
    showFilterSettings: true,
    showAddClusterEquipBtn: false,
    settings: {
      showSelectColumn: true,
      showFilter: true,
      showGroup: true,
      showReason: true,
      showMasterData: true
    }
  };

  const addInstrumentClicked = (state, isEditMode, item) => {
    setIsEditMode(isEditMode);
    if (item) {
      setSelectedItem(item);
    }
  };

  const onChangeOfActiveModule = (value) => {
    setActiveModule(value);
  };

  const cancelStep = () => {
    setActiveStep(0);
    onChangeOfActiveModule(SHOW_INSTRUMENT);
  };

  useEffect(() => {
    const checkIsInstrumentRepoViewUser = async () => {
      const actions = await checkRoleInstrumentRepo(groups?.length > 0, groups?.length > 0);
      setActionStatus(actions);
      setHasPermission(actions.hasRole === "Admin");
      if (reviewId) {
        setReviewAction(EQUIPMENT_REVIEW_ACTIONS?.review?.value);
        setOpenReviewModal(true);
      }
    };
    checkIsInstrumentRepoViewUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onReviewOrRevert = (value, inventoryId) => {
    setReviewInventoryId(inventoryId);
    setReviewAction(value);
    setOpenReviewModal(true);
  };

  const onCloseReviewModel = () => {
    setReviewInventoryId(null);
    setOpenReviewModal(false);
    onChangeOfActiveModule(SHOW_INSTRUMENT);
    history?.replace("/");
  };

  return (
    <MainContainer
      id="instrument-main-container"
      style={activeModule === MODIFY_CLUSTER ? { padding: "0 0 0 20px" } : { padding: "0 20px 0 20px" }}
    >
      {activeModule === SHOW_INSTRUMENT && (
        <PaginationWrapper initialValues={initialValues}>
          <CommonWrapper>
            <InstrumentsTable
              addInstrumentClicked={addInstrumentClicked}
              selectedEquipmentId={selectedEquipmentId}
              setSelectedEquipmentId={setSelectedEquipmentId}
              setActiveModule={setActiveModule}
              options={options}
              setIsEditMode={setIsEditMode}
              hasPermission={hasPermission}
              setHasPermission={setHasPermission}
              actionStatus={actionStatus}
              activeModule={activeModule}
              bulkImportFilters={bulkImportFilters}
              setBulkImportFilters={setBulkImportFilters}
              enableCheckbox={enableCheckbox}
              setEnableCheckbox={setEnableCheckbox}
              setQueryChoice={setQueryChoice}
              searchFilterCount={searchFilterCount}
              setSearchFilterCount={setSearchFilterCount}
              onReviewOrRevert={onReviewOrRevert}
            />
          </CommonWrapper>
        </PaginationWrapper>
      )}
      {activeModule === MODIFY_INSTRUMENT && (
        <>
          <StepperWrapper isEditMode={isEditMode}>
            <Formik
              validationSchema={getValidationSchema(activeStep, isEditMode)}
              initialValues={getInitialValue(activeStep, isEditMode)}
              isInitialValid={true}
              validateOnMount={false}
            >
              <AddInstrumentContainer
                cancelStep={cancelStep}
                isEditMode={isEditMode}
                setIsEditMode={setIsEditMode}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                activeModule={activeModule}
              ></AddInstrumentContainer>
            </Formik>
          </StepperWrapper>
        </>
      )}
      {activeModule === MODIFY_CLUSTER && (
        <>
          <StepperWrapper isEditMode={isEditMode}>
            <Formik
              validationSchema={getValidationSchema(activeStep, isEditMode)}
              initialValues={getInitialValue(activeStep, isEditMode)}
              isInitialValid={true}
              validateOnMount={false}
            >
              <AddEditClusterContainer
                cancelStep={cancelStep}
                isEditMode={isEditMode}
                setIsEditMode={setIsEditMode}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                selectedItem={isEditMode ? selectedItem : null}
                setActiveModule={setActiveModule}
                hasPermission={hasPermission}
                setHasPermission={setHasPermission}
                actionStatus={actionStatus}
                activeModule={activeModule}
              ></AddEditClusterContainer>
            </Formik>
          </StepperWrapper>
        </>
      )}
      {(activeModule === BULK_EDIT_ACTIVE || saveReason) && (
        <Formik
          validationSchema={reasonValidationSchema}
          initialValues={{ reasonName: "" }}
          isInitialValid={false}
          validateOnMount={false}
        >
          <BulkEditContainer
            activeModule={activeModule}
            setActiveModule={setActiveModule}
            selectedEquipmentId={selectedEquipmentId}
            setSelectedEquipmentId={setSelectedEquipmentId}
            saveReason={saveReason}
            setSaveReason={setSaveReason}
            bulkImportFilters={bulkImportFilters}
            setEnableCheckbox={setEnableCheckbox}
            queryChoice={queryChoice}
            searchFilterCount={searchFilterCount}
          />
        </Formik>
      )}
      {(reviewId || reviewInventoryId) && openReviewModal && (
        <EquipmentReviewDialog
          reviewId={reviewId}
          reviewAction={reviewAction}
          openReviewModal={openReviewModal}
          onCloseReviewModel={onCloseReviewModel}
          inventoryId={reviewInventoryId}
          fullUserID={fullUserID}
          onChangeOfActiveModule={onChangeOfActiveModule}
        />
      )}
    </MainContainer>
  );
};

export default InstrumentsMainPage;
