import React from "react";
import { OwcInputCustomized } from "./OwcInputCustomized";

export const SerialNumberInput = ({ style = {}, ...args }) => (
  <div className="owcInputBox" style={{ width: 313.5 + 8, ...style }}>
    <OwcInputCustomized
      dataTestid="text-field-serial-number"
      dataModel="serialNumber"
      onBlurValidation={(value) => value.trim().split(/\s+/).join(" ")}
      valueValidation={(value) => value?.trimStart().split(/\s+/).join(" ")}
      {...args}
    />
  </div>
);
