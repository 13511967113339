import React from "react";
import styled from "styled-components";
import { OwcTextarea } from "@one/react";

const IdentificationForm = styled.div`
  & > .selectBoxLarge {
    margin: 0 0 25px 0;
  }
`;

export const ReviewCommentOnlyDialogContent = ({ editComment, setEditComment }) => {
  const editCommentChange = (e) => {
    setEditComment(e.target.value);
  };
  return (
    <IdentificationForm>
      <OwcTextarea
        data-testid={`textarea-field-edit-comment`}
        label={"Add comment"}
        name="editComment"
        validation-mode="realtime"
        value={editComment}
        onInputReset={() => setEditComment("")}
        onInputChange={editCommentChange}
        onInputKeyDown={editCommentChange}
        onBlur={editCommentChange}
        reset
        cols="18"
        maxLength={80}
        minLength={0}
        minRows={2}
      />
    </IdentificationForm>
  );
};

export default ReviewCommentOnlyDialogContent;
