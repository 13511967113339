import * as yup from "yup";

/**
 *
 * @param {dimensionsValidationSchema} schema the schema which is used to validate that field
 * @param {changeTo} changeTo param to change the value if we need, default to empty string
 * @returns
 */

/**
 * schema for validating the equpiment identification form fields
 * have used formik and yup for setting up the validations
 * input type of select is validated against key, value or string based on the options
 * input type of text is validated against string
 */
export const dimensionsValidationSchema = yup.object({
  weightInkg: yup
    .string()
    .typeError("Enter a numeric value only")
    .matches(/^[0-9]*\.[0-9]{2}$/, "Enter a decimal value with 2 digits only")
    .nullable(),
  heightInmm: yup
    .string()
    .typeError("Enter a numeric value only")
    .matches(/^(|\d)+$/, "Enter a numeric value only")
    .nullable(),
  lengthInmm: yup
    .string()
    .typeError("Enter a numeric value only")
    .matches(/^(|\d)+$/, "Enter a numeric value only")
    .nullable(),
  depthInmm: yup
    .string()
    .typeError("Enter a numeric value only")
    .matches(/^(|\d)+$/, "Enter a numeric value only")
    .nullable(),
  clearanceLeftInmm: yup
    .string()
    .typeError("Enter a numeric value only")
    .matches(/^(|\d)+$/, "Enter a numeric value only")
    .nullable(),
  clearanceRightInmm: yup
    .string()
    .typeError("Enter a numeric value only")
    .matches(/^(|\d)+$/, "Enter a numeric value only")
    .nullable(),
  clearanceHeightInmm: yup
    .string()
    .typeError("Enter a numeric value only")
    .matches(/^(|\d)+$/, "Enter a numeric value only")
    .nullable(),
  clearanceToFrontInmm: yup
    .string()
    .typeError("Enter a numeric value only")
    .matches(/^(|\d)+$/, "Enter a numeric value only")
    .nullable(),
  clearanceBackwardsInmm: yup
    .string()
    .typeError("Enter a numeric value only")
    .matches(/^(|\d)+$/, "Enter a numeric value only")
    .nullable()
});
