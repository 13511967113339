import { Auth } from "aws-amplify";
import get from "lodash/get";
import {
  GROUPS_TOKEN_PATH,
  INSTRUMENT_REPOSITORY_USER,
  INSTRUMENT_REPOSITORY_VIEWER,
  INSTRUMENT_REPOSITORY_ADMIN
} from "../../constants";

export const checkRoleInstrumentRepo = async (showAddEquip = false, showAddCluster = false) => {
  try {
    const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
    const access_groups = get(currentAuthenticatedUser, GROUPS_TOKEN_PATH);
    let actions = {
      showAddEquip: true,
      showAddCluster: true,
      showImportCSV: true,
      showDownloadCSV: true,
      showStatusLog: true,
      showEdit: true,
      showDelete: true,
      showGroup: true,
      showReason: true,
      showMasterData: true,
      hasRole: "Admin",
      showRestore: true,
      exportBulkCSV: true
    };
    if (access_groups.includes(INSTRUMENT_REPOSITORY_ADMIN)) {
      return actions;
    } else if (access_groups.includes(INSTRUMENT_REPOSITORY_USER)) {
      actions.showAddEquip = showAddEquip;
      actions.showAddCluster = showAddCluster;
      actions.showDownloadCSV = false;
      actions.showImportCSV = false;
      actions.showStatusLog = false;
      actions.showEdit = true;
      actions.showDelete = true;
      actions.showGroup = false;
      actions.showReason = false;
      actions.showMasterData = false;
      actions.hasRole = "User";
      actions.showRestore = true;
      actions.exportBulkCSV = false;
      return actions;
    } else if (access_groups.includes(INSTRUMENT_REPOSITORY_VIEWER)) {
      actions.showAddEquip = false;
      actions.showAddCluster = false;
      actions.showDownloadCSV = false;
      actions.showImportCSV = false;
      actions.showStatusLog = false;
      actions.showEdit = false;
      actions.showDelete = false;
      actions.showGroup = false;
      actions.showReason = false;
      actions.showMasterData = false;
      actions.hasRole = "Viewer";
      actions.showRestore = false;
      actions.exportBulkCSV = false;
      return actions;
    }
  } catch (error) {
    console.log("error", error);
  }
};
