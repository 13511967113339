import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "../reducers";

const middlewares = [thunk];

const configureStore = (initialState) => {
  return createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middlewares)));
};

export default configureStore;
