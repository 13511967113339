import React, { useState, useCallback, useContext } from "react";
import { TogglerContext } from "../../contexts";
import { BackendInfoContext } from "../../features/backend-info-context/context";

const TogglerContextWrapper = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [listMessage, setListMessage] = useState(null);
  const [instrumentData, setInstrumentData] = useState(null);
  const [isMini, setIsMini] = useState(false);
  //toggle fullscreen
  const [isVisible, setIsVisible] = useState(true);
  const { setHideFooter } = useContext(BackendInfoContext);

  const toggleMenu = () => {
    setIsVisible(!isVisible);
    setHideFooter(!isVisible);
  };

  const toggle = useCallback(() => {
    setOpen((val) => !val);
  }, []);
  const fullScreenMode = useCallback(() => {
    setOpen(() => false);
  }, []);
  return (
    <TogglerContext.Provider
      value={{
        open,
        toggle,
        isMini,
        setIsMini,
        listMessage,
        setListMessage,
        instrumentData,
        setInstrumentData,
        fullScreenMode,
        toggleMenu,
        isVisible
      }}
    >
      {children}
    </TogglerContext.Provider>
  );
};

export default TogglerContextWrapper;
