import React from "react";
import styled from "styled-components";
import Search from "./Search";
// import SaveColumn from "../instruments/instrument-save-filter-column/SaveColumn";

export const TopBarStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TopBar = ({
  setQuery,
  label,
  "data-testid": dataTestId,
  children,
  addOnComponent,
  placeholder,
  compact,
  clearSearch
}) => {
  return (
    <TopBarStyled>
      <Search
        setQuery={setQuery}
        label={label}
        data-testid={dataTestId}
        addOnComponent={addOnComponent}
        placeholder={placeholder}
        compact={compact}
        clearSearch={clearSearch}
      />
      {children}
    </TopBarStyled>
  );
};

export default TopBar;
