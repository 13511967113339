import React from "react";
import styled, { css } from "styled-components";
import FetchInstrumentFromDataRiver from "./FetchInstrumentFromDataRiver";
import CustomSelect from "../../components/shared/CustomSelect";
import { useSelector } from "react-redux";
import { commonPropsForInputsWithoutValue } from "./helpers";
import InputWithCheckbox from "./InputWithCheckbox";
import DATA_MODEL_TABLE from "../../utils/constants/dataModelTable";
import { Checkbox, FormControlLabel } from "@mui/material";

export const MainRow = styled.div`
  margin-bottom: 1.25rem;
`;
export const TitleHeader = styled.h2`
  display: flex;
  justify-content: space-between;
`;
export const TitleRow = styled.h2`
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.4375rem;
  color: var(--one-color-gray-800);
  margin-bottom: 0.75rem;
  padding: 0 16px;
`;
export const RowInputs = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1.25rem;

  & > :first-child {
    flex: 1 1 70%;
  }
  & > :last-child {
    flex: 1 1 30%;
    margin-left: 16px;
  }
`;

const checkboxRowCheckedStyles = css`
  background-color: var(--one-color-blue-100);
`;

const checkboxRowDisabledStyles = css`
  background-color: rgba(250, 250, 250, 0.3);
`;

export const CheckboxRow = styled.div`
  background: var(--one-color-gray-50);
  border-radius: 0.25rem;
  padding-left: 0.75rem;
  display: flex;
  align-items: center;
  margin-right: -0.5rem;
  ${(props) => props.isChecked && props.isChecked === true && checkboxRowCheckedStyles}
  ${(props) => props.isDisabled && props.isDisabled === true && checkboxRowDisabledStyles}
`;

const onFetchFromDataRiver = (formik) => (data) =>
  formik.setValues({
    ...formik.values,
    systemStatus: {
      ...formik.values?.systemStatus,
      value: data?.systemStatus?.value
    },
    responsiblePerson: {
      ...formik.values?.responsiblePerson,
      value: data?.responsiblePerson
    },
    buildingLocation: {
      ...formik.values?.buildingLocation,
      value: data?.buildingLocation?.value
    },
    dateOfLastMaintanance: {
      ...formik.values?.dateOfLastMaintanance,
      value: data?.dateOfLastMaintanance
    },
    dateOfNextMaintanance: {
      ...formik.values?.dateOfNextMaintanance,
      value: data?.dateOfNextMaintanance
    },
    equipmentModel: {
      ...formik.values?.equipmentModel,
      value: data?.equipmentModel?.value
    },
    manufacturer: {
      ...formik.values?.manufacturer,
      value: data?.manufacturer
    },
    equipmentId: {
      ...formik.values?.equipmentId,
      value: data?.equipmentId
    }
  });

const BackendRequiredInputs = ({ formik, sites, isEdit }) => {
  const classes = {};
  return (
    <>
      <RowInputs>
        <CustomSelect
          variant="filled"
          style={{ margin: 0 }}
          options={sites.map((x) => x.siteName)}
          onChange={(evt) => {
            const value = evt.target.value;
            const _selected = sites.find((x) => x.siteName === value);
            formik.setTouched({ siteName: true });
            formik.setFieldValue("siteTimezone", _selected.siteTimezone);
            formik.setFieldValue("siteName", _selected.siteName, true);
          }}
          selectLabel={DATA_MODEL_TABLE.siteName.value}
          value={formik.values.siteName}
          testid="basic-data-req-fields-instrument-siteName"
          required
          error={Boolean(formik.errors.siteName)}
          helperText={formik.errors.siteName}
        />
        <CheckboxRow isDisabled={true}>
          <FormControlLabel
            control={
              <Checkbox
                classes={{
                  root: classes.checkbox
                }}
                disabled
                data-testid="basic-data-req-fields-instrument-siteName-disabled-checkbox"
              />
            }
            label="Update?"
          />
        </CheckboxRow>
      </RowInputs>
      <InputWithCheckbox
        fieldName="materialNumber"
        formik={formik}
        testid="material-number"
        label={DATA_MODEL_TABLE.materialNumber.value}
        commonProps={commonPropsForInputsWithoutValue}
        isDisabled={true}
        prefixTest="basic-data-req-fields-instrument"
        isReadOnly={isEdit}
        inputProps={{
          required: true
        }}
      />

      <InputWithCheckbox
        fieldName="serialNumber"
        formik={formik}
        testid="serial-number"
        label={DATA_MODEL_TABLE.serialNumber.value}
        commonProps={commonPropsForInputsWithoutValue}
        isDisabled={true}
        prefixTest="basic-data-req-fields-instrument"
        isReadOnly={isEdit}
        inputProps={{
          required: true
        }}
      />
    </>
  );
};

const FrontendRequiredInputs = ({ formik }) => {
  return (
    <>
      <InputWithCheckbox
        fieldName="instrumentName"
        formik={formik}
        testid="instrument-name"
        label="Equipment name"
        prefixTest="basic-data-req-fields-instrument"
        inputProps={{
          required: true
        }}
      />
      <InputWithCheckbox
        fieldName="equipmentModel"
        formik={formik}
        testid="instrument-type"
        label={DATA_MODEL_TABLE.equipmentModel.value}
        prefixTest="basic-data-req-fields-instrument"
        inputProps={{
          required: true
        }}
      />

      <InputWithCheckbox
        fieldName="instrumentGTIN"
        formik={formik}
        testid="instrument-GTIN"
        label="GTIN number"
        prefixTest="basic-data-req-fields-instrument"
        inputProps={{
          required: true
        }}
      />

      <InputWithCheckbox
        fieldName="instrumentRUDI"
        formik={formik}
        testid="instrument-RUDI"
        label="RUDI number"
        prefixTest="basic-data-req-fields-instrument"
        commonProps={commonPropsForInputsWithoutValue}
        isDisabled={true}
      />
    </>
  );
};

const BasicDataReqFieldsForm = ({ formik, isEdit }) => {
  const sites = useSelector((state) => state.user.sites);
  return (
    <MainRow data-testid="basic-data-req-form">
      <TitleHeader>
        <TitleRow>Equipment identification</TitleRow>
        <FetchInstrumentFromDataRiver
          materialNumber={formik.values.materialNumber}
          serialNumber={formik.values.serialNumber}
          onFetched={onFetchFromDataRiver(formik)}
        />
      </TitleHeader>
      <BackendRequiredInputs sites={sites} isEdit={isEdit} formik={formik} />
      <FrontendRequiredInputs formik={formik} />
    </MainRow>
  );
};
export default BasicDataReqFieldsForm;
