import React from "react";
import { OwcIconButton } from "@one/react/dist";
import { Tooltip } from "@mui/material";

const RefreshIcon = ({ item, refreshTableEvent }) => {
  return (
    <div style={{ marginLeft: "auto" }}>
      <Tooltip data-testid="refresh-icon-tooltip" title={"Refresh"} placement="left" arrow>
        <OwcIconButton
          key="refresh-icon"
          data-testid="refresh-icon"
          onClick={() => {
            refreshTableEvent();
          }}
          icon="refresh"
          type="legacy"
          flat
        />
        {/*data-testid
        <Refresh fontSize="small" data-testid="status-table-refresh-icon" /> */}
      </Tooltip>
    </div>
  );
};

export default RefreshIcon;
