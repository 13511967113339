import React, { useState, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { generateID } from "@digitallab/grid-common-components";
import { OwcWorkflowOverlay, OwcButton, OwcDatepicker, OwcIcon, OwcInput, OwcTypography, OwcBadge } from "@one/react";
import { compose } from "redux";
import { Box, CircularProgress, LinearProgress } from "@mui/material";
import { connect, useSelector } from "react-redux";
import { withApollo } from "react-apollo";
import Notify from "../notifications/Notify";
import ConfirmDialog from "./../../components/shared/ConfirmDialog";
import { CREATE_BULK_EDIT_EQUIPMENT_LIST, CREATE_REVIEW_REQUIRED_TRACKING } from "../../gql/landingapi/mutations";
import {
  ON_UPDATE_CHANGE_BULK_EDIT_EQUIP_LIST,
  ON_UPDATE_REVIEW_REQUIRED_TRACKING
} from "../../gql/landingapi/subscription";
import {
  LIST_BULK_EDIT_EQUIP_LIST,
  LIST_DIGITAL_LAB_INSTRUMENT_REPOSITORY_USERS,
  LIST_REVIEW_REQUIRED_TRACKING,
  VALIDATE_REVIEW_REQUIRED
} from "../../gql/landingapi/queries";
import GenericSubscription from "../../components/shared/GenericSubscription";
import useDialog from "../../utils/hooks/useDialog";
import ReasonDropdown from "../instruments/ReasonDropdown";
import { changeDateFormat } from "../../features/instruments/helpers";
import {
  entryType,
  OTHER_REASON,
  SHOW_INSTRUMENT,
  BULK_EDIT_ACTIVE,
  BULK_EDIT_LABELS,
  NORMAL_BULK_EDIT,
  SEARCH_FILTER_QUERY,
  changeLinkedInstanceStatusEnum,
  GROUP_LITERAL,
  equipmentStatus,
  SPLITTER
} from "../../constants";
import DATA_MODEL_TABLE from "../../utils/constants/dataModelTable";
import { find } from "lodash";
import { getAllData } from "../../utils/helpers/fetching";
import { sortBy } from "underscore";
import { useFormikContext } from "formik";
import { FullScreenCentered } from "./LoadInstrumentsInfo";
import { v4 } from "uuid";
import { convertToSnakeCase } from "../../utils/helpers/converters";

export const BulkContentContainer = styled.div`
  width: 971px;
  height: 218px;
  border-radius: 4px;
  border: 1px;
  box-shadow: 0px 1px 3px 0px #00000066;
`;
export const BulkContentBlock = styled.div`
  display: block;
  justify-content: space-between;
  position: absolute;
  top: 40%;
`;
const BulkEditContainer = ({
  client,
  activeModule,
  setActiveModule,
  selectedEquipmentId,
  setSelectedEquipmentId,
  saveReason,
  setSaveReason,
  bulkImportFilters,
  setEnableCheckbox,
  queryChoice,
  searchFilterCount,
  email,
  userId
}) => {
  const formik = useFormikContext();
  const [isExecutedMaintenance, setExecutedMaintenance] = useState(null);
  const [isNextMaintenance, setNextMaintenance] = useState(null);
  const [isDatesDocumented, setDatesDocumented] = useState("");
  const { openDialog, ...dialogProps } = useDialog();
  const [userList, setUserList] = useState([]);
  const [isReviewerUpdate, setIsReviewerUpdate] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [reviewInventoryId, setReviewInventoryId] = useState("");
  const [startSubscription, setStartSubscription] = useState(false);
  const [addComment, setAddComment] = useState("");
  const [progress, setProgress] = useState(0);
  const [progressStatus, setProgressStatus] = useState(0);
  const [failureCount, setFailureCount] = useState(0);
  const [progressCount, setProgressCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [cancelBulkEdit, setCancelBulkEdit] = useState(false);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);
  const { groups, actionStatus } = useSelector((state) => state?.user);

  const belongingToGroupKey = convertToSnakeCase(DATA_MODEL_TABLE?.belongingToGroup?.key);

  const visibleChangeHandler = (event) => {
    /* Cancel popup implementation */
    if (event?.target?.className === "owcoverayZIndex title has-title") {
      setCancelBulkEdit(true);
    }
  };
  const onUpdateCallback = (subscriptionData) => {
    const result = subscriptionData?.data?.onUpdateIRBulkEdit;
    if (result) {
      setAddComment("");
      setProgressStatus(result?.status);
      setProgressCount(result?.progressCount);
      setFailureCount(result?.failureCount);
      setTotalCount(result?.totalCount);
      setProgress((result?.progressCount / result?.totalCount) * 100);
    }
  };

  const onUpdateReviewRequiredCallback = async (reviewSubscriptionData) => {
    try {
      setReviewInventoryId(
        reviewSubscriptionData?.data?.onUpdateReviewRequiredTracking?.reviewRequiredInventoryIds || []
      );
      if (
        reviewSubscriptionData?.data?.onUpdateReviewRequiredTracking?.reviewRequired === "ALL" ||
        reviewSubscriptionData?.data?.onUpdateReviewRequiredTracking?.reviewRequired === "PARTIAL"
      ) {
        const { items } = await getAllData({
          client,
          query: LIST_DIGITAL_LAB_INSTRUMENT_REPOSITORY_USERS,
          fetchPolicy: "network-only",
          dataPath: ["data", "listDigitalLabInstrumentRepositoryUsers"],
          drillData: true,
          variables: {
            limit: 1000
          }
        });

        let list = items
          .filter((item) => item?.userId !== userId)
          .map((item) => ({
            ...item,
            key: item?.email,
            value:
              item?.givenName && item?.familyName && item?.name
                ? `${item?.givenName} ${item?.familyName} - ${item?.name}`
                : item?.givenName && item?.familyName
                ? `${item?.givenName} ${item?.familyName}`
                : item.email
          }));
        list = sortBy(list, "value");
        setUserList(() => list);
        setIsReviewerUpdate(() => true);
        setSaveReason(() => false);
        openDialog();
      } else {
        openDialog();
        setIsReviewerUpdate(() => false);
        setSaveReason(() => false);
      }
    } catch (error) {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: `Please try after sometime!`
      });
    } finally {
      setIsUpdateDisabled(() => true);
    }
  };

  let changeObject = useMemo(() => ({}), []);

  useEffect(() => {
    if (isExecutedMaintenance === null && isNextMaintenance !== null && isDatesDocumented === "") {
      delete changeObject.dateOfLastMaintanance;
      changeObject.dateOfNextMaintanance = changeDateFormat(isNextMaintenance, "yyyy-MM-dd");
      changeObject.documentedMaintenanceDate = "";
    } else if (isExecutedMaintenance !== null && isNextMaintenance === null && isDatesDocumented === "") {
      changeObject.dateOfLastMaintanance = changeDateFormat(isExecutedMaintenance, "yyyy-MM-dd");
      delete changeObject.dateOfNextMaintanance;
      delete changeObject.documentedMaintenanceDate;
    } else if (isExecutedMaintenance === null && isNextMaintenance === null && isDatesDocumented !== "") {
      delete changeObject.dateOfLastMaintanance;
      changeObject.dateOfNextMaintanance = null;
      changeObject.documentedMaintenanceDate = isDatesDocumented;
    } else if (isExecutedMaintenance !== null && isNextMaintenance !== null && isDatesDocumented === "") {
      changeObject.dateOfLastMaintanance = changeDateFormat(isExecutedMaintenance, "yyyy-MM-dd");
      changeObject.dateOfNextMaintanance = changeDateFormat(isNextMaintenance, "yyyy-MM-dd");
      changeObject.documentedMaintenanceDate = "";
    } else if (isExecutedMaintenance !== null && isNextMaintenance === null && isDatesDocumented !== "") {
      changeObject.dateOfLastMaintanance = changeDateFormat(isExecutedMaintenance, "yyyy-MM-dd");
      changeObject.dateOfNextMaintanance = null;
      changeObject.documentedMaintenanceDate = isDatesDocumented;
    }
  }, [changeObject, isDatesDocumented, isExecutedMaintenance, isNextMaintenance]);
  let selectedEquipList = "";
  let removedFirstIndex = [...selectedEquipmentId];
  selectedEquipList = `_id:${removedFirstIndex.shift()}`;
  if (selectedEquipmentId.length > 0) {
    removedFirstIndex.forEach((x) => {
      selectedEquipList += ` OR _id:${x}`;
    });
  }

  let queryValues = "";
  if (selectedEquipmentId.length > 0) {
    queryValues = selectedEquipmentId.map((data, index) => ({
      id: `${data}`
    }));
  }
  let filterQueryValues = "";
  if (searchFilterCount.length > 0) {
    filterQueryValues = searchFilterCount.map((data, index) => ({
      id: data.id[0]
    }));
  }

  let filterQuery = useRef({});

  useEffect(() => {
    let filterSplit = bulkImportFilters?.searchValue?.split(" ");
    let columnFilterGroups = [];
    filterSplit.forEach((value) => {
      if (value?.includes(belongingToGroupKey)) {
        let groupStr = value?.split(":")[1];
        groupStr = groupStr?.replaceAll(")", "");
        groupStr = groupStr?.replaceAll(`"`, "");
        columnFilterGroups.push(groupStr);
      }
    });
    let groupName = "";
    let userGroupStrs = [];
    let groupCloumnFilterStrs = [];
    if (actionStatus?.hasRole === "User" && groups.length > 0) {
      groups.forEach((userGroup) => {
        if (bulkImportFilters?.searchValue?.includes(belongingToGroupKey) && columnFilterGroups?.length > 0) {
          columnFilterGroups?.forEach((groupStr) => {
            if (groupStr?.startsWith(userGroup?.groupName)) {
              let checkDuplicateObj = `${belongingToGroupKey}:${groupStr}`;
              const addGroups = find(groupCloumnFilterStrs, (strValue) => {
                if (strValue === checkDuplicateObj) {
                  return true;
                }
              });
              if (!addGroups) groupCloumnFilterStrs.push(`${belongingToGroupKey}:${groupStr}`);
            }
          });
        } else {
          userGroupStrs.push(`(${belongingToGroupKey} : ${userGroup.groupName}*)`);
        }
      });
      groupName = userGroupStrs?.join(SPLITTER.OR);
    }
    if (queryChoice === NORMAL_BULK_EDIT) {
      filterQuery.current = {
        query: "(" + selectedEquipList + ")" || "*",
        start: 0
      };
    } else if (queryChoice === SEARCH_FILTER_QUERY) {
      let filterSearchQuery = { ...bulkImportFilters };
      filterSearchQuery.start = 0;
      filterSearchQuery.filterQuery =
        filterSearchQuery?.filterQuery &&
        filterSearchQuery?.filterQuery?.includes(`status:'${equipmentStatus?.pending?.value}'`)
          ? filterSearchQuery?.filterQuery?.replace(`status:'${equipmentStatus?.pending?.value}'`, "")
          : filterSearchQuery?.filterQuery;
      if (actionStatus.hasRole === "User" && groupCloumnFilterStrs?.length > 0) {
        let replaceStr = "";
        filterSearchQuery.searchValue?.split(")").forEach((splitValues) => {
          if (splitValues?.includes(belongingToGroupKey)) {
            replaceStr = `${splitValues})`;
          }
        });
        filterSearchQuery.searchValue = filterSearchQuery.searchValue?.replace(
          replaceStr,
          `(${groupCloumnFilterStrs?.join(SPLITTER.OR)})`
        );
      }

      let splitSearchValues = filterSearchQuery.searchValue ? filterSearchQuery.searchValue?.split("AND") : [];
      let removeIndex = -1;
      let isActiveAvailable = true;
      if (splitSearchValues?.length > 0) {
        splitSearchValues.forEach((value, index) => {
          if (value?.includes(`status:`)) {
            isActiveAvailable = value?.includes(`Active`) || value?.includes(`*`);
            removeIndex = index;
          }
        });
      }
      if (removeIndex !== -1 && isActiveAvailable) {
        splitSearchValues?.splice(removeIndex, 1);
        splitSearchValues?.push(`(status:'${equipmentStatus?.active?.value}')`);
      } else if (!filterSearchQuery?.filterQuery && removeIndex === -1) {
        splitSearchValues?.push(`(status:'${equipmentStatus?.active?.value}')`);
      }

      if (splitSearchValues?.length > 0) {
        splitSearchValues = splitSearchValues?.map((value) => value?.trim());
      }

      let queryDefault = splitSearchValues?.join(SPLITTER.AND);

      const strAddedWithDefaultQuery = groupName
        ? `AND (entry_type:${entryType?.standaloneEquipment}) AND (${groupName})`
        : `AND (entry_type:${entryType?.standaloneEquipment})`;

      filterQuery.current = {
        query:
          actionStatus.hasRole === "Admin" || (actionStatus.hasRole === "User" && groupCloumnFilterStrs?.length > 0)
            ? queryDefault
            : queryDefault + strAddedWithDefaultQuery,
        sort: `${filterSearchQuery.sortBy} ${filterSearchQuery.sort}`,
        start: 0
      };

      if (filterSearchQuery.queryOptions) {
        filterQuery.current = {
          ...filterQuery.current,
          filterQuery: filterSearchQuery.filterQuery,
          queryOptions: filterSearchQuery.queryOptions
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkImportFilters, queryChoice, selectedEquipList]);

  const updateReasons = async () => {
    setIsUpdateDisabled(() => false);
    const reviewID = v4();
    let reviewCreateResult = null;
    try {
      reviewCreateResult = await client.mutate({
        mutation: CREATE_REVIEW_REQUIRED_TRACKING,
        fetchPolicy: "no-cache",
        variables: {
          id: reviewID,
          query: queryChoice === SEARCH_FILTER_QUERY ? JSON.stringify(filterQueryValues) : JSON.stringify(queryValues),
          updateRecord: JSON.stringify(changeObject),
          createdById: userId
        }
      });
    } catch (error) {
      setIsUpdateDisabled(() => true);
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: `Please try after sometime!`
      });
    }

    if (reviewCreateResult && reviewCreateResult?.data?.createReviewRequiredTracking?.id) {
      await client.query({
        query: VALIDATE_REVIEW_REQUIRED,
        fetchPolicy: "no-cache",
        variables: {
          id: reviewID,
          query: queryChoice === SEARCH_FILTER_QUERY ? JSON.stringify(filterQueryValues) : JSON.stringify(queryValues),
          updateRecord: JSON.stringify(changeObject)
        }
      });
    }
  };

  const onSave = async () => {
    try {
      if (actionStatus.hasRole === "User") {
        filterQuery.current = {
          ...filterQuery.current,
          query: filterQuery.current.query.replaceAll(belongingToGroupKey, GROUP_LITERAL)
        };
      }
      const inputObj = {
        editReason: selectedReason,
        emailId: email,
        filter: JSON.stringify(filterQuery.current),
        change: changeObject,
        editComment: addComment,
        createdById: userId,
        status: changeLinkedInstanceStatusEnum?.SUBMITTED
      };
      const result = await client.mutate({
        mutation: CREATE_BULK_EDIT_EQUIPMENT_LIST,
        fetchPolicy: "no-cache",
        variables: {
          ...inputObj
        }
      });
      if (result?.data?.createIRBulkEdit) {
        setSaveReason(true);
        setProgressStatus(changeLinkedInstanceStatusEnum?.SUBMITTED);
        setAddComment("");
        setActiveModule(SHOW_INSTRUMENT);
        setSelectedEquipmentId([]);
      }
    } catch (error) {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: `Failed to save details!`
      });
      setAddComment("");
    }
  };
  const onReasonSave = async () => {
    try {
      if (actionStatus.hasRole === "User") {
        filterQuery.current = {
          ...filterQuery.current,
          query: filterQuery.current.query.replaceAll(belongingToGroupKey, GROUP_LITERAL)
        };
      }
      const inputObj = {
        editReason: selectedReason,
        emailId: email,
        filter: JSON.stringify(filterQuery.current),
        change: changeObject,
        editComment: addComment,
        createdById: userId,
        status: changeLinkedInstanceStatusEnum?.SUBMITTED,
        reviewRequiredInventoryIds: reviewInventoryId,
        reviewers: formik.values?.reviewers.map((obj) => obj.userId)
      };
      const result = await client.mutate({
        mutation: CREATE_BULK_EDIT_EQUIPMENT_LIST,
        fetchPolicy: "no-cache",
        variables: {
          ...inputObj
        }
      });
      if (result?.data?.createIRBulkEdit) {
        setSaveReason(true);
        setProgressStatus(changeLinkedInstanceStatusEnum?.SUBMITTED);
        setAddComment("");
        setActiveModule(SHOW_INSTRUMENT);
        setSelectedEquipmentId([]);
      }
    } catch (error) {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: `Failed to save details!`
      });
      setAddComment("");
    }
  };
  return (
    <>
      <OwcWorkflowOverlay
        className="owcoverayZIndex"
        visible={activeModule === BULK_EDIT_ACTIVE}
        onVisibleChange={visibleChangeHandler}
        disableBackdropClick
      >
        <div slot="title">{"Bulk edit"}</div>
        <div
          slot="content"
          style={{
            position: "relative",
            height: "50%"
          }}
        >
          {!isUpdateDisabled && (
            <FullScreenCentered>
              <CircularProgress data-testid="loading" size={80} />
            </FullScreenCentered>
          )}
          <BulkContentBlock>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginBottom: "10px",
                marginRight: "5px"
              }}
            >
              <OwcTypography variant="body2" style={{ marginRight: "10px" }}>
                Components selected
              </OwcTypography>

              <OwcBadge type="active">
                {queryChoice === NORMAL_BULK_EDIT ? selectedEquipmentId.length : searchFilterCount.length}
              </OwcBadge>
            </div>

            <BulkContentContainer>
              <div style={{ padding: "10px" }}>GxP & Maintenance Information</div>
              <div style={{ paddingLeft: "15px" }}>
                <div style={{ paddingTop: "25px", paddingBottom: "15px" }}>
                  <OwcDatepicker
                    autoClose
                    hasClearIcon={isExecutedMaintenance !== null}
                    format="dd-MMM-yyyy"
                    style={{ width: "308px" }}
                    label={BULK_EDIT_LABELS.DATE_OF_EXECUTED_MAINTENANCE}
                    placeholder={"DD-MM-YYYY"}
                    value={isExecutedMaintenance}
                    onValueChange={(ev) => {
                      let selected = ev.detail;
                      if (selected !== null) {
                        setExecutedMaintenance(new Date(ev.detail));
                      } else {
                        setExecutedMaintenance(selected);
                      }
                    }}
                    maxDate={new Date().getTime()}
                    invalidDateMessage={BULK_EDIT_LABELS.INVALID_DATE_FORMAT_ERROR_MESSAGE}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <OwcDatepicker
                    autoClose
                    hasClearIcon={isNextMaintenance !== null}
                    format="dd-MMM-yyyy"
                    label={BULK_EDIT_LABELS.NEXT_MAINTENANCE_OR_CALIBRATION}
                    placeholder={"DD-MM-YYYY"}
                    style={{ width: "308px" }}
                    value={isNextMaintenance}
                    onValueChange={(ev) => {
                      let selected = ev.detail;
                      if (selected !== null) {
                        setNextMaintenance(new Date(ev.detail));
                      } else {
                        setNextMaintenance(selected);
                      }
                    }}
                    minDate={new Date().getTime()}
                    disabled={isDatesDocumented !== ""}
                    invalidDateMessage={BULK_EDIT_LABELS.INVALID_DATE_FORMAT_ERROR_MESSAGE}
                  />

                  <OwcInput
                    data-testid={`text-field-group-name`}
                    label={BULK_EDIT_LABELS.MAINTENANCE_DATES_DOCUMENTED}
                    className="TextFieldInput"
                    style={{
                      marginRight: "20px",
                      marginLeft: "15px",
                      width: "615px"
                    }}
                    value={isDatesDocumented}
                    onInputChange={(ev) => setDatesDocumented(ev.detail)}
                    disabled={isNextMaintenance !== null}
                  >
                    <>
                      <span slot="assistive-text">{"SOP RT0020 mandatory (CLP only)"}</span>
                    </>
                    {isDatesDocumented !== "" && (
                      <OwcIcon
                        id={generateID.UUID("datesDocumented", `datesDocumentedTextField`, "icon_clear_circle")}
                        name="circle_clear_filled"
                        slot="suffix"
                        type="legacy"
                        onClick={() => setDatesDocumented("")}
                      />
                    )}
                  </OwcInput>
                </div>
              </div>
            </BulkContentContainer>
          </BulkContentBlock>
          {startSubscription && (
            <>
              <GenericSubscription
                query={LIST_BULK_EDIT_EQUIP_LIST}
                subscription={ON_UPDATE_CHANGE_BULK_EDIT_EQUIP_LIST}
                onUpdateCallback={onUpdateCallback}
                variables={{ limit: 1 }}
              />
              <GenericSubscription
                query={LIST_REVIEW_REQUIRED_TRACKING}
                subscription={ON_UPDATE_REVIEW_REQUIRED_TRACKING}
                onUpdateCallback={onUpdateReviewRequiredCallback}
                variables={{ limit: 1 }}
              />
            </>
          )}
        </div>
        <div slot="actions" style={{ padding: "25px" }}>
          <div style={{ marginRight: "25px" }}>
            <OwcButton
              data-testid="confirm-dialog-actions-button-approve"
              onClick={() => {
                setCancelBulkEdit(true);
              }}
              variant={"secondary"}
            >
              Cancel
            </OwcButton>
          </div>
          <div>
            <OwcButton
              disabled={
                !isUpdateDisabled ||
                (isExecutedMaintenance === null && isNextMaintenance === null && isDatesDocumented === "")
              }
              data-testid="confirm-dialog-actions-button-approve"
              onClick={() => {
                updateReasons();
                setStartSubscription(true);
              }}
              variant={"primary"}
            >
              Update
            </OwcButton>
          </div>
        </div>
      </OwcWorkflowOverlay>
      <ConfirmDialog
        {...dialogProps}
        open={cancelBulkEdit}
        approveText="Yes"
        approveColor="primary"
        approveVariant="contained"
        cancelText="No"
        cancelVariant="outlined"
        cancelColor="primary"
        onCancel={() => {
          setCancelBulkEdit(false);
        }}
        onApprove={() => {
          setEnableCheckbox(true);
          setActiveModule(SHOW_INSTRUMENT);
        }}
        title="Cancel Bulk edit"
        content="Do you want to cancel it?"
        disableBackdropClick={true}
      />
      {isReviewerUpdate ? (
        <ConfirmDialog
          {...dialogProps}
          userList={userList}
          approveText="Save"
          approveColor="primary"
          approveVariant="contained"
          cancelText="Cancel"
          cancelVariant="outlined"
          cancelColor="primary"
          approveDisable={
            selectedReason === ""
              ? true
              : selectedReason && selectedReason?.trim() === OTHER_REASON
              ? addComment === ""
              : false
          }
          onCancel={() => {
            setAddComment("");
          }}
          onApprove={() => {
            onReasonSave();
            setEnableCheckbox(false);
          }}
          title={<OwcTypography variant="subtitle2">Reason for bulk edit</OwcTypography>}
          content={
            <>
              <ReasonDropdown
                selectedReason={selectedReason}
                totalCount={selectedEquipmentId.length}
                queryChoice={queryChoice}
                searchFilterCount={searchFilterCount.length}
                reviewInventoryId={reviewInventoryId}
                isReviewerUpdate={isReviewerUpdate}
                userList={userList}
                setSelectedReason={setSelectedReason}
                addComment={addComment}
                setAddComment={setAddComment}
                designBulkEditStyle={true}
              />
            </>
          }
          disableBackdropClick={true}
        />
      ) : (
        <ConfirmDialog
          {...dialogProps}
          approveText="Save"
          approveColor="primary"
          approveVariant="contained"
          cancelText="Cancel"
          cancelVariant="outlined"
          cancelColor="primary"
          approveDisable={
            selectedReason === ""
              ? true
              : selectedReason && selectedReason?.trim() === OTHER_REASON
              ? addComment === ""
              : false
          }
          onCancel={() => {
            setAddComment("");
          }}
          onApprove={() => {
            onSave();
            setEnableCheckbox(false);
          }}
          title={<OwcTypography variant="subtitle2">Reason for bulk edit</OwcTypography>}
          content={
            <>
              <ReasonDropdown
                selectedReason={selectedReason}
                setSelectedReason={setSelectedReason}
                addComment={addComment}
                setAddComment={setAddComment}
                designBulkEditStyle={true}
              />
            </>
          }
          disableBackdropClick={true}
        />
      )}
      <ConfirmDialog
        {...dialogProps}
        open={saveReason}
        approveText="OK"
        approveColor="primary"
        approveVariant="contained"
        approveDisable={
          progressStatus === changeLinkedInstanceStatusEnum?.INPROGRESS ||
          progressStatus === changeLinkedInstanceStatusEnum?.SUBMITTED
            ? true
            : false
        }
        onApprove={() => {
          setSaveReason(false);
          setAddComment("");
          setProgress(0);
          setProgressStatus(0);
          setFailureCount(0);
          setProgressCount(0);
          setTotalCount(0);
          setSelectedEquipmentId([]);
          if (reviewInventoryId?.length > 0) {
            Notify({
              type: "success",
              icon: "circle_confirm",
              appName: "",
              text: `Changes have been edited, and a notification has been sent to the reviewers successfully.`
            });
          }
        }}
        title={<OwcTypography variant="subtitle2">Bulk update</OwcTypography>}
        content={
          progressStatus === changeLinkedInstanceStatusEnum?.SUBMITTED ? (
            <>
              <OwcTypography style={{ textAlign: "center" }}>
                Updated {progressCount} of {totalCount} components...
              </OwcTypography>
              <Box sx={{ width: "100%" }} py={[4, 0, 5]}>
                <LinearProgress variant="determinate" value={progress} />
              </Box>
            </>
          ) : progressStatus === changeLinkedInstanceStatusEnum?.INPROGRESS ? (
            <>
              <OwcTypography style={{ textAlign: "center" }}>
                Updated {progressCount} of {totalCount} components...
              </OwcTypography>
              <Box sx={{ width: "100%" }} py={[4, 0, 5]}>
                <LinearProgress variant="determinate" value={progress} />
              </Box>
            </>
          ) : (
            progressStatus === changeLinkedInstanceStatusEnum?.COMPLETED &&
            (failureCount > 0 ? (
              <>
                <OwcTypography style={{ textAlign: "center" }}>
                  Updated {progressCount - failureCount === 0 ? failureCount : progressCount - failureCount} of{" "}
                  {totalCount} components...
                </OwcTypography>
                <OwcTypography style={{ textAlign: "center" }}>
                  Failed components have been uploaded to your inbox.
                </OwcTypography>
                <OwcTypography style={{ textAlign: "center" }}>
                  Please review it and take appropriate action.
                </OwcTypography>
                <Box
                  sx={{
                    width: "100%",
                    color: "var(--one-color-cobas-red-400)"
                  }}
                  py={[4, 0, 5]}
                >
                  <LinearProgress variant="determinate" color="inherit" />
                </Box>
              </>
            ) : (
              <>
                <OwcTypography style={{ textAlign: "center" }}>
                  Updated {progressCount} of {totalCount} components...
                </OwcTypography>
                <Box sx={{ width: "100%" }} py={[4, 0, 5]}>
                  <LinearProgress variant="determinate" />
                </Box>
              </>
            ))
          )
        }
      />
    </>
  );
};
const mapStateToProps = (store) => ({
  id: store?.user?.id,
  email: store?.user?.email,
  userId: store?.user?.user
});

export default compose(connect(mapStateToProps, {}), withApollo)(BulkEditContainer);
