import { useFormikContext } from "formik";
import React from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { OwcButton } from "@one/react";
import { OTHER } from "../../constants";

const FormActions = ({
  stepList,
  activeStep,
  isNextDisabled,
  isSubmitDisabled,
  cancelHandle,
  prevButtonHandle,
  nextButtonHandle,
  submitHandle
}) => {
  const formik = useFormikContext();
  /**
   * This method is used to track the master-data creation through matomo statistic
   */
  const { trackEvent } = useMatomo();

  const handlerOnCreateMasterData = () => {
    // Track click on button
    trackEvent({ category: "Create master-data", action: "click-event" });
    submitHandle(formik.values);
  };

  return (
    <>
      <div style={{ display: "flex", flexGrow: "1" }}>
        {activeStep.id !== 0 && (
          <OwcButton
            data-testid="master-data-previous-step-button"
            variant="secondary"
            onClick={() => prevButtonHandle()}
            disabled={activeStep.id === 0 || Object.keys(formik.errors).length > 0}
          >
            Previous
          </OwcButton>
        )}
      </div>
      <div style={{ display: "flex" }}>
        <OwcButton
          data-testid="master-data-previous-step-button"
          onClick={() => cancelHandle()}
          variant="secondary"
          style={{ textTransform: "none", marginRight: "20px" }}
        >
          Cancel
        </OwcButton>
        {activeStep.id === stepList.length - 1 && (
          <OwcButton
            variant="primary"
            onClick={handlerOnCreateMasterData}
            style={{
              textTransform: "none"
            }}
            type="submit"
            data-testid="master-data-save-step-button"
            disabled={
              formik?.values?.noiseSource === OTHER
                ? formik?.values?.noiseSourceDetails === ""
                : false ||
                  activeStep.id !== stepList.length - 1 ||
                  isSubmitDisabled ||
                  Object.keys(formik.errors).length > 0
            }
          >
            Finalize
          </OwcButton>
        )}
        {activeStep.id >= 0 && activeStep.id < stepList.length - 1 && (
          <OwcButton
            variant="primary"
            style={{ textTransform: "none" }}
            type="submit"
            data-testid="master-data-next-step-button"
            disabled={activeStep.id === stepList.length - 1 || isNextDisabled}
            onClick={() => {
              if (formik.isValid && Object.keys(formik.errors).length === 0) {
                nextButtonHandle(formik.values);
              }
            }}
          >
            Next
          </OwcButton>
        )}
      </div>
    </>
  );
};

export default FormActions;
