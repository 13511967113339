import React, { useState, useEffect } from "react";
import { ConfirmDialog } from "../../components/shared";
import useDialog from "../../utils/hooks/useDialog";
import { withApollo } from "react-apollo";
import { GET_INSTRUMENT_FROM_DATA_RIVER } from "../../gql/landingapi/queries";
import Notify from "../notifications/Notify";

const FetchInstrumentFromDataRiver = ({
  setIsUpdateBadge = false,
  client,
  materialNumber,
  serialNumber,
  onFetched
}) => {
  const { openDialog, ...dialogProps } = useDialog();
  useEffect(() => {
    openDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [fetching, setFetching] = useState();
  const fetchDataCallback = async () => {
    try {
      setFetching(true);
      const result = await client.query({
        query: GET_INSTRUMENT_FROM_DATA_RIVER,
        fetchPolicy: "no-cache",
        variables: {
          materialNumber,
          serialNumber
        }
      });
      const data = result?.data?.getInstrumentFromDataRiver;
      setIsUpdateBadge(true);
      if (!data) throw new Error("No equipment found");
      onFetched(data);
    } catch (err) {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: `Failed to fetch SAP data.`
      });
      setIsUpdateBadge(false);
    } finally {
      setFetching(false);
    }
  };
  const handleButton = () => {
    fetchDataCallback();
  };
  return (
    <>
      <ConfirmDialog
        {...dialogProps}
        fetching={fetching}
        approveText="Confirm"
        approveColor="primary"
        approveVariant="contained"
        cancelText="Cancel"
        cancelVariant="outlined"
        cancelColor="primary"
        onApprove={() => handleButton()}
        title="Confirmation required"
        content="Do you want to fetch data from linked instance?"
      />
    </>
  );
};

export default withApollo(FetchInstrumentFromDataRiver);
