import React, { useEffect } from "react";
import styled from "styled-components";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import LinkedInstanceContainer from "./LinkedInstanceContainer";
import LinkedInstanceWrapper from "./LinkedInstanceWrapper";

const MainContainer = styled.div`
  padding: 0 20px 0 20px;
  margin-top: 0.25rem;
  width: 100%;
  box-sizing: border-box;
`;

const LinkedInstanceMainPage = () => {
  // Track page view
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView({
      documentTitle: "linkedinstance",
      href: `${window.location?.origin}/linkedinstance`
    });
    // eslint-disable-next-line
  }, []);

  return (
    <MainContainer>
      <LinkedInstanceWrapper>
        <LinkedInstanceContainer />
      </LinkedInstanceWrapper>
    </MainContainer>
  );
};

export default LinkedInstanceMainPage;
