import styled from "styled-components";

export const CreateFieldStyled = styled.div`
  display: flex;
  justify-content: left;
  padding-top: 8px;
  padding-bottom: 32px;
  & > .selectBox {
    margin: 0 20px 20px 0px;
    width: 100%;
    max-width: 100%;
  }
`;

export const TopBarGroupStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: centre;
  height: 40px;
  margin-bottom: 30px;
  margin-top: 15px;
`;
