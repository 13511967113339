import React, { useContext } from "react";
import AppBar from "./AppBarNotAuth";
import WelcomeScreen from "../components/WelcomeScreen";
import NoConnectionScreen from "../components/NoConnectionScreen";
import { useOnlineEndpointStatus } from "../utils/hooks";
import { BackendInfoContext } from "../features/backend-info-context/context";
import MainPageMaintenance from "./MainPageMaintenance";

const MainPageNotAuthenticated = () => {
  const status = useOnlineEndpointStatus(process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT, 5000);
  const { maintenance } = useContext(BackendInfoContext);
  if (maintenance) return <MainPageMaintenance />;
  return (
    <>
      <AppBar disabled={status ? false : true} />
      {status ? (
        <WelcomeScreen nameOfApplication={"Equipment repository"} />
      ) : (
        <NoConnectionScreen
          text={"We are having difficulty connecting to the server. Check your connection or try again later."}
          title={"No connection"}
          onCallback={() => {
            window.location.reload();
          }}
        />
      )}
    </>
  );
};
export default MainPageNotAuthenticated;
