import React, { useContext } from "react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import styled from "styled-components";
import { PaginationContext } from "../../../components/shared/pagination/PaginationContext";
import { FormControl, IconButton, MenuItem, Select } from "@mui/material";
import { CLUSTER_SUB_EQUIPMENT_MAX_SELECTED_LIMIT } from "../../../constants";
import { OwcButton } from "@one/react";
import { OwcTypography } from "@one/react/dist";

export const getCurrentDatas = (limit, pageVal, totalDatas) => {
  const startPoint = limit * pageVal;
  const endPoint = limit + limit * pageVal;
  return totalDatas.slice(startPoint, endPoint);
};

export const ButtonNext = ({
  handlerQueryDatas,
  pageIndex,
  setPageIndex,
  totalDatas,
  setTotalDatas,
  filteredDatas,
  setFilteredDatas
}) => {
  const { dispatchAction, nextToken, fetching, limit } = useContext(PaginationContext);
  const getData = async () => {
    const pageval = pageIndex + 1;
    setPageIndex(pageval);
    const currentItems = getCurrentDatas(limit, pageval, totalDatas);
    if (currentItems.length < limit && nextToken) {
      dispatchAction({
        type: "fetching",
        payload: true
      });
      const result = await handlerQueryDatas();
      dispatchAction({
        type: "nextData"
      });
      dispatchAction({
        type: "nextToken",
        payload: { token: result?.nextToken, data: result?.items }
      });
      setFilteredDatas(getCurrentDatas(limit, pageval, [...totalDatas, ...result?.items]));
      setTotalDatas([...totalDatas, ...result?.items]);
      dispatchAction({
        type: "fetching",
        payload: false
      });
    } else {
      setFilteredDatas([...currentItems]);
    }
  };
  return (
    <IconButton
      aria-label="Next page"
      data-testid="button-next-pagination"
      size="small"
      title="Next page"
      disabled={filteredDatas?.length < limit || fetching}
      onClick={() => {
        getData();
      }}
    >
      <NavigateNextIcon />
    </IconButton>
  );
};

export const ButtonPrev = ({ pageIndex, setPageIndex, totalDatas, setFilteredDatas }) => {
  const { fetching, limit } = useContext(PaginationContext);
  const disabled = pageIndex === 0;
  const getData = async () => {
    const pageval = pageIndex - 1;
    setPageIndex(pageval);
    const CurrentItems = getCurrentDatas(limit, pageval, totalDatas);
    setFilteredDatas([...CurrentItems]);
  };
  return (
    <IconButton
      aria-label="Prev page"
      data-testid="button-prev-pagination"
      size="small"
      title="Prev page"
      disabled={disabled || fetching}
      onClick={() => {
        getData();
      }}
    >
      <NavigateBeforeIcon />
    </IconButton>
  );
};

export const FirstPage = ({ pageIndex }) => {
  const { dispatchAction, fetching } = useContext(PaginationContext);
  const disabled = pageIndex === 0;
  return (
    <IconButton
      aria-label="first page"
      data-testid="run-log-first-page"
      size="small"
      title="First page"
      disabled={disabled || fetching}
      onClick={() => {
        dispatchAction({
          type: "refresh"
        });
      }}
    >
      <FirstPageIcon />
    </IconButton>
  );
};
const PageRowNuberSelectorStyled = styled.div`
  display: flex;
  align-items: center;
  & > div {
    margin-left: 8px;
  }
`;

export const PageRowNuberSelector = (options, addClusterEquip) => {
  const { limit, fetching, dispatchAction } = useContext(PaginationContext);
  const handleChange = (event) => {
    dispatchAction({ type: "setLimit", payload: event.target.value });
  };
  return (
    <PageRowNuberSelectorStyled>
      <div>Rows per page: </div>
      <FormControl variant="outlined">
        <Select
          margin="dense"
          labelId="paginator-row-number-selector"
          data-testid="paginator-row-number-selector"
          id="paginator-row-number-selector"
          value={limit}
          onChange={handleChange}
          disabled={fetching}
          MenuProps={{
            style: {
              zIndex: 1600
            }
          }}
        >
          <MenuItem data-testid="paginator-row-number-selector-item-v5" value={5}>
            5
          </MenuItem>
          <MenuItem data-testid="paginator-row-number-selector-item-v10" value={10}>
            10
          </MenuItem>
          <MenuItem data-testid="paginator-row-number-selector-item-v20" value={20}>
            20
          </MenuItem>
          <MenuItem data-testid="paginator-row-number-selector-item-v50" value={50}>
            50
          </MenuItem>
        </Select>
      </FormControl>
    </PageRowNuberSelectorStyled>
  );
};

const PaginationActionsStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  & > .pagination {
    display: flex;
    background-color: var(--one-color-cobas-white);
    width: 100%;
    justify-content: flex-end;
    padding: 1rem 0.5rem 0rem 0rem;
    align-items: center;
    & button:first-of-type {
      margin-left: 10px;
    }
  }
`;

export const CountHighlight = styled.div`
  font-size: 12px;
  font-style: normal;
  line-height: 23px;
  letter-spacing: 0px;
  background-color: ${(props) => props.theme.notification.success.backgroundIconColor};
  color: ${(props) => props.theme.notification.success.backgroundColor};
  border-radius: 50%;
  width: 22px;
  display: flex;
  justify-content: center;
  margin-left: 10px;
`;

export const TopBarButtons = styled.div`
  display: flex;
  margin-left: 9rem;
`;
export const EquipAddedStyle = styled.div`
  display: flex;
  align-items: center;
`;

export const PaginationActions = ({
  handlerQueryDatas,
  pageIndex,
  setPageIndex,
  addClusterEquip,
  options,
  totalDatas,
  setTotalDatas,
  filteredDatas,
  setFilteredDatas,
  clusterEquipments
}) => {
  return (
    <PaginationActionsStyled>
      <div className="pagination">
        <PageRowNuberSelector />
        <FirstPage pageIndex={pageIndex} />
        <ButtonPrev
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          totalDatas={totalDatas}
          setFilteredDatas={setFilteredDatas}
        />
        <ButtonNext
          setFilteredDatas={setFilteredDatas}
          handlerQueryDatas={handlerQueryDatas}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          totalDatas={totalDatas}
          setTotalDatas={setTotalDatas}
          filteredDatas={filteredDatas}
        />

        <TopBarButtons>
          {clusterEquipments && (
            <EquipAddedStyle>
              <OwcTypography variant="body2">Components added</OwcTypography>
              <CountHighlight>{clusterEquipments?.length}</CountHighlight>
            </EquipAddedStyle>
          )}
        </TopBarButtons>
        {options?.showAddClusterEquipBtn && (
          <OwcButton
            key="add-cluster-instrument"
            variant="primary"
            onClick={() => {
              addClusterEquip();
            }}
            style={{
              marginLeft: 20
            }}
            disabled={
              options?.selectedCluster === 0 || options?.selectedCluster > CLUSTER_SUB_EQUIPMENT_MAX_SELECTED_LIMIT
            }
          >
            Add to cluster
          </OwcButton>
        )}
      </div>
    </PaginationActionsStyled>
  );
};

export default PaginationActions;
