import React, { useEffect } from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { ChecklistContainer } from "./ChecklistContainer";
import { themeMapping } from "@one/web-components";
import { THEME_NAME } from "../../constants";

const THEME = themeMapping[THEME_NAME];

const LinkedInstanceMainPage = () => {
  // Track page view
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView({
      documentTitle: "checklist",
      href: `${window.location?.origin}/checklist`
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div
      style={{
        padding: `0 ${THEME["one-spacer-20"]} 0 ${THEME["one-spacer-20"]}`,
        marginTop: "0.25rem",
        width: THEME["one-sizer-base-100-p"],
        boxSizing: "border-box"
      }}
    >
      <ChecklistContainer />
    </div>
  );
};

export default LinkedInstanceMainPage;
