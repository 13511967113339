import React from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";
import { GroupContext } from "./context";
import { useContext } from "react";
import { OwcButton } from "@one/react";

export const TopBarButtons = styled.div`
  display: flex;
  margin-left: auto;
`;

const TopBarButton = ({ setDialogOpen }) => {
  const formik = useFormikContext();
  const { setIsEditMode } = useContext(GroupContext);
  return (
    <TopBarButtons>
      <OwcButton
        key="create-group-button"
        onClick={() => {
          setIsEditMode(false);
          formik.resetForm();
          formik.setFieldValue("groupName", "", true);
          formik.setFieldValue("userIds", null, true);
          setDialogOpen(true);
        }}
        variant="primary"
      >
        Add group
      </OwcButton>
    </TopBarButtons>
  );
};

export default TopBarButton;
