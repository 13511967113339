import React from "react";
import GpxMaintainanceInformation from "./GpxMaintainanceInformation";
import EquipmentDescription from "./EquipmentDescription";
import LaboratoryInfo from "./LaboratoryInfo";
import AnalyzerConfiguration from "./AnalyzerConfiguration";
import EquipmentIdentification from "./EquipmentIdentification";
import { OwcExpandableGroup, OwcExpandable } from "@one/react";

export const AddStepContent = ({ step, isEditMode, isUpdateBadge, sopHelperText, isLinkedInstance }) => {
  switch (step) {
    case 0:
      return (
        <EquipmentIdentification
          isEditMode={isEditMode}
          isUpdateBadge={isUpdateBadge}
          sopHelperText={sopHelperText}
          isLinkedInstance={isLinkedInstance}
        />
      );
    case 1:
      return <EquipmentDescription isEditMode={isEditMode} isUpdateBadge={isUpdateBadge} />;
    case 2:
      return <LaboratoryInfo isUpdateBadge={isUpdateBadge} sopHelperText={sopHelperText} />;
    case 3:
      return <GpxMaintainanceInformation isUpdateBadge={isUpdateBadge} sopHelperText={sopHelperText} />;
    case 4:
      return <AnalyzerConfiguration isEditMode={isEditMode} />;
    default:
      return "Unknown Step";
  }
};

export const EditStepContent = ({
  expandID,
  isEditMode,
  isUpdateBadge,
  sopHelperText,
  isLinkedInstance,
  expandableGroupElement,
  expandedElementsChangedHandler
}) => {
  const step = expandID;

  const expandableElements = [
    {
      title: "Basic identification",
      content: (
        <EquipmentIdentification
          isEditMode={isEditMode}
          isUpdateBadge={isUpdateBadge}
          sopHelperText={sopHelperText}
          isLinkedInstance={isLinkedInstance}
        />
      )
    },
    {
      title: "Equipment description",
      content: <EquipmentDescription isEditMode={isEditMode} isUpdateBadge={isUpdateBadge} />
    },
    {
      title: "Laboratory and responsibility",
      content: <LaboratoryInfo isUpdateBadge={isUpdateBadge} sopHelperText={sopHelperText} isEditMode={isEditMode} />
    },
    {
      title: "Qualification status and maintenance",
      content: (
        <GpxMaintainanceInformation
          isUpdateBadge={isUpdateBadge}
          sopHelperText={sopHelperText}
          isEditMode={isEditMode}
        />
      )
    },
    {
      title: "Configuration and availability",
      content: <AnalyzerConfiguration isEditMode={isEditMode} />
    }
  ];

  return (
    <OwcExpandableGroup ref={expandableGroupElement} onExpandedElementsChanged={expandedElementsChangedHandler}>
      {expandableElements.map(({ title, content }, index) => (
        <OwcExpandable key={`expandable${index}`} variant="standard" round expanded={step === index}>
          <span slot="title">{title}</span>
          <span slot="content">{content}</span>
        </OwcExpandable>
      ))}
    </OwcExpandableGroup>
  );
};
