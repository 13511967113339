import { OwcTooltip } from "@one/react";
import { find, get, isEmpty } from "lodash";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { GXPemptyInstrumentsOptions } from "../../../constants";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";
import { rearangeDisplayDate, validateSelection } from "../../../utils/helpers/text";
import { DetailsCellStyled } from "./DetailsCellStyled";
import { ReviewDetailsCellsWrapperStyled } from "./DetailsCellsWrapperStyled";
import { DetailsCell, getModifiedChanges, TagLogComponent } from "./DetailsCells";

const getModifiedChangeString = (data, firstKey, secondKey) => {
  return data.map((item) => {
    return { value: `${item[firstKey]},${item[secondKey]}` };
  });
};

export const ReviewDetailsCells = ({
  changedItem,
  item,
  infoMeta,
  displayColumns,
  Wrapper = ReviewDetailsCellsWrapperStyled,
  CellWrapper = DetailsCellStyled
}) => {
  const linkedInstanceList = useSelector((state) => state.instruments?.linkedInstanceList);
  const getChangedValuesArray = (changedItemConfig, changes) => {
    if (
      [
        DATA_MODEL_TABLE.dateOfNextMaintanance.key,
        DATA_MODEL_TABLE.dateOfLastMaintanance.key,
        DATA_MODEL_TABLE.dateOfNextPeriodicReview.key,
        DATA_MODEL_TABLE.createdAt.key,
        DATA_MODEL_TABLE.updatedAt.key
      ].includes(changedItemConfig?.field)
    ) {
      return {
        oldvalue: changes?.oldvalue ? rearangeDisplayDate(changes?.oldvalue) : null,
        newvalue: changes?.newvalue ? rearangeDisplayDate(changes?.newvalue) : null
      };
    }
    if (
      [DATA_MODEL_TABLE.room.key, DATA_MODEL_TABLE.floor.key, DATA_MODEL_TABLE.buildingLocation.key, "module"].includes(
        changedItemConfig?.field
      )
    ) {
      if (changes?.value) {
        return changes?.value || null;
      } else {
        if (
          changes?.oldvalue?.value === changes?.newvalue?.value ||
          (!changes?.oldvalue?.value && !changes?.newvalue?.value)
        ) {
          return null;
        }
        return {
          oldvalue: changes?.oldvalue?.value,
          newvalue: changes?.newvalue?.value
        };
      }
    }
    if (changedItemConfig?.field === "linkedInstance") {
      const oldvalue = find(linkedInstanceList, {
        linkedInstance: changes?.oldvalue
      });
      const newvalue = find(linkedInstanceList, {
        linkedInstance: changes?.newvalue
      });
      return {
        oldvalue: oldvalue?.linkedInstanceDisplay,
        newvalue: newvalue?.linkedInstanceDisplay
      };
    }
    if (changedItemConfig?.field === "sop") {
      return getModifiedChanges(changes, "value");
    }
    if (changedItemConfig?.field === "qualificationDocuments") {
      if (changes?.value) {
        return getModifiedChanges(changes?.value, "name", "documentId");
      }
    }

    if (changedItemConfig?.field === "qualificationStatus") {
      const oldValue = validateSelection(GXPemptyInstrumentsOptions.qualificationStatus, { key: changes?.oldvalue });

      const newvalue = validateSelection(GXPemptyInstrumentsOptions.qualificationStatus, { key: changes?.newvalue });
      return {
        oldvalue: oldValue,
        newvalue: newvalue
      };
    }
    if (changedItemConfig?.field === "installedTests") {
      return getModifiedChanges(changes, "version", "name");
    }

    if (
      [DATA_MODEL_TABLE.inventoryNumber.key, DATA_MODEL_TABLE.maintenanceIntervalInDays.key].includes(
        changedItemConfig?.field
      )
    ) {
      return {
        oldvalue: changes?.oldvalue?.toString(),
        newvalue: changes?.newvalue?.toString()
      };
    }

    return changes;
  };

  return (
    <Wrapper data-testid="audit-trail-details-cells-wrapper" style={{ width: "96%", margin: "auto" }}>
      {displayColumns.map((fieldId, index) => {
        const currentItemConfig = find(infoMeta.fields, {
          field: fieldId
        });
        const changedItemValues = changedItem[fieldId]
          ? getChangedValuesArray(currentItemConfig, changedItem[fieldId])
          : null;
        if (changedItemValues) {
          return fieldId === DATA_MODEL_TABLE.tags.key ? (
            <div
              key={`${index}_${new Date().getTime()}`}
              style={{
                marginRight: "40px",
                marginBottom: "20px",
                width: "16%"
              }}
            >
              <>
                <div data-testid="details-cell-label" id={DATA_MODEL_TABLE.tags.key}>
                  {DATA_MODEL_TABLE.tags.value}
                </div>
                <OwcTooltip anchor={DATA_MODEL_TABLE.tags.key}>{DATA_MODEL_TABLE.tags.value}</OwcTooltip>
              </>
              <div
                style={{
                  fontSize: "14px",
                  lineHeight: "18px",
                  fontWeight: "500",
                  color: "#000000",
                  width: "16%"
                }}
              >
                <TagLogComponent item={changedItem[fieldId]} />
              </div>
            </div>
          ) : (
            <Fragment key={`${index}_${new Date().getTime()}`}>
              {currentItemConfig ? (
                <DetailsCell
                  isAuditTrial={true}
                  style={{
                    width:
                      currentItemConfig.field === DATA_MODEL_TABLE.configurationDetails.key ? "calc( 32% + 40px )" : ""
                  }}
                  isReview={true}
                  Wrapper={CellWrapper}
                  label={currentItemConfig?.label}
                  {...(currentItemConfig?.props ?? {})}
                  cellValue={changedItemValues}
                />
              ) : (
                ""
              )}
            </Fragment>
          );
        } else {
          const getBody = (fieldValue, item) => {
            let body = "-";
            body = get(item, fieldValue) || "-";
            if (Array.isArray(body) && body?.length === 0) {
              return "-";
            }
            switch (fieldValue) {
              case DATA_MODEL_TABLE.qualificationStatus.key:
              case DATA_MODEL_TABLE.controlledEquipmentStatus.key:
                return (
                  find(GXPemptyInstrumentsOptions[fieldValue], {
                    key: item?.[fieldValue]
                  })?.value || "-"
                );
              case DATA_MODEL_TABLE.checkInStartPeriod.key:
              case DATA_MODEL_TABLE.checkInGracePeriod.key:
                return item?.[fieldValue] ? `${item?.[fieldValue]} min` : "-";
              case DATA_MODEL_TABLE.room.key:
              case DATA_MODEL_TABLE.floor.key:
              case DATA_MODEL_TABLE.buildingLocation.key:
              case "module":
                return item?.[fieldValue]?.value;
              case DATA_MODEL_TABLE.dateOfNextMaintanance.key:
              case DATA_MODEL_TABLE.dateOfLastMaintanance.key:
              case DATA_MODEL_TABLE.dateOfNextPeriodicReview.key:
                return item?.[fieldValue] ? rearangeDisplayDate(item?.[fieldValue]) : "-";
              case DATA_MODEL_TABLE?.qualificationDocuments?.key:
                return (
                  !isEmpty(item?.[fieldValue]) &&
                  getModifiedChangeString(item?.[fieldValue]?.value, "name", "documentId")
                );
              case DATA_MODEL_TABLE?.tags?.key:
                return !isEmpty(item?.[fieldValue]) && item?.[fieldValue].join(",");
              case DATA_MODEL_TABLE.installedTests.key:
                return !isEmpty(item?.[fieldValue]) && getModifiedChangeString(item?.[fieldValue], "version", "name");
              case DATA_MODEL_TABLE.inventoryNumber.key:
              case DATA_MODEL_TABLE.maintenanceIntervalInDays.key:
                return item?.[fieldValue]?.toString() || "-";
              default:
                return body;
            }
          };

          let body = "-";
          body = getBody(currentItemConfig?.field, item);

          return currentItemConfig !== undefined ? (
            <DetailsCell
              key={`${index}_${new Date().getTime()}`}
              Wrapper={CellWrapper}
              isReview={true}
              isAuditTrial={true}
              label={currentItemConfig?.label}
              {...(currentItemConfig?.props ?? {})}
              cellValue={body}
            />
          ) : (
            <Fragment key={`${index}_${new Date().getTime()}`} />
          );
        }
      })}
    </Wrapper>
  );
};
