import { OwcListItem, OwcSelectDropdown } from "@one/react";
import React, { useContext, useEffect } from "react";
import { MASTER_DATA_LIST } from "../../constants";
import { MasterDataContext } from "./MasterDataContext";

const MasterDataDropDown = () => {
  const { selectedListItem, setSelectedListItem, setSelectedModule, setIsActionButtonDisabled } =
    useContext(MasterDataContext);

  const handleChange = (event) => {
    const index = event.detail[0];
    const selectedItem = MASTER_DATA_LIST[index];
    setSelectedListItem(selectedItem?.listName);
    if (selectedItem) {
      setIsActionButtonDisabled(false);
    }
  };

  useEffect(() => {
    const selectedItem = MASTER_DATA_LIST.find((item) => item.listName === selectedListItem);
    setSelectedModule(selectedItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedListItem]);
  let importedTypesList = MASTER_DATA_LIST?.filter((x) => x.isDisabled === false);
  const populateMenu = importedTypesList.map((item) => {
    return (
      <OwcListItem key={item?.listName} value={item?.listName}>
        {item?.listName}
      </OwcListItem>
    );
  });

  return (
    <OwcSelectDropdown
      data-testid="master-data-drop-down"
      id="masterDataDropDown"
      name="masterDataDropDown"
      label="Type"
      onSelectChange={handleChange}
      value={[selectedListItem]}
      outlined
      style={{ width: 250, marginRight: 10 }}
    >
      {populateMenu}
    </OwcSelectDropdown>
  );
};

export default MasterDataDropDown;
