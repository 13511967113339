import React, { useCallback } from "react";
import { FormComponents } from "./AddEquipmentStyle";
import { connect } from "react-redux";
import { compose, withApollo } from "react-apollo";
import { updateInstrumentDetail as updateInstrumentDetailAction } from "../redux/actions";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";
import { OwcTypography } from "@one/react";
import { SOP_MANDATORY_CLP } from "../../../constants";
import LinkedInstanceStep from "./LinkedInstanceStep";
import { SerialNumberInput } from "../../../components/shared/SerialNumberInput";
import SiteSelect from "./SiteSelect";
import { MaterialNumberInput } from "../../../components/shared/MaterialNumberInput";
import { InventoryNumberInput } from "../../../components/shared/InventoryNumberInput";
import { EquipmentIdInput } from "../../../components/shared/EquipmentIdInput";
import { RudiInput } from "../../../components/shared/RudiInput";
import { GtinInput } from "../../../components/shared/GtinInput";
import { useFormikContext } from "formik";

const EquipmentIdentification = ({ isEditMode, instrumentDetail, sopHelperText, isLinkedInstance }) => {
  const formik = useFormikContext();
  const isDisabled = useCallback(
    (fieldName) => {
      const secondaryIdValues = instrumentDetail?.linkedInstanceRef?.secondaryIdMapping
        ? instrumentDetail?.linkedInstanceRef?.secondaryIdMapping
        : instrumentDetail?.secondaryIDMapping;
      const areThereAnySemiColon = secondaryIdValues?.includes(";");
      return areThereAnySemiColon
        ? secondaryIdValues?.split("; ").includes(fieldName)
        : secondaryIdValues === fieldName;
    },
    [instrumentDetail?.linkedInstanceRef?.secondaryIdMapping, instrumentDetail?.secondaryIDMapping]
  );

  return (
    <>
      {!isEditMode && (
        <OwcTypography variant="subtitle2" style={{ padding: "0px 20px 20px 0px" }}>
          Basic identification
        </OwcTypography>
      )}
      <FormComponents>
        <LinkedInstanceStep isLinkedInstance={isLinkedInstance} sopHelperText={sopHelperText} isEditMode={isEditMode} />{" "}
        <SiteSelect
          isEditMode={isEditMode}
          instrumentDetail={undefined}
          sites={undefined}
          updateInstrumentDetail={undefined}
        />
        {!isDisabled(DATA_MODEL_TABLE.serialNumber.key) && (
          <div className="owcInputBox" style={{ width: 313.5 }}>
            <SerialNumberInput
              formik={formik}
              assistiveText={sopHelperText && SOP_MANDATORY_CLP}
              required={instrumentDetail?.linkedInstanceRef?.linkedInstance === "None"}
            />
          </div>
        )}
        {!isDisabled(DATA_MODEL_TABLE.materialNumber.key) && <MaterialNumberInput />}
        <InventoryNumberInput assistiveText={SOP_MANDATORY_CLP} />
        {!isDisabled(DATA_MODEL_TABLE.equipmentId.key) && <EquipmentIdInput />}
        <RudiInput />
        <GtinInput />
      </FormComponents>
    </>
  );
};

const mapStateToProps = (state) => ({
  instrumentDetail: state.instruments?.instrumentDetail,
  sites: state.user.sites
});

export default compose(
  connect(mapStateToProps, {
    updateInstrumentDetail: updateInstrumentDetailAction
  }),
  withApollo
)(EquipmentIdentification);
