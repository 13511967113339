import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { OwcButton } from "@one/react";

export const TopBarButtons = styled.div`
  display: flex;
  justify-content: right;
`;

const ExportCsvTopBarButton = ({ setDialogOpen }) => {
  const history = useHistory();
  return (
    <TopBarButtons data-testid="export-csv-top-bar-button">
      <OwcButton
        key="back-button"
        onClick={() => {
          history?.goBack();
        }}
        variant="secondary"
        style={{ margin: "16px 0" }}
      >
        Back
      </OwcButton>
    </TopBarButtons>
  );
};

export default ExportCsvTopBarButton;
