import React, { useCallback, useState, useEffect } from "react";
import { debounce } from "lodash";
import { DEBOUNCE_DELAY } from "../../constants";
import { OwcIcon, OwcIconButton, OwcInput } from "@one/react";
const Search = ({
  setQuery,
  label,
  testid = "search",
  disabled = false,
  clearSearch,
  addOnComponent,
  onClearSearch = null,
  compact,
  placeholder
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (clearSearch === "") {
      setQuery("");
      setSearchQuery("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearSearch]);

  const delayedQuery = useCallback(
    debounce((q) => {
      setQuery(q);
    }, DEBOUNCE_DELAY),
    [setQuery]
  );
  const onChange = (e) => {
    setSearchQuery(e.target.value);
    delayedQuery(e.target.value);
  };
  const onClear = () => {
    setQuery("");
    setSearchQuery("");
    if (typeof onClearSearch === "function") {
      onClearSearch();
    }
  };
  return (
    <OwcInput
      label={label}
      id="searchInstrument"
      data-testid="searchInstrument"
      variant="filled"
      value={searchQuery}
      disabled={disabled}
      onInputChange={onChange}
      compact={compact}
      placeholder={placeholder}
    >
      <OwcIcon slot="prefix" name="search" type="legacy" />
      <div slot="suffix">
        {searchQuery && (
          <OwcIconButton
            data-testid="clear-search-button"
            icon="circle_clear"
            type="filled"
            onClick={() => onClear()}
            disabled={disabled}
            flat
          />
        )}
        <span slot="suffix">{addOnComponent ? addOnComponent : ""}</span>
      </div>
    </OwcInput>
  );
};

export default Search;
