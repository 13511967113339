import { DEFAULT_SITE_NAME, DEFAULT_SITE_TIMEZONE } from "../../../constants";
export const emptyInstrument = {
  siteName: DEFAULT_SITE_NAME,
  siteTimezone: DEFAULT_SITE_TIMEZONE,
  materialNumber: "",
  serialNumber: "",
  belongingToGroup: null,
  buildingLocation: null,
  csv: null,
  electronicRecord: null,
  electronicSignatures: null,
  equipmentId: null,
  gxpRelevant: null,
  qualificationStatus: null,
  equipmentModel: "",
  variant: "",
  linkedInstance: null,
  secondaryID: "",
  location: "",
  manufacturer: "",
  responsiblePerson: null,
  room: null,
  floor: null,
  configurationBaseline: "",
  equipmentCategory: "",
  installedTests: [],
  instrumentRUDI: "",
  isBookable: false,
  isVisualized: false,
  maintenancePlan: "",
  qualificationDocuments: null,
  comment: "",
  responsibleProxy: null,
  softwareVersion: "",
  systemOwner: "",
  sop: [],
  dateOfLastMaintanance: null,
  dateOfNextMaintanance: null,
  dateOfNextPeriodicReview: null,
  maintenanceIntervalInDays: "",
  documentedMaintenanceDate: "",
  instrumentGTIN: null,
  displayImage: "",
  cluster: "",
  equipmentAdministrator: "",
  equipmentNickName: "",
  functionalLocation: "",
  costCenter: "",
  inventoryNumber: null,
  checkInRequired: false,
  checkInStartPeriod: null,
  checkInGracePeriod: null
};
