import { connect } from "react-redux";
import { useFormikContext } from "formik";
import { commonPropsForInputsWithoutValue } from "../helpers";
import { useEffect, useState, useRef } from "react";
import { find } from "lodash";
import { compose, withApollo } from "react-apollo";
import { FormComponents } from "./AddEquipmentStyle";
import { updateBuilding, updateFloor, updateRoomData } from "../LoadInstrumentsInfo";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";
import { OwcInput, OwcTypography, OwcIcon } from "@one/react";
import { SOP_MANDATORY_CLP } from "../../../constants";
import CommonOwcEditableDropDown from "../cluster/steps/CommonOwcEditableDropDown";
import { defaultInputStyle } from "../../../utils/helpers/text";

const LaboratoryInfo = ({ sites, client, sopHelperText, isEditMode }) => {
  const formik = useFormikContext();
  const [buildings, setBuildings] = useState([]);
  const [floors, setFloors] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);

  const currentBuilding = useRef(null);
  const currentFloor = useRef(null);

  useEffect(() => {
    formik.setFieldTouched("systemOwner");
    const loadFieldDatas = async () => {
      const loadInitialBuildingsPromise = loadInitialBuildings(client, formik.values.siteName);
      const loadInitialFloorsPromise = loadInitialFloors(
        client,
        formik.values.siteName,
        formik.values.buildingLocation?.id
      );
      const loadInitialRoomsPromise = loadInitialRooms(client, formik.values.siteName, formik.values.floor?.id);
      await Promise.allSettled([loadInitialBuildingsPromise, loadInitialFloorsPromise, loadInitialRoomsPromise]);
      setSelectedBuilding(() => `${formik.values.buildingLocation?.buildingNumber}`);
      setSelectedFloor(() => `${formik.values.floor?.floorCode}`);
      setSelectedRoom(() => `${formik.values.room?.roomCode}`);
    };
    if (formik.values.siteName) {
      loadFieldDatas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.siteName]);

  const loadInitialBuildings = async (client, siteName) => {
    const site = find(sites, { siteName });
    const { buildings: buildingData } = await updateBuilding(client, site?.siteId);
    setBuildings(buildingData);
  };

  const loadInitialFloors = async (client, siteName, buildindId) => {
    const site = find(sites, { siteName: siteName });
    const { floors: floorData } = await updateFloor(client, site.siteId, buildindId);
    setFloors(floorData);
  };

  const loadInitialRooms = async (client, siteName, floorId) => {
    const site = find(sites, { siteName: siteName });
    const { rooms: roomData } = await updateRoomData(client, site.siteId, floorId);
    setRooms(roomData);
  };

  const onBuildingSelected = (buildingObj) => {
    formik.setValues({
      ...formik.values,
      floor: null,
      room: null,
      buildingLocation: buildingObj
    });
    setSelectedBuilding(() => buildingObj?.buildingNumber);
    if (!buildingObj) {
      setFloors(() => []);
      setRooms(() => []);
      setSelectedFloor(() => null);
      setSelectedRoom(() => null);
    }
  };

  const onFloorSelected = (floorObj) => {
    formik.setValues({
      ...formik.values,
      room: null,
      floor: floorObj
    });
    setSelectedFloor(() => floorObj?.floorCode);
    if (!floorObj) {
      setRooms(() => []);
      setSelectedRoom(() => null);
    }
  };

  const onRoomSelected = (roomObj) => {
    formik.setValues({
      ...formik.values,
      room: roomObj
    });
    setSelectedRoom(roomObj?.roomCode ?? null);
  };

  const onOpenBuilding = async () => {
    currentBuilding.current = selectedBuilding;
  };

  const onCloseBuilding = async () => {
    if (formik.values.siteName) {
      if (currentBuilding?.current !== selectedBuilding) {
        const site = find(sites, { siteName: formik.values.siteName });
        const { floors: floorData } = await updateFloor(client, site.siteId, formik.values.buildingLocation?.id);
        setFloors(floorData);
        setRooms(() => []);
        setSelectedFloor(() => formik.values.floor?.floorCode);
        setSelectedRoom(() => null);
      }
    } else if (!formik.values.siteName) {
      setBuildings(() => []);
      setFloors(() => []);
      setRooms(() => []);
      setSelectedBuilding(() => null);
      setSelectedFloor(() => null);
      setSelectedRoom(() => null);
    }
  };

  const onOpenFloor = async () => {
    currentFloor.current = selectedFloor;
  };

  const onCloseFloor = async () => {
    if (formik.values.siteName) {
      if (currentFloor?.current !== selectedFloor) {
        const site = find(sites, { siteName: formik.values.siteName });
        const { rooms: roomData } = await updateRoomData(client, site.siteId, formik.values.floor?.id);
        setRooms(() => [...roomData]);
        setSelectedRoom(() => formik.values.room?.roomCode);
      }
    } else if (!formik.values.siteName) {
      setBuildings(() => []);
      setFloors(() => []);
      setRooms(() => []);
      setSelectedBuilding(() => null);
      setSelectedFloor(() => null);
      setSelectedRoom(() => null);
    }
  };

  return (
    <>
      {!isEditMode && (
        <OwcTypography variant="subtitle2" style={{ padding: "0px 20px 20px 0px" }}>
          Laboratory and responsibility
        </OwcTypography>
      )}
      <FormComponents>
        <div className="owcInputBox">
          <OwcInput
            data-testid={`select-field-belongingToGroup`}
            label={`${DATA_MODEL_TABLE.belongingToGroup.value} *`}
            style={defaultInputStyle}
            name="belongingToGroup"
            value={formik.values?.belongingToGroup}
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
            {...commonPropsForInputsWithoutValue({
              formik,
              key: "belongingToGroup",
              dataTestIdKey: "belongingToGroup",
              dataTestIdForFormKey: "text-field"
            })}
          >
            {formik.errors["belongingToGroup"] && formik.touched["belongingToGroup"] === true && (
              <>
                <span
                  // id={generateID.UUID(adminTopBannerMessage, `topBannerMessage`, "error_slot")}
                  slot="error-text"
                >
                  {formik.errors["belongingToGroup"]}
                </span>
              </>
            )}
            {formik.values["belongingToGroup"] !== "" && formik.touched["belongingToGroup"] === true && (
              <OwcIcon
                // id={generateID.UUID(
                //   adminTopBannerMessage,
                //   `topBannerMessage`,
                //   "icon_clear_circle"
                // )}
                name="circle_clear_filled"
                slot="suffix"
                type="legacy"
                onClick={() => formik.setFieldValue("belongingToGroup", "", true)}
              />
            )}
          </OwcInput>
        </div>
        <>
          <CommonOwcEditableDropDown
            labelValue={false}
            label={DATA_MODEL_TABLE.buildingLocation.value + ` *`}
            defaultLabel={DATA_MODEL_TABLE.buildingLocation.value + ` *`}
            style={defaultInputStyle}
            list={buildings}
            propValue="buildingNumber"
            selected={selectedBuilding}
            onChange={(selectedValue) => {
              const selVal = buildings?.find((value, index) => index === selectedValue);
              onBuildingSelected(selVal ?? null);
            }}
            onOpen={onOpenBuilding}
            onClose={onCloseBuilding}
            required={true}
            helperText={formik.errors[DATA_MODEL_TABLE.buildingLocation.key]}
          />
        </>
        <>
          <CommonOwcEditableDropDown
            labelValue={false}
            label={DATA_MODEL_TABLE.floor.value + ` *`}
            defaultLabel={DATA_MODEL_TABLE.floor.value + ` *`}
            keylabel={DATA_MODEL_TABLE.floor.key}
            style={defaultInputStyle}
            list={floors}
            propValue="floorCode"
            selected={selectedFloor}
            onChange={(selectedValue) => {
              const selVal = floors?.find((value, index) => index === selectedValue);
              onFloorSelected(selVal ?? null);
            }}
            onOpen={onOpenFloor}
            onClose={onCloseFloor}
            required={true}
            disabled={!selectedBuilding}
            helperText={formik.errors[DATA_MODEL_TABLE.floor.key]}
          />
        </>
        <>
          <CommonOwcEditableDropDown
            labelValue={false}
            label={DATA_MODEL_TABLE.room.value + ` *`}
            keylabel={DATA_MODEL_TABLE.room.key}
            defaultLabel={DATA_MODEL_TABLE.room.value + ` *`}
            style={defaultInputStyle}
            list={rooms}
            propValue="roomCode"
            selected={selectedRoom}
            onChange={(selectedValue) => {
              const selVal = rooms?.find((value, index) => index === selectedValue);

              onRoomSelected(selVal ?? null);
            }}
            // onOpen={onCloseFloor}
            disabled={!selectedFloor}
            required={true}
            helperText={!selectedFloor ? "" : formik.errors[DATA_MODEL_TABLE.room.key]}
          />
        </>
        <div className="owcInputBox">
          <OwcInput
            data-testid={`text-field-responsible-person`}
            label={DATA_MODEL_TABLE.responsiblePerson.value + ` *`}
            style={defaultInputStyle}
            name="responsiblePerson"
            value={formik.values?.responsiblePerson}
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
            {...commonPropsForInputsWithoutValue({
              formik,
              key: "responsiblePerson",
              dataTestIdKey: "responsiblePerson",
              dataTestIdForFormKey: "text-field"
            })}
          >
            {formik.errors["responsiblePerson"] && formik.touched["responsiblePerson"] === true && (
              <>
                <span
                  // id={generateID.UUID(adminTopBannerMessage, `topBannerMessage`, "error_slot")}
                  slot="error-text"
                >
                  {formik.errors["responsiblePerson"]}
                </span>
              </>
            )}
            {formik.values["responsiblePerson"] !== "" && formik.touched["responsiblePerson"] === true && (
              <OwcIcon
                // id={generateID.UUID(
                //   adminTopBannerMessage,
                //   `topBannerMessage`,
                //   "icon_clear_circle"
                // )}
                name="circle_clear_filled"
                slot="suffix"
                type="legacy"
                onClick={() => formik.setFieldValue("responsiblePerson", "", true)}
              />
            )}
          </OwcInput>
        </div>
        <div className="owcInputBox">
          <OwcInput
            data-testid={`text-field-test-equipment-admin`}
            label={DATA_MODEL_TABLE.equipmentAdministrator.value}
            style={defaultInputStyle}
            name="equipmentAdministrator"
            value={formik.values?.equipmentAdministrator}
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            {...commonPropsForInputsWithoutValue({
              formik,
              key: "equipmentAdministrator",
              dataTestIdKey: "equipmentAdministrator",
              dataTestIdForFormKey: "text-field",
              sopHelperText
            })}
          >
            {formik.values[DATA_MODEL_TABLE.equipmentAdministrator.key] !== "" &&
              formik.touched[DATA_MODEL_TABLE.equipmentAdministrator.key] === true && (
                <OwcIcon
                  name="circle_clear_filled"
                  slot="suffix"
                  type="legacy"
                  onClick={() => formik.setFieldValue([DATA_MODEL_TABLE.equipmentAdministrator.key], "", true)}
                />
              )}
            <span slot="assistive-text">{SOP_MANDATORY_CLP}</span>
          </OwcInput>
        </div>
        <div className="owcInputBox">
          <OwcInput
            data-testid={`text-field-system-owner`}
            label={DATA_MODEL_TABLE.systemOwner.value}
            style={defaultInputStyle}
            name="systemOwner"
            value={formik.values?.systemOwner}
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            {...commonPropsForInputsWithoutValue({
              formik,
              key: "systemOwner",
              dataTestIdKey: "systemOwner",
              dataTestIdForFormKey: "text-field",
              sopHelperText
            })}
          >
            {formik.values[DATA_MODEL_TABLE.systemOwner.key] !== "" &&
              formik.touched[DATA_MODEL_TABLE.systemOwner.key] === true && (
                <OwcIcon
                  name="circle_clear_filled"
                  slot="suffix"
                  type="legacy"
                  onClick={() => formik.setFieldValue([DATA_MODEL_TABLE.systemOwner.key], "", true)}
                />
              )}
            <span slot="assistive-text">{SOP_MANDATORY_CLP}</span>
          </OwcInput>
        </div>
        <div className="owcInputBox">
          <OwcInput
            data-testid={`text-field-second-responsible-proxy`}
            label={DATA_MODEL_TABLE.responsibleProxy.value}
            style={defaultInputStyle}
            name="responsibleProxy"
            value={formik.values?.responsibleProxy}
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            {...commonPropsForInputsWithoutValue({
              formik,
              key: "responsibleProxy",
              dataTestIdKey: "responsibleProxy",
              dataTestIdForFormKey: "text-field"
            })}
          >
            {formik.values[DATA_MODEL_TABLE.responsibleProxy.key] !== "" &&
              formik.touched[DATA_MODEL_TABLE.responsibleProxy.key] === true && (
                <OwcIcon
                  name="circle_clear_filled"
                  slot="suffix"
                  type="legacy"
                  onClick={() => formik.setFieldValue([DATA_MODEL_TABLE.responsibleProxy.key], null, true)}
                />
              )}
          </OwcInput>
        </div>
        <div className="owcInputBox">
          <OwcInput
            data-testid={`text-field-second-functional-location`}
            label={DATA_MODEL_TABLE.functionalLocation.value}
            style={defaultInputStyle}
            name="functionalLocation"
            value={formik.values?.functionalLocation}
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            {...commonPropsForInputsWithoutValue({
              formik,
              key: "functionalLocation",
              dataTestIdKey: "functionalLocation",
              dataTestIdForFormKey: "text-field"
            })}
          >
            {formik.values[DATA_MODEL_TABLE.functionalLocation.key] !== "" &&
              formik.touched[DATA_MODEL_TABLE.functionalLocation.key] === true && (
                <OwcIcon
                  name="circle_clear_filled"
                  slot="suffix"
                  type="legacy"
                  onClick={() => formik.setFieldValue([DATA_MODEL_TABLE.functionalLocation.key], "", true)}
                />
              )}
          </OwcInput>
        </div>
        <div className="owcInputBox">
          <OwcInput
            data-testid={`text-field-second-cost-center`}
            label={DATA_MODEL_TABLE.costCenter.value}
            style={defaultInputStyle}
            name="costCenter"
            value={formik.values?.costCenter}
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            {...commonPropsForInputsWithoutValue({
              formik,
              key: "costCenter",
              dataTestIdKey: "costCenter",
              dataTestIdForFormKey: "text-field"
            })}
          >
            {formik.values[DATA_MODEL_TABLE.costCenter.key] !== "" &&
              formik.touched[DATA_MODEL_TABLE.costCenter.key] === true && (
                <OwcIcon
                  name="circle_clear_filled"
                  slot="suffix"
                  type="legacy"
                  onClick={() => formik.setFieldValue([DATA_MODEL_TABLE.costCenter.key], "", true)}
                />
              )}
          </OwcInput>
        </div>
      </FormComponents>
    </>
  );
};

const mapStateToProps = (state) => ({
  sites: state.user.sites
});

export default compose(connect(mapStateToProps, {}), withApollo)(LaboratoryInfo);
