import React from "react";
import styled from "styled-components";
import { OwcTypography } from "@one/react";

const StepSectionConatiner = styled.div`
  display: flex;
  justify-content: space-evenly;
`;
const SectionContainer = styled.div`
  width: 50%;
  padding-left: 15px;
  padding-right: 5%;
`;

export const StepCircle = styled.div`
  font-size: 16px;
  background-color: var(--one-color-background-brand-1);
  color: var(--one-color-cobas-white);
  border-radius: 50%;
  width: 25px;
  display: flex;
  justify-content: center;
`;

const StepHeading = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const StepContentContainer = styled.div``;

const StaticStepContent = ({ stepContents }) => {
  return (
    <>
      <StepSectionConatiner>
        {stepContents?.map((item, index) => {
          return (
            <>
              <SectionContainer>
                <StepContentContainer>
                  <StepHeading>
                    <StepCircle>{index + 1}</StepCircle>{" "}
                    <OwcTypography
                      style={{
                        "padding-left": "10px",
                        color: "var(--one-color-background-brand-1)"
                      }}
                    >
                      Step {index + 1} of {stepContents?.length}
                    </OwcTypography>
                  </StepHeading>
                  <OwcTypography variant="subtitle2" style={{ "margin-bottom": "8px" }}>
                    {item?.stepHeader}
                  </OwcTypography>
                  <OwcTypography variant="body2" style={{ "margin-bottom": "8px" }}>
                    {item?.stepBodyNewLine}
                  </OwcTypography>
                  <OwcTypography variant="body2" style={{ "margin-bottom": "8px" }}>
                    {item?.stepBody}
                  </OwcTypography>
                  {item?.stepFooter && (
                    <OwcTypography variant="body2" style={{ "margin-bottom": "8px" }}>
                      <strong>Note:</strong> {item?.stepFooter}
                    </OwcTypography>
                  )}
                </StepContentContainer>
              </SectionContainer>
            </>
          );
        })}
      </StepSectionConatiner>
    </>
  );
};

export default StaticStepContent;
