export const LOAD_USER_INFO = "[USER_INFO] LOAD_USER_INFO";
export const LOAD_LAST_FILTER = "[USER_INFO] LOAD_LAST_FILTER";
export const LOAD_ACTION_STATUS = "[USER_INFO] LOAD_ACTION_STATUS";
export const UPDATE_USER_FILTERS = "[USER_INFO] UPDATE_USER_FILTERS";
export const UPDATE_USER_DISPLAY_COLUMNS = "[USER_INFO] UPDATE_USER_DISPLAY_COLUMNS";
export const UPDATE_USER_LAST_DISPLAY_COLUMN = "[USER_INFO] UPDATE_USER_LAST_DISPLAY_COLUMN";
export const UPDATE_LAST_FAV_FILTER = "[USER_INFO] UPDATE_LAST_FAV_FILTER";
export const UPDATE_LAST_PAGE_SIZE = "[USER_INFO] UPDATE_LAST_PAGE_SIZE";
export const UPDATE_USER_SYSTEM_DISPLAY_COLUMN = "[USER_INFO] UPDATE_USER_SYSTEM_DISPLAY_COLUMN";
export const UPDATE_SEARCH_PREFERENCE = "[USER_INFO] UPDATE_SEARCH_PREFERENCE";
