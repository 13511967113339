import * as yup from "yup";
import { GXPemptyInstrumentsOptions as enumOptions } from "../../../constants";
import { map } from "lodash";

const getCustomNullable = (schema, changeTo = "") => schema.nullable().transform((value) => value ?? changeTo);

export const InstrumentValidationSchema = yup.object({
  siteName: getCustomNullable(
    yup
      .string("Enter site")
      .required("Site is required")
      .test("Invalid data", "Site is required", function (val) {
        if (!val) return false;
        return true;
      })
  ),
  siteTimezone: getCustomNullable(yup.string("Enter site timezone").required("Site timezone is required")),
  manufacturer: getCustomNullable(yup.string("Enter manufacturer").required("Manufacturer is required")),
  equipmentModel: getCustomNullable(yup.string("Enter type").required("Model is required")),
  serialNumber: getCustomNullable(yup.string("Enter serial number").trim()),
  materialNumber: getCustomNullable(yup.string("Enter material number").trim()),
  instrumentGTIN: getCustomNullable(yup.string("Enter GTIN")),
  equipmentId: getCustomNullable(yup.string("Enter equipment id")),
  instrumentRUDI: getCustomNullable(yup.string("Enter RUDI")),
  equipmentCategory: getCustomNullable(yup.string("Enter category")),
  comment: getCustomNullable(yup.string("Enter comments")),
  belongingToGroup: getCustomNullable(
    yup.string("Enter belonging to group").required("Belonging to group is required")
  ),
  buildingLocation: yup
    .object({
      key: yup.string("Enter building").required("Building is required"),
      value: yup.string("Enter building").required("Building is required")
    })
    .required("Building is required")
    .nullable(),
  responsiblePerson: getCustomNullable(
    yup.string("Enter responsible person").required("Responsible person is required")
  ),
  responsibleProxy: getCustomNullable(yup.string("Enter second responsible person")),
  room: yup
    .object({
      key: yup.string("Enter room is required").required("Room is required"),
      value: yup.string("Enter room is required").required("Room is required")
    })
    .required("Room is required")
    .nullable(),
  floor: yup
    .object({
      key: yup.string("Enter floor is required").required("Floor is required"),
      value: yup.string("Enter floor is required").required("Floor is required")
    })
    .required("Floor is required")
    .nullable(),
  cluster: getCustomNullable(yup.string("Enter cluster")),
  equipmentAdministrator: getCustomNullable(yup.string("Enter equipment administrator")),
  equipmentNickName: getCustomNullable(yup.string("Enter equipment nick name")),
  functionalLocation: getCustomNullable(yup.string("Enter functional location")),
  constCenter: getCustomNullable(yup.string("Enter cost center")),
  systemOwner: getCustomNullable(yup.string("Enter system owner")),
  sop: yup
    .array(
      yup.object({
        key: yup.string("Enter sop is required"),
        value: yup.string("Enter sop is required")
      })
    )
    .nullable(),
  csv: getCustomNullable(
    yup.string("Enter CSV").test("Invalid data", "Please provide valid CSV relevent", function (val) {
      if (!val) return true;
      const values = map(enumOptions.csv, "value");
      return values.includes(val);
    })
  ),
  electronicSignatures: getCustomNullable(
    yup
      .string("Enter electronic signatures")
      .test("Invalid data", "Please provide valid electronic signature", function (val) {
        if (!val) return true;
        const values = map(enumOptions.electronicSignatures, "value");
        return values.includes(val);
      })
  ),
  electronicRecord: getCustomNullable(
    yup
      .string("Enter electronic record")
      .test("Invalid data", "Please provide valid electronic record", function (val) {
        if (!val) return true;
        const values = map(enumOptions.electronicRecord, "value");
        return values.includes(val);
      })
  ),
  gxpRelevant: getCustomNullable(
    yup.string("Enter gxp relevant").test("Invalid data", "Please provide valid GXP relevent", function (val) {
      if (!val) return true;
      const values = map(enumOptions.gxpRelevant, "value");
      return values.includes(val);
    })
  ),
  qualificationStatus: getCustomNullable(
    yup
      .string("Enter qualification status")
      .required("Qualification status is required")
      .test("Invalid data", "Please provide valid qualification status", function (val) {
        if (!val) return false;
        const values = map(enumOptions.qualificationStatus, "value");
        return values.includes(val);
      })
  ),
  dateOfNextPeriodicReview: getCustomNullable(
    yup
      .string("Enter next periodic review")
      .test("Invalid date", "Please provide next periodic review date as MM-dd-yyyy", function (val) {
        if (!val) return true;
        var date = new Date(val);
        return date?.getTime() ? true : false;
      })
  ),
  dateOfNextMaintanance: getCustomNullable(
    yup
      .string("Enter next maintenance")
      .test("Invalid date", "Please provide next maintenance date as MM-dd-yyyy", function (val) {
        if (!val) return true;
        var date = new Date(val);
        return date?.getTime() ? true : false;
      })
  ),
  dateOfLastMaintanance: getCustomNullable(
    yup
      .string("Enter last maintenance")
      .test("Invalid date", "Please provide last maintenance date as MM-dd-yyyy", function (val) {
        if (!val) return true;
        var date = new Date(val);
        return date?.getTime() ? true : false;
      })
  ),
  maintenancePlan: getCustomNullable(yup.string("Enter maintenance plan")),
  softwareVersion: getCustomNullable(yup.string("Enter software version")),
  configurationBaseline: getCustomNullable(yup.string("Enter configuration baseline")),
  isBookable: yup.bool().nullable(),
  isVisualized: yup.bool().nullable()
});
