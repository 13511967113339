const initialState = {
  instruments: [],
  buildingList: [],
  floorList: [],
  roomList: [],
  manufacturerList: [],
  categoryList: [],
  categoryMappingMasterList: [],
  clusterCategoryList: [],
  reasonList: [],
  linkedInstanceList: [],
  groupList: [],
  sopList: [],
  modelList: [],
  clusterModelList: [],
  responsiblePersonList: [],
  equipmentAdministratorList: [],
  buildingDataOnSite: {},
  floorDataOnSite: {},
  roomDataOnSite: {},
  limit: 5,
  nextToken: null,
  pageTokenArray: [],
  filterApplied: false,
  instrumentDetail: {
    materialNumber: "",
    serialNumber: "",
    belongingToGroup: null,
    buildingLocation: null,
    csv: null,
    electronicRecord: null,
    electronicSignatures: null,
    equipmentId: "",
    gxpRelevant: null,
    qualificationStatus: null,
    controlledEquipmentStatus: null,
    equipmentModel: null,
    linkedInstance: null,
    location: "",
    manufacturer: "",
    responsiblePerson: null,
    siteName: "",
    siteTimezone: "",
    floor: null,
    room: null,
    configurationBaseline: "",
    equipmentCategory: null,
    installedTests: [],
    instrumentRUDI: "",
    isBookable: false,
    isVisualized: false,
    maintenancePlan: "",
    qualificationDocuments: null,
    comment: "",
    responsibleProxy: null,
    softwareVersion: "",
    systemOwner: "",
    sop: [],
    dateOfLastMaintanance: null,
    dateOfNextMaintanance: null,
    dateOfNextPeriodicReview: null,
    documentedMaintenanceDate: "",
    maintenanceIntervalInDays: "",
    instrumentGTIN: null,
    displayImage: "",
    cluster: "",
    equipmentAdministrator: "",
    equipmentNickName: "",
    functionalLocation: "",
    costCenter: "",
    inventoryNumber: null,
    modelName: "",
    secondaryIDMapping: "",
    variant: "",
    editReason: "",
    editComment: "",
    reviewers: []
  },
  editInstrumentDetail: {
    materialNumber: "",
    serialNumber: "",
    belongingToGroup: null,
    buildingLocation: null,
    csv: null,
    electronicRecord: null,
    electronicSignatures: null,
    equipmentId: "",
    gxpRelevant: null,
    qualificationStatus: null,
    controlledEquipmentStatus: null,
    equipmentModel: null,
    linkedInstance: null,
    location: "",
    manufacturer: "",
    responsiblePerson: null,
    siteName: "",
    siteTimezone: "",
    floor: null,
    room: null,
    configurationBaseline: "",
    equipmentCategory: null,
    installedTests: [],
    instrumentRUDI: "",
    isBookable: false,
    isVisualized: false,
    maintenancePlan: "",
    qualificationDocuments: {
      value: []
    },
    comment: "",
    responsibleProxy: null,
    softwareVersion: "",
    systemOwner: "",
    sop: [],
    dateOfLastMaintanance: null,
    dateOfNextMaintanance: null,
    dateOfNextPeriodicReview: null,
    documentedMaintenanceDate: "",
    maintenanceIntervalInDays: "",
    instrumentGTIN: null,
    displayImage: "",
    cluster: "",
    equipmentAdministrator: "",
    equipmentNickName: "",
    functionalLocation: "",
    costCenter: "",
    inventoryNumber: null,
    modelName: "",
    secondaryIDMapping: "",
    variant: "",
    editReason: "",
    editComment: "",
    reviewers: []
  },
  filters: {
    site: "",
    group: "",
    manufacturer: "",
    category: "",
    responsiblePerson: "",
    equipmentAdministrator: ""
  },
  search: "",
  loading: false,
  ediMasterDataDetail: {
    equipmentCategory: "",
    manufacturer: "",
    equipmentModel: "",
    variant: "",
    displayImage: "",
    isActive: true,
    weightInkg: null,
    heightInmm: null,
    lengthInmm: null,
    depthInmm: null,
    clearanceLeftInmm: null,
    clearanceRightInmm: null,
    clearanceHeightInmm: null,
    clearanceToFrontInmm: null,
    clearanceBackwardsInmm: null
  }
};

export default initialState;
