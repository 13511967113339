import React from "react";
import { OwcInputCustomized } from "./OwcInputCustomized";

export const GtinInput = ({ style = {}, ...args }) => (
  <div className="owcInputBox" style={{ width: 313.5 + 8, ...style }}>
    <OwcInputCustomized
      dataTestid="text-field-GTIN"
      dataModel="instrumentGTIN"
      valueValidation={undefined}
      onBlurValidation={undefined}
      {...args}
    />
  </div>
);
