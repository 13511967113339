import React, { useEffect } from "react";
import { DEFAULT_IR_PAGE_SIZE } from "../../constants";
import styled from "styled-components";
import PaginationWrapper from "../../components/shared/pagination/PaginationWrapper";
import GroupWapper from "./GroupWapper";
import GroupContent from "./GroupContent";
import { useSelector } from "react-redux";
import CommonWrapper from "../../common-context/CommonWrapper";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const MainContainer = styled.div`
  padding: 20px 20px 0 20px;
  margin-top: 0.25rem;
  width: 100%;
  box-sizing: border-box;
`;

const GroupMainPage = () => {
  let lastPageSize = useSelector((state) => state?.user?.lastPageSize);
  if (typeof lastPageSize === "string" && lastPageSize !== "") {
    lastPageSize = JSON.parse(lastPageSize);
  }
  // Track page view
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView({
      documentTitle: "Group",
      href: `${window.location?.origin}/Group`
    });
    // eslint-disable-next-line
  }, []);

  const initialValues = {
    fetching: false,
    prevTokens: [],
    nextToken: null,
    nextData: 0,
    limit: lastPageSize?.ir_group ? lastPageSize?.ir_group : DEFAULT_IR_PAGE_SIZE.ir_group,
    data: [],
    sortDirection: "ASC",
    orderBy: "groupName",
    refreshData: 0
  };

  return (
    <MainContainer>
      <GroupWapper>
        <PaginationWrapper initialValues={initialValues}>
          <CommonWrapper>
            <GroupContent />
          </CommonWrapper>
        </PaginationWrapper>
      </GroupWapper>
    </MainContainer>
  );
};

export default GroupMainPage;
