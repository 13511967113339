import React, { useEffect } from "react";
import styled from "styled-components";
import Notify from "../notifications/Notify";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { OwcButton } from "@one/react";

const TableauContainer = styled.div`
  margin: 1.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Imago, sans-serif;
  color: var(--one-color-background-brand-1);
  & > h2 {
    font-size: 40px;
    font-weight: 500;
  }
  & > h5 {
    font-size: 18px;
    color: #333;
  }
`;

const TableauEquipmentPage = () => {
  const { tableau } = window;
  // Track page view
  const { trackPageView } = useMatomo();

  useEffect(() => {
    const gqlEndpointUrl = process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT;
    localStorage.setItem("GQLEndpoint", gqlEndpointUrl);
    localStorage.removeItem("redirectToTableau");

    trackPageView({
      documentTitle: "Tableau Equipments",
      href: `${window.location?.origin}/tableau-equipments`
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitTableau = () => {
    tableau.connectionName = "ER Equipment data";
    try {
      tableau.submit();
    } catch (error) {
      console.log(error);
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: "This page can be accessed only from Tableau."
      });
    }
  };

  return (
    <TableauContainer data-testid="tableau-equipment-container">
      <h2>Welcome to Tableau Web Data Connector.</h2>
      <h5>Please click the below button to start to fetch data.</h5>
      <OwcButton data-testid={`fetch-equipment-button`} variant="primary" type="button" onClick={submitTableau}>
        Fetch equipment
      </OwcButton>
    </TableauContainer>
  );
};

export default TableauEquipmentPage;
