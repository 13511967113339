import React, { useContext } from "react";
import { ReasonContext } from "./context";
import { useFormikContext } from "formik";
import { OwcIconButton } from "@one/react";
import Tooltip from "@mui/material/Tooltip";

import { OTHER_REASON } from "../../constants";
const Actions = ({ item }) => {
  const { setDialogOpen, setIsEditMode, setDialogCancelOpen, setCurrentReason } = useContext(ReasonContext);
  const formik = useFormikContext();
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "right" }}>
      <Tooltip data-testid={"equipment-edit-button-tooltip" + item.id} title={"Edit reason"} placement="left" arrow>
        <OwcIconButton
          key={`booking-admin-table-edit-instrument-button-${item.id}`}
          data-testid={`booking-admin-table-edit-instrument-button-${item.id}`}
          onClick={() => {
            setIsEditMode(true);
            setDialogOpen(true);
            setCurrentReason(item.configText);
            formik.resetForm();
            formik.setFieldValue("configText", item.configText, true);
            formik.setFieldValue("reasonId", item.id, true);
          }}
          icon="edit"
          type="legacy"
          flat
          disabled={item?.configText === OTHER_REASON}
        />
        {/*
        data-testid
         <EditIcon
          fontSize="small"
          data-testid="booking-admin-table-edit-instrument-icon"
        /> */}
      </Tooltip>

      <Tooltip data-testid={"equipment-delete-button-tooltip" + item.id} title={"Delete reason"} placement="left" arrow>
        <OwcIconButton
          key={`booking-admin-table-delete-instrument-button-key-${item.id}`}
          data-testid={`booking-admin-table-delete-instrument-button-${item.id}`}
          onClick={() => {
            setDialogCancelOpen(true);
            formik.setFieldValue("reasonId", item.id, true);
          }}
          icon="delete"
          type="legacy"
          flat
          disabled={item?.configText === OTHER_REASON}
        />
        {/* data-testid
          <DeleteIcon
            fontSize="small"
            data-testid="booking-admin-table-delete-instrument-icon"
          /> */}
      </Tooltip>
    </div>
  );
};
export default Actions;
