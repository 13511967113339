import React, { Fragment, useEffect, useState } from "react";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@mui/material";
import Notify from "../notifications/Notify";
import { EQUIPMENT_APPROVE_REVIEW } from "../../gql/landingapi";
import { EQUIPMENT_REVIEW_ACTIONS } from "../../constants";
import styled from "styled-components";

export const FullScreenCenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  width: 100vw;
  height: 100vh;, ...syncFields
`;

export const getSuccessMessage = (action) => {
  switch (action) {
    case "add":
      return "New equipment has been reviewed and approved successfully!";
    case "decommission":
      return "Request to remove equipment has been approved, equipment is deleted.";
    case "edit":
      return "Equipment details are reviewed and approved successfully.";
    default:
      return "SUCCESS!";
  }
}

export const ReAuthenticationHandler = ({ client, children, user }) => {
  const [ loading, setLoading ] = useState(true);

  const handleReAuth = async () => {
    const reAuth = localStorage.getItem("reAuth");
    const notification = {
      type: "warning",
      icon: "caution",
      appName: "Unable to approve equipment at this moment",
      text: "Please try again."
    };
    if (reAuth) {
      try {
        const reAuthData = JSON.parse(reAuth);
        if (reAuthData.user.toLowerCase() === user.id.toLowerCase()) {
          const result = await client.mutate({
            mutation: EQUIPMENT_APPROVE_REVIEW,
            variables: {
              reviewIds: [ reAuthData.reviewId ],
              editReason: reAuthData.reason,
              editComment: reAuthData.comment,
            },
            fetchPolicy: "no-cache"
          });
          if (
            !result?.error &&
            EQUIPMENT_REVIEW_ACTIONS.success.key === result.data.approveChange.status
          ) {
            notification.type = "success";
            notification.icon = "circle_confirm";
            notification.appName = "";
            notification.text = getSuccessMessage(reAuthData.action);
          } else if (EQUIPMENT_REVIEW_ACTIONS.duplicate.key === result.data.approveChange.status) {
            notification.appName = "Duplicate detected"
            notification.text = "Please investigate or reach out to an Administrator";
          }
        } else {
          notification.appName = "You cannot approve equipment";
          notification.text = "You are not selected as the reviewer";
        }
      } catch (e) {
        console.log("ReAuthenticationHandler error: ", e);
      } finally {
        Notify(notification);
      }
    }
    localStorage.removeItem("reAuth");
    setLoading(false);
  };

  useEffect(() => {
    handleReAuth();
    // eslint-disable-next-line
  }, []);

  return loading ?
    ( <FullScreenCenteredContainer>
      <CircularProgress data-testid="loading" size={ 80 } />
    </FullScreenCenteredContainer> ) :
    ( <Fragment>
      { children }
    </Fragment> );
};

export default compose(connect(), withApollo)(ReAuthenticationHandler);
