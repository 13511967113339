import { compose } from "redux";
import { withApollo } from "react-apollo";
import { CircularProgress } from "@mui/material";
import { ScreenCentered } from "../../components/shared/CustomSelect";
import { OwcIcon } from "@one/react";
import { useEffect, useState } from "react";
import { CHECKLIST_AUDIT_TRIAL_ITEM_ACTIONS_DISPLAY_VALUES } from "../../constants";
import { GET_CHECKLIST_TRAIL_LOG } from "../../gql/landingapi";
import { getData } from "../../utils/helpers/fetching";
import { format } from "date-fns";
import { IconWithTexts, ChecklistDetails } from "@digitallab/grid-common-components";

/**
 * Displays audit logs for checklists
 * @param {object} props
 * @param {object} props.data - checklist data
 */

const trimUserForDisplay = (username) => username.replace(/.*roche\.com_/, "");

export const ChecklistTrailLog = compose(withApollo)(({ client, data }) => {
  const [loading, setLoading] = useState(false);
  const [dataWithAuditTrailLog, setDataWithAuditTrailLog] = useState(data);
  const [auditTrailLogError, setAuditTrailLogError] = useState(undefined);

  useEffect(() => {
    setLoading(true);
    setAuditTrailLogError(undefined);
    getData({
      client,
      query: GET_CHECKLIST_TRAIL_LOG,
      variables: { createdAt: data.createdAt },
      dataPath: ["data", "getChecklist"]
    })
      .then((result) => {
        setDataWithAuditTrailLog(result);
        setAuditTrailLogError(false);
        setLoading(false);
      })
      .catch((error) => {
        setAuditTrailLogError(error.message);
        setLoading(false);
      });
  }, [client, data]);

  return (
    <div style={{ width: "100%", minHeight: 320 }}>
      <ChecklistDetails checklistData={dataWithAuditTrailLog} />
      <br />
      {loading || auditTrailLogError === undefined ? (
        <ScreenCentered>
          <CircularProgress data-testid="loading" size={30} />
        </ScreenCentered>
      ) : auditTrailLogError ? (
        <ScreenCentered>
          <IconWithTexts
            icon={<OwcIcon name="alarm" family="outlined"></OwcIcon>}
            mainText="Error"
            additionalText={auditTrailLogError}
          />
        </ScreenCentered>
      ) : (
        dataWithAuditTrailLog.auditTrail?.map((entry) => {
          return (
            <div key={entry.date} style={{ padding: 12 }}>
              <strong> {format(new Date(entry.date), "dd-MMMM-yyyy, HH:mm")}</strong> (
              {CHECKLIST_AUDIT_TRIAL_ITEM_ACTIONS_DISPLAY_VALUES[entry.action]} by {trimUserForDisplay(entry.user)})
            </div>
          );
        })
      )}
    </div>
  );
});
