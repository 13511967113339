import React, { useState } from "react";
import { OwcIconButton, OwcListItem, OwcMenu } from "@one/react";
import ReactDOM from "react-dom";

const MasterDataAction = ({ params, editMasterData, viewAuditMasterData }) => {
  const [anchorEl, setAnchorEl] = useState(false);
  const handleSettingClose = () => {
    setAnchorEl(false);
  };
  const handleSettingClick = (event, param) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <OwcIconButton
        icon="more_vertical"
        type="legacy"
        flat
        onClick={(event) => {
          handleSettingClick(event, params);
        }}
        id={params?.data?.id}
      />
      {ReactDOM.createPortal(
        <OwcMenu
          data-testid="simple-menu"
          anchor={params?.data?.id}
          visible={anchorEl}
          onClickOutside={handleSettingClose}
          disablePortal={true}
        >
          <OwcListItem
            data-testid="simple-menu-filter"
            onClick={() => {
              setAnchorEl(null);
              editMasterData({
                equipmentCategory: params?.data?.equipmentCategory,
                manufacturer: params?.data?.manufacturer,
                equipmentModel: params?.data?.equipmentModel,
                variant: params?.data?.variant
              });
            }}
          >
            Edit
          </OwcListItem>
          <OwcListItem
            data-testid="simple-menu-filter"
            onClick={() => {
              setAnchorEl(null);
              viewAuditMasterData({
                id: params?.data?.id
              });
            }}
          >
            View audit trail
          </OwcListItem>
        </OwcMenu>,
        document.body
      )}
    </>
  );
};

export default MasterDataAction;
