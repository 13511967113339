import ContentContainer from "./ContentContainer";
import SelectItemsWrapper from "../../../components/context/SelectItemsWrapper";
import { useState } from "react";
import { CLUSTER_STEPS } from "../../../constants";
import { connect } from "react-redux";
import { compose, withApollo } from "react-apollo";
import {
  updateClusterDetail as updateClusterDetailAction,
  updateEditClusterDetail as updateEditClusterDetailAction
} from "./redux/actions";

const AddEditClusterContainer = ({
  setActiveModule,
  isEditMode,
  selectedItem,
  setIsEditMode,
  actionStatus,
  hasPermission,
  setHasPermission,
  activeModule
}) => {
  const [activeStep, setActiveStep] = useState(CLUSTER_STEPS[0]);
  const [stepList, setStepList] = useState(CLUSTER_STEPS);

  const handleStepClick = (item) => {
    if (isEditMode) {
      setActiveStep(item);
    }
  };

  return (
    <>
      <SelectItemsWrapper>
        <ContentContainer
          handleStepClick={handleStepClick}
          stepList={stepList}
          setStepList={setStepList}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          setActiveModule={setActiveModule}
          isEditMode={isEditMode}
          selectedItem={selectedItem}
          setIsEditMode={setIsEditMode}
          hasPermission={hasPermission}
          setHasPermission={setHasPermission}
          actionStatus={actionStatus}
          activeModule={activeModule}
        />
      </SelectItemsWrapper>
    </>
  );
};

export default compose(
  connect(null, {
    updateEditClusterDetail: updateEditClusterDetailAction,
    updateClusterDetail: updateClusterDetailAction
  }),
  withApollo
)(AddEditClusterContainer);
