import React, { useCallback, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import { useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  MASTER_DATA_STEPS,
  MasterDataFieldTextFilter,
  MasterDataFieldSetFilter,
  listOfNumberFilter,
  MASTER_DATA_DEFAULT_COLUMNS,
  listOfFieldsSortable,
  MASTER_DATA_DEFAULT_FILTER,
  dimentionUnits,
  masterDataTableColumn,
  conversionFields,
  masterDataFieldTextareaFilter,
  AUDIT_TRAIL_LIMIT
} from "../../constants";
import MasterDataAction from "./MasterDataAction";
import { compose } from "redux";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import MasterDataDialog from "./MasterDataDialog";
import { Formik } from "formik";
import { find } from "lodash";
import {
  GET_IR_CATEGORY_MAPPING,
  LIST_IR_MASTER_DATA_MAPPINGS,
  LIST_REPO_MASTER_DATA_AUDIT_BY_MASTER_DATA_REF_AND_MODIFIED_DATE,
  LIST_USER_DISPLAY_BY_USERID_AND_DISPLAYMODEL,
  LIST_USER_FILTER_BY_USERID_AND_FILTERMODEL
} from "../../gql/landingapi/queries";
import { OwcIcon, OwcIconButton, OwcList, OwcListItem, OwcMenu, OwcRadio, OwcTooltip, OwcTypography } from "@one/react";
import omitDeep from "omit-deep-lodash";
import { getValidationSchema, getInitialValue } from "./master-data-form/validation-schemas-step";
import { getAllData, getData } from "../../utils/helpers/fetching";
import MasterDataEditDialog from "./MasterDataEditDialog";
import AuditTrialDialog from "../instruments/audit-trial/AuditTrialDialog";
import { DLabDataGrid, DLabTopBarAgGrid, DLabSaveConfiguration } from "@digitallab/grid-common-components";
import { MY_LAB, TOOL_PANELS, filterKeyMapping } from "../instruments/cluster/dLabGridConstant";
import { filter } from "lodash";
import { loadStepFormList as loadStepFormListAction } from "../instruments/redux/actions";
import {
  clusterCategoryMappings,
  equipmentCategoryMappings,
  getEquipmentAdministratorMaster,
  getGroupMaster,
  getLinkedInstance,
  getResponsibleMaster,
  getSops,
  getTagsMaster
} from "../instruments/loadInstrumentsInfoService";
import { getSortedUniqueList, sortedList } from "../../features/importFile/utils/helpers";
import { OwcButton } from "@one/react";
import {
  convertStrToObjColumns,
  convertStrToObjFilters,
  onAgGridColumnApply,
  onAgGridFilterApply
} from "./master-data-list/masterDataListService";
import DATA_MODEL_TABLE from "../../utils/constants/dataModelTable";
import {
  onCreateSelectColumn,
  onCreatefilter,
  onUpdateFilter,
  onUpdateSelectColumn,
  onDeleteSaveConfigurations
} from "./master-data-list/masterDataListService";
import {
  CREATE_IR_DISPLAY_COLUMN,
  UPDATE_IR_DISPLAY_COLUMN,
  DELETE_IR_DISPLAY_COLUMN,
  CREATE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER,
  UPDATE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER,
  DELETE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER
} from "../../gql/landingapi/mutations";
import {
  updateUserDisplayColumns as updateUserDisplayColumnsAction,
  updateUserFilters as updateUserFiltersAction
} from "../user/redux/actions";

import convert from "convert-units";

export const getAllMasterData = async ({ client, site }) => {
  return Promise.all([
    equipmentCategoryMappings(client),
    clusterCategoryMappings(client),
    getGroupMaster(client),
    getResponsibleMaster(client),
    getTagsMaster(client),
    getEquipmentAdministratorMaster(client),
    getLinkedInstance(client),
    getSops(client)
  ]).then((response) => {
    const equipmentCategoryMappingConfigurations = response[0];
    const clusterCategoryMappingConfigurations = response[1];
    const groupMasters = response[2];
    const filterLookups = response[3];
    const tagsMasters = response[4];
    const equipmentAdministrators = response[5];
    const linkedInstanceMasters = response[6];
    const sopMasters = response[7];
    return {
      listIRCategoryMappingConfigurations: {
        items: equipmentCategoryMappingConfigurations.items
      },
      listIRManufacturerMasters: {
        items: getSortedUniqueList(equipmentCategoryMappingConfigurations.items, "manufacturer")
      },
      listIRVariant: {
        items: getSortedUniqueList(equipmentCategoryMappingConfigurations.items, "variant")
      },
      listIRGroupMasters: {
        items: sortedList(groupMasters.items, ["value"])
      },
      listIRSOPMasters: {
        items: sortedList(sopMasters.items, ["value"])
      },
      listIRequipmentModelConfigurations: {
        items: getSortedUniqueList(equipmentCategoryMappingConfigurations.items, "equipmentModel")
      },
      listIRclusterModelConfigurations: {
        items: getSortedUniqueList(clusterCategoryMappingConfigurations.items, "equipmentModel")
      },
      listIREquipmentCategoryConfigurations: {
        items: getSortedUniqueList(equipmentCategoryMappingConfigurations.items, "equipmentCategory")
      },
      listIRClusterCategoryMappings: {
        items: getSortedUniqueList(clusterCategoryMappingConfigurations.items, "equipmentCategory")
      },
      listIRFilterLookups: {
        items: sortedList(filterLookups.items, ["filterValue"])
      },
      listIRTags: {
        items: sortedList(tagsMasters.items, ["filterValue"])
      },
      listIREquipmentAdministrators: {
        items: sortedList(equipmentAdministrators?.items, ["filterValue"])
      },
      listLinkedInstanceMasters: {
        items: sortedList(linkedInstanceMasters.items, ["linkedInstanceDisplay"])
      }
    };
  });
};

export const getUserDisplayColumn = async ({ client, userId }) => {
  const { items: listUserDisplayByUserIDAndDisplayModel } = await getAllData({
    client,
    query: LIST_USER_DISPLAY_BY_USERID_AND_DISPLAYMODEL,
    variables: { limit: 1000, userID: userId, displayModel: "CATEGORYMAPPING" },
    dataPath: ["data", "listUserDisplayByUserIDAndDisplayModel"],
    drillData: true
  });

  return listUserDisplayByUserIDAndDisplayModel;
};

export const getUserFilters = async ({ client, userId }) => {
  const { items: listUserFilterByUserIDAndFilterModel } = await getAllData({
    client,
    query: LIST_USER_FILTER_BY_USERID_AND_FILTERMODEL,
    variables: { limit: 1000, userID: userId, filterModel: "CATEGORYMAPPING" },
    dataPath: ["data", "listUserFilterByUserIDAndFilterModel"],
    drillData: true
  });

  return listUserFilterByUserIDAndFilterModel;
};

const MasterDataContainer = ({
  client,
  email,
  userId,
  showSaveConfiguration = true,
  limit,
  loadStepFormList,
  site,
  updateUserDisplayColumns,
  updateUserFilters
}) => {
  const [masterData, setMasterData] = useState(null);
  const [activeStep, setActiveStep] = useState(MASTER_DATA_STEPS[0]);
  const [stepList, setStepList] = useState(MASTER_DATA_STEPS);
  const [isEditMode, setIsEditMode] = useState(false);
  const [units, setUnits] = useState("metric");
  const [showDimensionList, setShowDimensionList] = useState(false);
  const [dimensionAnchor, setDimensionAnchor] = useState(null);

  const [refreshFlag, setRefreshFlag] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [masterDataDetail, setMasterDataDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [auditLogs, setAuditLogs] = useState();
  const [openAuditTrial, setOpenAuditTrial] = useState(false);

  const gridRef = useRef();
  //Ag-Grid function
  const [gridApi, setGridApi] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(10);

  const [initialLoadIndex, setInitialLoadIndex] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [clearSearch, setClearSearch] = useState("");
  const [eqFloatingFilter, setEqfloatingFilter] = useState(true);
  const [showSideBar, setShowSideBar] = useState(false);
  const [showAdvancedFilter, setShowAdvancedfilter] = useState(false);
  const [currentSideBarPanel, setCurrentSideBarPanel] = useState("");
  const [counter, setCounter] = useState(0);
  const [disableResetAll, setDisableResetAll] = useState(false);
  const [savedColumns, setSavedColumns] = useState([]);
  const [savedFilters, setSavedFilters] = useState([]);
  const [masterItems, setMasterItems] = useState({});
  const { userDisplays, userFilters } = useSelector((state) => state?.user);
  const userDisplayList = useRef([]);
  const [selectedColumn, setSelectedColumn] = useState({});
  const [selectedFilter, setSelectedFilter] = useState({});

  useEffect(() => {
    if (!selectedColumn?.displayName && !selectedFilter?.filterName && !searchText) {
      setDisableResetAll(true);
    } else {
      setDisableResetAll(false);
    }
    const updateColumnFilterList = async () => {
      const listUserDisplays = await getUserDisplayColumn({ client, userId });
      updateUserDisplayColumns(listUserDisplays);
      const listUserFilters = await getUserFilters({ client, userId });
      updateUserFilters(listUserFilters);
    };
    updateColumnFilterList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColumn, selectedFilter, searchText]);

  useEffect(() => {
    userDisplayList.current = [...userDisplays];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSavedColumns(() => [...userDisplays]);
    // eslint-disable-next-line
  }, [userDisplays]);

  useEffect(() => {
    setSavedFilters(() => [...userFilters]);
    // eslint-disable-next-line
  }, [userFilters]);

  useEffect(() => {
    if (gridApi) {
      gridRef.current.api.setQuickFilter(searchText);
      if (gridRef.current.api.getDisplayedRowCount() === 0) {
        gridRef.current.api.showNoRowsOverlay();
      } else {
        gridRef.current.api.hideOverlay();
      }
    }
    setClearSearch(() => searchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    if (gridApi) {
      onGridReady(gridApi);
      setTotalRows(gridRef.current.api.getDisplayedRowCount());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowPerPage, searchText, refreshFlag, units]);

  const onApplyFilter = useCallback(
    (savedFilterModel) => {
      const filterObj =
        savedFilterModel && typeof savedFilterModel === "string"
          ? JSON.parse(savedFilterModel)
          : savedFilterModel || MASTER_DATA_DEFAULT_FILTER;

      const filterDefinitionObj = Object.keys(filterObj)?.includes("filterName")
        ? convertStrToObjFilters(savedFilterModel)?.filterDefinition
        : filterObj;
      const { currentSidePannel } = onAgGridFilterApply({
        gridRef,
        savedFilterModel: filterDefinitionObj,
        filterKeyMapping
      });
      setCurrentSideBarPanel(currentSidePannel);
      setTotalRows(gridRef.current.api.getDisplayedRowCount());
    },
    [setCurrentSideBarPanel]
  );

  const onApplyColumns = useCallback((savedShowcolumns) => {
    let columns = null;
    if (Array.isArray(savedShowcolumns)) {
      columns = savedShowcolumns;
    } else {
      columns = convertStrToObjColumns(savedShowcolumns)?.displayDefinition?.showColumns;
    }

    onAgGridColumnApply({
      gridRef,
      savedShowcolumns: columns
    });
  }, []);

  useEffect(() => {
    const getMasterData = async () => {
      const { items } = await getAllData({
        client,
        query: LIST_IR_MASTER_DATA_MAPPINGS,
        dataPath: ["data", "listIRCategoryMappings"],
        variables: { limit: 1000 },
        drillData: true
      });
      const masterData = await getAllMasterData({
        client,
        site: site
      });
      loadStepFormList(omitDeep(masterData, "__typename"));
      const listUserDisplays = await getUserDisplayColumn({ client, userId });
      updateUserDisplayColumns(listUserDisplays);
      const listUserFilters = await getUserFilters({ client, userId });
      updateUserFilters(listUserFilters);
      const updatedItems = items.map((obj) =>
        obj.isActive
          ? {
              ...obj,
              weightInkg: obj.weightInkg ? parseFloat(obj.weightInkg).toFixed(2) : obj.weightInkg,
              isActive: "Yes"
            }
          : {
              ...obj,
              weightInkg: obj.weightInkg ? parseFloat(obj.weightInkg).toFixed(2) : obj.weightInkg,
              isActive: "No"
            }
      );
      setMasterItems(updatedItems ?? []);
      setMasterData(updatedItems ?? []);
      setTotalRows(updatedItems?.length);
      setLoading(() => false);
    };
    setLoading(() => true);
    getMasterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshFlag]);

  useEffect(() => {
    const finalData =
      units === dimentionUnits.imperialUnit ? covertMasterToDimensionImperial(masterItems) : masterItems ?? [];
    setMasterData(finalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [units, masterItems]);

  const onFirstDataRendered = useCallback(() => {
    setEqfloatingFilter((prevState) => !prevState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMasterData = async (item, client) => {
    try {
      const result = await client.query({
        query: GET_IR_CATEGORY_MAPPING,
        fetchPolicy: "no-cache",
        variables: {
          equipmentCategory: item?.equipmentCategory,
          manufacturer: item?.manufacturer,
          equipmentModel: item?.equipmentModel,
          variant: item?.variant,
          modelName: "IRCategoryMapping"
        }
      });
      if (result) {
        return result?.data?.getIRCategoryMapping;
      }
    } catch (err) {
      console.log("cluster check error", err);
    }
  };

  const handleMasterDataFieldValuesForEdit = async (instrument, client) => {
    let tempInstrumentDetail = omitDeep(instrument, "__typename", "editReason");
    tempInstrumentDetail.configText = "";
    tempInstrumentDetail.editComment = "";
    if (tempInstrumentDetail.weightInkg) {
      tempInstrumentDetail.weightInkg = parseFloat(tempInstrumentDetail.weightInkg).toFixed(2) || null;
    }
    return tempInstrumentDetail;
  };

  const covertMasterToDimensionImperial = (items) => {
    let tempDimensionImperialVal;
    const resultData = [];
    if (items?.length > 0) {
      items?.forEach((item) => {
        let tempVal = {};
        conversionFields.forEach((dimensionVal) => {
          const keys = Object.keys(item);
          if (keys.includes(dimensionVal)) {
            tempVal = {
              ...tempVal,
              [dimensionVal]: dimensionCovertVal(dimensionVal, item, tempDimensionImperialVal)
            };
          }
        });
        const dimensionResult = { ...item, ...tempVal };
        resultData.push(dimensionResult);
      });
    }
    return resultData;
  };

  //  Dimension convert unit for master data table

  const dimensionCovertVal = (dimensionVal, item, tempDimensionImperialVal) => {
    switch (dimensionVal) {
      case DATA_MODEL_TABLE.weightInkg.key:
        const convertedValue =
          item?.[dimensionVal] !== null ? convert(item?.weightInkg).from("kg").to("lb") : item?.[dimensionVal];
        tempDimensionImperialVal =
          convertedValue !== null ? parseFloat(convertedValue.toFixed(2)) || null : item?.[dimensionVal];

        break;
      default:
        const convertedValueInch =
          item?.[dimensionVal] !== null ? convert(item?.[dimensionVal]).from("mm").to("in") : item?.[dimensionVal];
        tempDimensionImperialVal =
          convertedValueInch !== null ? parseFloat(convertedValueInch.toFixed(2)) || null : item?.[dimensionVal];
        break;
    }
    return tempDimensionImperialVal;
  };

  const editMasterData = async (item) => {
    setIsEditMode(true);
    const result = await getMasterData(item, client);
    let editData = await handleMasterDataFieldValuesForEdit(result, client);
    setMasterDataDetail(editData);
    setOpenEditModal(true);
  };

  const viewAuditMasterData = async (item) => {
    try {
      setOpenAuditTrial(true);
      setLoading(true);
      let { items: auditLogChanges } = await getData({
        client,
        query: LIST_REPO_MASTER_DATA_AUDIT_BY_MASTER_DATA_REF_AND_MODIFIED_DATE,
        variables: {
          limit: AUDIT_TRAIL_LIMIT,
          masterDataRef: item?.id,
          modelName: "IRCategoryMapping"
        },
        dataPath: ["data", "listRepoMasterDataAuditByMasterDataRefAndModifiedDate"],
        drillData: true
      });
      setAuditLogs(auditLogChanges);
    } catch (err) {
      console.warn(err);
      setAuditLogs([]);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseAuditTrial = (isOpenEdit) => {
    setOpenAuditTrial(false);
  };

  const onSave = async ({
    currentState,
    chipName = "",
    type = "update",
    isAgGridObj = false,
    pannel = "",
    isSaveConfigUpdated = false,
    updateRedux = true
  }) => {
    let result = null;
    if (pannel === TOOL_PANELS?.filters) {
      let obj = {};
      if (isAgGridObj) {
        for (const key in MASTER_DATA_DEFAULT_FILTER.filterDefinition) {
          if (currentState[key] === undefined) {
            obj[key] = MASTER_DATA_DEFAULT_FILTER.filterDefinition[key];
          } else if (currentState[key]?.filterType === "set") {
            obj[key] = currentState[key]?.values;
          } else {
            obj[key] = currentState[key];
          }
        }
      } else {
        obj = {
          ...currentState
        };
      }
      const lastSavedFilter = {
        filterDefinition: obj,
        filterName: chipName
      };
      if (type === "create") {
        lastSavedFilter.userID = userId;
        lastSavedFilter.chipName = chipName;
        result = await onCreatefilter({
          id: userId,
          email,
          userFilters,
          lastFavFilter: lastSavedFilter,
          client,
          query: CREATE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER,
          filterModel: "CATEGORYMAPPING",
          updateUserFilters
        });
      } else {
        result = await onUpdateFilter({
          lastFavFilter: lastSavedFilter,
          isSaveConfigUpdated: isSaveConfigUpdated,
          updateRedux,
          id: userId,
          email,
          client,
          query: UPDATE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER,
          filterModel: "CATEGORYMAPPING"
        });
      }
      setSelectedFilter(lastSavedFilter);
      setTotalRows(gridRef.current.api.getDisplayedRowCount());
      return { savedObj: lastSavedFilter, result };
    } else if (pannel === TOOL_PANELS?.columns) {
      const savedColumns = [...currentState];
      const updatedSavedColumns = savedColumns.map((column, index) => {
        const dataModelKey = column?.colId;
        const obj = {
          key: dataModelKey,
          val: DATA_MODEL_TABLE[dataModelKey]?.value || "ignore",
          order: index,
          show: DATA_MODEL_TABLE[dataModelKey]?.value?.length ? !column?.hide : "ignore",
          sortStatus: listOfFieldsSortable.includes(DATA_MODEL_TABLE[dataModelKey]?.key)
        };
        return obj;
      });
      const showColumns = filter(updatedSavedColumns, { show: true });
      const hideColumns = filter(updatedSavedColumns, { show: false });
      let savecolumnObj = {
        displayName: chipName,
        displayDefinition: {
          showColumns,
          hideColumns
        }
      };
      if (type === "create") {
        savecolumnObj.userID = userId;
        savecolumnObj.chipName = chipName;
        result = await onCreateSelectColumn({
          id: userId,
          email,
          userDisplayList,
          unParsedData: savecolumnObj,
          client,
          query: CREATE_IR_DISPLAY_COLUMN,
          updateUserDisplayColumns,
          // updateLastDisplayColumn,
          displayModel: "CATEGORYMAPPING"
        });
      } else {
        result = await onUpdateSelectColumn({
          data: JSON.stringify(savecolumnObj),
          isSaveConfigUpdated,
          updateRedux,
          id: userId,
          email,
          client,
          query: UPDATE_IR_DISPLAY_COLUMN,
          userDisplayList,
          updateUserDisplayColumns,
          displayModel: "CATEGORYMAPPING"
          // updateLastDisplayColumn
        });
      }
      setSelectedColumn(savecolumnObj);
      return { savedObj: savecolumnObj, result };
    }
  };

  const onChipDelete = async (chipToDelete, lastSavedChip, panel, userFilters) => {
    if (panel === TOOL_PANELS?.filters) {
      setSelectedFilter(MASTER_DATA_DEFAULT_FILTER);
    } else if (panel === TOOL_PANELS?.columns) {
      setSelectedColumn(MASTER_DATA_DEFAULT_COLUMNS);
    }

    return await onDeleteSaveConfigurations({
      userId,
      chipToDelete,
      panel,
      lastSavedChip,
      client,
      email,
      deleteFilterQuery: DELETE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER,
      deleteColumnQuery: DELETE_IR_DISPLAY_COLUMN,
      displayModel: "CATEGORYMAPPING",
      userFilters,
      userDisplayList,
      updateUserFilters,
      updateUserDisplayColumns
    });
  };

  const onColumnChange = (selectedSavedColumn) => {
    setSelectedColumn(selectedSavedColumn);
    onApplyColumns(selectedSavedColumn ? selectedSavedColumn : MASTER_DATA_DEFAULT_COLUMNS);
    const currentState = gridRef?.current?.columnApi.getColumnState();
    onSave({
      currentState,
      chipName: selectedSavedColumn ? selectedSavedColumn?.displayName : "",
      pannel: TOOL_PANELS?.columns,
      updateRedux: true
    });
  };

  const onFilterDropDownChange = (selectedSavedFilter) => {
    setSelectedFilter(selectedSavedFilter);
    onApplyFilter(selectedSavedFilter ? selectedSavedFilter : MASTER_DATA_DEFAULT_FILTER);
    const currentState = gridRef?.current?.api.getFilterModel();
    onSave({
      currentState,
      chipName: selectedSavedFilter ? selectedSavedFilter?.filterName : "",
      pannel: TOOL_PANELS?.filters,
      updateRedux: true,
      isAgGridObj: selectedSavedFilter ? !(selectedSavedFilter?.filterName === MY_LAB) : true
    });
  };

  const actionRender = (params) => {
    return (
      <>
        <MasterDataAction params={params} editMasterData={editMasterData} viewAuditMasterData={viewAuditMasterData} />
      </>
    );
  };

  const onColumnEverythingChanged = (params) => {
    if (gridRef && gridRef?.current?.columnApi) {
      const currentState = gridRef?.current?.columnApi.getColumnState();
      if (params?.source && params?.source === "toolPanelUi") {
        gridRef.current.columnApi.applyColumnState({
          state: currentState,
          applyOrder: true
        });
        /* Default Change */
        onSave({
          currentState,
          chipName: "",
          pannel: TOOL_PANELS?.columns
        });
      }
    }
  };

  const onFilterChanged = (params) => {
    if (Object.keys(params?.api?.getFilterModel()).length > 0) {
      setCounter(() => Object.keys(params?.api?.getFilterModel()).length);
    } else {
      setCounter(() => 0);
    }
    let rowData = [];
    gridRef?.current?.api?.forEachNodeAfterFilterAndSort((node) => rowData.push(node.data));

    if (gridRef && gridRef?.current?.api && rowData.length === 0) {
      gridRef.current.api.showNoRowsOverlay();
    } else if (gridRef && gridRef?.current?.api) {
      gridRef.current.api.hideOverlay();
    }

    if (Object.keys(params).includes("afterFloatingFilter")) {
      onSave({
        currentState: params?.api?.getFilterModel(),
        chipName: "",
        type: "update",
        isAgGridObj: true,
        pannel: TOOL_PANELS?.filters,
        updateRedux: false
      });
    }
  };

  const onGridReady = (params) => {
    setGridApi(params);
    if (initialLoadIndex === 0) {
      onApplyColumns(MASTER_DATA_DEFAULT_COLUMNS);
      onApplyFilter(MASTER_DATA_DEFAULT_FILTER);
      setInitialLoadIndex((prevState) => prevState + 1);
    }
  };

  const defaultColDef = useMemo(() => {
    let defaultDef = {
      floatingFilter: eqFloatingFilter,
      flex: 1,
      minWidth: 150,
      resizable: true,
      suppressMenu: true
    };
    return {
      ...defaultDef
    };
  }, [eqFloatingFilter]);

  const dLabColumnDef = useMemo(() => {
    let columnFields = [];
    let columnData = selectedColumn?.displayDefinition
      ? [...selectedColumn?.displayDefinition?.showColumns, ...selectedColumn?.displayDefinition?.hideColumns]
      : masterDataTableColumn();

    columnData?.forEach((column) => {
      let defaultObj = {
        field: column.key,
        headerName: column.val,
        sortable: true,
        minWidth: 250,
        hide: !column.show,
        tooltipField: column.key === DATA_MODEL_TABLE?.equipmentModel?.key ? column.key : "",
        valueFormatter: (params) => (params.value === "null" || !params.value ? "-" : params.value)
      };

      if (conversionFields?.includes(column.key)) {
        let obj = find(masterDataTableColumn(), { key: column.key });
        let fieldValue = units !== "metric" ? obj?.imperialField : obj?.key;
        defaultObj = {
          ...defaultObj,
          headerName: DATA_MODEL_TABLE[fieldValue]?.value
        };
      }

      if (MasterDataFieldTextFilter.includes(column.key)) {
        defaultObj = {
          ...defaultObj,
          filter: "agTextColumnFilter",
          filterParams: {
            defaultToNothingSelected: true,
            buttons: ["reset", "apply"]
          }
        };
      }
      if (masterDataFieldTextareaFilter.includes(column.key)) {
        defaultObj = {
          ...defaultObj,
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["contains"],
            defaultToNothingSelected: true,
            buttons: ["reset", "apply"]
          }
        };
      }
      if (MasterDataFieldSetFilter.includes(column.key)) {
        defaultObj = {
          ...defaultObj,
          filter: "agSetColumnFilter",
          filterParams: {
            defaultToNothingSelected: true,
            buttons: ["reset"]
          }
        };
      }
      if (listOfNumberFilter.includes(column.key)) {
        defaultObj = {
          ...defaultObj,
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultToNothingSelected: true,
            filterOptions: ["equals", "lessThan", "greaterThan", "inRange", "blank"],
            defaultJoinOperator: "OR",
            buttons: ["reset", "apply"]
          }
        };
      }
      columnFields.push(defaultObj);
    });

    columnFields = [
      ...columnFields,
      {
        field: "actions",
        headerName: "Actions",
        maxWidth: 50,
        filter: false,
        pinned: "right",
        lockVisible: true,
        cellClass: "action-render",
        cellRenderer: actionRender
      }
    ];

    return columnFields;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [units]);

  const sideBarDef = useMemo(() => {
    const columnsDef = {
      minWidth: 200,
      width: 276,
      id: "columns",
      labelDefault: "Columns",
      labelKey: "columns",
      iconKey: "column-icon",
      toolPanel: "agColumnsToolPanel",
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressColumnSelectAll: true
      }
    };

    const filtersDef = {
      minWidth: 200,
      width: 276,
      id: "filters",
      labelDefault: "Filters",
      labelKey: "filters",
      iconKey: "filter",
      toolPanel: "agFiltersToolPanel"
    };

    let pannels = [];
    let sideBarPannel = [
      {
        minWidth: 200,
        width: 276,
        id: "save_configuration",
        labelDefault: "Saved configurations",
        labelKey: "loading",
        iconKey: "setting-icon",
        toolPanel: () => {
          let savedTags = [];
          let savedTag = {};
          let panels = [];
          if (showSideBar && currentSideBarPanel === TOOL_PANELS?.filters) {
            const savedfilters = [...userFilters];
            savedTags = savedfilters.map((userFilter) => {
              userFilter.chipName = userFilter?.filterName;
              return userFilter;
            });
            savedTag = {
              ...MASTER_DATA_DEFAULT_FILTER,
              chipName: MASTER_DATA_DEFAULT_FILTER?.filterName
            };
          } else if (showSideBar && currentSideBarPanel === TOOL_PANELS?.columns) {
            savedTags = [...userDisplays].map((savedColumn) => {
              savedColumn.chipName = savedColumn?.displayName;
              return savedColumn;
            });
            savedTag = {
              ...MASTER_DATA_DEFAULT_COLUMNS,
              chipName: MASTER_DATA_DEFAULT_COLUMNS?.displayName
            };
          }
          if (showSideBar) {
            const sideBars = gridRef && gridRef?.current?.api?.getSideBar();
            panels = sideBars
              ? sideBars?.toolPanels?.map((panel) => panel?.id)
              : [TOOL_PANELS?.filters, TOOL_PANELS?.saveConfiguration];
          }
          return (
            <DLabSaveConfiguration
              savedChips={savedTags}
              savedChip={savedTag}
              currentSideBarPanel={currentSideBarPanel}
              gridRef={gridRef}
              onSave={onSave}
              onChipDelete={onChipDelete}
              onApplyColumns={onApplyColumns}
              onApplyFilter={onApplyFilter}
              defaultShowColumns={MASTER_DATA_DEFAULT_COLUMNS}
              defaultFilterObj={MASTER_DATA_DEFAULT_FILTER}
              sideBarPanels={[...panels]}
            />
          );
        }
      }
    ];

    if (currentSideBarPanel === TOOL_PANELS?.columns) {
      pannels = [columnsDef];
    } else if (currentSideBarPanel === TOOL_PANELS?.filters) {
      pannels = [filtersDef];
    }

    if (showSaveConfiguration) {
      pannels = [...pannels, ...sideBarPannel];
    }
    return {
      toolPanels: pannels,
      defaultToolPanel: gridRef?.current?.api?.isSideBarVisible()
        ? gridRef?.current?.api?.getOpenedToolPanel() || "filters"
        : currentSideBarPanel,
      hiddenByDefault: !showSideBar
    };
    // eslint-disable-next-line
  }, [showSideBar]);

  const onRowsPerPageChanged = (value) => {
    setRowPerPage(value);
  };

  let attr = {
    height: 480,
    rowQuickSearch: true,
    suppressPaginationPanel: true,
    paginationPageSize: rowPerPage,
    paginationConfig: {
      rowsPerPage: rowPerPage,
      total: totalRows
    },
    columnDefs: dLabColumnDef,
    pagination: true,
    animateRows: true,
    rowSelection: "multiple",
    rowMultiSelectWithClick: true,
    rowExport: false,
    masterDetail: false,
    suppressContextMenu: true,
    isGroupOpenByDefault: false,
    defaultToolPanel: "filters",
    hiddenByDefault: true,
    cacheBlockSize: rowPerPage,
    serverSideInfiniteScroll: false,
    serverSideSortOnServer: true,
    suppressRowClickSelection: true,
    rowData: masterData && masterData?.length > 0 ? masterData : [],
    onColumnEverythingChanged,
    onFilterChanged,
    onRowsPerPageChanged,
    onGridReady,
    setRowPerPage,
    rowPerPage,
    totalRows,
    sideBar: sideBarDef,
    defaultColDef
  };

  const resetAll = async () => {
    setClearSearch(() => "");
    onApplyFilter(MASTER_DATA_DEFAULT_FILTER);
    onApplyColumns(MASTER_DATA_DEFAULT_COLUMNS);
    await onUpdateFilter({
      lastFavFilter: MASTER_DATA_DEFAULT_FILTER,
      updateRedux: true,
      id: userId,
      email,
      client,
      query: UPDATE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER,
      filterModel: "CATEGORYMAPPING"
    });
    setSelectedColumn(() => MASTER_DATA_DEFAULT_COLUMNS);
    await onUpdateSelectColumn({
      data: JSON.stringify(MASTER_DATA_DEFAULT_COLUMNS),
      updateRedux: true,
      id: userId,
      email,
      client,
      query: UPDATE_IR_DISPLAY_COLUMN,
      filterModel: "CATEGORYMAPPING",
      userDisplayList,
      updateUserDisplayColumns
    });
    setSelectedFilter(() => MASTER_DATA_DEFAULT_FILTER);
    setCounter(() => 0);
    setDisableResetAll(() => true);
  };

  const onToggleAdvancedFilter = (isFilterToggled) => {
    setEqfloatingFilter(isFilterToggled);
  };

  const handleSettingClick = (event, param) => {
    setDimensionAnchor(event.currentTarget);
  };

  const handleSettingClose = () => {
    setDimensionAnchor(null);
    setShowDimensionList(false);
  };

  return (
    <div style={{ width: "100%" }}>
      {openAuditTrial && (
        <AuditTrialDialog
          title="Log entry information"
          openAuditTrial={openAuditTrial}
          handleClose={handleCloseAuditTrial}
          changeItem={auditLogs}
          dataTestId="audit-trial"
          isLoading={loading}
          hideEquipmentInfo={true}
        />
      )}
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 20,
            position: "relative"
          }}
        >
          <OwcTypography variant="title6" style={{ display: "flex", margin: "1px" }}>
            Equipment master data list
          </OwcTypography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px"
          }}
        >
          <DLabTopBarAgGrid
            gridRef={gridRef}
            setSearchText={setSearchText}
            clearSearch={clearSearch}
            disableResetAll={disableResetAll}
            showSideBar={showSideBar}
            setShowSideBar={setShowSideBar}
            setCurrentSideBarPanel={setCurrentSideBarPanel}
            counter={counter}
            setShowAdvancedfilter={setShowAdvancedfilter}
            showAdvancedFilter={showAdvancedFilter}
            onToggleAdvancedFilter={onToggleAdvancedFilter}
            selectedColumn={selectedColumn}
            selectedFilter={selectedFilter}
            savedColumns={savedColumns}
            savedFilters={savedFilters}
            onColumnChange={onColumnChange}
            onFilterChange={onFilterDropDownChange}
            onResetAll={resetAll}
          />
          <span name="topBarAgGrid">
            <span
              style={{
                marginLeft: "auto"
              }}
            >
              <div>
                <OwcIconButton
                  flat
                  onClick={(e) => {
                    setShowDimensionList(!showDimensionList);
                    handleSettingClick(e);
                  }}
                >
                  <OwcIcon
                    name="ruler"
                    type="outlined"
                    style={{
                      color: dimensionAnchor ? "var(--one-color-interaction-default-brand-1)" : ""
                    }}
                    id="dimensionList"
                  />
                </OwcIconButton>
                <OwcTooltip anchor="dimensionList" placement="left">
                  Change Units
                </OwcTooltip>
              </div>
              {ReactDOM.createPortal(
                <OwcMenu
                  anchor="dimensionList"
                  visible={dimensionAnchor}
                  onClickOutside={handleSettingClose}
                  style={
                    !showDimensionList
                      ? { display: "none" }
                      : {
                          width: "244px",
                          height: "160px",
                          bordeRadius: "4px",
                          border: "1px",
                          position: "relative"
                        }
                  }
                >
                  <OwcList expandable expanded header="Select Units">
                    <OwcListItem
                      selected={dimentionUnits.metricUnit === units ? true : false}
                      onClick={() => {
                        setUnits(dimentionUnits.metricUnit);
                      }}
                    >
                      <OwcRadio>Metric</OwcRadio>
                    </OwcListItem>
                    <OwcListItem
                      onClick={() => {
                        setUnits(dimentionUnits.imperialUnit);
                      }}
                    >
                      <OwcRadio>Imperial</OwcRadio>
                    </OwcListItem>
                  </OwcList>
                </OwcMenu>,
                document.body
              )}
            </span>
          </span>
        </div>
        <DLabDataGrid
          {...attr}
          overlayNoRowsTemplate={
            loading
              ? '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">Loading....</span>'
              : '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No Rows To Show</span>'
          }
          defaultColDef={defaultColDef}
          gridRef={gridRef}
          units={units}
          onFirstDataRendered={onFirstDataRendered}
          enableBrowserTooltips={true}
        >
          <span name="pagination" style={{ display: "flex", alignItems: "center" }}>
            <OwcButton
              variant="primary"
              onClick={() => {
                setIsEditMode(false);
                setOpenAddModal(true);
              }}
            >
              Add equipment master data
            </OwcButton>
          </span>
        </DLabDataGrid>
      </>
      <Formik
        validationSchema={getValidationSchema(activeStep, isEditMode)}
        initialValues={getInitialValue(activeStep, isEditMode)}
        isInitialValid={true}
        validateOnMount={false}
      >
        <>
          {openAddModal && (
            <MasterDataDialog
              open={openAddModal}
              setOpen={setOpenAddModal}
              stepList={stepList}
              setStepList={setStepList}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              setRefreshFlag={setRefreshFlag}
              isEditMode={isEditMode}
              userId={userId}
            />
          )}
          {openEditModal && (
            <MasterDataEditDialog
              open={openEditModal}
              setOpen={setOpenEditModal}
              masterDataDetail={masterDataDetail}
              setRefreshFlag={setRefreshFlag}
              isEditMode={isEditMode}
              userId={userId}
            />
          )}
        </>
      </Formik>
    </div>
  );
};

const mapStateToProps = (store) => ({
  id: store?.user?.id,
  email: store?.user?.email,
  userId: store?.user?.user,
  site: store?.user?.site
});

export default compose(
  connect(mapStateToProps, {
    loadStepFormList: loadStepFormListAction,
    updateUserDisplayColumns: updateUserDisplayColumnsAction,
    updateUserFilters: updateUserFiltersAction
  }),
  withApollo
)(MasterDataContainer);
