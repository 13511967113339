const DATA_LABEL_TABLE = {
  LEFT_MENU_BAR: {
    main_menu_label: "Menu",
    equiment_list_menu_label: "Equipment list",
    equipment_import_menu_label: "Import",
    equipment_admin_menu_label: "Admin panel"
  },
  IMPORT_TABS: {
    equipment_import: "Equipment import",
    master_data_import: "Master data import"
  }
};

export default DATA_LABEL_TABLE;
