import React from "react";
import { OwcIcon, OwcInput } from "@one/react";

/**
 * OwcInput with formik data and icon to clear
 * @param {object} props
 * @param {object} props.formik - Formik state and methods
 * @param {string} props.dataKey - main text
 * @param {string} props.assistiveText - additional text
 * @param {React.CSSProperties} props.style - additional text
 */

export const OwcInputWithFormik = ({ formik, dataKey, assistiveText, style, ...args }) => (
  <OwcInput
    name={dataKey}
    style={{ width: "100%", ...style }}
    value={formik.values[dataKey]}
    onInputChange={formik.handleChange}
    onBlur={formik.handleBlur}
    error={formik.touched[dataKey] && formik.errors[dataKey]}
    {...args}
  >
    {formik.touched[dataKey] && formik.errors[dataKey] && <span slot="error-text">{formik.errors[dataKey]}</span>}
    {formik.values[dataKey] !== "" && (
      <OwcIcon
        name="circle_clear_filled"
        slot="suffix"
        type="legacy"
        onClick={() => formik.setFieldValue(dataKey, "", true)}
      />
    )}
    <span slot="assistive-text">{assistiveText}</span>
  </OwcInput>
);
