import { useEffect, useRef, useState } from "react";
import { LIST_DIGITAL_LAB_INSTRUMENT_REPOSITORY_USERS } from "../../gql/landingapi";
import { getAllData } from "../helpers/fetching";

export default function useGetUserList(client, user) {
  const [userList, setUserList] = useState([]);
  const isMountedRef = useRef(true);

  const getList = async () => {
    const { items } = await getAllData({
      client,
      query: LIST_DIGITAL_LAB_INSTRUMENT_REPOSITORY_USERS,
      fetchPolicy: "network-only",
      dataPath: ["data", "listDigitalLabInstrumentRepositoryUsers"],
      drillData: true,
      variables: {
        limit: 1000
      }
    });

    const list = items
      .filter((item) => item?.userId !== user?.user)
      .map(({ givenName, familyName, name, email, ...item }) => ({
        ...item,
        key: item?.email,
        value: !(givenName && familyName && name)
          ? email
          : `${givenName || ""} ${familyName || ""} ${name ? ` - ${name}` : ""}`.trim()
      }));

    isMountedRef.current && setUserList(() => list);
  };

  useEffect(() => {
    getList();

    return () => {
      isMountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return userList;
}
