import React, { useState } from "react";
import { OwcIconButton, OwcListItem, OwcMenu } from "@one/react";
import ReactDOM from "react-dom";

const LinkedInstanceAction = ({ params, inititateEditAction }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSettingClose = () => {
    setAnchorEl(null);
  };
  const handleSettingClick = (event, param) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <OwcIconButton
        class="one-icons action-icon"
        data-testid="Linked-instance-setting-button"
        onClick={(event) => {
          handleSettingClick(event, params);
        }}
        icon="more_vertical"
        type="legacy"
        flat
        id={params?.data?.id[0]}
      />
      {ReactDOM.createPortal(
        <OwcMenu
          data-testid="simple-menu"
          visible={anchorEl}
          onClickOutside={handleSettingClose}
          anchor={params?.data?.id[0]}
        >
          <OwcListItem
            data-testid="simple-menu-filter"
            onClick={() => {
              inititateEditAction(params?.data?.id[0]);
              setAnchorEl(null);
            }}
          >
            Edit Linked instance and Secondary ID
          </OwcListItem>
        </OwcMenu>,
        document.body
      )}
    </>
  );
};

export default LinkedInstanceAction;
