import React, { useEffect, useState } from "react";
import * as yup from "yup";
import styled from "styled-components";
import { MainRow, TitleRow } from "./BasicDataReqFieldsForm";
import { InputModal } from "./AssaysDataForm";
import { useFormikContext } from "formik";
import DATA_MODEL_TABLE from "../../utils/constants/dataModelTable";
import { OwcIcon, OwcIconButton, OwcInput } from "@one/react";
import { Clear, Done } from "@mui/icons-material";
import { Button, Checkbox, FormControlLabel, IconButton } from "@mui/material";

const DocumentsNameRow = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: var(--one-color-gray-800);
  margin: 2px 0;
`;
const DocumentsInfoRow = styled.div`
  padding: 0.75rem 0;
`;
const DocumentsActionRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
const DocumentsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: thin solid var(--one-color-gray-300);
`;
const DocumentsButtons = styled(DocumentsActionRow)`
  max-height: 48px;
`;

const DocumentsList = styled.div`
  margin-bottom: 1rem;
  margin-left: 16px;
  margin-right: 16px;
  overflow-y: auto;
  max-height: 120px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DocURLContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 16px 0 16px;
  border-top: thin solid var(--one-color-gray-300);
`;

export const ButtonsForAttachment = ({ handleEditOrAdd, classes, attachment, onClear, dataTestIdKey }) => {
  return (
    <>
      <Button
        data-testid={`${dataTestIdKey}-button-edit`}
        variant="outlined"
        color="primary"
        type="button"
        onClick={handleEditOrAdd}
        classes={{
          outlined: classes.onlyIconButtonOutlined
        }}
      >
        <Done />
      </Button>
      <Button
        data-testid={`${dataTestIdKey}-button-clear`}
        variant="outlined"
        type="button"
        onClick={onClear}
        classes={{
          outlined: classes.onlyIconButtonOutlinedDefault
        }}
      >
        <Clear />
      </Button>
    </>
  );
};

const validationSchema = yup.object({
  name: yup.string("Enter name").trim().required("Enter name"),
  documentId: yup.string("Enter document id").trim().required("Enter document id")
});

const QualificationDocumentsTitle = ({ formik }) => {
  const classes = {};
  return (
    <TitleContainer>
      <TitleRow data-testid="documents-data-form-title">{DATA_MODEL_TABLE.qualificationDocuments.value}</TitleRow>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              classes={{
                root: classes.checkbox
              }}
              color="primary"
              data-testid="documents-data-form-documents-checkbox-issynchornized"
              checked={formik.values.qualificationDocuments?.isSynchronized}
              onChange={formik.handleChange}
              name="qualificationDocuments.isSynchronized"
            />
          }
          label="Update all documents"
        />
      </div>
    </TitleContainer>
  );
};

const DocumentInput = ({ uploadedDocument, fieldname, setuploadedDocument, isSubmitted, errors, label }) => {
  return (
    <OwcInput
      data-testid={`documents-data-form-document-${fieldname}-input`}
      variant="filled"
      id={fieldname}
      name={fieldname}
      label={label}
      value={uploadedDocument?.[fieldname]}
      onInputKeyDown={(event) =>
        setuploadedDocument({
          ...uploadedDocument,
          [fieldname]: event.target.value
        })
      }
      error={isSubmitted && Boolean(errors?.[fieldname])}
      helperText={isSubmitted && errors?.[fieldname]}
      FormHelperTextProps={{
        "data-testid": `documents-data-form-document-${fieldname}-input-helper-text`
      }}
    >
      {uploadedDocument?.[fieldname]?.length > 0 && (
        <OwcIconButton
          slot="suffix"
          data-testid={`documents-data-form-document-${fieldname}-input-clear-button`}
          icon="circle_clear"
          type="filled"
          flat
          onClick={() =>
            setuploadedDocument({
              ...uploadedDocument,
              [fieldname]: ""
            })
          }
        />
      )}
    </OwcInput>
  );
};

const MainAddLinkedDocument = ({
  formik,
  uploadedDocument,
  setuploadedDocument,
  isSubmitted,
  setIsSubmitted,
  handleEditOrAdd,
  errors
}) => {
  const classes = {};
  return (
    <MainRow data-testid="documents-data-form">
      <QualificationDocumentsTitle formik={formik} />
      <DocURLContainer>
        <InputModal style={{ marginRight: 20, width: 170 }}>
          <DocumentInput
            errors={errors}
            isSubmitted={isSubmitted}
            fieldname="name"
            label="Document name"
            setuploadedDocument={setuploadedDocument}
            uploadedDocument={uploadedDocument}
          />
        </InputModal>
        <InputModal style={{ width: 170 }}>
          <DocumentInput
            errors={errors}
            isSubmitted={isSubmitted}
            fieldname="documentId"
            label="Document Id"
            setuploadedDocument={setuploadedDocument}
            uploadedDocument={uploadedDocument}
          />
        </InputModal>
        <DocumentsButtons data-testid="documents-data-form-document-buttons-inputs">
          <ButtonsForAttachment
            dataTestIdKey="documents-data-form-document"
            onClear={() => {
              setIsSubmitted(false);
              setuploadedDocument({
                index: -1,
                name: "",
                documentId: ""
              });
            }}
            attachment={uploadedDocument}
            handleEditOrAdd={handleEditOrAdd}
            classes={classes}
          />
        </DocumentsButtons>
      </DocURLContainer>
    </MainRow>
  );
};

const handleEditOrAddAction =
  ({ uploadedDocument, setIsSubmitted, formik, setuploadedDocument, values, setValues }) =>
  () => {
    try {
      if (formik.values?.qualificationDocuments?.value === undefined) {
        values.qualificationDocuments = {};
        values.qualificationDocuments.value = [];
        setValues({ ...values });
      }
      validationSchema.validateSync(uploadedDocument);
      setIsSubmitted(false);
      const { index, ...documentToAdd } = uploadedDocument;
      if (index > -1) {
        formik.setFieldValue(`qualificationDocuments.value.${index}`, {
          ...documentToAdd
        });
      } else {
        formik.setFieldValue("qualificationDocuments.value", [
          ...formik.values?.qualificationDocuments.value,
          { ...documentToAdd }
        ]);
      }
      setuploadedDocument({
        index: -1,
        name: "",
        documentId: ""
      });
    } catch (e) {
      setIsSubmitted(true);
    }
  };

const DocumentsDataForm = ({ formik }) => {
  const { values, setValues } = useFormikContext();
  const [uploadedDocument, setuploadedDocument] = useState({
    index: -1,
    name: "",
    documentId: ""
  });
  const [errors, setErrors] = useState({
    name: null,
    documentId: null
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const validate = async () => {
      setErrors({
        name: await validationSchema
          .validateAt("name", uploadedDocument)
          .then(() => null)
          .catch((error) => error.errors[0]),
        documentId: await validationSchema
          .validateAt("documentId", uploadedDocument)
          .then(() => null)
          .catch((error) => error.errors[0])
      });
    };

    validate();
  }, [uploadedDocument]);

  return (
    <>
      <MainAddLinkedDocument
        errors={errors}
        formik={formik}
        handleEditOrAdd={handleEditOrAddAction({
          formik,
          setIsSubmitted,
          setuploadedDocument,
          uploadedDocument,
          values,
          setValues
        })}
        isSubmitted={isSubmitted}
        setIsSubmitted={setIsSubmitted}
        setuploadedDocument={setuploadedDocument}
        uploadedDocument={uploadedDocument}
      />
      <DocumentsList data-testid="documents-data-form-document-list">
        {formik.values.qualificationDocuments?.value?.map((test, index) => (
          <DocumentsRow key={index}>
            <DocumentsInfoRow data-testid="documents-data-form-document-list-info-row">
              <DocumentsNameRow data-testid="documents-data-form-document-list-info-row-name">
                {test.name}
              </DocumentsNameRow>
              <DocumentsNameRow data-testid="documents-data-form-document-list-info-row-documentId">
                {test.documentId}
              </DocumentsNameRow>
            </DocumentsInfoRow>
            <DocumentsActionRow data-testid="documents-data-form-document-list-action-buttons">
              <IconButton
                type="button"
                onClick={() => {
                  setuploadedDocument({
                    index,
                    ...test
                  });
                }}
                data-testid="documents-data-form-document-list-edit-iconbutton"
              >
                <OwcIcon name="edit" type="legacy" />
              </IconButton>
              <IconButton
                type="button"
                onClick={() => {
                  formik.setFieldValue(
                    "qualificationDocuments.value",
                    formik.values.qualificationDocuments?.value?.filter((_, theIndex) => theIndex !== index)
                  );
                }}
                data-testid="documents-data-form-document-list-delete-iconbutton"
              >
                <OwcIcon name="delete" type="legacy" />
              </IconButton>
            </DocumentsActionRow>
          </DocumentsRow>
        ))}
      </DocumentsList>
    </>
  );
};
export default DocumentsDataForm;
