import * as yup from "yup";

const getCustomNullable = (schema, changeTo = "") => schema.nullable().transform((value) => value ?? changeTo);

export const ClusterInformationSchema = yup.object().shape({
  siteName: getCustomNullable(yup.string("Enter site").required("Site is required").typeError("Invalid type")),
  buildingLocation: yup
    .object({
      id: yup.string("Enter building").typeError("Invalid type").required("Building is required"),
      buildingNumber: yup.string("Enter building").typeError("Invalid type").required("Building is required")
    })
    .required("Building is required")
    .nullable(),
  floor: yup
    .object({
      id: yup.string("Enter floor").typeError("Invalid type").required("Floor is required"),
      floorCode: yup.string("Enter floor").typeError("Invalid type").required("Floor is required")
    })
    .required("Floor is required")
    .nullable(),
  room: yup
    .object({
      id: yup.string("Enter room").typeError("Invalid type").required("Room is required"),
      roomCode: yup.string("Enter room").typeError("Invalid type").required("Room is required")
    })
    .required("Room is required")
    .nullable(),
  equipmentAdministrator: yup.string("Enter test equipment admin").typeError("Invalid type").nullable(),
  responsiblePerson: yup
    .string("Enter responsible person")
    .required("Responsible person is required")
    .typeError("Invalid type")
    .nullable(),
  responsibleProxy: getCustomNullable(
    yup.string("Enter responsible proxy").required("Responsible proxy is required").typeError("Invalid type")
  ),
  belongingToGroup: yup.string("Enter group").required("Group is required").typeError("Invalid type").nullable(),
  equipmentCategory: getCustomNullable(
    yup.string("Enter category").typeError("Invalid type").required("Category is required")
  ),

  equipmentModel: getCustomNullable(yup.string("Enter model").typeError("Invalid type").required("Model is required")),
  variant: yup.string("Enter variant").typeError("Invalid type").nullable(),
  equipmentNickName: getCustomNullable(yup.string("Enter nick name").typeError("Invalid type").nullable()),
  cluster: getCustomNullable(yup.string("Enter cluster").typeError("Invalid type").nullable()),
  isBookable: yup.bool(),
  isVisualized: yup.bool(),
  qualificationDocuments: yup
    .object({
      value: yup.array(
        yup.object({
          name: yup.string("Enter name").typeError("Invalid type").nullable(),
          documentId: yup.string("Enter documentId").typeError("Invalid type").nullable()
        })
      )
    })
    .nullable(),
  qualificationStatus: yup
    .object({
      value: getCustomNullable(
        yup.string("Enter qualification status").typeError("Invalid type").required("Qualification status is required")
      )
    })
    .required("Qualification status is required")
    .nullable(),
  gxpRelevant: yup
    .object({
      value: getCustomNullable(yup.string("Enter GxP relevant").typeError("Invalid type")).required(
        "Gxp relevant is required"
      )
    })
    .required("Gxp relevant is required")
    .nullable(),
  dateOfNextMaintanance: yup.date().typeError("Invalid date").nullable(),
  trackPositionInCluster: yup.bool(),
  softwareVersion: yup.string("Enter software version").typeError("Invalid type").nullable(),
  leadingSoftwareVersionId: yup.string("please select leading software version").typeError("Invalid type").nullable(),
  tags: yup
    .array(
      yup
        .string("You can add/select max of 10 tags and each tag of 20 characters max")

        .max(20, "You can add/select max of 10 tags and each tag of 20 characters max")
        .required("You can add/select max of 10 tags and each tag of 20 characters max")
    )
    .max(10, "You can add/select max of 10 tags and each tag of 20 characters max")
    .nullable(),
  systemStatus: yup.string("Enter system status").typeError("Invalid type").nullable()
});
