import React from "react";
import { IR_FILE_UPLOAD_STATUS } from "../../constants";
import styled from "styled-components";

const StatusFieldDiv = styled.div`
  &.submittedStatus {
    color: var(--one-color-gray-600);
  }
  &.inprogressStatus {
    color: #ffc414;
  }
  &.completedStatus {
    color: var(--one-color-legacy-green-400);
  }
  &.failedStatus {
    color: var(--one-color-cobas-red-400);
  }
`;

const StatusField = ({ item }) => {
  let statusClass;
  let statusText;
  IR_FILE_UPLOAD_STATUS.forEach((value) => {
    if (item?.status === value?.statusKey) {
      statusClass = value?.statusClass;
      statusText = value?.statusText;
    }
  });
  return (
    <StatusFieldDiv
      style={{ marginLeft: "10px" }}
      data-testid="status-text-field"
      key={statusText}
      className={statusClass}
    >
      {statusText}
    </StatusFieldDiv>
  );
};

export default StatusField;
