import * as R from "ramda";
import { at } from "lodash/fp";
import styled from "styled-components";
import { format } from "date-fns";
import { DAYS, GXPemptyInstrumentsOptions, MONTHS } from "../../../constants";
import { find } from "lodash";
import { Tooltip } from "@mui/material";
import Tags from "../Tags";
import DATA_MODEL_TABLE from "../../constants/dataModelTable";

const RegularCell = styled.div`
  display: flex;
  align-items: center;
`;

export const emailToName = (email) => (typeof email === "string" ? email.split("@")[0]?.replace(/\./g, " ") : "");

export const getText = (text) => R.compose(R.toLower, R.trim)(text);

export const filterByQuery = (
  data,
  query,
  propFilter = [
    "serialNumber",
    "materialNumber",
    "room.value",
    "floor.value",
    "buildingLocation.value",
    "equipmentModel.value",
    "responsiblePerson"
  ],
  propSort = ["value"],
  reverse = false
) => {
  if (!query) R.sort(R.descend(R.path(propSort)))(data);
  const _query = getText(query);
  const valuesAt = at(propFilter);
  const result = R.compose(
    R.sort(R.descend(R.path(propSort))),
    R.filter(R.compose(R.contains(_query), getText, R.compose(R.join(""), valuesAt)))
  )(data);

  return reverse ? result.reverse() : result;
};
const IsBookableCell = ({ item }) => {
  return item.isBookable ? (
    <Tooltip title={"Yes"} placement="bottom-start">
      <RegularCell>Yes</RegularCell>
    </Tooltip>
  ) : (
    <Tooltip title={"No"} placement="bottom-start">
      <RegularCell>No</RegularCell>
    </Tooltip>
  );
};
const IsVisualizedCell = ({ item }) => {
  return item.isVisualized ? (
    <Tooltip title={"Shown"} placement="bottom-start">
      <RegularCell>Shown</RegularCell>
    </Tooltip>
  ) : (
    <Tooltip title={"Not shown"} placement="bottom-start">
      <RegularCell>Not shown</RegularCell>
    </Tooltip>
  );
};
export function rearangeDisplayDate(inputDate) {
  // expects Y-m-d
  var splitDate = inputDate.split("-");
  if (splitDate.count === 0) {
    return "-";
  }
  var year = splitDate[0];
  var month = splitDate[1];
  var day = splitDate[2];

  return day + "-" + month + "-" + year;
}

const DateFormatterNextMaintainance = ({ item }) => {
  return (
    <Tooltip
      title={item?.dateOfNextMaintanance ? rearangeDisplayDate(item?.dateOfNextMaintanance) : "-"}
      placement="bottom-start"
    >
      <span>{item?.dateOfNextMaintanance ? rearangeDisplayDate(item?.dateOfNextMaintanance) : "-"}</span>
    </Tooltip>
  );
};
const DateFormatterLastMaintanance = ({ item }) => {
  return (
    <Tooltip
      title={item?.dateOfLastMaintanance ? rearangeDisplayDate(item?.dateOfLastMaintanance) : "-"}
      placement="bottom-start"
    >
      <span>{item?.dateOfLastMaintanance ? rearangeDisplayDate(item?.dateOfLastMaintanance) : "-"}</span>
    </Tooltip>
  );
};
const DateFormatterPeriodicReview = ({ item }) => {
  return (
    <Tooltip
      title={item?.dateOfNextPeriodicReview ? rearangeDisplayDate(item?.dateOfNextPeriodicReview) : "-"}
      placement="bottom-start"
    >
      <span>{item?.dateOfNextPeriodicReview ? rearangeDisplayDate(item?.dateOfNextPeriodicReview) : "-"}</span>
    </Tooltip>
  );
};

const installedTests = ({ item }) => {
  return (
    <Tooltip title={item?.installedTests?.length > 0 ? item?.installedTests?.length : "-"} placement="bottom-start">
      <RegularCell>{item?.installedTests?.length > 0 ? item?.installedTests?.length : "-"}</RegularCell>
    </Tooltip>
  );
};
const qualificationDocuments = ({ item }) => {
  return (
    <Tooltip
      title={item?.qualificationDocuments?.value?.length > 0 ? item?.qualificationDocuments?.value?.length : "-"}
      placement="bottom-start"
    >
      <RegularCell>
        {item?.qualificationDocuments?.value?.length > 0 ? item?.qualificationDocuments?.value?.length : "-"}
      </RegularCell>
    </Tooltip>
  );
};

const tags = ({ item }) => {
  return <Tags chips={item?.tags}></Tags>;
};
export const getDynamicFields = (columns) => {
  let fields = {};
  for (const column of columns) {
    let x = {};
    x[column.key] = { text: column.val, sortable: column.sortStatus };
    if (column.key === "dateOfNextMaintanance") x[column.key].component = DateFormatterNextMaintainance;
    if (column.key === "dateOfLastMaintanance") x[column.key].component = DateFormatterLastMaintanance;
    if (column.key === "dateOfNextPeriodicReview") x[column.key].component = DateFormatterPeriodicReview;
    if (column.key === "isBookable") x[column.key].component = IsBookableCell;
    if (column.key === "isVisualized") x[column.key].component = IsVisualizedCell;
    if (column.key === "qualificationDocuments") x[column.key].component = qualificationDocuments;
    if (column.key === "installedTests") x[column.key].component = installedTests;
    if (column.key === DATA_MODEL_TABLE?.tags?.key) x[column.key].component = tags;
    Object.assign(fields, x);
  }
  return fields;
};

export const getStringCapitalized = (value) => {
  const lowerCaseStr = value?.toLowerCase();
  return lowerCaseStr.charAt(0).toUpperCase() + lowerCaseStr.slice(1);
};

export const valueOrEmpty = (value, itsADate = false, defaultValue = "") => {
  if (value === "null") {
    return "";
  } else if (value && itsADate) {
    return format(new Date(value), "dd-MMM-yyyy");
  } else if (value && !itsADate) {
    return value;
  } else {
    return defaultValue;
  }
};

export const jsonToCSV = (obj) => {
  let output = "";
  if (Array.isArray(obj) && obj.length > 0) {
    output = obj.map(function (item) {
      return item.value;
    });
    return output.join(",");
  }
  return output;
};

export const changeDateFormat = (inputDate, format = null) => {
  try {
    if (typeof inputDate === "string") {
      if (format === null) {
        const dateObj = new Date(inputDate).toUTCString();
        const splitDateFormat = dateObj?.split(" ");
        const currentDate = splitDateFormat[1] + "-" + splitDateFormat[2] + "-" + splitDateFormat[3];
        return new Date(currentDate);
      }
      if (format === "dd-MMM-yyyy" || format === "DD-MMM-YYYY") {
        const dateObj = new Date(inputDate).toUTCString();
        const splitDateFormat = dateObj?.split(" ");
        return splitDateFormat[1] + "-" + splitDateFormat[2] + "-" + splitDateFormat[3];
      }
      if (format === "mm/dd/yyyy h:mm:ss") {
        const dateObjString = new Date(inputDate).toUTCString();
        let dateArray = inputDate.split("T");
        dateArray = dateArray[0].split("-");
        const splitDateFormat = dateObjString?.split(" ");
        const currentDate = parseInt(dateArray[1]) + "/" + dateArray[2] + "/" + splitDateFormat[3];
        const timeVal = inputDate.split("T");
        const time = timeVal[1].split(":");
        let hours = parseInt(time[0]);
        const seconds = time[2].slice(0, 2);
        let mid = " AM";
        if (hours === 12) {
          mid = " PM";
        }
        if (hours > 12) {
          hours = hours % 12;
          mid = " PM";
          if (hours === 0) {
            mid = " AM";
          }
        }
        if (hours < 10) {
          hours = "0" + hours;
        }
        return currentDate + ", " + hours + ":" + time[1] + ":" + seconds + mid;
      }
      if (format === "h:mm") {
        let timeVal = inputDate.split("T");
        let time = timeVal[1].split(":");
        let hours = parseInt(time[0]);

        let mid = " am";
        if (hours === 12) {
          mid = " pm";
        }
        if (hours > 12) {
          hours = hours % 12;
          mid = " pm";
          if (hours === 0) {
            mid = " am";
          }
        }
        return hours + ":" + time[1] + mid;
      }
      if (format === "ISOToDate") {
        const timeVal = inputDate.split("T");
        const date = timeVal[0].split("-");
        const month = parseInt(date[1]) - 1;
        const time = timeVal[1].split(":");
        const hours = parseInt(time[0]);
        const dateObj = new Date(date[2] + "-" + MONTHS[month] + "-" + date[0]);
        dateObj.setTime(dateObj.getTime() + hours * 60 * 60 * 1000);
        return dateObj;
      }
    } else {
      if (format === "EEEE, dd-MMM-yyyy") {
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const weekDay = inputDate.getDay();
        const monthVal = inputDate.getMonth();
        const convertedDate = inputDate.toLocaleString("en-GB", {
          timeZone: localTimeZone
        });

        const splitDate = convertedDate?.split(",");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        const splitDateFormat = splitDate[0]?.split("/");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        let day = splitDateFormat[0];
        let year = splitDateFormat[2];

        return DAYS[weekDay] + ", " + day + "-" + MONTHS[monthVal] + "-" + year + " .";
      }
      if (format === "DD-MMM-YYYY" || format === "dd-MMM-yyyy") {
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const monthVal = inputDate.getMonth();
        const convertedDate = inputDate.toLocaleString("en-GB", {
          timeZone: localTimeZone
        });

        const splitDate = convertedDate?.split(",");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        const splitDateFormat = splitDate[0]?.split("/");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        let day = splitDateFormat[0];
        let year = splitDateFormat[2];

        return day + "-" + MONTHS[monthVal] + "-" + year;
      }
      if (format === "yyyy-MM-dd") {
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const convertedDate = inputDate.toLocaleString("en-GB", {
          timeZone: localTimeZone
        });

        const splitDate = convertedDate?.split(",");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        const splitDateFormat = splitDate[0]?.split("/");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        let day = splitDateFormat[0];
        var month = splitDateFormat[1];
        let year = splitDateFormat[2];

        return year + "-" + month + "-" + day;
      }
      if (format === "ISO") {
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const convertedDate = inputDate.toLocaleString("en-GB", {
          timeZone: localTimeZone
        });

        let isoVal = inputDate.toTimeString();
        isoVal = isoVal.split(" ");
        const splitDate = convertedDate?.split(",");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        const splitDateFormat = splitDate[0]?.split("/");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        let day = splitDateFormat[0];
        let month = splitDateFormat[1];
        let year = splitDateFormat[2];

        return year + "-" + month + "-" + day + "T" + isoVal[0] + ".000Z";
      }
    }
  } catch (error) {
    console.log(error);
  }
};

const envs = ["dev", "test", "staging", "stage", "hotfix"];

export const getEnv = () => {
  if (envs?.includes(process.env?.REACT_APP_ENV)) {
    if (process.env?.REACT_APP_ENV === "staging" || process.env?.REACT_APP_ENV === "stage") {
      return "stg";
    } else {
      return process.env?.REACT_APP_ENV;
    }
  } else {
    return null;
  }
};

export const getEnvLebel = (currentEnv) => {
  switch (currentEnv) {
    case "hotfix":
      return "Test Hotfix Environment";
    case "test":
      return "Test Environment";
    case "stg":
      return "Staging Environment";
    case "dev":
      return "Development Environment";
    default:
      return "";
  }
};

export const checkEnvReturnValue = (currentEnv, returnVal) => {
  if (currentEnv === "hotfix" || currentEnv === "test" || currentEnv === "stg" || currentEnv === "dev") {
    return returnVal[0];
  } else {
    return returnVal[1];
  }
};

export const getHashCommitValue = (url) => {
  const fragments = url.split("/");
  return fragments[fragments.length - 1].toUpperCase();
};

export const scrollAvailability = () => {
  const currLocation = window.location.href.split("/");
  return currLocation[currLocation.length - 1] === "info" ? "auto" : "hidden";
};

/**
 * Getting cluster popover informations .
 * @param {Object} clusterData - The object/cluster info is to generate formatted json.
 * @param {Object} clusterDetailsColumns - The object is a base object structure for cluster detail.
 * @param {Object} clusterSubEquipmentsObj - The object is a base object structure for cluster subequipment.
 * @param {Object} options - The object is for display different section display option for the popover.
 * @param {string} clusterDetailHeading - The string is for display cluster Detail Heading String.
 * @param {string} clusterSubEquipmentsHeading - The string is for display cluster SubEquipments Heading String.
 */
export const getClusterPopoverInfo = (
  clusterData,
  clusterDetailsColumns,
  clusterSubEquipmentsObj,
  options,
  clusterDetailHeading,
  clusterSubEquipmentsHeading
) => {
  const equipmentInfo = {
    equipmentDetail: {
      id: clusterData.id,
      clusterSubEquipmentsHeading: clusterSubEquipmentsHeading,
      detailHeading: clusterDetailHeading,
      options: options
    }
  };

  const clusterSubEquipmentsData = clusterData?.subEquipment.items.map((equipment, index) => {
    const subEquipmentRow = {};
    for (const [key] of Object.entries(clusterSubEquipmentsObj)) {
      if (key === "index") {
        subEquipmentRow[key] = index + 1;
      } else if (key === "qualificationStatus") {
        subEquipmentRow[key] =
          equipment[key] !== null && equipment[key] !== "null"
            ? equipment[key] !== "NA"
              ? equipment[key].charAt(0).toUpperCase() + equipment[key].slice(1).toLowerCase()
              : equipment[key]
            : "-";
      } else {
        subEquipmentRow[key] = equipment[key] !== null && equipment[key] !== "null" ? equipment[key] : "-";
      }
    }
    return subEquipmentRow;
  });

  equipmentInfo.equipmentDetail.clusterSubEquipments = [clusterSubEquipmentsObj, ...clusterSubEquipmentsData];
  equipmentInfo.equipmentDetail.detail = {
    ...clusterDetailsColumns
  };
  Object.keys(equipmentInfo.equipmentDetail.detail).forEach((key) => {
    if (clusterData.hasOwnProperty(key)) {
      equipmentInfo.equipmentDetail.detail[key].value =
        clusterData[key] !== null && clusterData[key] !== "null"
          ? key === DATA_MODEL_TABLE?.qualificationStatus?.key
            ? getEnumValue(DATA_MODEL_TABLE?.qualificationStatus?.key, clusterData[key])
            : clusterData[key]
          : "-";
    }
  });
  return equipmentInfo;
};

export const getEnumValue = (fieldKey, fieldValue) => {
  const enumArray = GXPemptyInstrumentsOptions[fieldKey] || [];
  const enumObj = find(enumArray, { key: fieldValue }) || null;

  return enumObj ? enumObj?.value : "-";
};

export const onlyUnique = (list) => {
  return [...new Set(list)];
};

export const getContainerHeight = (currentEnv, remainingHeight) => {
  return currentEnv
    ? window.innerHeight - (126 + remainingHeight) // envBanner + header + footer + pageHeader
    : window.innerHeight - (75 + remainingHeight); //  header + footer + pageHeader
};

export const validateSelection = (baseObj, findingData) => {
  const valData = find(baseObj, findingData);
  if (valData) return valData?.value || null;
};

export const isValidJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const valueOrEmptyString = (value) => (value ? value : "-");

export const concatenateText = (text, stringsArray, addSpaces = true) => {
  const arrayText = stringsArray.join(addSpaces ? " " : "");
  return `${text}${addSpaces ? " " : ""}${arrayText}`;
};

export const defaultInputStyle = { width: 313.5, height: 48 };
