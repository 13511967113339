import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";


export const FullScreenCenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  width: 100vw;
  height: 100vh;, ...syncFields
`;

export const LogoutRedirect = () => {
  useEffect(() => {
    if (localStorage.getItem('reAuth')) {
      Auth.federatedSignIn({
        provider: process.env.REACT_APP_COGNITO_PROVIDER_ID
      })
    } else {
      window.location = window.location.origin;
    }
  }, []);
  return <FullScreenCenteredContainer>
    <CircularProgress data-testid="loading" size={ 80 } />
  </FullScreenCenteredContainer>;
}

export default LogoutRedirect;
