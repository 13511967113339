import React, { useEffect } from "react";
import styled from "styled-components";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MasterDataContainer from "./MasterDataContainer";
import MasterDataWrapper from "./MasterDataWrapper";

const MainContainer = styled.div`
  padding: 0 20px 0 20px;
  margin-top: 0.25rem;
  width: 100%;
  box-sizing: border-box;
`;

const MasterDataMainPage = () => {
  // Track page view
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView({
      documentTitle: "masterData",
      href: `${window.location?.origin}/masterData`
    });
    // eslint-disable-next-line
  }, []);

  return (
    <MainContainer>
      <MasterDataWrapper>
        <MasterDataContainer />
      </MasterDataWrapper>
    </MainContainer>
  );
};

export default MasterDataMainPage;
