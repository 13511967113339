import React, { useState, useEffect } from "react";
import { ExportCsvContext } from "./context";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const ExportCsvWapper = ({ children }) => {
  const [selectedItem, setSelectedItem] = useState({});
  // Track page view
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView({
      documentTitle: "Export csv",
      href: `${window.location?.origin}/export-csv`
    });
    // eslint-disable-next-line
  }, []);

  return (
    <ExportCsvContext.Provider
      value={{
        selectedItem,
        setSelectedItem
      }}
    >
      {children}
    </ExportCsvContext.Provider>
  );
};

export default ExportCsvWapper;
