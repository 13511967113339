import { OwcIcon } from "@one/react";
import { ToastContainer, toast } from "react-toastify";
import styled, { css } from "styled-components";

export const StyledToastContainer = styled(ToastContainer).attrs({
  className: "toast-container",
  toastClassName: "toast",
  bodyClassName: "body",
  progressClassName: "progress"
})`
  height: 72px;
  width: 400px;
  border-radius: 4px;

  .toast {
    top: 56px;
    padding: 0;
    box-sizing: border-box;
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.34902);
  }

  button[aria-label="close"] {
    display: none;
  }

  .body {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .progress {
    background: transparent;
  }
`;

const snackbarColors = (props) => {
  switch (props.type) {
    case "success": {
      return css`
        color: var(--one-color-gray-800);
        background: var(--one-color-cobas-white);
        border: 2px solid var(--one-color-background-positive-1);
        & > .icon {
          background: var(--one-color-background-positive-1);
        }
        & > .icon > .snackbarIconSizeColor {
          font-size: 45px;
          color: var(--one-color-cobas-white);
        }
      `;
    }

    case "warning": {
      return css`
        color: var(--one-color-gray-800);
        background: var(--one-color-cobas-white);
        border: 2px thin var(--one-color-red-a-300);
        & > .icon {
          background: var(--one-color-red-a-300);
        }
        & > .icon > .snackbarIconSizeColor {
          font-size: 45px;
          color: var(--one-color-cobas-white);
        }
      `;
    }
    default: {
      return css``;
    }
  }
};

export const CustomSnackbarStyled = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  box-sizing: border-box;
  position: relative;
  padding: 0;
  height: 100%;
  min-height: 72px;
  color: black;
  background: white;
  border: 2px solid black;
  & > .text,
  & > .icon {
    word-wrap: break-all;
    box-sizing: border-box;
    transform: scale(1.03);
    & > i {
      font-size: 38px;
    }
  }
  & > .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 72px;
    height: auto;
    background: black;
    color: white;
  }
  & > .text {
    font-family: "Roboto", sans-serif;
    flex: 1 1 auto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    padding: 16px 24px 15px 19px;
    display: flex;
    flex-direction: flex-start;
    align-items: center;
  }
  ${snackbarColors}
`;

export const CustomSnackbar = ({ type, icon, text, appName }) => (
  <CustomSnackbarStyled type={type} data-testid="custom-snackbar">
    <div className="icon" data-testid={`customSnackbar-icon`}>
      <OwcIcon className="snackbarIconSizeColor" name={icon} type="legacy" />
    </div>
    <div className="text" data-testid={`customSnackbar-text`}>
      <span>
        <strong>{appName}</strong> {` ${text}`}
      </span>
    </div>
  </CustomSnackbarStyled>
);

export const Snackbar = ({ icon = "alarm", text = "", appName = "", type = "warning" }) =>
  toast(<CustomSnackbar {...{ icon, text, appName, type }} />);

export default Snackbar;
