import RefreshIcon from "./RefreshIcon";
import StatusField from "./StatusField";
import ModuleField from "./ModuleField";
import ErrorField from "./ErrorField";
import DateAndTimeField from "./DateAndTimeField";
import { UploadedByCell } from "../../components/shared/CustomCell";

export const StatusLogTableMeta = {
  rowId: ["s3VersionId"],
  tableProps: {
    style: {
      maxBodyHeight: "60vh"
    }
  },
  fields: {
    targetModule: {
      text: "Type of import",
      component: ModuleField,
      key: "targetModule",
      headProps: {},
      cellProps: {}
    },
    userName: {
      text: "Imported by",
      component: UploadedByCell,
      headProps: {},
      cellProps: {}
    },
    uploadDate: {
      text: "Date and Time (local time)",
      component: DateAndTimeField,
      key: "uploadDate",
      sortable: true,
      headProps: {},
      cellProps: {}
    },
    errorFileName: {
      text: "Error",
      component: ErrorField,
      key: "errorFileName",
      headProps: {},
      cellProps: {}
    },
    status: {
      text: "Status",
      component: StatusField,
      key: "status",
      headProps: {
        component: RefreshIcon
      },
      cellProps: {}
    }
  }
};

export default StatusLogTableMeta;
