import React, { useState } from "react";
import { StepperContext } from "../Context";
import { getSteps } from "./../../../constants";

const PaginationWrapper = ({ children, isEditMode }) => {
  const [stepsArray, setStepsArray] = useState(getSteps(isEditMode));
  const [secondaryIdMappingFields, setSecondaryIdMappingFields] = useState([]);
  const [instrumentPrevData, setInstrumentPrevData] = useState(null);
  const [expandID, setExpandID] = useState(0);
  return (
    <StepperContext.Provider
      value={{
        stepsArray,
        setStepsArray,
        secondaryIdMappingFields,
        setSecondaryIdMappingFields,
        instrumentPrevData,
        setInstrumentPrevData,
        expandID,
        setExpandID
      }}
    >
      {children}
    </StepperContext.Provider>
  );
};

export default PaginationWrapper;
