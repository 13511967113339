import React from "react";
import { Auth } from "aws-amplify";
import IconForApp from "../icons/IconForApp";
import { OwcButton, OwcDoubleGlobalArea, OwcIcon, OwcIconButton, OwcTypography } from "@one/react";
import styles from "./AppBarNotAuth.module.scss";
import { Link } from "@mui/material";

const CustomLink = ({ children, disabled, currentUrl, ...props }) => (
  <Link
    onClick={() => {
      if (disabled !== true) {
        if (currentUrl.includes("tableau-equipments")) {
          localStorage.setItem("redirectToTableau", true);
        }
        if (process.env.REACT_APP_COGNITO_LOGIN_ENABLED?.trim()?.toUpperCase() !== "TRUE") {
          Auth.federatedSignIn({
            provider: process.env.REACT_APP_COGNITO_PROVIDER_ID
          });
        } else {
          Auth.federatedSignIn();
        }
      }
    }}
    style={{
      textDecoration: "none"
    }}
    {...props}
  >
    {children}
  </Link>
);

const AppBarNotAuth = ({ disabled = false }) => {
  const currentUrl = window.location.href;
  const infoInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <OwcDoubleGlobalArea className={styles.appBarStyled} data-testid="app-bar-not-auth-main-page">
      <div slot="primary" style={{ justifyContent: "space-between" }}>
        <div
          style={{
            paddingLeft: "16px",
            display: "flex",
            alignItems: "center"
          }}
        >
          <IconForApp />
          <OwcTypography variant="subtitle1">Equipment Repository</OwcTypography>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CustomLink disabled={disabled} data-testid="link-to-sign-in-on-button" currentUrl={currentUrl}>
            <OwcButton data-testid="sign-in-button" disabled={disabled} variant="primary" style={{ marginRight: 12 }}>
              Sign in
            </OwcButton>
          </CustomLink>
          <OwcIconButton
            flat
            data-testid="help-button"
            onClick={() => infoInNewTab(process.env.REACT_APP_INFO_URL)}
            icon="circle_info"
            style={{ marginRight: "26px" }}
          />
          <OwcIcon
            type="legacy"
            style={{
              margin: "0 8px",
              color: "var(--one-color-interaction-default-brand-1)",
              width: "48px"
            }}
            name="roche_logo"
          />
        </div>
      </div>
    </OwcDoubleGlobalArea>
  );
};

export default AppBarNotAuth;
