import React from "react";
import { ThemeProvider } from "styled-components";

export const mainTheme = {
  table: {
    borderColor: "var(--one-color-gray-300)",
    color: "var(--one-color-gray-800)",
    backgroundColor: "var(--one-color-cobas-white)",
    header: {
      backgroundColor: "var(--one-color-cobas-gray-150)"
    },
    cell: {
      backgroundColor: "var(--one-color-cobas-white)"
    }
  },
  notification: {
    warning: {
      color: "var(--one-color-gray-800)",
      backgroundColor: "var(--one-color-cobas-white)",
      border: "2px solid var(--one-color-cobas-red-400)",
      backgroundIconColor: "var(--one-color-cobas-red-400)"
    },
    success: {
      color: "var(--one-color-gray-800)",
      backgroundColor: "var(--one-color-cobas-white)",
      border: "2px solid var(--one-color-legacy-green-400)",
      backgroundIconColor: "var(--one-color-legacy-green-400)"
    },
    alert: {
      color: "var(--one-color-gray-800)",
      backgroundColor: "var(--one-color-cobas-white)",
      border: "2px solid #FFC414",
      backgroundIconColor: "#FFC414",
      iconColor: "#000000"
    }
  },
  snackbar: {
    warning: {
      color: "var(--one-color-gray-800)",
      backgroundColor: "var(--one-color-cobas-white)",
      border: "2px solid var(--one-color-cobas-red-400)",
      backgroundIconColor: "var(--one-color-cobas-red-400)"
    },
    success: {
      color: "var(--one-color-gray-800)",
      backgroundColor: "var(--one-color-cobas-white)",
      border: "2px solid var(--one-color-legacy-green-400)",
      backgroundIconColor: "var(--one-color-legacy-green-400)"
    },
    alert: {
      color: "var(--one-color-gray-800)",
      backgroundColor: "var(--one-color-cobas-white)",
      border: "2px solid #FFC414",
      backgroundIconColor: "#FFC414"
    }
  }
};

const StyledThemeProvider = ({ children }) => {
  return <ThemeProvider theme={mainTheme}>{children}</ThemeProvider>;
};

export default StyledThemeProvider;
