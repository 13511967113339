import React from "react";
import { find } from "lodash";
import { MASTER_DATA_LIST } from "../../constants";

const ModuleField = ({ item }) => {
  if (item?.targetModule === "EQUIPMENT") {
    return <div data-testid="module-text-field">{`PML Import`}</div>;
  } else {
    const moduleField = find(MASTER_DATA_LIST, {
      targetModule: item?.targetModule
    });
    return <div data-testid="module-text-field">{moduleField?.listName}</div>;
  }
};

export default ModuleField;
