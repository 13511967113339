import React, { useState } from "react";
import { FormComponents } from "../instruments/addEditEquipment/AddEquipmentStyle";
import { useFormikContext } from "formik";
import { commonPropsForInputsWithoutValue } from "../instruments/helpers";
import { connect } from "react-redux";
import DATA_MODEL_TABLE from "../../utils/constants/dataModelTable";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { OwcInput, OwcTypography, OwcIcon, OwcSlideToggle } from "@one/react";
import { compose, withApollo } from "react-apollo";
import { GridAutocomplete } from "@digitallab/grid-common-components";

const MasterDataAttributes = ({ instrumentDetail, client, setIsNextDisabled, isEditMode = false }) => {
  const formik = useFormikContext();
  const listOptions = useSelector((state) => state.instruments);
  const [loading, setLoading] = useState(true);

  if (!isEditMode) {
    setIsNextDisabled(
      Object.keys(formik.errors).length > 0 ||
        !formik.isValid ||
        !formik.values?.equipmentCategory ||
        !formik.values?.manufacturer ||
        !formik.values?.equipmentModel
    );
  }

  useEffect(() => {
    if (isEditMode && formik.values?.equipmentCategory) {
      setLoading(() => false);
      formik.setFieldTouched("equipmentCategory");
      formik.setFieldTouched("manufacturer");
      formik.setFieldTouched("equipmentModel");
    }
    if (!isEditMode) {
      setLoading(() => false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values]);

  return (
    <>
      {!isEditMode && (
        <OwcTypography variant="subtitle2" style={{ padding: "0px 20px 20px 0px" }}>
          Basic attributes
        </OwcTypography>
      )}
      {isEditMode && (
        <div style={{ display: "flex" }}>
          <OwcTypography variant="subtitle1" style={{ marginRight: "36px" }} data-testid={`doc-data-isbookable`}>
            <OwcSlideToggle
              data-testid={`toggle-field-booking`}
              checked={formik.values?.isActive}
              onInputChange={() => formik.setFieldValue("isActive", !formik.values?.isActive, true)}
              labelPlacement="right"
            >
              Activate the equipment master data
            </OwcSlideToggle>
          </OwcTypography>
        </div>
      )}
      <FormComponents>
        <>
          {!loading && (
            <>
              <div className="owcInputBox">
                <GridAutocomplete
                  dataTestid="equipmentCategory-select"
                  size="small"
                  style={{ width: 254, height: 48 }}
                  value={formik.values?.equipmentCategory || null}
                  optionsList={listOptions?.categoryList}
                  label={DATA_MODEL_TABLE.equipmentCategory.value + ` *`}
                  disabled={!formik.values?.isActive}
                  onAutoCompleteChange={(selectedValue) => {
                    if (selectedValue !== null) {
                      formik.setFieldValue("equipmentCategory", selectedValue, true);
                    } else {
                      formik.setFieldValue("equipmentCategory", "", true);
                    }
                  }}
                  addNewValue={true}
                />
              </div>
              <div className="owcInputBox">
                <GridAutocomplete
                  dataTestid="manufacturer-select"
                  size="small"
                  style={{ width: 254, height: 48 }}
                  value={formik.values?.manufacturer || null}
                  optionsList={listOptions?.manufacturerList}
                  label={DATA_MODEL_TABLE.manufacturer.value + ` *`}
                  disabled={!formik.values?.isActive}
                  onAutoCompleteChange={(selectedValue) => {
                    if (selectedValue !== null) {
                      formik.setFieldValue("manufacturer", selectedValue, true);
                    } else {
                      formik.setFieldValue("manufacturer", "", true);
                    }
                  }}
                  addNewValue={true}
                />
              </div>
              <div className="owcInputBox">
                <GridAutocomplete
                  dataTestid="equipmentModel-select"
                  size="small"
                  style={{ width: 254, height: 48 }}
                  value={formik.values?.equipmentModel || null}
                  optionsList={listOptions?.modelList}
                  label={DATA_MODEL_TABLE.equipmentModel.value + ` *`}
                  disabled={!formik.values?.isActive}
                  onAutoCompleteChange={(selectedValue) => {
                    if (selectedValue !== null) {
                      formik.setFieldValue("equipmentModel", selectedValue, true);
                    } else {
                      formik.setFieldValue("equipmentModel", "", true);
                    }
                  }}
                  addNewValue={true}
                />
              </div>
            </>
          )}
          <div className="owcInputBox">
            <OwcInput
              data-testid={`text-field-variant`}
              label="Variant"
              name="variant"
              style={{ width: 254, height: 48 }}
              value={formik.values?.variant}
              onInputChange={formik.handleChange}
              onBlur={() => {
                formik.setFieldValue("variant",formik.values.variant.trim());
                return formik.handleBlur;
              }}
              disabled={!formik.values?.isActive}
              {...commonPropsForInputsWithoutValue({
                formik,
                key: "variant",
                dataTestIdKey: "variant",
                dataTestIdForFormKey: "text-field"
              })}
            >
              {formik.values?.variant !== "" && formik.touched?.variant === true && !isEditMode && (
                <OwcIcon
                  name="circle_clear_filled"
                  slot="suffix"
                  type="legacy"
                  onClick={() => formik.setFieldValue("variant", "", true)}
                />
              )}
            </OwcInput>
          </div>
          <div className="owcInputBox">
            <OwcInput
              data-testid={`text-field-displayImage`}
              label="Display image"
              name="displayImage"
              style={{ width: 254, height: 48 }}
              value={formik.values?.displayImage}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={!formik.values?.isActive}
              {...commonPropsForInputsWithoutValue({
                formik,
                key: "displayImage",
                dataTestIdKey: "displayImage",
                dataTestIdForFormKey: "text-field"
              })}
            >
              {formik.values?.displayImage !== "" &&
                formik.touched?.displayImage === true &&
                formik.values?.isActive && (
                  <OwcIcon
                    name="circle_clear_filled"
                    slot="suffix"
                    type="legacy"
                    onClick={() => formik.setFieldValue("displayImage", "", true)}
                  />
                )}
            </OwcInput>
          </div>
        </>
      </FormComponents>
    </>
  );
};

const mapStateToProps = (state) => ({
  instrumentDetail: state.instruments?.instrumentDetail
});

export default compose(connect(mapStateToProps), withApollo)(MasterDataAttributes);
