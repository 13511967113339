import { combineReducers } from "redux";
import instrumentsReducer from "../features/instruments/redux/reducer";
import clustersReducer from "../features/instruments/cluster/redux/reducer";
import userReducer from "../features/user/redux/reducer";

const rootReducer = combineReducers({
  user: userReducer,
  instruments: instrumentsReducer,
  clusters: clustersReducer
});

export default rootReducer;
