const ACCEPTABLE_SITES = [
  'Ludwigsburg',
  'Mannheim',
  'Penzberg',
]

export function isNewData4eye({belongingToGroup, siteName}) {
    return (belongingToGroup?.startsWith('DSR') &&
        ACCEPTABLE_SITES.includes(siteName));
}

export function isData4eye({belongingToGroup, siteName, gxpRelevant}) {
    return (belongingToGroup?.startsWith('DSR') &&
        ACCEPTABLE_SITES.includes(siteName) &&
        (gxpRelevant?.value ?? gxpRelevant)?.toLowerCase?.() === 'yes');
}

export function isId4eye(id) {
    return id?.startsWith?.("4epf_");
}
