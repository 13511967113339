import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import NotFoundIcon from "../icons/NotFoundIcon";
import { OwcButton } from "@one/react";

export const ErrorScreenStyled = styled.div`
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 96px);
  transform: translateY(-40px);
  color: var(--one-color-background-brand-1);
  & > h2 {
    font-size: 34px;
    font-weight: 500;
    text-align: center;
    line-height: 40px;
    margin-bottom: 12px;
  }
  & > h5 {
    font-size: 16px;
    font-weight: 500;
    width: 377px;
    white-space: wrap;
    text-overflow: ellipsis;
    text-align: center;
    line-height: 22px;
    margin-top: 0;
    margin-bottom: 24px;
    color: #333;
  }
  & > h6 {
    font-size: 16px;
    font-weight: 500;
    white-space: wrap;
    text-align: center;
    line-height: 22px;
    margin-top: 0;
    margin-bottom: 24px;
    color: #333;
  }
`;

const NotFoundScreen = ({
  text = "Sorry, we can't find page you are looking for.",
  title = "Page not found",
  redirectPath = "/"
}) => {
  const history = useHistory();
  return (
    <ErrorScreenStyled data-testid="main-page-not-authenticated-with-error-not-found">
      <NotFoundIcon />
      <h2>{title}</h2>
      <h5>{text}</h5>
      <OwcButton
        data-testid="main-page-action-button-not-found"
        variant="primary"
        onClick={() => {
          history?.replace(redirectPath);
        }}
      >
        Go to home
      </OwcButton>
    </ErrorScreenStyled>
  );
};

export default NotFoundScreen;
