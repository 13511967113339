import { useState, useCallback } from "react";
import { SelectItemsContext } from "./contexts";

export const SelectItemsWrapper = ({ children, initialSelected = [], compareItem = (x, y) => x?.id === y?.id }) => {
  const [selected, setSelected] = useState(initialSelected);

  const onSelect = useCallback((item) => {
    setSelected((prev) =>
      prev.some((i) => compareItem(i, item)) ? prev.filter((i) => !compareItem(i, item)) : [...prev, item]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSelected = useCallback(
    (item) => selected.some((i) => compareItem(i, item)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected]
  );

  const clearSelections = useCallback(() => {
    setSelected([]);
  }, []);

  const updateSelectedItems = useCallback((items) => {
    setSelected([...items]);
  }, []);

  return (
    <SelectItemsContext.Provider
      value={{
        selectedCount: selected.length,
        selected,
        onSelect,
        isSelected,
        clearSelections,
        updateSelectedItems,
        setSelected
      }}
    >
      {children}
    </SelectItemsContext.Provider>
  );
};

export default SelectItemsWrapper;
