import {
  LIST_IR_CATEGORY_MAPPINGS_MASTER,
  LIST_IR_CLUSTER_CATEGORY_MAPPINGS_MASTER,
  LIST_IR_FILTER_LOOKUPS_MASTER,
  LIST_IR_GROUP_MASTER,
  LIST_IR_LINKED_INSTANCE_MASTER,
  LIST_IR_SOP_MASTER
} from "../../gql/landingapi/queries";
import { getAllData } from "../../utils/helpers/fetching";
import { uniqList } from "../importFile/utils/helpers";

export const equipmentCategoryMappings = async (client) => {
  return await getAllData({
    client,
    query: LIST_IR_CATEGORY_MAPPINGS_MASTER,
    dataPath: ["data", "listIRCategoryMappings"],
    variables: { limit: 1000 },
    drillData: true
  });
};

export const clusterCategoryMappings = async (client) => {
  return await getAllData({
    client,
    query: LIST_IR_CLUSTER_CATEGORY_MAPPINGS_MASTER,
    dataPath: ["data", "listIRClusterCategoryMappings"],
    variables: { limit: 1000 },
    drillData: true
  });
};

export const getGroupMaster = async (client) => {
  const groups = await getAllData({
    client,
    query: LIST_IR_GROUP_MASTER,
    dataPath: ["data", "listIRGroupMasters"],
    variables: { limit: 1000 },
    drillData: true
  });
  return { items: uniqList(groups.items, "value") || [] };
};

export const getResponsibleMaster = async (client) => {
  const responsiblePresons = await getAllData({
    client,
    query: LIST_IR_FILTER_LOOKUPS_MASTER,
    dataPath: ["data", "listIRFilterLookups"],
    variables: { limit: 1000, field: "responsiblePerson" },
    drillData: true
  });
  return { items: uniqList(responsiblePresons.items, "filterValue") || [] };
};

export const getTagsMaster = async (client) => {
  const tags = await getAllData({
    client,
    query: LIST_IR_FILTER_LOOKUPS_MASTER,
    dataPath: ["data", "listIRFilterLookups"],
    variables: { limit: 1000, field: "tags" },
    drillData: true
  });
  return { items: uniqList(tags.items, "filterValue") || [] };
};

export const getEquipmentAdministratorMaster = async (client) => {
  const equipmentAdministrators = await getAllData({
    client,
    query: LIST_IR_FILTER_LOOKUPS_MASTER,
    dataPath: ["data", "listIRFilterLookups"],
    variables: { limit: 1000, field: "equipmentAdministrator" },
    drillData: true
  });
  return {
    items: uniqList(equipmentAdministrators?.items, "filterValue") || []
  };
};

export const getLinkedInstance = async (client) => {
  return await getAllData({
    client,
    query: LIST_IR_LINKED_INSTANCE_MASTER,
    dataPath: ["data", "listSupportedLinkedInstances"],
    variables: { limit: 1000 },
    drillData: true
  });
};

export const getSops = async (client) => {
  return await getAllData({
    client,
    query: LIST_IR_SOP_MASTER,
    dataPath: ["data", "listIRSOPMasters"],
    variables: { limit: 1000 },
    drillData: true
  });
};
