import React, { useContext, useState, useEffect, useCallback } from "react";
import { useFormikContext } from "formik";
import { commonPropsForInputsWithoutValue } from "../helpers";
import { connect } from "react-redux";
import { compose, withApollo } from "react-apollo";
import { updateInstrumentDetail as updateInstrumentDetailAction } from "../redux/actions";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";
import { StepperContext } from "../Context";
import { useSelector } from "react-redux";
import { uniqList } from "../../importFile/utils/helpers";
import { OwcInput, OwcIcon } from "@one/react";
import CommonOwcEditableDropDown from "../cluster/steps/CommonOwcEditableDropDown";
import { SOP_MANDATORY_CLP } from "../../../constants";
import { defaultInputStyle } from "../../../utils/helpers/text";

const LinkedInstanceStep = ({
  isEditMode,
  updateInstrumentDetail,
  instrumentDetail,
  linkedInstanceList,
  isLinkedInstance: disableSecondayIdFields
}) => {
  const formik = useFormikContext();
  const { secondaryIdMappingFields, setSecondaryIdMappingFields } = useContext(StepperContext);
  const listOptions = useSelector((state) => state.instruments);
  const categoryMappingList = listOptions.categoryMappingMasterList;
  const [modelList, setModelList] = useState(listOptions.modelList);
  const [selectedLinkedInstance, setSelectedLinkedInstance] = useState(null);
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);

  const onLinkedInstanceSelected = useCallback(
    async (linkedInstance) => {
      formik.resetForm();
      const selectLinkedInstance = linkedInstanceList.find(
        (item) => item.linkedInstance === linkedInstance?.linkedInstance
      );
      const selectedSecondaryIdMapping = selectLinkedInstance?.secondaryIdMapping;
      formik.setValues({
        ...formik.values,
        linkedInstance: linkedInstance
      });
      updateInstrumentDetail({
        ...instrumentDetail,
        secondaryIDMapping: selectedSecondaryIdMapping
      });
      setSecondaryIdMappingFields(selectedSecondaryIdMapping?.split("; "));
      setSelectedLinkedInstance(() => linkedInstance?.linkedInstanceDisplay);
    },
    [formik, instrumentDetail, linkedInstanceList, setSecondaryIdMappingFields, updateInstrumentDetail]
  );

  const getDropDownList = (value, selectedField, dependentField) => {
    const filteredList = categoryMappingList.filter((item) => item[selectedField] === value);

    return uniqList(filteredList.map((x) => x[dependentField])).sort();
  };

  useEffect(() => {
    if (formik?.values?.linkedInstance) {
      setSelectedLinkedInstance(() => `${formik?.values?.linkedInstance?.linkedInstanceDisplay}`);
      setSelectedManufacturer(() => `${formik?.values?.manufacturer}`);
      setSelectedModel(() => `${formik?.values?.equipmentModel}`);
    }
    if (isEditMode) {
      if (formik?.values?.linkedInstance?.linkedInstance === "None") {
        const tempMList = getDropDownList(
          formik?.values?.manufacturer,
          DATA_MODEL_TABLE?.manufacturer?.key,
          DATA_MODEL_TABLE?.equipmentModel?.key
        );
        setModelList(() => tempMList);
      }
    }
    if (!isEditMode && !formik?.values?.linkedInstance) {
      setSelectedLinkedInstance(() => `${formik?.values?.linkedInstance?.linkedInstanceDisplay}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.linkedInstance]);

  const manufacturerChange = (value) => {
    if (value !== selectedManufacturer) {
      const tempMList = getDropDownList(
        value,
        DATA_MODEL_TABLE?.manufacturer?.key,
        DATA_MODEL_TABLE?.equipmentModel?.key
      );
      const modelValue = tempMList?.length === 1 ? tempMList[0] : "";
      formik.setValues({
        ...formik.values,
        manufacturer: value,
        equipmentModel: modelValue
      });
      setSelectedModel(() => modelValue);
      setModelList(() => tempMList);
      setSelectedManufacturer(() => value);
    }
    if (!value) {
      setModelList(() => []);
      setSelectedModel(() => null);
    }
  };
  const modelChange = (value) => {
    if (value !== selectedModel) {
      formik.setValues({
        ...formik.values,
        equipmentModel: value
      });
      setSelectedModel(() => value);
    }
  };
  return (
    <>
      <CommonOwcEditableDropDown
        key="linkedInstanceDisplay"
        labelValue={false}
        label={DATA_MODEL_TABLE.linkedInstance.value + ` *`}
        defaultLabel={DATA_MODEL_TABLE.linkedInstance.value + ` *`}
        propValue="linkedInstanceDisplay"
        style={defaultInputStyle}
        list={linkedInstanceList}
        selected={selectedLinkedInstance}
        onChange={(selectedValue) => {
          const selVal = linkedInstanceList?.find((value, index) => index === selectedValue);
          onLinkedInstanceSelected(selVal ?? null);
        }}
        required={true}
        disabled={disableSecondayIdFields}
        helperText={formik.errors[DATA_MODEL_TABLE.linkedInstance.key]}
      />
      {secondaryIdMappingFields?.map((field) => {
        if ([DATA_MODEL_TABLE?.manufacturer?.key, DATA_MODEL_TABLE?.equipmentModel?.key].includes(field)) {
          const isManufacturer = field === DATA_MODEL_TABLE?.manufacturer?.key;
          return (
            <CommonOwcEditableDropDown
              key={field}
              labelValue={false}
              label={DATA_MODEL_TABLE[field].value + ` *`}
              defaultLabel={DATA_MODEL_TABLE[field].value + ` *`}
              style={defaultInputStyle}
              list={isManufacturer ? listOptions.manufacturerList : modelList}
              selected={isManufacturer ? selectedManufacturer : selectedModel}
              onChange={(selectedValue) =>
                isManufacturer
                  ? manufacturerChange(
                      listOptions.manufacturerList?.find((value, index) => index === selectedValue) ?? null
                    )
                  : modelChange(modelList?.find((value, index) => index === selectedValue) ?? null)
              }
              required={true}
              disabled={disableSecondayIdFields}
              helperText={formik.errors[DATA_MODEL_TABLE[field].key]}
            />
          );
        }
        return (
          <div className="owcInputBox">
            <OwcInput
              data-testid={`text-field-${field}`}
              variant="filled"
              style={defaultInputStyle}
              label={`${DATA_MODEL_TABLE[field].value} *`}
              name={field}
              id={field}
              required
              disabled={instrumentDetail?.linkedInstanceRef?.linkedInstance !== "None" && disableSecondayIdFields}
              value={formik.values?.[field]?.trim().split(/\s+/).join(" ")}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              {...commonPropsForInputsWithoutValue({
                formik,
                key: field,
                dataTestIdKey: field,
                dataTestIdForFormKey: "text-field"
              })}
            >
              {formik.errors[field] && formik.touched[field] === true && (
                <>
                  <span slot="error-text">{formik.errors[field]}</span>
                </>
              )}
              {formik.values[field] !== "" && formik.touched[field] === true && !disableSecondayIdFields && (
                <OwcIcon
                  name="circle_clear_filled"
                  slot="suffix"
                  type="legacy"
                  onClick={() => formik.setFieldValue([field], "", true)}
                />
              )}
              {field && field === DATA_MODEL_TABLE.serialNumber.key && (
                <>
                  <span slot="assistive-text">{SOP_MANDATORY_CLP}</span>
                </>
              )}
            </OwcInput>
          </div>
        );
      })}
    </>
  );
};

const mapStateToProps = (state) => ({
  instrumentDetail: state.instruments?.instrumentDetail,
  linkedInstanceList: state.instruments?.linkedInstanceList
});

export default compose(
  connect(mapStateToProps, {
    updateInstrumentDetail: updateInstrumentDetailAction
  }),
  withApollo
)(LinkedInstanceStep);
