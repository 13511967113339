import React, { Fragment } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { GridAutocomplete } from "@digitallab/grid-common-components";
import { find } from "lodash";
import { OwcTextarea, OwcTypography } from "@one/react";
import { OTHER_REASON } from "../../constants";

const IdentificationForm = styled.div`
  & > .selectBoxLarge {
    margin: 0 0 25px 0;
  }
`;

const SignInMessageContainer = styled.div`
  min-height: 100px;
  background: var(--one-color-cobas-white);
  box-sizing: border-box;
  margin: 10px 0;
  box-shadow: inset 0 0 0 0.0625rem var(--one-color-interaction-default-neutral-2);
  background-color: var(--one-color-interaction-default-base-1);
  border-radius: 0.25rem;
`;

const SignInMessageDiv = styled.div`
  padding: 20px;
`;

const SignInMessageDivBody = styled.div`
  padding: 0 20px 20px 20px;
`;

export const ReviewReasonDialogContent = ({
  isApproveAction,
  editReason,
  setEditReason,
  editComment,
  setEditComment
}) => {
  const listOptions = useSelector((state) => state.instruments);
  const editCommentChange = (e) => setEditComment(e.target.value);
  return (
    <Fragment>
      <IdentificationForm>
        <GridAutocomplete
          dataTestid="action-data"
          className="selectBoxLarge"
          size="meduim"
          optionsList={ listOptions?.reasonList }
          propValue="configText"
          propLabel="configText"
          label="Select reason *"
          value={ find(listOptions?.reasonList, {
            configText: editReason
          }) }
          key="editReason"
          disableClearable
          onAutoCompleteChange={ (selectedValue) => {
            if (selectedValue !== null) {
              setEditReason(selectedValue?.configText?.trim());
            }
          } }
        />

        <OwcTextarea
          data-testid={ `textarea-field-edit-comment` }
          label={ editReason === OTHER_REASON ? "Add comment *" : "Add comment" }
          name="editComment"
          validation-mode="realtime"
          value={ editComment }
          error={ !editComment }
          required={ editReason === OTHER_REASON }
          onInputReset={ () => setEditComment("") }
          onInputChange={ editCommentChange }
          onInputKeyDown={ editCommentChange }
          onBlur={ editCommentChange }
          reset
          cols="18"
          maxLength={ 80 }
          minLength={ 0 }
          minRows={ 2 }
        />
      </IdentificationForm>
      {
        isApproveAction &&
        <SignInMessageContainer>
          <SignInMessageDiv>
            <OwcTypography variant="button">
              This action requires your additional sign in
            </OwcTypography>
          </SignInMessageDiv>
          <SignInMessageDivBody>
            <OwcTypography variant="body1">
              After clicking the “Sign in and Approve” button you will be redirected to the login page.
            </OwcTypography>
          </SignInMessageDivBody>
        </SignInMessageContainer>
      }
    </Fragment>
  );
};

export default ReviewReasonDialogContent;
