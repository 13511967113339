import * as types from "./actionTypes";

export const loadInstruments = (instruments) => ({
  type: types.LOAD_INSTRUMENTS,
  payload: instruments
});

export const loadStepFormList = (list) => ({
  type: types.LOAD_ALL_LISTS,
  payload: list ?? []
});

export const loadBuildingData = (buildings) => ({
  type: types.LOAD_BUILDING_LISTS,
  payload: buildings ?? []
});
export const loadRoomData = (rooms) => ({
  type: types.LOAD_ROOM_LISTS,
  payload: rooms ?? []
});
export const loadFloorData = (floors) => ({
  type: types.LOAD_FLOOR_LISTS,
  payload: floors ?? []
});
export const loadGroupData = (groups) => ({
  type: types.LOAD_GROUP_LISTS,
  payload: groups ?? []
});
export const loadReasonData = (reasons) => ({
  type: types.LOAD_REASON_LISTS,
  payload: reasons ?? []
});
export const loadLinkedInstanceData = (linkedInstance) => ({
  type: types.LOAD_LINKED_INSTANCE_LISTS,
  payload: linkedInstance ?? []
});
export const loadResponsiblePersonData = (responsiblePersons) => ({
  type: types.LOAD_RESPONSIBLE_PERSON_LISTS,
  payload: responsiblePersons ?? []
});

export const loadEquipmentAdministratorData = (equipmentAdministrator) => ({
  type: types.LOAD_EQUIPMENT_ADMINISTRATOR_LISTS,
  payload: equipmentAdministrator ?? []
});

export const updateInstrumentDetail = (payload) => ({
  type: types.UPDATE_INSTRUMENT_DETAIL,
  payload: payload
});

export const updateEditInstrumentDetail = (payload) => ({
  type: types.UPDATE_EDIT_INSTRUMENT_DETAIL,
  payload: payload
});

export const updateInstrumentFilter = (payload) => ({
  type: types.UPDATE_INSTRUMENT_FILTER,
  payload: payload
});
export const updateLimit = (payload) => ({
  type: types.UPDATE_LIMIT,
  payload: payload
});
export const updateNextToken = (payload) => ({
  type: types.UPDATE_NEXT_TOKEN,
  payload: payload
});
export const resetPageTokenArray = (payload) => ({
  type: types.RESET_PAGE_TOKEN_ARRAY,
  payload: payload
});
export const updatePageTokenArray = (payload) => ({
  type: types.UPDATE_PAGE_TOKEN_ARRAY,
  payload: payload
});
export const updateFilterApplied = (payload) => ({
  type: types.UPDATE_FILTER_APPLIED,
  payload: payload
});
export const updateSearchFilter = (payload) => ({
  type: types.UPDATE_SEARCH_FILTER,
  payload: payload
});
export const updateLoading = (payload) => ({
  type: types.UPDATE_LOADING,
  payload: payload
});
export const updateBuildingOnSite = (payload) => ({
  type: types.UPDATE_BUILDINGS_ON_SITE,
  payload: payload
});
export const updateFloorOnSite = (payload) => ({
  type: types.UPDATE_FLOOR_ON_SITE,
  payload: payload
});
export const updateRoomOnSite = (payload) => ({
  type: types.UPDATE_ROOM_ON_SITE,
  payload: payload
});
export const updateEditMasterDataDetail = (payload) => ({
  type: types.UPDATE_EDIT_MASTER_DATA_DETAIL,
  payload: payload
});
