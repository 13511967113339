import styled from "styled-components";
import { DetailsCellStyled } from "./DetailsCellStyled";

export const DetailsCellsWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${DetailsCellStyled} {
    margin-right: 40px;
    margin-bottom: 20px;
    width: 180px;
  }
`;

export const ReviewDetailsCellsWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${DetailsCellStyled} {
    margin-right: 40px;
    margin-bottom: 20px;
    width: 16%;
  }
`;
