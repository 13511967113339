import { DEFAULT_FILTER, DEFAULT_IR_PAGE_SIZE, SEARCH_PREFERENCE_LIST } from "../../../constants";
const initialState = {
  email: "",
  sites: [],
  userFilters: [],
  userDisplays: [],
  lastDisplayColumns: null,
  systemDisplays: {},
  lastFavFilter: DEFAULT_FILTER,
  lastFilter: {},
  lastPageSize: DEFAULT_IR_PAGE_SIZE,
  searchPreference: SEARCH_PREFERENCE_LIST,
  actionStatus: {},
  myLabFilter: {}
};

export default initialState;
