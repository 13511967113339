import { OwcButton, OwcIconButton, OwcListItem, OwcModalDialog, OwcSelectDropdown, OwcTypography } from "@one/react";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { ITEMS_LIST_VALUES, itemsList } from "./consts";

const ModalDialogContentWrapper = styled.div`
  padding: 1rem;
`;
const ModalDialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  .modal-dialog-header-item {
    align-self: center;
  }
`;

const ModalDialogContent = styled.div`
  margin: 1rem 0;
  .export-data-select-dropdown {
    width: 100%;
  }
`;

const ModalDialogContentLabel = styled.div`
  padding: 2rem 0 1rem 0;
`;

const ModalDialogFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

const ExportDataDialog = ({
  exportModalTitle,
  exportDataModalVisible,
  setDialogVisible,
  actionStatus,
  handleExportType,
  downloadBulkCSVDisable,
  exportDisable
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [helperText, setHelperText] = useState("First select an export type!");

  const getHelperText = (selectedValue) => {
    return itemsList.find((item) => item.value === selectedValue)?.description || helperText;
  };

  const getExportOptions = () => {
    return itemsList.filter((item) => item.accessLevel.includes(actionStatus?.hasRole)) || [];
  };

  useEffect(() => {
    setHelperText(getHelperText(selectedOption));
    // eslint-disable-next-line
  }, [selectedOption]);

  return (
    <>
      <OwcModalDialog
        onBackdropClick={() => {
          setDialogVisible(false);
        }}
        visible={exportDataModalVisible}
      >
        <ModalDialogContentWrapper>
          <ModalDialogHeader>
            <OwcTypography className="modal-dialog-header-item" variant="subtitle2">
              {exportModalTitle}
            </OwcTypography>
            <OwcIconButton
              flat
              icon="clear"
              className="modal-dialog-header-item"
              family="outlined"
              onClick={() => {
                setDialogVisible(false);
              }}
            />
          </ModalDialogHeader>
          <ModalDialogContent>
            <OwcSelectDropdown
              className="export-data-select-dropdown"
              placeholder="Select export type"
              onSelectChange={(event) => {
                const [index] = event.detail;
                setSelectedOption(getExportOptions()[index]?.value);
              }}
            >
              {getExportOptions().map((item) => {
                switch (item.value) {
                  case ITEMS_LIST_VALUES.BULK_CHANGE:
                    item.disabled = downloadBulkCSVDisable;
                    break;
                  case ITEMS_LIST_VALUES.CSV_EQUIPMENT:
                    item.disabled = exportDisable;
                    break;
                  default:
                    item.disabled = false;
                }
                return (
                  <OwcListItem key={item.value} value={item.value} disabled={item.disabled}>
                    {item.label}
                  </OwcListItem>
                );
              })}
            </OwcSelectDropdown>
            <ModalDialogContentLabel>
              <OwcTypography variant="subtitle2">Export data with the following criteria:</OwcTypography>
            </ModalDialogContentLabel>
            <OwcTypography variant="body2">{helperText}</OwcTypography>
          </ModalDialogContent>
          <ModalDialogFooter>
            <OwcButton
              variant="secondary"
              onClick={() => {
                setDialogVisible(false);
              }}
            >
              Cancel
            </OwcButton>
            <OwcButton
              variant="primary"
              onClick={() => {
                handleExportType(selectedOption);
                setDialogVisible(false);
              }}
              disabled={selectedOption === null}
            >
              Export
            </OwcButton>
          </ModalDialogFooter>
        </ModalDialogContentWrapper>
      </OwcModalDialog>
    </>
  );
};

ExportDataDialog.defaultProps = {
  exportModalTitle: "Export data",
  exportDataModalVisible: false,
  setDialogVisible: () => {},
  actionStatus: [],
  handleExportType: () => {},
  downloadBulkCSVDisable: true,
  exportDisable: true
};
export default ExportDataDialog;
