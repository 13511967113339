import Actions from "./Actions";

export const ReasonTableMeta = {
  fields: {
    configText: {
      text: "Reason",
      headProps: {},
      cellProps: {}
    },
    action: {
      component: Actions,
      headProps: {},
      cellProps: {}
    }
  }
};

export default ReasonTableMeta;
