import React, { useEffect } from "react";
import { DEFAULT_LIMIT } from "../../constants";
import styled from "styled-components";
import PaginationWrapper from "../../components/shared/pagination/PaginationWrapper";
import ExportCsvWapper from "./ExportCsvWapper";
import ExportCsvContent from "./ExportCsvContent";

const MainContainer = styled.div`
  padding: 0 20px 0 20px;
  margin-top: 0.25rem;
  width: 100%;
  box-sizing: border-box;
`;

const ExportCsvPage = ({ setIsInboxActive, isInboxActive, setInboxCount }) => {
  const initialValues = {
    fetching: false,
    prevTokens: [],
    nextToken: null,
    nextData: 0,
    limit: DEFAULT_LIMIT,
    data: [],
    sortDirection: "DESC",
    orderBy: "formattedDate",
    refreshData: 0
  };
  useEffect(() => {
    setIsInboxActive(false);
    setInboxCount(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainContainer>
      <ExportCsvWapper>
        <PaginationWrapper initialValues={initialValues}>
          <ExportCsvContent isInboxActive={isInboxActive} />
        </PaginationWrapper>
      </ExportCsvWapper>
    </MainContainer>
  );
};

export default ExportCsvPage;
