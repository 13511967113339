import React, { useState } from "react";

export const MasterDataContext = React.createContext({});

export const MasterDataWapper = ({ children }) => {
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedListItem, setSelectedListItem] = useState("");
  const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(true);
  return (
    <MasterDataContext.Provider
      value={{
        selectedModule,
        setSelectedModule,
        selectedListItem,
        setSelectedListItem,
        isActionButtonDisabled,
        setIsActionButtonDisabled
      }}
    >
      {children}
    </MasterDataContext.Provider>
  );
};

export default MasterDataWapper;
