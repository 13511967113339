import React, { useState, useEffect, useContext, useRef } from "react";
import { AddContainer, AddContainerBody } from "../addEditEquipment/AddEquipmentStyle";
import { Formik } from "formik";
import FormActions from "./FormActions";
import ClusterInformation from "./steps/ClusterInformation";
import AddEquipment from "./steps/AddEquipment";
import ClusterComponents from "./steps/ClusterComponents";
import styled from "styled-components";
import { ClusterInformationSchema } from "./validations/ClusterInformationSchema";
import { connect } from "react-redux";
import { compose, withApollo } from "react-apollo";
import {
  updateClusterDetail as updateClusterDetailAction,
  updateEditClusterDetail as updateEditClusterDetailAction
} from "./redux/actions";
import { MANAGE_CLUSTER } from "../../../gql/landingapi/mutations";
import Notify from "../../notifications/Notify";
import {
  CLUSTER_SUB_EQUIPMENT_MAX_SELECTED_LIMIT,
  CLUSTER_SUB_EQUIPMENT_MIN_SELECTED_LIMIT,
  emptyClusterInformation,
  SHOW_INSTRUMENT,
  MODIFY_CLUSTER,
  REMOVE_OTHER_FIELDS
} from "../../../constants";
import { changeDateFormat } from "../helpers";
import { loadReasonData as loadReasonDataAction } from "../redux/actions";
import { isEqual } from "lodash";
import omitDeep from "omit-deep-lodash";
import { SelectItemsContext } from "../../../components/context/contexts";
import { OwcExpandable, OwcExpandableGroup, OwcWorkflowOverlay } from "@one/react";
import ConfirmDialog from "../../../components/shared/ConfirmDialog";
import FormSteps from "../../../components/shared/FormSteps";
import useDialog from "../../../utils/hooks/useDialog";
import { getReasons } from "../LoadInstrumentsInfo";

export const AddContainerHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom: thin solid var(--one-color-gray-300);
  font-weight: 500;
`;
const ContentContainer = ({
  stepList,
  setStepList,
  clusterDetail,
  activeStep,
  setActiveStep,
  setActiveModule,
  updateClusterDetail,
  client,
  isEditMode,
  selectedItem,
  editClusterDetail,
  loadReasonData,
  setIsEditMode,
  updateEditClusterDetail,
  actionStatus,
  hasPermission,
  setHasPermission,
  activeModule,
  handleStepClick
}) => {
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [clusterEquipments, setClusterEquipments] = useState([]);
  const [isSubCluster, setIsSubCluster] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [clusteSubEquipments, setClusterSubEquipments] = useState([]);
  const [selectedCluster, setSelectedCluster] = useState("");
  const { updateSelectedItems } = useContext(SelectItemsContext);
  const { openDialog, ...dialogProps } = useDialog();
  const expandableGroupElement = useRef(null);
  const [expandID, setExpandID] = useState(0);

  const updateClusteSubEquipmentsObjs = (subEquipObjs) => {
    setClusterSubEquipments(subEquipObjs);
  };

  const getFormContentByStep = () => {
    switch (activeStep.id) {
      case 0:
        return (
          <ClusterInformation
            isNextDisabled={isNextDisabled}
            setIsNextDisabled={setIsNextDisabled}
            activeStep={activeStep}
            isEditMode={isEditMode}
            isSubCluster={isSubCluster}
          />
        );
      case 1:
        return (
          <AddEquipment
            setClusterEquipments={setClusterEquipments}
            isSubCluster={isSubCluster}
            clusterEquipments={clusterEquipments}
            subEquipments={selectedItem?.subEquipment?.items || []}
            isNextDisabled={isNextDisabled}
            setIsNextDisabled={setIsNextDisabled}
            isEditMode={isEditMode}
            setDisableSave={setDisableSave}
            hasPermission={hasPermission}
            setHasPermission={setHasPermission}
            actionStatus={actionStatus}
            activeModule={activeModule}
            clusteSubEquipments={clusteSubEquipments}
            selectedCluster={selectedCluster}
            updateClusteSubEquipmentsObjs={updateClusteSubEquipmentsObjs}
            client={client}
          />
        );
      case 2:
        return (
          <ClusterComponents
            clusteSubEquipments={clusteSubEquipments}
            updateClusteSubEquipmentsObjs={updateClusteSubEquipmentsObjs}
          />
        );
      default:
        return <ClusterInformation />;
    }
  };

  const nextButtonHandle = (formData) => {
    updateClusterDetail(formData);
    const newRowId = activeStep.id + 1;
    const activeRow = stepList.find((x) => x.id === newRowId);
    setActiveStep(activeRow);
  };
  const prevButtonHandle = () => {
    const newRowId = activeStep.id - 1;
    const activeRow = stepList.find((x) => x.id === newRowId);
    setActiveStep(activeRow);
  };
  const submitHandle = async (clusterData) => {
    setIsSubmitDisabled(true);

    if (clusterData?.buildingLocation) {
      clusterData.buildingLocation = {
        key: clusterData?.buildingLocation?.id,
        value: clusterData?.buildingLocation?.buildingNumber
      };
    }

    if (clusterData?.floor) {
      clusterData.floor = {
        key: clusterData?.floor?.id,
        value: clusterData?.floor?.floorCode
      };
    }

    if (clusterData?.room) {
      clusterData.room = {
        key: clusterData?.room?.id,
        value: clusterData?.room?.roomCode
      };
    }
    if (clusterData?.gxpRelevant) {
      clusterData.gxpRelevant = clusterData?.gxpRelevant?.key;
    }
    if (clusterData?.qualificationStatus) {
      clusterData.qualificationStatus = clusterData?.qualificationStatus?.key;
    }
    if (clusterData?.dateOfNextMaintanance) {
      clusterData.dateOfNextMaintanance = changeDateFormat(clusterData.dateOfNextMaintanance, "yyyy-MM-dd");
    }
    if (clusterData?.leadingSoftwareVersionId !== undefined && clusterData?.leadingSoftwareVersionId) {
      const tempSubEquipments = clusteSubEquipments;
      tempSubEquipments.map((x) => {
        x.isClusterSWVersion = x.id === clusterData?.leadingSoftwareVersionId;
        return x;
      });
      clusterData.subEquipments = tempSubEquipments;
    } else {
      const tempSubEquipments = clusteSubEquipments;
      clusterData.softwareVersion = null;
      tempSubEquipments.map((x) => {
        x.isClusterSWVersion = false;
        return x;
      });
      clusterData.subEquipments = tempSubEquipments;
    }
    if (clusterData?.tags && clusterData?.tags?.length > 0) {
      clusterData.tags.sort();
    }

    clusterData = omitDeep(clusterData, "leadingSoftwareVersionId");
    try {
      const result = await client.mutate({
        mutation: MANAGE_CLUSTER,
        variables: {
          cluster: clusterData
        },
        fetchPolicy: "no-cache"
      });
      if (result?.data?.manageCluster?.id) {
        Notify({
          type: "success",
          icon: "circle_confirm",
          appName: "",
          text: "Cluster created successfully!"
        });
        setIsSubmitDisabled(false);
        updateClusterDetail({ ...emptyClusterInformation });
        updateEditClusterDetail({});
        setActiveModule(SHOW_INSTRUMENT);
      } else {
        Notify({
          type: "warning",
          icon: "caution",
          appName: "",
          text: `Cluster created failed! `
        });
        setIsSubmitDisabled(false);
      }
    } catch (error) {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: `Cluster created failed! `
      });
      setIsSubmitDisabled(false);
    }
  };

  const updateHandle = async (clusterData) => {
    setDisableSave(true);
    let updateClusterData = { ...emptyClusterInformation };

    Object.keys(updateClusterData).map((key) => {
      if (key === "action") {
        updateClusterData[key] = "UPDATE";
      } else if (key === "subEquipments" && !clusterData.hasOwnProperty("subEquipments")) {
        updateClusterData[key] = clusteSubEquipments.map((val) => {
          return {
            id: val?.id,
            positionInCluster: val?.positionInCluster,
            isClusterSWVersion: val?.id === clusterData?.leadingSoftwareVersionId
          };
        });
      } else {
        updateClusterData[key] = clusterData[key];
      }
      return updateClusterData;
    });

    if (updateClusterData?.buildingLocation) {
      updateClusterData.buildingLocation = {
        key: updateClusterData?.buildingLocation?.id,
        value: updateClusterData?.buildingLocation?.buildingNumber
      };
    }

    if (updateClusterData?.floor) {
      updateClusterData.floor = {
        key: updateClusterData?.floor?.id,
        value: updateClusterData?.floor?.floorCode
      };
    }

    if (updateClusterData?.room) {
      updateClusterData.room = {
        key: updateClusterData?.room?.id,
        value: updateClusterData?.room?.roomCode
      };
    }
    if (updateClusterData?.gxpRelevant) {
      updateClusterData.gxpRelevant = updateClusterData?.gxpRelevant?.key;
    }
    if (updateClusterData?.qualificationStatus) {
      updateClusterData.qualificationStatus = updateClusterData?.qualificationStatus?.key;
    }
    if (updateClusterData?.dateOfNextMaintanance) {
      updateClusterData.dateOfNextMaintanance = changeDateFormat(updateClusterData.dateOfNextMaintanance, "yyyy-MM-dd");
    }
    updateClusterData.editReason = clusterData.configText;
    if (!clusterData.editComment) {
      updateClusterData.editComment = "";
    } else {
      updateClusterData.editComment = clusterData.editComment;
    }
    updateClusterData.id = clusterData.id;
    updateClusterData = omitDeep(updateClusterData, "leadingSoftwareVersionId");
    if (clusterData?.leadingSoftwareVersionId !== undefined && clusterData?.leadingSoftwareVersionId) {
      const tempSubEquipments = updateClusterData?.subEquipments;
      tempSubEquipments.map((x) => {
        x.isClusterSWVersion = x.id === clusterData?.leadingSoftwareVersionId;
        return x;
      });
      updateClusterData.subEquipments = tempSubEquipments;
    } else {
      updateClusterData.softwareVersion = null;
      const tempSubEquipments = updateClusterData?.subEquipments;
      tempSubEquipments.map((x) => {
        x.isClusterSWVersion = false;
        return x;
      });
      updateClusterData.subEquipments = tempSubEquipments;
    }
    if (updateClusterData?.tags && updateClusterData?.tags?.length > 0) {
      updateClusterData.tags.sort();
    } else {
      updateClusterData.tags = [];
    }

    //leadingSoftwareVersionId
    try {
      const result = await client.mutate({
        mutation: MANAGE_CLUSTER,
        variables: {
          cluster: updateClusterData
        },
        fetchPolicy: "no-cache"
      });
      if (result?.data?.manageCluster?.id) {
        Notify({
          type: "success",
          icon: "circle_confirm",
          appName: "",
          text: "Cluster updated successfully!"
        });

        setDisableSave(false);
        updateClusterDetail({ ...emptyClusterInformation });
        updateEditClusterDetail({});
        setActiveModule(SHOW_INSTRUMENT);
      } else {
        Notify({
          type: "warning",
          icon: "caution",
          appName: "",
          text: `Cluster updated failed! `
        });
        setDisableSave(false);
      }
    } catch (error) {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: `Cluster updated failed! `
      });
      setDisableSave(false);
    }
  };

  useEffect(() => {
    for (let index = 0; index < stepList.length; index++) {
      stepList[index].valid =
        stepList[index].id < activeStep.id ||
        (clusterEquipments.length >= CLUSTER_SUB_EQUIPMENT_MIN_SELECTED_LIMIT &&
          clusterEquipments.length <= CLUSTER_SUB_EQUIPMENT_MAX_SELECTED_LIMIT);
      stepList[index].completed =
        stepList[index].id < activeStep.id ||
        (clusterEquipments.length >= CLUSTER_SUB_EQUIPMENT_MIN_SELECTED_LIMIT &&
          clusterEquipments.length <= CLUSTER_SUB_EQUIPMENT_MAX_SELECTED_LIMIT);

      stepList[index].active = stepList[index].id === activeStep.id;
    }
    setStepList([...stepList]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  useEffect(() => {
    if (isEditMode) {
      for (let index = 0; index < stepList.length; index++) {
        stepList[index].valid = true;
        stepList[index].completed = true;
        stepList[index].active = stepList[index].id === activeStep.id;
      }
      setStepList([...stepList]);
      const subEquipmentItems = selectedItem?.subEquipment?.items;
      subEquipmentItems.sort((a, b) => a.positionInCluster - b.positionInCluster);

      setSelectedCluster(selectedItem?.id);
      setIsSubCluster(selectedItem?.clusterId);
      setClusterEquipments([...subEquipmentItems?.map((item) => item?.id)]);
      updateSelectedItems([...subEquipmentItems?.map((item) => item?.id)]);
      const temp = subEquipmentItems.map((x) => {
        return { id: x?.id, positionInCluster: x?.positionInCluster };
      });
      updateClusteSubEquipmentsObjs(temp);
      updateReasonsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateReasonsData = async () => {
    const reasonData = await getReasons(client);
    loadReasonData(reasonData);
  };
  useEffect(() => {
    //validation of subEquipment count of enable/disable save button step1
    if (isEditMode) {
      let clusterDetailWithOut = { ...clusterDetail };
      clusterDetailWithOut = omitDeep(clusterDetailWithOut, REMOVE_OTHER_FIELDS);
      let editClusterDetailWithout = { ...editClusterDetail };
      editClusterDetailWithout = omitDeep(editClusterDetailWithout, REMOVE_OTHER_FIELDS);
      let buildingRoomFloorProperty = false;
      if (clusterDetail.buildingLocation !== null && clusterDetail.floor !== null && clusterDetail.room !== null) {
        let clusterDetailLocation = {
          id: clusterDetail.buildingLocation.id,
          floor: clusterDetail.floor.id,
          room: clusterDetail.room.id
        };
        let editClusterDetailLocation = {
          id: editClusterDetail.buildingLocation.id,
          floor: editClusterDetail.floor.id,
          room: editClusterDetail.room.id
        };
        buildingRoomFloorProperty = isEqual(clusterDetailLocation, editClusterDetailLocation);
      }

      let dateObjectComparison = true;

      if (clusterDetail?.dateOfNextMaintanance !== null && editClusterDetail?.dateOfNextMaintanance !== null) {
        dateObjectComparison =
          changeDateFormat(clusterDetail?.dateOfNextMaintanance, "yyyy-MM-dd") ===
          editClusterDetail?.dateOfNextMaintanance;
      } else if (clusterDetail?.dateOfNextMaintanance !== null && editClusterDetail?.dateOfNextMaintanance === null) {
        dateObjectComparison = false;
      }
      setDisableSave(
        isEqual(clusterDetailWithOut, editClusterDetailWithout) && buildingRoomFloorProperty && dateObjectComparison
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clusterDetail]);

  const visibleChangeHandler = (event) => {
    /* Cancel popup implementation */
    if (event?.target?.className === "owcoverayZIndex title has-title") {
      openDialog();
    }
  };

  const expandedElementsChangedHandler = (event) => {
    setExpandID(() => event?.detail[0]);
  };

  return (
    <>
      <OwcWorkflowOverlay
        className="owcoverayZIndex"
        visible={activeModule === MODIFY_CLUSTER}
        onVisibleChange={visibleChangeHandler}
        disableBackdropClick
      >
        <Formik validationSchema={ClusterInformationSchema} initialValues={{ ...clusterDetail }} isInitialValid={true}>
          <>
            <div slot="title">{isEditMode ? "Edit cluster" : "Add cluster"}</div>
            <div
              slot="content"
              style={{
                padding: "0",
                display: "block",
                marginBottom: "0",
                height: "100%"
              }}
            >
              {!isEditMode && <FormSteps stepsArray={stepList} handleClick={handleStepClick} isEditMode={isEditMode} />}
              <AddContainer id="add-edit-cluster-container">
                {isEditMode ? (
                  <OwcExpandableGroup
                    ref={expandableGroupElement}
                    onExpandedElementsChanged={expandedElementsChangedHandler}
                    // multiple
                  >
                    <OwcExpandable variant="standard" round expanded={expandID === 0}>
                      <span slot="title">Cluster information</span>
                      {expandID === 0 && (
                        <span
                          slot="content"
                          style={{
                            height: "fit-content"
                          }}
                        >
                          <ClusterInformation
                            isNextDisabled={isNextDisabled}
                            setIsNextDisabled={setIsNextDisabled}
                            activeStep={activeStep}
                            isEditMode={isEditMode}
                            isSubCluster={isSubCluster}
                          />
                        </span>
                      )}
                    </OwcExpandable>
                    <OwcExpandable variant="standard" round expanded={expandID === 1}>
                      <span slot="title">Update components</span>
                      {expandID === 1 && (
                        <span
                          slot="content"
                          style={{
                            height: "fit-content"
                          }}
                        >
                          <AddEquipment
                            setClusterEquipments={setClusterEquipments}
                            isSubCluster={isSubCluster}
                            clusterEquipments={clusterEquipments}
                            subEquipments={selectedItem?.subEquipment?.items || []}
                            isNextDisabled={isNextDisabled}
                            setIsNextDisabled={setIsNextDisabled}
                            isEditMode={isEditMode}
                            setDisableSave={setDisableSave}
                            hasPermission={hasPermission}
                            setHasPermission={setHasPermission}
                            actionStatus={actionStatus}
                            activeModule={activeModule}
                            clusteSubEquipments={clusteSubEquipments}
                            selectedCluster={selectedCluster}
                            updateClusteSubEquipmentsObjs={updateClusteSubEquipmentsObjs}
                            client={client}
                          />
                        </span>
                      )}
                    </OwcExpandable>
                    <OwcExpandable variant="standard" round expanded={expandID === 2}>
                      <span slot="title">Cluster components</span>
                      {expandID === 2 && (
                        <span
                          slot="content"
                          style={{
                            height: "fit-content"
                          }}
                        >
                          <ClusterComponents
                            clusteSubEquipments={clusteSubEquipments}
                            updateClusteSubEquipmentsObjs={updateClusteSubEquipmentsObjs}
                            // expandID={expandID}
                            // previousValue={previousValue}
                          />
                        </span>
                      )}
                    </OwcExpandable>
                  </OwcExpandableGroup>
                ) : (
                  <AddContainerBody id="add-edit-cluster-body">{getFormContentByStep()}</AddContainerBody>
                )}
              </AddContainer>
              <ConfirmDialog
                {...dialogProps}
                approveText="Yes"
                approveColor="primary"
                approveVariant="contained"
                cancelText="No"
                cancelVariant="outlined"
                cancelColor="primary"
                onApprove={() => {
                  updateClusterDetail({ ...emptyClusterInformation });
                  updateEditClusterDetail({});
                  setActiveModule(SHOW_INSTRUMENT);
                }}
                title="Cancel cluster"
                content="Do you want to cancel it?"
                disableBackdropClick={true}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                height: "calc(100% - 120px)",
                backgroundColor: "var(--one-color-interaction-hover-brand-3)"
              }}
            >
              <div
                style={{
                  width: "60%",
                  margin: "auto",
                  height: "calc(100% - 60px)",
                  backgroundColor: "var(--one-color-interaction-disabled-base-1)"
                }}
              ></div>
            </div>
            <div slot="actions" style={{ padding: "16px" }}>
              <FormActions
                stepList={stepList}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                isNextDisabled={isNextDisabled}
                setIsNextDisabled={setIsNextDisabled}
                prevButtonHandle={prevButtonHandle}
                nextButtonHandle={nextButtonHandle}
                submitHandle={submitHandle}
                cancelHandle={openDialog}
                isSubmitDisabled={isSubmitDisabled}
                isEditMode={isEditMode}
                updateHandle={updateHandle}
                disableSave={disableSave}
                setDisableSave={setDisableSave}
                clusterEquipments={clusterEquipments}
                clusteSubEquipments={clusteSubEquipments}
              ></FormActions>
            </div>
          </>
        </Formik>
      </OwcWorkflowOverlay>
    </>
  );
};

const mapStateToProps = (state) => ({
  clusterDetail: state?.clusters?.clusterDetail,
  editClusterDetail: state?.clusters?.editClusterDetail
});

export default compose(
  connect(mapStateToProps, {
    updateEditClusterDetail: updateEditClusterDetailAction,
    updateClusterDetail: updateClusterDetailAction,
    loadReasonData: loadReasonDataAction
  }),
  withApollo
)(ContentContainer);
